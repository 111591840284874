<!-- ALL funds table-->
<div class="w-100">
  <div class="significant-raise table-card h-100">
      <!-- tabs -->
      <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
          <div class="pl-2 large-heading">
              <h2>All funds</h2>
          </div>
          <div>
              <tab-horisontal [tabSelected]="currentTab" (tabSelectedChange)="selectedTabChanged($event)" [tabs]="pageTabs" class="tab-nowrap"></tab-horisontal>
          </div>
      </div>

      <!-- tabs -->

      <!--tables-->
      <div class="mt-4" [ngSwitch]="currentTab">
          <!-- overview -->
          <div *ngSwitchCase="'Overview'">
              <overview-table showclass="true"></overview-table>
          </div>
          <!-- /overview -->

          <!-- Price and performance -->
          <div *ngSwitchCase="'Price and performance'">
              <div>
                <price-and-performance-table showclass="true"> </price-and-performance-table>
              </div>
          </div>
          <!-- /Price and performance -->
      </div>
      <!--tables-->
  </div>
</div>
<!-- ALL funds table-->
