import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  shareToFb() {
    window.open("https://www.facebook.com/sharer/sharer.php?u=" + window.location.href);
  }

  shareToTwitter() {
    window.open("https://twitter.com/intent/tweet?text=" + window.location.href);
  }

  shareToLinkedIn() {
    window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href);
  }

  shareToMail(subject: string) {
    window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=` + window.location.href);
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }

  redirectTo(url: string, target: string) {
    window.open(url.toLowerCase(), target);
  }

  convertToSlug(text) {
    return text.toLowerCase().replace(/[^\w -]+/g, '').replace(/ +/g, '-');
  }

}
