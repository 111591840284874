import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StockbrokingLandingPage } from 'src/app/models/content/save-and-invest/stockbroking-landing-page.model';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-stockbroking-options',
  templateUrl: './stockbroking-options.component.html',
})
export class StockbrokingOptionsComponent implements OnInit {

  public content: any = {};

  public pageTabs = [
    "Local shares",
    "Offshore shares",
    "Single stock futures",
    "Contracts for difference",
    "Scriptfin",
    "Currency futures",
    "Exchange traded products"
  ];

  public currentTab = "Local shares";

  constructor(private pageService: PageService, private router: Router) {
    pageService
      .GetPage<StockbrokingLandingPage>(pageService.URIs.stockbroking)
      .subscribe((content) => {
        this.content = new StockbrokingLandingPage(content);
      });
}

public DynamicButtonClick(action: any) {
  if (action.isExternal) {
    window.open(action.url, "_blank");
  } else {
    this.router.navigateByUrl(action.url);
  }
}

  ngOnInit() {
  }

}
