import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PSGInsurePage } from "src/app/models/content/about-us/psg-insure.model";
import { PageService } from "src/app/services/page.service";
import { DOCUMENT } from "@angular/common";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";
@Component({
  selector: "app-about-psg-insure",
  templateUrl: "./about-psg-insure.component.html",
})
export class AboutPsgInsureComponent implements OnInit, AfterViewInit {
  public content: any = {};
  public fragment: string;
  public index: any;
  public teamMember: any;
  public isPageLoading: boolean = true;

  constructor(
    public pageService: PageService,
    private router: Router,
    private route: ActivatedRoute,
    public seoService: SeoService,
    @Inject(DOCUMENT) document,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<PSGInsurePage>(pageService.URIs.aboutUsPSGInsure)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new PSGInsurePage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });

    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
    });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  public openTeamMemberModal(member) {
    this.teamMember = member;
  }

  ngAfterViewInit() {
    this.scrollTimer();
  }

  public async scrollTimer() {
    setTimeout(() => {
      let doc = document.getElementById(this.fragment);
      if (doc != undefined) {
        window.scrollTo(0, doc.offsetTop - 120);
      } else {
        this.scrollTimer();
      }
    }, 1000);
  }
}
