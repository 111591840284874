import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styles: [],
})
export class HeroComponent implements OnInit {
  @Input("content") content: any;

  constructor(private router: Router) { }

  ngOnInit() { }

  public saveForGoal() {
    window.open("/save-and-invest/goal-calculator", "_blank");
  }

  public saveForEducation() {
    window.open("/save-and-invest/education-calculator", "_blank");
  }

  public saveForRetirement() {
    window.open("/save-and-invest/retirement-calculator", "_blank");
  }

  goToLink() {
    window.open("/save-and-invest/retirement-calculator", "_blank");
  }

  public NavigateToOptions() {
    this.router.navigateByUrl("options");
  }

  public NavigateToFindAdviser() {
    this.router.navigateByUrl("find-an-adviser");
  }

  DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

}
