export class EconomicCalendarPage {
  header: any = {};
  body: any = {};

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    if (this.header.content)
      this.header.content = this.header.content.replace(
        /<p>/g,
        '<p class="p-length-sm text-shadow">'
      );

    let count: number = 0;
    this.body.events.forEach((event) => {
      this.body.events[count].content = this.body.events[count].content.replace(
        /<ol>/g,
        '<ol style="list-style: none; list-style-type: none;">'
      );
      this.body.events[count].content = this.body.events[count].content.replace(
        /<li>/g,
        "<br />"
      );
      count++;
    });
  }
}
