import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { FAQPage } from "src/app/models/content/support/faq.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: [],
})
export class FaqComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<FAQPage>(pageService.URIs.faQ)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new FAQPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
