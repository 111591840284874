import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InsuranceForYourThingsPage } from 'src/app/models/content/insurance/for-your-things.model';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-value-add-insurance-cover',
  templateUrl: './value-add-insurance-cover.component.html',
  styles: []
})
export class ValueAddInsuranceCoverComponent implements OnInit {

  public content: any = {};

  constructor(private pageService: PageService, private router: Router) {
    pageService.GetPage<InsuranceForYourThingsPage>(pageService.URIs.insuranceForYourThings).subscribe(content => {
      this.content = new InsuranceForYourThingsPage(content);
    });
  }

  public DynamicButtonClick(action: any) {
   if (action.isExternal) {
     window.open(action.url, "_blank");
   } else {
     this.router.navigateByUrl(action.url);
   }
  }

  ngOnInit() {
  }

}
