<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  class="container-fluid research-insights--hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="d-flex flex-column align-content-start justify-content-md-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1 class="mega-title--page-title text-shadow">
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>

<div class="container-xl research-insights--card-row">
  <div class="row d-flex">
    <div class="col-md-12">
      <div class="research-insights-hero-card">
        <h3
          *ngIf="content?.header?.headerBannerContent.length > 0"
          class="medium-heading cyan"
        >
          {{ content?.header?.headerBannerContent[0].heading }}
        </h3>
        <p>{{ content?.header?.headerBannerContent[0].headline }}</p>
        <div
          [innerHTML]="content?.header?.headerBannerContent[0].content"
          class="research-insights-hero-card-content"
        ></div>
      </div>
    </div>
  </div>
</div>

<app-latest-reports
  [latestReports]="latestReports"
  [keywords]="latestReportsKeywords"
>
</app-latest-reports>

<div class="container-fluid mt-5 pb-5 greybg-right h-100">
  <div class="container-xl">
    <div class="col-12">
      <div class="heading-border-right">
        <h2 class="section-title--mobile text-right">
          {{ content?.body?.sections[1]?.heading }}
        </h2>
      </div>
    </div>
  </div>
  <div class="container-xl py-3 my-3 h-100">
    <div class="row mb-3" *ngIf="content?.body?.sections[1].cards.length > 0">
      <div class="card-deck mb-3">
        <div
          class="card news--card"
          *ngFor="let card of content?.body?.sections[1].cards"
        >
          <div class="card-body d-flex flex-column">
            <h5 class="small-heading mb-3">
              {{ card.heading }}
            </h5>
            <p>{{ card.subheading }}</p>
            <p class="body-small" [innerHTML]="card.content"></p>
            <div class="text-center">
              <img
                *ngIf="card?.image != null"
                class="rounded-circle"
                width="100px"
                height="100px"
                [src]="card?.image"
                alt="icon"
              />
            </div>
            <div class="btn-row mt-auto">
              <button-text
                *ngFor="let action of card.actions"
                class="mr-2"
                [buttonStyle]="action.isPrimary ? 'solid' : 'outline'"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl py-5">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>
