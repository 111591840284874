<div class="row no-gutters align-items-center">

    <!-- tabs left side desktop -->
    <div class="col-md-auto d-none d-md-block">
        <tab-vertical [(tabSelected)]="currentTab" [tabs]="pageTabs" class=""></tab-vertical>
    </div>

    <!-- tabs top mobile -->
    <div class="col-md-12 mb-2 d-block d-md-none">
        <div class="tabScroll overflow-auto">
            <tab-horisontal [(tabSelected)]="currentTab" [tabs]="pageTabs" class="tab-nowrap-space blurEdge">
            </tab-horisontal>
        </div>
    </div>
    <!-- /tabs top mobile -->

    <div class="col-md-9" [ngSwitch]="currentTab">
        <!-- Local shares -->
        <div class="row no-gutters" *ngSwitchCase="'Local shares'">

            <div class="col-md-4 px-1">
                <img [src]="content?.body?.sections[2]?.items[0]?.image" class="img-cover-stockb slight-raise" />
            </div>

            <div class="col-md-8 px-1">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[2]?.items[0]?.heading}}</h2>
                    <p [innerHTML]="content?.body?.sections[2]?.items[0]?.content"></p>
                    <div>
                      <ng-container *ngFor="let action of content?.body?.sections[2]?.items[0]?.actions">
                        <button-text
                            class="{{action.isPrimary ? '': 'mr-2'}}"
                            buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action?.label"
                            (click)="DynamicButtonClick(action)">
                        </button-text>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Local shares -->

        <!-- Offshore shares -->
        <div class="row no-gutters" *ngSwitchCase="'Offshore shares'">

            <div class="col-md-4 px-1">
                <img [src]="content?.body?.sections[2]?.items[1]?.image" class="img-cover-stockb slight-raise" />
            </div>

            <div class="col-md-8 px-1">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[2]?.items[1]?.heading}}</h2>
                    <p [innerHTML]="content?.body?.sections[2]?.items[1]?.content"></p>
                    <div>
                      <ng-container *ngFor="let action of content?.body?.sections[2]?.items[1]?.actions">
                        <button-text
                            class="{{action.isPrimary ? '': 'mr-2'}}"
                            buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action?.label"
                            (click)="DynamicButtonClick(action)">
                        </button-text>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Offshore shares -->

        <!-- Single stock futures -->
        <div class="row no-gutters" *ngSwitchCase="'Single stock futures'">

            <div class="col-md-4 px-1">
                <img [src]="content?.body?.sections[2]?.items[2]?.image" class="img-cover-stockb slight-raise" />
            </div>

            <div class="col-md-8 px-1">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[2]?.items[2]?.heading}}</h2>
                    <p [innerHTML]="content?.body?.sections[2]?.items[2]?.content"></p>
                    <div>
                      <ng-container *ngFor="let action of content?.body?.sections[2]?.items[2]?.actions">
                        <button-text
                            class="{{action.isPrimary ? '': 'mr-2'}}"
                            buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action?.label"
                            (click)="DynamicButtonClick(action)">
                        </button-text>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Single stock futures -->

        <!-- Contracts for difference -->
        <div class="row no-gutters" *ngSwitchCase="'Contracts for difference'">

            <div class="col-md-4 px-1">
                <img [src]="content?.body?.sections[2]?.items[3]?.image" class="img-cover-stockb slight-raise" />
            </div>

            <div class="col-md-8 px-1">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[2]?.items[3]?.heading}}</h2>
                    <p [innerHTML]="content?.body?.sections[2]?.items[3]?.content"></p>
                    <div>
                      <ng-container *ngFor="let action of content?.body?.sections[2]?.items[3]?.actions">
                        <button-text
                            class="{{action.isPrimary ? '': 'mr-2'}}"
                            buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action?.label"
                            (click)="DynamicButtonClick(action)">
                        </button-text>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Contracts for difference -->

        <!-- Scriptfin -->
        <div class="row no-gutters" *ngSwitchCase="'Scriptfin'">

            <div class="col-md-4 px-1">
                <img [src]="content?.body?.sections[2]?.items[4]?.image" class="img-cover-stockb slight-raise" />
            </div>

            <div class="col-md-8 px-1">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[2]?.items[4]?.heading}}</h2>
                    <p [innerHTML]="content?.body?.sections[2]?.items[4]?.content"></p>
                    <div>
                      <ng-container *ngFor="let action of content?.body?.sections[2]?.items[4]?.actions">
                        <button-text
                            class="{{action.isPrimary ? '': 'mr-2'}}"
                            buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action?.label"
                            (click)="DynamicButtonClick(action)">
                        </button-text>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Scriptfin -->

        <!-- Currency futures -->
        <div class="row no-gutters" *ngSwitchCase="'Currency futures'">

            <div class="col-md-4 px-1">
                <img [src]="content?.body?.sections[2]?.items[5]?.image" class="img-cover-stockb slight-raise" />
            </div>

            <div class="col-md-8 px-1">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[2]?.items[5]?.heading}}</h2>
                    <p [innerHTML]="content?.body?.sections[2]?.items[5]?.content"></p>
         
                    <a class="cyan" href="tel:{{content?.body?.sections[2]?.items[5]?.contact[0]?.contactNumber}}">{{content?.body?.sections[2]?.items[5]?.contact[0]?.contactNumber}}</a> <span> or 
                    <a  class="cyan">{{content?.body?.sections[2]?.items[5]?.contact[0]?.emailAddress}}</a></span>
                    <div>
                      <ng-container *ngFor="let action of content?.body?.sections[2]?.items[5]?.actions">
                        <button-text
                            class="{{action.isPrimary ? '': 'mr-2'}}"
                            buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action?.label"
                            (click)="DynamicButtonClick(action)">
                        </button-text>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Currency futures -->

        <!-- Exchange traded products -->
        <div class="row no-gutters" *ngSwitchCase="'Exchange traded products'">

            <div class="col-md-4 px-1">
                <img [src]="content?.body?.sections[2]?.items[6]?.image" class="img-cover-stockb slight-raise" />
            </div>

            <div class="col-md-8 px-1">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[2]?.items[6]?.heading}}</h2>
                    <p [innerHTML]="content?.body?.sections[2]?.items[6]?.content"></p>
                    <div>
                      <ng-container *ngFor="let action of content?.body?.sections[2]?.items[6]?.actions">
                        <button-text
                            class="{{action.isPrimary ? '': 'mr-2'}}"
                            buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action?.label"
                            (click)="DynamicButtonClick(action)">
                        </button-text>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Exchange traded products -->


    </div>
</div>
