<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  class="container-fluid campaign-hero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left col-11">
        <h1
          class="mega-title--mobile campaignHeading text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
        <h3
          class="mt-4 large-heading text-shadow pl-2"
          [ngClass]="
            content?.header?.subheadingColour
              ? content?.header?.subheadingColour
              : 'white'
          "
        >
          {{ content?.header?.subheading }}
          <!-- - Mars van der Colff -->
        </h3>
      </div>
    </div>
  </div>
</div>
<!-- /hero section -->

<!-- single card -->
<div *ngIf="!isPageLoading" class="container-xl campaign-hero--cards">
  <div class="row justify-content-around">
    <div class="col-lg-12">
      <div class="blur-card" *ngFor="let card of content?.header?.cards">
        <p
          class="large-heading p-lg-4 p-md-3 text-center largeQuote"
          [innerHTML]="card?.content"
        ></p>
      </div>
    </div>
  </div>
</div>
<!-- /single cards -->

<!--sections-->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5">
  <div class="container-xl h-100">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-12" *ngFor="let content of content?.body?.sections">
        <div class="mb-5 mt-5" *ngFor="let sectionContent of content?.sections">
          <div [innerHTML]="sectionContent?.content"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--/sections-->
