import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-careers',
  templateUrl: './lead-careers.component.html'
})
export class LeadCareersComponent implements OnInit {

  url = 'https://forms.office.com/r/jFSY1UgwWv';

  constructor() { }

  ngOnInit() {
  }

}
