export class BecomeAnAdviserPage {
    header: any = {};
    body: any = {};

    constructor(init: any = {}) {
        for (const key of Object.keys(init)) {
            this[key] = init[key];
        }

        if (this.header.content)
            this.header.content = this.header.content.replace(/<p>/g, '<p class="p-length-sm text-shadow">');

        if (this.body.sections != null && this.body.sections.length > 0) {
            this.body.sections[0].sections[0].content = this.body.sections[0].sections[0].content.replace(/<ul>/g, '<ul class="checkbox-list">');
            this.body.sections[1].sections[0].content = this.body.sections[1].sections[0].content.replace(/<ul>/g, '<ul class="checkbox-list">');
            this.body.sections[2].sections[0].content = this.body.sections[2].sections[0].content.replace(/<ul>/g, '<ul class="checkbox-list">');
        }
    }
}