<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="content.header !== undefined && !isPageLoading">
  <!-- hero section -->
  <div
    class="container-fluid falcon-hero"
    [style.backgroundImage]="'url(' + content?.header?.image + ')'"
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div class="heading-border-left col-11">
          <h1
            class="mega-title--mobile falconHeading text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>

        <div class="col-md-9 text-center mx-auto">
          <div
            class="d-flex flex-wrap align-items-center justify-content-center mx-auto"
          >
            <ng-container *ngFor="let action of content?.header?.actions">
              <button
                type="button"
                class="button mb-2"
                ngClass="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }} mr-2"
                (click)="DynamicButtonClick(action)"
                style="width: 240px"
              >
                {{ action?.label }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- single card -->
  <div class="container campaign-hero--cards">
    <div class="d-flex justify-content-center">
      <div class="blur-card">
        <p
          class="small-heading text-center"
          [innerHTML]="content?.header?.excerpt"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></p>
      </div>
    </div>
  </div>
  <!-- /single cards -->
  <!-- hero section -->

  <!-- products -->
  <div class="container">
    <div class="heading-border-left">
      <h3 class="mt-4 mb-2">{{ content?.header?.cardsHeading }}</h3>
    </div>
    <div class="row my-3">
      <div
        class="col-md-4 py-3"
        *ngFor="let card of content?.header?.cardsMoneyMarketFunds"
      >
        <div class="blur-card">
          <!-- product title -->
          <div class="heading-border-left mb-0">
            <h2>{{ card?.heading }}</h2>
          </div>

          <!-- cards -->
          <div
            class="d-flex flex-row"
            *ngIf="card?.investFrom && card?.monthlyDebitOrder"
          >
            <div
              class="d-flex flex-column align-items-start justify-content-center mt-2 pl-md-2"
            >
              <div class="card-round white-bg noticeable-raise p-4 mr-3">
                <p class="body-bold body-small text-center mb-1">Invest from</p>
                <h4 class="cyan text-center">{{ card?.investFrom }}</h4>
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-start justify-content-center mt-2 pl-md-2"
            >
              <div class="card-round white-bg noticeable-raise p-4">
                <p class="body-bold body-small text-center mb-1">
                  Monthly Debit order
                </p>
                <h4 class="cyan text-center">{{ card?.monthlyDebitOrder }}</h4>
              </div>
            </div>
          </div>

          <!-- product content -->
          <div class="mt-4 pl-md-2">
            <p class="body-med" [innerHTML]="card?.content"></p>
          </div>

          <!-- download link -->
          <div
            class="d-flex align-items-center pl-md-2 product-dl"
            *ngFor="let download of card?.downloads"
          >
            <icon-font
              name="icon-file-download"
              colour="cyan"
              class="icon-dl mr-2"
            >
            </icon-font>
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              *ngIf="download?.url"
              [href]="download?.url"
              >{{ download?.label }}</a
            >
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              [href]="download?.file"
              *ngIf="download?.file"
              >{{ download?.label }}</a
            >
          </div>

          <div class="row mt-3 pl-md-3">
            <div class="col-md-12 pl-md-2 btn-row">
              <ng-container *ngFor="let action of card?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }} mr-2"
                  [label]="action.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- products -->

  <!-- Enhanced Interest Fund of Funds -->
  <div class="container" *ngIf="enhancedInterestFofResponse?.length > 0">
    <h3 class="mt-4 mb-4">PSG Wealth Enhanced Interest FoF</h3>
    <table class="table table-bordered table-hover mb-5">
      <thead>
        <tr>
          <th class="align-middle">Date</th>
          <th class="align-middle">Share Class Legal Name</th>
          <th class="align-middle">Category</th>
          <th class="align-middle">Lead Days</th>
          <th class="align-middle">Average Annual Nominal Yield</th>
          <th class="align-middle">Annual Effective Yield</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of enhancedInterestFofResponse">
          <td>{{ item?.date }}</td>
          <td>{{ item?.shareClassLegalName }}</td>
          <td>{{ item?.category }}</td>
          <td>{{ item?.leadDays }}</td>
          <td>{{ item?.averageAnnualNominalYield }}</td>
          <td>{{ item?.annualEffectiveYield }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- table -->
  <div class="container">
    <div class="heading-border-left">
      <h3 class="mt-4 mb-2">{{ content?.body?.heading }}</h3>
    </div>
    <div [innerHTML]="content?.body?.excerpt"></div>
  </div>
  <!-- table -->

  <!-- image -->
  <div class="container mb-5">
    <h2 class="mt-4 mb-2">{{ content?.body?.subheading }}</h2>
    <img [src]="content?.body?.image" class="img-fluid" />
  </div>
  <!-- table -->
</div>
