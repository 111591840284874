<div class="loader-center-container full-height" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngIf="!isPageLoading">
  <!-- Header -->
  <div
    class="container-fluid news-page-hero"
    [style.backgroundImage]="'url(' + content?.header?.image + ')'"
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div class="heading-border-left">
          <h1
            class="mega-title--page-title news-page text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="ml-0">
          <div class="row pl-4">
            <div class="col-md-12">
              <h3
                class="mb-4 white text-shadow"
                [ngClass]="
                  content?.header?.subheadingColour
                    ? content?.header?.subheadingColour
                    : 'white'
                "
              >
                {{ content?.header?.subheading }}
              </h3>
              <div
                *ngFor="let action of content?.header?.actions"
                class="d-inline-block mb-2 mb-md-0"
              >
                <button-text
                  class="{{ action.isPrimary ? 'mr-2' : 'mr-2' }}"
                  buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                  [label]="action.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Featured -->
  <div class="container-fluid daliy-investment-card">
    <div class="container-xl">
      <div class="row">
        <div
          *ngIf="content?.header?.featuredArticles[0]"
          [ngClass]="
            content?.header?.featuredArticles[1] ? 'col-md-8' : 'col-md-12'
          "
          class="mb-3"
        >
          <div
            class="diu--card d-flex h-100"
            [style.backgroundImage]="
              'url(' + content?.header?.featuredArticles[0]?.image + ')'
            "
          >
            <div class="d-flex flex-column justify-content-end">
              <h1 class="white">
                {{ content?.header?.featuredArticles[0]?.headline }}
              </h1>
              <div class="mt-2">
                <button-text
                  buttonStyle="solid"
                  label="Read more"
                  (click)="goToURI(content?.header?.featuredArticles[0]?.uri)"
                ></button-text>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3" *ngIf="content?.header?.featuredArticles[1]">
          <div class="card news--card header-row">
            <img
              class="card-img-top"
              [src]="content?.header?.featuredArticles[1]?.image"
              alt="Card image cap"
            />
            <div class="card-body">
              <div class="card-tags pb-2">
                <span
                  *ngFor="
                    let category of content?.header?.featuredArticles[1]
                      ?.categories
                  "
                  role="button"
                  class="basic-badge"
                  data-toggle="modal"
                  data-target="#modal-article-tags"
                  (click)="
                    pageService.SetArticle(category); $event.preventDefault()
                  "
                >
                  {{ category }}
                </span>
              </div>
              <h5 class="small-heading">
                {{ content?.header?.featuredArticles[1]?.headline }}
              </h5>
              <p
                class="body-small"
                [innerHTML]="content?.header?.featuredArticles[1]?.excerpt"
              ></p>
              <a
                (click)="goToURI(content?.header?.featuredArticles[1].uri)"
                class="textlink"
                >Read more</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="archive">&nbsp;</div>

  <!-- Downloads -->
  <div class="container-xl py-2 my-2 py-md-3 my-md-3">
    <div
      class="row mt-5"
      *ngIf="content?.body?.formsAndFactsSheet[0].categories.length > 0"
    >
      <div class="col-12">
        <h3 class="small-heading mb-3 pl-1">Fact Sheets:</h3>
      </div>
      <div
        class="col-12 col-md-6"
        *ngFor="
          let category of content?.body?.formsAndFactsSheet[0].categories;
          let i = index
        "
      >
        <!-- acccordion -->
        <div
          class="accordion mb-5 mb-md-0"
          [id]="'downloads' + removeWhiteSpace(category.name)"
        >
          <div class="accordian-inner">
            <div [id]="'download' + removeWhiteSpace(category.name)">
              <h2 class="mb-0">
                <button
                  class="accordian-button"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapsedownload' + removeWhiteSpace(category.name)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapsedownload' + removeWhiteSpace(category.name)
                  "
                >
                  {{ category.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapsedownload' + removeWhiteSpace(category.name)"
              class=""
              [attr.aria-labelledby]="
                'download' + removeWhiteSpace(category.name)
              "
              [attr.data-parent]="
                '#downloads' + removeWhiteSpace(category.name)
              "
            >
              <div class="accordian-body">
                <ul class="p-0" *ngFor="let download of category.downloads">
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /acccordion -->
      </div>
      <div class="col-12">
        <h3 class="small-heading mt-5 mb-3 pl-1">
          {{ content?.body?.formsAndFactsSheet[1]?.name }}:
        </h3>
      </div>
      <div
        class="col-12 col-md-6"
        *ngFor="
          let category of content?.body?.formsAndFactsSheet[1].categories;
          let i = index
        "
      >
        <!-- acccordion -->
        <div
          class="accordion mb-5 mb-md-0"
          [id]="'downloadsArchive' + removeWhiteSpace(category.name)"
        >
          <div class="accordian-inner">
            <div [id]="'downloadArchive' + removeWhiteSpace(category.name)">
              <h2 class="mb-0">
                <button
                  class="accordian-button"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapsedownloadArchive' + removeWhiteSpace(category.name)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapsedownloadArchive' + removeWhiteSpace(category.name)
                  "
                >
                  {{ category.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapsedownloadArchive' + removeWhiteSpace(category.name)"
              class=""
              [attr.aria-labelledby]="
                'downloadArchive' + removeWhiteSpace(category.name)
              "
              [attr.data-parent]="
                '#downloadsArchive' + removeWhiteSpace(category.name)
              "
            >
              <div class="accordian-body">
                <ul class="p-0" *ngFor="let download of category.downloads">
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /acccordion -->
      </div>
    </div>
  </div>

  <!-- Recommended articles section -->
  <div class="container-fluid py-2 my-2 py-md-3 my-md-3">
    <div class="container-xl">
      <div>
        <h2 class="mb-4">{{ content?.body?.sections[0]?.heading }}</h2>
      </div>
      <div class="card-deck">
        <div
          class="card news--card"
          *ngFor="let article of content?.body?.sections[0]?.articles"
        >
          <img
            class="card-img-top"
            [src]="article?.image"
            alt="Card image cap"
          />
          <div class="card-body d-flex flex-column">
            <div class="card-tags pb-2">
              <span
                role="button"
                class="basic-badge"
                data-toggle="modal"
                *ngFor="let category of article?.categories"
                data-target="#modal-article-tags"
                (click)="
                  pageService.SetArticle(category); $event.preventDefault()
                "
                >{{ category }}</span
              >
            </div>
            <h5 class="small-heading">{{ article.headline }}</h5>
            <p class="body-small" [innerHTML]="article.excerpt"></p>
            <a (click)="goToURI(article.uri)" class="textlink mt-auto"
              >Read more</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
