import { Component, OnInit, Input, OnDestroy, Renderer2 } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { RouterModule } from "@angular/router";
import { LivingAnnuityPage } from "src/app/models/content/save-and-invest/living-annuity-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";
import { SEO_FOR_PAGES } from "src/app/models/SEO/seo-page-script";
@Component({
  selector: "app-retirement-products",
  templateUrl: "./retirement-products.component.html",
})
export class RetirementProductsComponent implements OnInit, OnDestroy {
  @Input() dataHash: string;
  public content: any = {};
  public isPageLoading: boolean = true;

  //dynamic script
  JSON_LD_DATA: string = SEO_FOR_PAGES.retirement_products_script;

  customOptions: OwlOptions = {
    loop: true,
    animateOut: "",
    animateIn: "",
    slideTransition: "ease-in-out",
    URLhashListener: true,
    startPosition: "URLHash",
    autoHeight: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="icon icon-angle-left"></i>',
      '<i class="icon icon-angle-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };

  constructor(
    private pageService: PageService,
    private route: ActivatedRoute,
    private router: Router,
    private RouterModule: RouterModule,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<LivingAnnuityPage>(pageService.URIs.livingAnnuity)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new LivingAnnuityPage(content);

        this.seoService.updateMetaInfoForPage(
          "Save and invest | PSG",
          window.location.href,
          content?.body?.products[0]?.image,
          "No taxes are payable on interest, dividends or capital gains earned from the underlying instruments of the investment. Income is paid after tax is deducted and paid over to SARS on your behalf."
        );
      });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, this.JSON_LD_DATA);
  }

  ngOnDestroy() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
