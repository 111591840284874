import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EmailTemplate } from "src/app/models/content/email/email-templates.model";
import { GoalCalculator } from "src/app/models/content/save-and-invest/goal-calculator.model";
import { SaveForAGoalComponent } from "src/app/models/content/save-and-invest/save-for-a-goal-component.model";
import { CalculatorService } from "src/app/services/calculator/calculator.service";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
declare var $: any;

@Component({
  selector: "app-goal-calculator",
  templateUrl: "./goal-calculator.component.html",
})
export class GoalCalculatorComponent implements OnInit {
  sendResults: boolean = false;
  public invalidYearsInvested: boolean = false;
  public resetFormClicked: boolean = false;
  public sendMeResultsForm: FormGroup;
  public goalCalculatorForm: FormGroup;
  public isInitLoading: boolean = true;
  private EmailTemp = new EmailTemplate();
  public lumbsum_selected = true;
  public content: any = {};
  public shareHeadline: string;

  //send email settings
  public contactUsResponseMsg: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private calculatorService: CalculatorService,
    private seoService: SeoService,
    private pageService: PageService,
    private renderer: Renderer2
  ) {
    this.comp = 1;
    this.BuildLeadForm();
    this.BuildsendMeResultsForm();

    pageService
      .GetPage<SaveForAGoalComponent>(pageService.URIs.saveForAGoal)
      .subscribe((content) => {
        this.content = new SaveForAGoalComponent(content);
        this.shareHeadline = this.content?.header?.heading?.replace("&", "%26");
        this.seoService.updateMetaInfoForPage(
          this.content?.seo?.title,
          window.location.href,
          this.content?.header?.image,
          this.content?.seo?.description
        );
      });
  }

  public toggleResultsForm() {
    if (!(this.final_results_saving_amount > 0)) return;
    this.sendResults = !this.sendResults;
  }

  ngOnInit() {
    this.setDefaultValues();
    this.seoService.setJsonLd(this.renderer, null);
  }

  public yearsInvested: number = 0;
  public investedYearsValue(years: number) {
    this.yearsInvested = years;
    if (this.yearsInvested > 0) this.invalidYearsInvested = false;
    else this.invalidYearsInvested = true;
  }

  //constant variable

  //email form group
  public emailForm = this.formBuilder.group({
    initials: ["", Validators.required],
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    emailAddress: ["", Validators.required],
    cellNumber: ["", Validators.required],
    idNumber: ["", Validators.required],
    message: ["", Validators.required],
  });

  //inputs

  public inc_rate: string; // NOT //How much will you increase your investment with each payment: (payment_increase_with)
  private comp: number;
  private irn: number;
  private r: number;
  public total_inv: number = 0;
  public total_inv_out: string = "0";
  private real_return: number;

  public final_results_saving_amount: number = 0;
  public final_results_saving_amount_out: string = "0";

  // investment pattern
  get inv_pattern_input() {
    return this.goalCalculatorForm.get("inv_pattern_input");
  }

  public triggerReturnsRadioChange(e) {
    let radioInputEl = e.target.parentElement.children.returns_in_value_input;
    radioInputEl.checked = true;

    this.goalCalculatorForm.patchValue({
      returns_in_value_input: radioInputEl.value,
    });
  }

  public triggerRateRadioChange(e) {
    let radioInputEl = e.target.parentElement.children.inc_rate_value_input;
    radioInputEl.checked = true;

    this.goalCalculatorForm.patchValue({
      inc_rate_value_input: radioInputEl.value,
    });
  }

  public onLumpsumChanged(e) {
    let target = e.target.parentElement.children.inv_pattern_input;
    target.checked = true;

    this.goalCalculatorForm.patchValue({
      inv_pattern_input: target.value,
    });

    if (target.value == "lumpsum") {
      this.lumbsum_selected = true;
    } else {
      this.lumbsum_selected = false;
    }
  }
  // today or future value
  get returns_in_value_input() {
    return this.goalCalculatorForm.get("returns_in_value_input");
  }

  // today or future value
  get inc_rate_value_input() {
    return this.goalCalculatorForm.get("inc_rate_value_input");
  }

  private commaSeparated(amount: number) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  public resetForm() {
    this.goalCalculatorForm.reset();
    this.setDefaultValues();
    this.investedYearsValue(0);
    this.invalidYearsInvested = false;
    this.resetFormClicked = true;
    this.final_results_saving_amount = 0;
    this.final_results_saving_amount_out = "0";
    this.total_inv = 0;
    this.total_inv_out = "0";
    this.sendMeResultsForm.reset();
    this.sendResults = false;
    this.lumbsum_selected = true;
  }

  private setDefaultValues() {
    this.inv_pattern_input.setValue("lumpsum", {
      onlySelf: true,
    });

    this.returns_in_value_input.setValue("today", {
      onlySelf: true,
    });

    this.inc_rate_value_input.setValue("yes", {
      onlySelf: true,
    });
  }

  private BuildLeadForm(): void {
    this.goalCalculatorForm = this.formBuilder.group({
      inv_pattern_input: ["", Validators.compose([Validators.required])],
      amount_input: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[1-9]\d*$/),
        ]),
      ],
      inc_rate_value_input: ["", Validators.compose([Validators.required])],
      returns_in_value_input: ["", Validators.compose([Validators.required])],
    });
  }

  private BuildsendMeResultsForm(): void {
    this.sendMeResultsForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      surname: [""],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]),
      ],
    });
  }

  private MarkLeadFormAsTouched(): void {
    this.goalCalculatorForm.controls.inv_pattern_input.markAsTouched();
    this.goalCalculatorForm.controls.amount_input.markAsTouched();
    this.goalCalculatorForm.controls.inc_rate_value_input.markAsTouched();
    this.goalCalculatorForm.controls.returns_in_value_input.markAsTouched();
  }

  // convenience getter for easy access to form fields
  get formValidation() {
    return this.goalCalculatorForm.controls;
  }

  get resultsFormValidation() {
    return this.sendMeResultsForm.controls;
  }

  public calculateSavings() {
    this.MarkLeadFormAsTouched();
    this.resetFormClicked = false;

    if (this.yearsInvested <= 0) {
      this.invalidYearsInvested = true;
      return;
    }

    if (this.goalCalculatorForm.invalid) return;

    let inc_rate_value: number = 0;
    let years: number = Number(this.yearsInvested);

    //property declaration
    let inv_returns: number = 0;

    //converting to a percentage

    let inv_pattern =
      this.goalCalculatorForm.controls["inv_pattern_input"].value;
    let amount = this.goalCalculatorForm.controls["amount_input"].value;
    this.inc_rate =
      this.goalCalculatorForm.controls["inc_rate_value_input"].value;
    let comp_time_value = GoalCalculator.compound;
    let returns_in_value =
      this.goalCalculatorForm.controls["returns_in_value_input"].value;

    let n: number = 1 / years;

    //increase per payment
    if (this.inc_rate === "yes") inc_rate_value = GoalCalculator.inflation;
    else inc_rate_value = 0;

    //fixed investment returns
    if (years >= 1 && years <= 2) {
      inv_returns = 0.06;
      this.real_return = 1;
    } //short-term returns

    if (years >= 3 && years <= 5) {
      inv_returns = 0.08;
      this.real_return = 3;
    } //medium-term returns

    if (years >= 6) {
      inv_returns = 0.1;
      this.real_return = 5;
    } //ong-term returns

    //compound factor for compound investment
    switch (comp_time_value) {
      case "monthly":
        this.comp = 12;
        break;
      case "daily":
        this.comp = 365.25;
        break;
      case "quarterly":
        this.comp = 4;
        break;
      case "yearly":
        this.comp = 1;
        break;
      default:
        this.comp = 1;
        break;
    }

    //returns allowing for inflation
    if (returns_in_value === "today") {
      this.irn = (1 + inv_returns) / (1 + GoalCalculator.inflation) - 1;
    } else this.irn = inv_returns;

    //returns if lump sum
    switch (inv_pattern) {
      case "lumpsum":
        this.final_results_saving_amount = Number(
          (
            amount * Math.pow(1 + this.irn / this.comp, this.comp * years)
          ).toFixed(2)
        );

        this.final_results_saving_amount_out = this.commaSeparated(
          this.final_results_saving_amount
        );
        break;
      case "weekly":
        n = 4;
        this.comp = this.comp / n;
        inc_rate_value = inc_rate_value / n;
        this.irn = this.irn / n;
        this.irn = Math.pow(1 + this.irn / this.comp, this.comp - 1); //check why irn defined twice
        this.final_results_saving_amount =
          (amount / (this.irn - inc_rate_value)) *
          (Math.pow(1 + this.irn, n * years) -
            Math.pow(1 + inc_rate_value, n * years)) *
          (1 + this.irn * GoalCalculator.T);
        this.final_results_saving_amount_out = this.commaSeparated(
          this.final_results_saving_amount
        );
        break;

      //returns if monthly
      case "monthly":
        n = 12;
        this.comp = this.comp / n;
        inc_rate_value = inc_rate_value / n;
        this.irn = this.irn / n;
        // this.irn = Math.pow((1 + this.irn) / this.comp, this.comp - 1);
        this.final_results_saving_amount = Number(
          (
            (amount / (this.irn - inc_rate_value)) *
            (Math.pow(1 + this.irn, n * years) -
              Math.pow(1 + inc_rate_value, n * years)) *
            (1 + this.irn * GoalCalculator.T)
          ).toFixed(2)
        );
        this.final_results_saving_amount_out = this.commaSeparated(
          this.final_results_saving_amount
        );
        break;

      //returns if quarterly
      case "quarterly":
        n = 4;
        this.comp = this.comp / n;
        inc_rate_value = inc_rate_value / n;
        this.irn = this.irn / n;
        this.irn = Math.pow(1 + this.irn / this.comp, this.comp - 1);
        this.final_results_saving_amount = Number(
          (
            (amount / (this.irn - inc_rate_value)) *
            (Math.pow(1 + this.irn, n * years) -
              Math.pow(1 + inc_rate_value, n * years)) *
            (1 + this.irn * GoalCalculator.T)
          ).toFixed(2)
        );
        this.final_results_saving_amount_out = this.commaSeparated(
          this.final_results_saving_amount
        );
        break;

      //returns if semi-annually
      case "semianually":
        n = 2;
        this.comp = this.comp / n;
        inc_rate_value = inc_rate_value / n;
        this.irn = this.irn / n;
        this.irn = Math.pow(1 + this.irn / this.comp, this.comp - 1);
        this.final_results_saving_amount =
          (amount / (this.irn - inc_rate_value)) *
          (Math.pow(1 + this.irn, n * years) -
            Math.pow(1 + inc_rate_value, n * years)) *
          (1 + this.irn * GoalCalculator.T);
        this.final_results_saving_amount_out = this.commaSeparated(
          this.final_results_saving_amount
        );
        break;

      //returns if yearly
      case "yearly":
        n = 1;
        this.comp = this.comp / n;
        inc_rate_value = inc_rate_value / n;
        this.irn = Math.pow(1 + this.irn / this.comp, this.comp) - 1;
        this.final_results_saving_amount = Number(
          (
            (amount / (this.irn - inc_rate_value)) *
            (Math.pow(1 + this.irn, years) -
              Math.pow(1 + inc_rate_value, years)) *
            (1 + this.irn * GoalCalculator.T)
          ).toFixed(2)
        );
        this.final_results_saving_amount_out = this.commaSeparated(
          this.final_results_saving_amount
        );
        break;
    }

    //
    if (inc_rate_value === 0) {
      this.total_inv = Number((amount * n * years).toFixed(2));
      this.total_inv_out = this.commaSeparated(this.total_inv);
    } else {
      this.r = 1 + inc_rate_value;
      this.total_inv = Number(
        ((amount * (1 - Math.pow(this.r, n * years))) / (1 - this.r)).toFixed(2)
      );

      this.total_inv_out = this.commaSeparated(this.total_inv);
    }

    //temp save results
    localStorage.setItem("inv_pattern_input", inv_pattern);
    localStorage.setItem("amount", this.commaSeparated(Number(amount)));
    localStorage.setItem("yearsInvested", this.yearsInvested.toString());
    localStorage.setItem(
      "returns_in_value",
      returns_in_value === "today" ? "today's value" : "nominal returns"
    );
    localStorage.setItem(
      "inc_rate",
      this.inc_rate === "yes"
        ? "increase your investment contributions in line with inflation"
        : "not increase"
    );
    localStorage.setItem("total_inv", this.commaSeparated(this.total_inv));

    switch (inv_pattern) {
      case "lumpsum":
        localStorage.setItem("inv_pattern_input", "Lump sum");
        break;
      case "yearly":
        localStorage.setItem("inv_pattern_input", "Yearly");
        break;
      case "monthly":
        localStorage.setItem("inv_pattern_input", "Monthly");
        break;
      default:
        localStorage.setItem("inv_pattern_input", "Monthly");
    }

    localStorage.setItem(
      "final_results_saving_amount",
      this.commaSeparated(this.final_results_saving_amount)
    );
    localStorage.setItem("inv_returns", (inv_returns * 100).toString());
    localStorage.setItem("real_return", this.real_return.toString());
  }

  private produceFaceBookTrackingEvent() {
    try {
      (window as any).fbq("track", "CompleteRegistration");
    } catch (err) {
      return;
    }
  }

  public sendMyResults() {
    this.MarksendMeResultsFormTouched();

    if (this.sendMeResultsForm.invalid) {
      return;
    }

    let firstName = this.sendMeResultsForm.controls["firstName"].value;
    let email = this.sendMeResultsForm.controls["email"].value;

    this.calculatorService
      .sendComms({
        Name: `${firstName} ${this.sendMeResultsForm.controls["surname"].value}`,
        Email: email,
        ContactNumber: '',
        Message: this.EmailTemp.getGoalCalTemplate(firstName),
        Subject: "View your calculation",
        ToAddresses: ["social.leads.reporting@psg.co.za", email],
      })
      .subscribe(
        (x) => {
          if (x) {
            this.contactUsResponseMsg = "Results successfully sent!";
            $("#result-modal-standard-sm").modal("show");
            this.produceFaceBookTrackingEvent();
          } else {
            this.contactUsResponseMsg = "Results not sent!";
            $("#result-modal-standard-sm").modal("show");
          }
          this.toggleResultsForm();
          this.sendMeResultsForm.reset();
        },
        (error) => {
          this.contactUsResponseMsg = "Results not sent!";
          $("#result-modal-standard-sm").modal("show");
        }
      );
    this.isInitLoading = false;
  }

  private MarksendMeResultsFormTouched(): void {
    this.sendMeResultsForm.controls.firstName.markAsTouched();
    this.sendMeResultsForm.controls.email.markAsTouched();
    this.sendMeResultsForm.controls.surname.markAsTouched();
  }
}
