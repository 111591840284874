import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LivingAnnuityPage } from 'src/app/models/content/save-and-invest/living-annuity-page.model';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: "app-living-annuity",
  templateUrl: "./living-annuity.component.html",
})
export class LivingAnnuityComponent {
  public content: any = {};

  constructor(private pageService: PageService, private router: Router) {
    pageService
      .GetPage<LivingAnnuityPage>(pageService.URIs.livingAnnuity)
      .subscribe((content) => {
        this.content = new LivingAnnuityPage(content);
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }
}
