<div class="container-fluid slides-scale">
    <div class="container-xl">

        <div class="row no-gutters">

            <!-- content left col -->
            <div class="col-md-6 order-2 order-md-1 py-3">

                <!-- product title -->
                <div class="heading-border-left mb-0">
                    <h1 class="section-title--product">{{content?.body?.products[2]?.name}}</h1>
                </div>

                <!-- cards -->
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column align-items-start justify-content-center mt-2 pl-md-2">
                        <div class="card-round white-bg noticeable-raise p-4 mr-3">
                            <p class="body-bold body-small text-center mb-1">Invest from</p>
                            <h4 class="cyan text-center">{{content?.body?.products[2]?.minimumLumpSum}}</h4>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-start justify-content-center mt-2 pl-md-2">
                        <div class="card-round white-bg noticeable-raise p-4">
                            <p class="body-bold body-small text-center mb-1">Monthly Debit order</p>
                            <h4 class="cyan text-center">{{content?.body?.products[2]?.monthlyDebitOrder}}</h4>
                        </div>
                    </div>
                </div>

                <!-- product content -->
                <div class="mt-4 pl-md-2">
                  <div [innerHTML]="content?.body?.products[2]?.content"></div>
                </div>

                <!-- download link -->
                <div class="d-flex align-items-center pl-md-2 product-dl" *ngFor="let download of content?.body?.products[2]?.downloads;">
                    <icon-font name="icon-file-download" colour="cyan" class="icon-dl mr-2">
                    </icon-font>
                     <a class="textlink body-med body-bold text-dl" target="_blank" *ngIf="content?.body?.products[2]?.downloads[0]?.url" [href]="content?.body?.products[2]?.downloads[0]?.url">{{content?.body?.products[2]?.downloads[0]?.label}}</a>
                    <a class="textlink body-med body-bold text-dl" target="_blank" [href]="content?.body?.products[2]?.downloads[0]?.file" *ngIf="content?.body?.products[2]?.downloads[0]?.file">{{content?.body?.products[2]?.downloads[0]?.label}}</a>
                   </div>


                <div class="row mt-3 pl-md-3">
                    <div class="col-md-12 pl-md-2 btn-row">
                        <ng-container *ngFor="let action of content?.body?.products[2]?.actions">
                          <button-text
                           class="{{action.isPrimary ? '': 'mr-2'}}"
                           buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action?.label"
                           (click)="DynamicButtonClick(action)">
                         </button-text>
                        </ng-container>
                    </div>

                </div>


            </div>


            <!-- image right col -->
            <div class="col-md-6 pl-md-5 order-1 order-md-2 d-none d-md-block">

                <img [src]="content?.body?.products[2]?.image"
                    class="img-cover prod-img significant-raise test-img" />

            </div>
        </div>



    </div>

</div>
