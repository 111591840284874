import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Observable } from "rxjs";
import { startWith, take, finalize } from "rxjs/operators";
import { BranchOfficePage } from "src/app/models/content/financial-advice/branch-office-page.model";
import { FindAdviserPage } from "src/app/models/content/financial-advice/find-adviser-page.model";
import { AdvisorService } from "src/app/services/advisor.service";
import { HelperService } from 'src/app/services/helper/helper.service';
import { PageService } from "src/app/services/page.service";
import { SearchService } from "src/app/services/search.service";
import { SeoService } from "src/app/services/socialLinks.service";
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: "app-adviser-profile",
  templateUrl: "./adviser-profile.component.html",
  styles: [],
})
export class AdviserProfileComponent implements OnInit {
  isLoadingOffice: boolean = false;
  public isLoading: boolean = false;
  public isPageLoading: boolean = true;
  public isInitLoading: boolean = true;
  private token: string;
  public advisor: any = {};
  public advisorDetail: any = {};
  public qualifications: Array<string>;

  // Lists
  public location: string = "";
  public cities: any = [];

  //City
  filteredCities: any[];

  public emailResponseMessage: string;

  public captchaResolved: boolean = false;

  public isWebLeadAllowed: boolean = false;

  public leadForm: FormGroup;
  public sendLeadObject: any = {};
  public content: FindAdviserPage = new FindAdviserPage({});

  //Lists
  public titles: any[] = [];
  public genders: any[] = [];

  // New Api
  public profileImage: any;
  public defualtImages: string;
  private imgElement: HTMLImageElement;
  public hasQualifications: boolean;
  private meta_description: string;
  private meta_title: string;
  public designations: string[];
  private isCFP: string = "CFP";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public advisorService: AdvisorService,
    private formBuilder: FormBuilder,
    public sanitizer: DomSanitizer,
    private pageService: PageService,
    private searchService: SearchService,
    public seoService: SeoService,
    private renderer: Renderer2,
    public helper: HelperService
  ) {

    this.route.paramMap.subscribe(params => {

      const personId = Number(params.get('id'));

      this.isPageLoading = true;
      this.hasQualifications = false;

      // New Person Details Api
      this.advisorService.getPersonDetails(personId).pipe(finalize(() => { this.isPageLoading = false; })).subscribe((advisorDetails) => {

        var advisorIndex = advisorDetails.data.data_arr.data.findIndex(
          (x) => x.person_id == personId
        );
        if (advisorIndex === -1) {
          this.router.navigateByUrl("/financial-advice/find-an-adviser");
          return;
        }
        this.advisorDetail = advisorDetails.data.data_arr.data[advisorIndex];

        // redirect to external website
        this.helper.redirectTo(`${environment.advisersUrl}/financial-advice/adviser-profile/${this.advisorDetail?.person_name}-${this.advisorDetail?.person_surname}`, '_self');
        return false;

        this.qualifications = (<string>(
          this.advisorDetail.person_qualifications
        )).split(";");

        if (
          this.advisorDetail.person_qualifications != "" &&
          this.advisorDetail.person_qualifications != "null"
        ) {
          this.qualifications = (<string>(
            this.advisorDetail.person_qualifications
          )).split(";");
        }
        this.advisor = this.advisorDetail;

        if (
          this.qualifications.length > 0 &&
          this.qualifications[0].toString() !== ""
        ) {
          if (this.qualifications[0] !== "null") {
            this.hasQualifications = true;
          }
        }

        let hasCFP = this.qualifications.filter(
          (x) => x.trim() == this.isCFP
        );

        if (hasCFP.length > 0 && this.hasQualifications) {
          let index = this.qualifications.indexOf(hasCFP[0]);
          this.qualifications.splice(index, 1);
          this.qualifications = [hasCFP[0], ...this.qualifications];
        }

        //check for designations
        this.designations = (<string>(
          this.advisorDetail.prof_designation.trim()
        )).split(" ");

        let hasCFPdesignation = this.designations.filter(
          (x) => x.trim() == this.isCFP
        );

        if (hasCFPdesignation.length > 0) {
          let index = this.designations.indexOf(this.isCFP);
          this.designations.splice(index, 1);
          this.designations = [`${this.isCFP} \u00AE`, ...this.designations];
          this.designations = this.designations.map((x) => {
            if (x === "CA") return `${x.trim()}(SA)`;
            return x;
          });
        }

        this.BuildLeadForm();

        this.meta_title = `${this.advisorDetail.person_name} ${this.advisorDetail.person_surname} ${this.advisorDetail.person_jobtitle} | PSG Adviser`;
        this.meta_description = `Hello I am ${this.advisorDetail.person_name
          } ${this.advisorDetail.person_surname
          }, do you need help with your financial planning? I am a PSG ${this.advisorDetail.person_jobtitle
          }, 
            I specialise in ${this.advisorDetail.discipline_name}${this.advisorDetail.secondary_disciplines.length > 0 ? ", " : ""
          }${this.advisorDetail.secondary_disciplines.join(
            ", "
          )}.Please feel free to contact me on ${this.advisorDetail.office_phone != ""
            ? `+${this.advisorDetail.office_phone}`
            : this.advisorDetail.person_phonecell
          } or email me on ${this.advisorDetail.person_emailexch}.`;

        this.imgElement = document.createElement("img");
        this.imgElement.id = "myImg";

        this.imgElement.alt = `(<h1> ${this.advisorDetail.person_name} ${this.advisorDetail.person_surname} PSG ${this.advisorDetail.person_jobtitle}</h1>)`;
      });

      // New Profile Images
      advisorService.GetProfileImage(personId).subscribe((imageDetails) => {
        if (imageDetails != null) {
          this.profileImage = imageDetails.data.data_arr.data.image_string;

          if (this.profileImage != null || this.profileImage != undefined) {
            this.profileImage = this.cleanURL(
              "url(data:image/png;base64," + this.profileImage + ")"
            );
          }
        }
        this.seoService.updateMetaInfoForPage(
          this.meta_title,
          window.location.href,
          this.profileImage,
          this.meta_description
        );
      });
      this.defualtImages = "url(../../../../assets/images/adviser-profile.svg)";
    });
  }

  public sanitizeJobTitle(jobtitle: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      jobtitle.replace("&amp;", "&")
    )["changingThisBreaksApplicationSecurity"];
  }

  public cleanURL(oldURL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustStyle(oldURL);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    var cities = this.cities.filter((city) =>
      city.name.toLowerCase().includes(filterValue)
    );
    return cities.map((x) => x.name);
  }

  public GetProductsFromAdvisor(advisor) {
    return JSON.parse(advisor.discipline);
  }

  public GetLocationFromAdvisor(advisor) {
    return JSON.parse(advisor.address);
  }

  public RemoveWhitespace(string: string): string {
    if (string != null || string != undefined) {
      return string.replace(/\s/g, "");
    }
  }

  public GoToLinkedIn() {
    window.open(this.advisor.person_linkedin, "_blank");
  }

  public OpenAdviserLeadDialog(advisor: any): void {
    this.advisorService.GetToken().subscribe((x) => {
      this.token = x;
      this.CheckAllowedWebLeads();

      this.GetTitles();
      this.GetGenders();
    });
  }

  public GetTitles() {
    this.advisorService.GetTitles(this.token).subscribe((x) => {
      this.titles = x;
    });
  }

  public GetGenders() {
    this.advisorService.GetGenders(this.token).subscribe((x) => {
      this.genders = x;
    });
  }

  public CheckAllowedWebLeads() {
    this.advisorService
      .CheckAllowedWebLeads(this.advisor.person_id, this.token)
      .subscribe((x) => {
        this.isWebLeadAllowed = x;

        this.isInitLoading = false;
      });
  }

  public resolved(captchaResponse: string) {
    this.captchaResolved = true;
  }

  public Save() {
    if (this.isLoading) return;
    this.MarkLeadFormAsTouched();
    if (this.leadForm.invalid || !this.captchaResolved) return;

    this.ExtractLeadForm();
    this.isLoading = true;
    this.advisorService
      .SendLead(this.sendLeadObject, this.token)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.BuildLeadForm();
          $("#modal-standard-sm").modal("hide");
          $("#email-response-modal").modal("show");
        })
      )
      .subscribe(
        (a) => {
          this.emailResponseMessage = "Email sent successfully";
        },
        (error) => {
          this.isLoading = false;
          if (error.code == "lead--post--leadexists")
            this.emailResponseMessage =
              "Email not sent, You have already requested an advisor.";
          else
            this.emailResponseMessage =
              "Email not sent, please try again later.";
        }
      );
    if (this.advisor.person_emailexch != "") {
      this.advisorService
        .sendComms({
          Name: `${this.sendLeadObject.per_title} ${this.sendLeadObject.per_initial} ${this.sendLeadObject.per_firstname} ${this.sendLeadObject.per_lastname}`,
          Email: this.sendLeadObject.per_email,
          ContactNumber: this.sendLeadObject.per_cellnr,
          Message: `ID Number: ${this.sendLeadObject.lead_idnr} \n Province: ${this.leadForm.controls.province.value} \n Closest City/Town: ${this.leadForm.controls.closestCityTown.value} \n Message: ${this.leadForm.controls.message.value}`,
          Subject: "Message Me",
          ToAddresses: [this.advisor.person_emailexch],
        })
        .subscribe();
    } else if (this.advisor.office_id != 0 && this.advisor.office_id != null) {
      this.advisorService
        .GetBranchOffice(this.advisor.office_id)
        .subscribe((branchOfficeResults) => {
          (<Array<any>>branchOfficeResults.data).forEach((office) => {
            if (office.office_email != "" && office.office_email != null) {
              this.advisorService
                .sendComms({
                  Name: `${this.sendLeadObject.per_title} ${this.sendLeadObject.per_initial} ${this.sendLeadObject.per_firstname} ${this.sendLeadObject.per_lastname}`,
                  Email: this.sendLeadObject.per_email,
                  ContactNumber: this.sendLeadObject.per_cellnr,
                  Message: `ID Number: ${this.sendLeadObject.lead_idnr} \n Province: ${this.leadForm.controls.province.value} \n Closest City/Town: ${this.leadForm.controls.closestCityTown.value} \n Message: ${this.leadForm.controls.message.value}`,
                  Subject: "Message Me",
                  ToAddresses: [office.office_email],
                })
                .subscribe();
            }
          });
        });
    } else {
      this.advisorService
        .sendComms({
          Name: `${this.sendLeadObject.per_title} ${this.sendLeadObject.per_initial} ${this.sendLeadObject.per_firstname} ${this.sendLeadObject.per_lastname}`,
          Email: this.sendLeadObject.per_email,
          ContactNumber: this.sendLeadObject.per_cellnr,
          Message: `ID Number: ${this.sendLeadObject.lead_idnr} \n Province: ${this.leadForm.controls.province.value} \n Closest City/Town: ${this.leadForm.controls.closestCityTown.value} \n Message: ${this.leadForm.controls.message.value}`,
          Subject: "Message Me",
          ToAddresses: ["social.leads.reporting@psg.co.za"],
        })
        .subscribe();
    }
    this.advisorService
      .sendComms({
        Name: `${this.sendLeadObject.per_title} ${this.sendLeadObject.per_initial} ${this.sendLeadObject.per_firstname} ${this.sendLeadObject.per_lastname}`,
        Email: this.sendLeadObject.per_email,
        ContactNumber: this.sendLeadObject.per_cellnr,
        Message: `ID Number: ${this.sendLeadObject.lead_idnr} \n Province: ${this.leadForm.controls.province.value} \n Closest City/Town: ${this.leadForm.controls.closestCityTown.value} \n Message: ${this.leadForm.controls.message.value}`,
        Subject: "Message Me",
        ToAddresses: ["social.leads.reporting@psg.co.za"],
      })
      .subscribe();
  }

  public getBranch(adviser: any) {

    this.isLoadingOffice = true;

    this.advisorService.setSelectedBranch(adviser.team_id);

    // get uri from a search on the team name
    this.searchService.GetSearch(adviser.team_name, "").subscribe((result: any) => {

      // if there is a result, get the last alias in the uri and navigate
      if (result.length > 0) {

        this.isLoadingOffice = false;

        const uri = result[0].uri;
        if (uri) {
          this.router.navigate([`branch-office/${uri.substring(uri.lastIndexOf("/") + 1, uri.length)}`]);
        } else {
          this.router.navigate([`branch-office/${adviser.web_team_name}`]);
        }

      } else {

        this.isLoadingOffice = false;
        this.router.navigate([`branch-office/${adviser.web_team_name}`]);

      }

    });
  }

  private MarkLeadFormAsTouched(): void {
    this.leadForm.controls.gender.markAsTouched();
    this.leadForm.controls.title.markAsTouched();
    this.leadForm.controls.initials.markAsTouched();
    this.leadForm.controls.firstName.markAsTouched();
    this.leadForm.controls.lastName.markAsTouched();
    this.leadForm.controls.emailAddress.markAsTouched();
    this.leadForm.controls.cellNumber.markAsTouched();
    this.leadForm.controls.idNumber.markAsTouched();
    this.leadForm.controls.province.markAsTouched();
    this.leadForm.controls.closestCityTown.markAsTouched();
    this.leadForm.controls.message.markAsTouched();
  }

  private ExtractLeadForm(): void {
    this.sendLeadObject.per_gender = this.leadForm.controls.gender.value;
    this.sendLeadObject.per_title = this.leadForm.controls.title.value;
    this.sendLeadObject.per_initial = this.leadForm.controls.initials.value;
    this.sendLeadObject.per_firstname = this.leadForm.controls.firstName.value;
    this.sendLeadObject.per_lastname = this.leadForm.controls.lastName.value;
    this.sendLeadObject.per_email = this.leadForm.controls.emailAddress.value;
    this.sendLeadObject.per_cellnr = this.leadForm.controls.cellNumber.value;
    this.sendLeadObject.lead_idnr = this.leadForm.controls.idNumber.value;

    this.sendLeadObject.message = `Province: ${this.leadForm.controls.province.value}
    City: ${this.leadForm.controls.closestCityTown.value}
    ${this.leadForm.controls.message.value}`;

    this.sendLeadObject.ibase_id = this.advisor.person_id;
  }

  private BuildLeadForm(): void {
    this.leadForm = this.formBuilder.group({
      gender: [0, Validators.compose([Validators.required])],
      title: ["", Validators.compose([Validators.required])],
      initials: ["", Validators.compose([Validators.required])],
      firstName: ["", Validators.compose([Validators.required])],
      lastName: ["", Validators.compose([Validators.required])],
      emailAddress: ["", Validators.compose([Validators.required])],
      cellNumber: ["", Validators.compose([Validators.required])],
      idNumber: [""],
      province: [""],
      closestCityTown: [""],
      message: [""],
    });

    this.leadForm.controls.closestCityTown.valueChanges
      .pipe(startWith(""))
      .subscribe((value) => {
        this.location = value.toString();
        this.filteredCities = this._filter(value.toString());
      });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
