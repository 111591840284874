import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-asset-management-client-bulk-investor',
  templateUrl: './lead-asset-management-client-bulk-investor.component.html'
})
export class LeadAssetManagementClientBulkInvestorComponent implements OnInit {

  url = 'https://forms.office.com/r/E52dvmTCmA';

  constructor() { }

  ngOnInit() {
  }

}
