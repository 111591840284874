export class ForYourEstatePage {
    header: any = {};
    body: any = {};

    constructor(init: any = {}) {
        for (const key of Object.keys(init)) {
            this[key] = init[key];
        }

        if (this.header.content)
            this.header.content = this.header.content.replace(/<p>/g, '<p class="p-length-md text-shadow">');


        if (this.header.cards != null && this.header.cards.length > 0) {
            for (let i = 0; i < this.header.cards.length; i++) {
                this.header.cards[i].content = this.header.cards[i].content.replace(/<ul>/g, '<ul class="dot-list">');
            }
        }

        if (this.body.sections != null && this.body.sections.length > 0) {
            for (let i = 0; i < this.body.sections.length; i++) {
                if (this.body.sections[i].cards != null && this.body.sections[i].cards.length > 0) {
                    for (let j = 0; j < this.body.sections[i].cards.length; j++) {
                        this.body.sections[i].cards[j].content = this.body.sections[i].cards[j].content.replace(/<h4>/g, '<h4 class="section-title cyan">');
                    }
                }
            }
        }
    }
}