import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout/default-layout.component';
import { StandaloneLayoutComponent } from './layouts/standalone-layout/standalone-layout/standalone-layout.component';

// home page
import { LandingPageComponent } from './components/landing-page/landing-page.component';

// contact us page
import { ContactUsComponent } from './components/contact-us/contact-us.component';

// save & invest
import { SaveAndInvestComponent } from './components/save-and-invest/save-and-invest.component';
import { SavingsComponent } from './components/save-and-invest/savings/savings.component';
import { CompareInvestmentProductsComponent } from './components/save-and-invest/compare-investment-products/compare-investment-products.component';
import { InvestmentProductsComponent } from './components/save-and-invest/investment-products/investment-products.component';
import { HowToInvestComponent } from './components/save-and-invest/how-to-invest/how-to-invest.component';
import { BusinessRetirementServicesComponent } from './components/save-and-invest/business-retirement-services/business-retirement-services.component';
import { RetirementPlanningComponent } from './components/save-and-invest/retirement-planning/retirement-planning.component';
import { RetirementProductsComponent } from './components/save-and-invest/retirement-products/retirement-products.component';
import { StockbrokingComponent } from './components/save-and-invest/stockbroking/stockbroking.component';
import { EducateYourselfComponent } from './components/save-and-invest/educate-yourself/educate-yourself.component';

// calculators
import { GoalCalculatorComponent } from './components/save-and-invest/goal-calculator/goal-calculator.component';
import { RetirementCalculatorComponent } from './components/save-and-invest/retirement-calculator/retirement-calculator.component';

// local & global funds
import { AllFundsComponent } from './components/fund-pages/all-funds/all-funds.component';
import { AllPsgFundsComponent } from './components/fund-pages/all-psg-funds/all-psg-funds.component';
import { PsgMultiManagedFundsComponent } from './components/fund-pages/psg-multi-managed-funds/psg-multi-managed-funds.component';
import { PsgSingleManagedFundsComponent } from './components/fund-pages/psg-single-managed-funds/psg-single-managed-funds.component';
import { MoneyMarketFundsComponent } from './components/fund-pages/money-market-funds/money-market-funds.component';

// financial advice
import { FinancialAdviceComponent } from './components/financial-advice/financial-advice.component';
import { AdviserProfileComponent } from './components/financial-advice/adviser-profile/adviser-profile.component';
import { FindAnAdviserComponent } from './components/financial-advice/find-an-adviser/find-an-adviser.component';
import { BranchOfficeComponent } from './components/financial-advice/branch-office/branch-office.component';
import { BranchOfficeNewsComponent } from './components/financial-advice/branch-office-news/branch-office-news.component';
import { AdviceForYourselfComponent } from './components/financial-advice/advice-for-yourself/advice-for-yourself.component';
import { AdviceForYourEstateComponent } from './components/financial-advice/advice-for-your-estate/advice-for-your-estate.component';
import { AdviceForYourEmployeesComponent } from './components/financial-advice/advice-for-your-employees/advice-for-your-employees.component';

// insurance
import { InsuranceComponent } from './components/insurance/insurance.component';
import { ForYourEmployeesComponent } from './components/insurance/for-your-employees/for-your-employees.component';
import { ForYourBusinessComponent } from './components/insurance/for-your-business/for-your-business.component';
import { ForYourselftComponent } from './components/insurance/for-yourselft/for-yourselft.component';
import { ForYourThingsComponent } from './components/insurance/for-your-things/for-your-things.component';
import { CompareInsuranceComponent } from './components/insurance/compare-insurance/compare-insurance.component';

// about us
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AboutPsgInsureComponent } from './components/about-us/about-psg-insure/about-psg-insure.component';
import { InvestorRelationsComponent } from './components/investor-relations/investor-relations.component';
import { InvestorInformationComponent } from './components/investor-relations/investor-information/investor-information.component';
import { SensAndShareDataComponent } from './components/investor-relations/sens-and-share-data/sens-and-share-data.component';
import { CorporateGovernanceComponent } from './components/investor-relations/corporate-governance/corporate-governance.component';
import { PsgWealthComponent } from './components/about-us/psg-wealth/psg-wealth.component';
import { PsgAssetManagementComponent } from './components/about-us/psg-asset-management/psg-asset-management.component';
import { AssetManagementNewsAndPublicationsComponent } from './components/about-us/psg-asset-management/news-and-publications/asset-management-news-and-publications/asset-management-news-and-publications.component';
import { AssetManagementFormsComponent } from './components/about-us/psg-asset-management/forms/asset-management-forms/asset-management-forms.component';
import { AssetManagementFactSheetsComponent } from './components/about-us/psg-asset-management/fact-sheets/asset-management-fact-sheets/asset-management-fact-sheets.component';
import { ArchiveComponent } from './components/about-us/archive/archive.component';
import { ESGComponent } from './components/about-us/psg-asset-management/esg/esg.component';

// careers
import { CareersComponent } from './components/careers/careers/careers.component';
import { GraduateProgrammeComponent } from './components/careers/graduate-programme/graduate-programme.component';
import { PartnerWithPsgComponent } from './components/careers/partner-with-psg/partner-with-psg.component';
import { BecomeAnAdviserComponent } from './components/careers/become-an-adviser/become-an-adviser.component';
import { EmployeeComponent } from './components/careers/employee/employee.component';

// news and publications
import { NewsAndPublicationsComponent } from './components/news-and-publications/news-and-publications.component';
import { EventsAndWebinarsComponent } from './components/news-and-publications/events-and-webinars/events-and-webinars.component';
import { NewsAndPublicationArticleComponent } from './components/news-and-publications/news-and-publication-article/news-and-publication-article.component';
import { DiuArticleComponent } from './components/news-and-publications/diu-article/diu-article.component';
import { DiuArticleStaticComponent } from './components/news-and-publications/diu-article-static/diu-article-static.component';
import { DIUVideoComponent } from './components/news-and-publications/diu-video/diu-video.component';
import { DiuEconomicCalenderComponent } from './components/news-and-publications/diu-economic-calender/diu-economic-calender.component';
import { ReportsComponent } from './components/news-and-publications/research-hub/reports/reports.component';
import { ThinkBigSouthAfricaComponent } from './components/news-and-publications/our-events/think-big-south-africa/think-big-south-africa.component';

// research hub
import { MarketsManualComponent } from './components/news-and-publications/research-hub/markets-manual/markets-manual.component';
import { WeekAheadComponent } from './components/news-and-publications/research-hub/week-ahead/week-ahead.component';
import { MacroEconomicInsightsComponent } from './components/news-and-publications/research-hub/macro-economic-insights/macro-economic-insights.component';
import { FundResearchComponent } from './components/news-and-publications/research-hub/fund-research/fund-research.component';
import { EquityResearchComponent } from './components/news-and-publications/research-hub/equity-research/equity-research.component';
import { InTheMediaComponent } from './components/news-and-publications/research-hub/in-the-media/in-the-media.component';
import { ResearchInsightsComponent } from './components/news-and-publications/research-hub/research-insights/research-insights.component';
import { SolutionInsightsComponent } from './components/news-and-publications/research-hub/solution-insights/solution-insights.component';
import { HouseViewEquityPortfoliosComponent } from './components/news-and-publications/research-hub/house-view-equity-portfolios/house-view-equity-portfolios.component';
import { FundOfFundsComponent } from './components/news-and-publications/research-hub/fund-of-funds/fund-of-funds.component';

// support
import { FaqComponent } from './components/support/faq/faq.component';
import { GlossaryComponent } from './components/support/glossary/glossary.component';
import { LegalComponent } from './components/support/legal/legal.component';
import { ReportsAndNoticesComponent } from './components/support/reports-and-notices/reports-and-notices.component';
import { FormsAndFactsSheetsComponent } from './components/support/forms-and-facts-sheets/forms-and-facts-sheets.component';
import { FinancialToolsComponent } from './components/support/financial-tools/financial-tools.component';

// search
import { SearchComponent } from './components/search/search.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SensArticlesComponent } from './components/investor-relations/send-articles/sens-articles.component';

// campaigns
import { CampaignV1Component } from './components/campaign-pages/campaign-v1/campaign-v1.component';
import { CampaignV2Component } from './components/campaign-pages/campaign-v2/campaign-v2.component';
import { FalconComponent } from './components/campaign-pages/falcon/falcon.component';
import { EducationCalculatorComponent } from './components/save-and-invest/education-calculator/education-calculator.component';
import { InvestmentCalculatorsComponent } from './components/save-and-invest/investment-calculators/investment-calculators.component';

// start your journey
import { StartYourJourneyComponent } from './components/start-your-journey/start-your-journey/start-your-journey.component';

// lead forms
import { LeadInsuranceCallComponent } from './components/shared/lead-forms/lead-insurance-call/lead-insurance-call.component';
import { LeadInsuranceQuoteComponent } from './components/shared/lead-forms/lead-insurance-quote/lead-insurance-quote.component';
import { LeadClientThinkBigComponent } from './components/shared/lead-forms/lead-client-think-big/lead-client-think-big.component';
import { LeadAdviserComponent } from './components/shared/lead-forms/lead-adviser/lead-adviser.component';
import { LeadClientComponent } from './components/shared/lead-forms/lead-client/lead-client.component';
import { LeadAssetManagementClientComponent } from './components/shared/lead-forms/lead-asset-management-client/lead-asset-management-client.component';
import { LeadAssetManagementClientAdviserComponent } from './components/shared/lead-forms/lead-asset-management-client-adviser/lead-asset-management-client-adviser.component';
import { LeadAssetManagementClientBulkInvestorComponent } from './components/shared/lead-forms/lead-asset-management-client-bulk-investor/lead-asset-management-client-bulk-investor.component';
import { LeadContactMeComponent } from './components/shared/lead-forms/lead-contact-me/lead-contact-me.component';
import { LeadCareersComponent } from './components/shared/lead-forms/lead-careers/lead-careers.component';
import { LeadClientServicesComponent } from './components/shared/lead-forms/lead-client-services/lead-client-services.component';
import { NotFoundComponent } from './components/shared/not-found/not-found.component';
import { LeadSubscriptionsComponent } from './components/shared/lead-forms/lead-subscriptions/lead-subscriptions.component';

const routes: Routes = [

  // root
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  // default layouts
  {
    path: '', component: DefaultLayoutComponent,
    children: [
      // home
      { path: 'home', component: LandingPageComponent },

      // financial advice
      {
        path: 'financial-advice', component: StandaloneLayoutComponent,
        children: [
          { path: '', component: FinancialAdviceComponent },
          { path: 'for-yourself', component: AdviceForYourselfComponent },
          { path: 'for-your-estate', component: AdviceForYourEstateComponent },
          { path: 'for-your-employees', component: AdviceForYourEmployeesComponent },
          { path: 'adviser-profile/:id', component: AdviserProfileComponent },
          { path: 'find-an-adviser', component: FindAnAdviserComponent },
        ]
      },

      // branch office
      { path: 'branch-office/:branchname', component: BranchOfficeComponent },
      { path: 'branch-office-news', component: BranchOfficeNewsComponent },

      // save & invest
      {
        path: 'save-and-invest', component: StandaloneLayoutComponent,
        children: [
          { path: '', component: SaveAndInvestComponent },
          { path: 'savings', component: SavingsComponent },
          { path: 'compare-investment-products', component: CompareInvestmentProductsComponent },
          { path: 'investment-products', component: InvestmentProductsComponent },
          { path: 'how-to-invest', component: HowToInvestComponent },
          { path: 'retirement-fund-services', component: BusinessRetirementServicesComponent },
          { path: 'retirement-planning', component: RetirementPlanningComponent },
          { path: 'retirement-products', component: RetirementProductsComponent },
          { path: 'stockbroking', component: StockbrokingComponent },
          { path: 'educate-yourself', component: EducateYourselfComponent },
          { path: 'psg-cash-account', component: MoneyMarketFundsComponent },
        ]
      },

      // insurance
      {
        path: 'insurance', component: StandaloneLayoutComponent,
        children: [
          { path: '', component: InsuranceComponent },
          { path: 'for-your-employees', component: ForYourEmployeesComponent },
          { path: 'for-your-business', component: ForYourBusinessComponent },
          { path: 'for-yourself', component: ForYourselftComponent },
          { path: 'for-your-things', component: ForYourThingsComponent },
          { path: 'compare-insurance-products', component: CompareInsuranceComponent },
        ]
      },

      // about us
      {
        path: 'about-us', component: StandaloneLayoutComponent,
        children: [
          { path: '', component: AboutUsComponent },
          { path: 'psg-insure', component: AboutPsgInsureComponent },
          { path: 'psg-wealth', component: PsgWealthComponent },
          { path: 'archive', component: ArchiveComponent },
          { path: 'psg-asset-management/articles/:newsArticle', component: NewsAndPublicationArticleComponent },
        ]
      },

      // investor relations
      {
        path: 'investor-relations', component: StandaloneLayoutComponent,
        children: [
          { path: '', component: InvestorRelationsComponent },
          { path: 'overview', component: InvestorRelationsComponent },
          { path: 'investor-information', component: InvestorInformationComponent },
          { path: 'corporate-governance-and-responsibility', component: CorporateGovernanceComponent },
          { path: 'sens-and-share-data', component: SensAndShareDataComponent },
          { path: 'sens-articles', component: SensArticlesComponent },
        ]
      },

      // asset management
      {
        path: 'asset-management', component: StandaloneLayoutComponent,
        children: [
          { path: '', component: PsgAssetManagementComponent },
          { path: 'about-us', component: PsgAssetManagementComponent },
          { path: 'esg-investment-philosophy', component: ESGComponent },
          { path: 'news-and-publications', component: AssetManagementNewsAndPublicationsComponent },
          { path: 'forms', component: AssetManagementFormsComponent },
          { path: 'fact-sheets', component: AssetManagementFactSheetsComponent },
        ]
      },

      // news and publications
      {
        path: 'news-and-publications', component: StandaloneLayoutComponent,
        children: [
          { path: '', component: NewsAndPublicationsComponent },
          { path: 'daily-investment-updates', component: DiuArticleComponent },
          { path: 'daily-investment-updates/:newsArticle', component: DiuArticleComponent },
          { path: 'articles/insure/:newsArticle', component: NewsAndPublicationArticleComponent },
          { path: 'articles/asset-management/:newsArticle', component: NewsAndPublicationArticleComponent },
          { path: 'articles/wealth/:newsArticle', component: NewsAndPublicationArticleComponent },
          { path: 'articles/feature/:newsArticle', component: NewsAndPublicationArticleComponent },
          { path: 'events-and-webinars', component: EventsAndWebinarsComponent },
          { path: 'economic-calendar', component: DiuEconomicCalenderComponent },
          // { path: "research-hub/markets-manual/reports/:report", component: ReportsComponent },
          { path: 'our-events/think-big-south-africa', component: ThinkBigSouthAfricaComponent },
        ]
      },
      { path: 'article', component: NewsAndPublicationArticleComponent },
      { path: 'forms-and-Facts-sheets', component: FormsAndFactsSheetsComponent },
      { path: 'daily-investment-update', component: DiuArticleComponent },
      { path: 'wealthonline', component: DiuArticleStaticComponent },
      { path: 'daily-investment-update-video', component: DIUVideoComponent },

      // careers
      {
        path: 'careers', component: StandaloneLayoutComponent,
        children: [
          { path: '', component: CareersComponent },
          { path: 'graduate-programme', component: GraduateProgrammeComponent },
          { path: 'partner-with-psg', component: PartnerWithPsgComponent },
          { path: 'become-an-adviser', component: BecomeAnAdviserComponent },
        ]
      },
      // { path: "life-at-psg", component: EmployeeComponent },

      // research hub
      // {
      //   path: 'research-hub', component: StandaloneLayoutComponent,
      //   children: [
      //     { path: '', component: MarketsManualComponent },
      //     { path: "week-ahead", component: WeekAheadComponent },
      //     { path: "macroeconomic-research", component: MacroEconomicInsightsComponent },
      //     { path: "fund-research", component: FundResearchComponent },
      //     { path: "equity-research", component: EquityResearchComponent },
      //     { path: "in-the-media", component: InTheMediaComponent },
      //     { path: "research-insights", component: ResearchInsightsComponent },
      //     { path: "solution-insights", component: SolutionInsightsComponent },
      //     { path: "house-view-equity-portfolios", component: HouseViewEquityPortfoliosComponent },
      //     { path: "fund-of-funds", component: FundOfFundsComponent },
      //   ]
      // },

      // contact us
      { path: 'contact-us', component: ContactUsComponent },

      // calculators
      {
        path: 'save-and-invest', component: StandaloneLayoutComponent,
        children: [
          { path: 'goal-calculator', component: GoalCalculatorComponent },
          { path: 'retirement-calculator', component: RetirementCalculatorComponent },
          { path: 'education-calculator', component: EducationCalculatorComponent },
        ]
      },

      // local & global funds
      { path: 'all-funds', component: AllFundsComponent },
      { path: 'all-psg-funds', component: AllPsgFundsComponent },
      { path: 'psg-multi-managed-funds', component: PsgMultiManagedFundsComponent },
      { path: 'psg-single-managed-funds', component: PsgSingleManagedFundsComponent },

      // support
      {
        path: 'support', component: StandaloneLayoutComponent,
        children: [
          { path: 'forms-and-Facts-sheets', component: FormsAndFactsSheetsComponent },
          { path: 'financial-tools', component: FinancialToolsComponent },
          { path: 'glossary', component: GlossaryComponent },
          { path: 'legal', component: LegalComponent },
          { path: 'faq', component: FaqComponent },
        ]
      },

      // search
      { path: 'search', component: SearchComponent },
      { path: 'search-results', component: SearchResultsComponent },

      // investment calculators campaign
      { path: 'savings', component: InvestmentCalculatorsComponent },

      // campaign
      { path: 'George-Super-Kitchen', component: CampaignV1Component },
      { path: 'bigger-picture', component: FalconComponent },
      { path: 'smartboy', component: CampaignV2Component },

      // other
      { path: 'glossary', component: GlossaryComponent },
      { path: 'reports-and-notices', component: ReportsAndNoticesComponent },

      // redirects
      { path: 'asset-management', redirectTo: 'asset-management/about-us', pathMatch: 'full' },
      { path: 'about-us/psg-asset-management', redirectTo: 'asset-management/about-us', pathMatch: 'full' },
      { path: 'about-us/psg-asset-management/esg-investment-philosophy', redirectTo: 'asset-management/esg-investment-philosophy', pathMatch: 'full' },
      { path: 'think-big-sa-competition', redirectTo: 'news-and-publications/our-events/think-big-south-africa', pathMatch: 'full' },

      // psg online
      // { path: 'getting-started', component: StartYourJourneyComponent },

      // not found
      { path: '404', component: NotFoundComponent },


    ]
  },

  // standalone layouts
  {
    path: 'insurance', component: StandaloneLayoutComponent,
    children: [
      { path: 'lead-call-me', component: LeadInsuranceCallComponent },
      { path: 'lead-quote', component: LeadInsuranceQuoteComponent },
    ]
  },
  {
    path: 'lead', component: StandaloneLayoutComponent,
    children: [
      { path: '', component: LeadClientThinkBigComponent },
      { path: 'adviser', component: LeadAdviserComponent },
    ]
  },
  { path: '', component: StandaloneLayoutComponent, children: [{ path: 'lead-form', component: LeadClientComponent }] },
  {
    path: 'asset-management', component: StandaloneLayoutComponent,
    children: [
      { path: 'lead/individual', component: LeadAssetManagementClientComponent },
      { path: 'lead/adviser', component: LeadAssetManagementClientAdviserComponent },
      { path: 'lead/bulk-investor', component: LeadAssetManagementClientBulkInvestorComponent },
    ]
  },
  { path: '', component: StandaloneLayoutComponent, children: [{ path: 'client-service', component: LeadContactMeComponent }] },
  { path: 'query', component: StandaloneLayoutComponent, children: [{ path: 'client-services', component: LeadClientServicesComponent }] },
  { path: 'careers', component: StandaloneLayoutComponent, children: [{ path: 'query', component: LeadCareersComponent }] },
  { path: 'email', component: StandaloneLayoutComponent, children: [{ path: 'subscriptions', component: LeadSubscriptionsComponent }] },

  // not found
  { path: '**', redirectTo: '404', pathMatch: 'full' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollOffset: [0, 132],
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
