import { Injectable } from '@angular/core';
import jsonRedirectsFile from '../../../assets/files/external-redirects.json';

@Injectable({
  providedIn: 'root'
})
export class ExternalRedirectsService {

  urlsToRedirect: any = jsonRedirectsFile;

  constructor() { }

  checkIfCurrentUrlExists(currentUrl: string) {
    return this.urlsToRedirect.filter((alias: { from: string; }) => alias.from === currentUrl)[0];
  }

}
