<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  class="container-fluid campaign-hero-GSK"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left col-11">
        <h1
          class="mega-title--page-title campaignHeading text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
        <h3
          class="mt-4 large-heading text-shadow pl-2"
          [ngClass]="
            content?.header?.subheadingColour
              ? content?.header?.subheadingColour
              : 'white'
          "
        >
          {{ content?.header?.subheading }}
          <!-- - Mars van der Colff -->
        </h3>
      </div>
    </div>
  </div>
</div>
<!-- /hero section -->

<!-- single card -->
<div *ngIf="!isPageLoading" class="container-xl campaign-hero--cards">
  <div class="row justify-content-around">
    <div class="col-lg-12">
      <div class="blur-card" *ngFor="let card of content?.header?.cards">
        <p
          class="large-heading p-lg-4 p-md-3 text-center largeQuote"
          [innerHTML]="card?.content"
        ></p>
      </div>
    </div>
  </div>
</div>
<!-- /single cards -->

<!-- video section-->
<div class="container-xl" *ngFor="let videoContent of content?.body?.sections">
  <div class="mt-5" *ngFor="let video of videoContent?.videos">
    <!--social icons-->
    <div class="row d-flex justify-content-end mb-2">
      <div class="p-2">
        <button class="button simple-icon" (click)="shareToMail()">
          <icon-font
            name="icon-envelope"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToLinkedIn()">
          <icon-font
            name="icon-linkedin"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToTwitter()">
          <icon-font
            name="icon-twitter"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToFb()">
          <icon-font
            name="icon-facebook"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToWhatsApp()">
          <icon-font
            name="icon-whatsapp"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
      </div>
    </div>
    <!--social icons-->

    <!-- video -->
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div
          class="embed-responsive embed-responsive-16by9 card-round significant-raise"
        >
          <iframe
            width="520"
            height="270"
            [src]="video?.url | safe"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <!-- video -->
  </div>
</div>
<!-- /video section -->

<!-- content-row -->
<div *ngIf="!isPageLoading" class="container-fluid bg-left p-0 h-100">
  <div
    class="container-xl my-5 py-5 h-100"
    *ngFor="let content of content?.body?.sections"
  >
    <div class="row" *ngFor="let contentData of content?.sections">
      <div class="col-lg-5 col-md-6 py-4">
        <h2 class="large-heading">
          {{ contentData?.heading }}
        </h2>
        <h4 class="small-heading mb-2">
          {{ contentData?.subheading }}
        </h4>
        <p [innerHTML]="contentData?.content"></p>
      </div>

      <div class="col-lg-6 offset-lg-1 col-md-6 position-relative">
        <div class="blue-line col-md-5 d-none d-lg-block"></div>
        <img [src]="content?.image" class="img-cover card-round" />
      </div>
    </div>
  </div>
</div>
<!-- /content-row -->

<div class="container-xl mt-5">
  <div class="col-6">
    <button-text
      buttonStyle="solid"
      label="Contact Us"
      [routerLink]="['/contact-us']"
    >
    </button-text>
  </div>
</div>
