import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { BaseService } from "./base/base.service";
import { SettingsService } from "./base/settings.service";
import { LoggerService } from "./base/logger.service";
import { DailyInvestmentUpdatesArticle } from "../models/dailyInvestmentUpdateArticle.model";
import { AllArticle } from "../models/allArticle.model";

@Injectable()
export class ArticleService extends BaseService {
  constructor(
    public settings: SettingsService,
    loggerService: LoggerService,
    http: HttpClient
  ) {
    super(loggerService, http);
  }

  public GetOfficeArticles(filters: [] = []): Observable<AllArticle[]> {
    let resultOfficeArticles: AllArticle[] = [];
    return this.Post(`${this.settings.ArticleService.Search}`, {
      uri: "/za/news-and-publications/articles/office",
      limit: 10,
      filters: filters,
    }).pipe(
      map((result) => {
        result.articles.forEach((art) => {
          resultOfficeArticles.push({
            body: art.body,
            byLine: art.byLine,
            categories: art.categories,
            excerpt: art.excerpt,
            headline: art.headline,
            imageUrl: art.imageUrl,
            publishDate: art.publishDate,
            title: art.title,
            uri: art.uri,
            author: art.author,
          });
        });
        return resultOfficeArticles;
      })
    );
  }

  public GetAllDailyInvestmentUpdatesArticles(
    filters: any[] = []
  ): Observable<DailyInvestmentUpdatesArticle[]> {
    let resultDailyInvestmentUpdatesArticles: DailyInvestmentUpdatesArticle[] =
      [];
    return this.Post(`${this.settings.ArticleService.Search}`, {
      uri: "/za/news-and-publications/daily-investment-updates",
      limit: 10,
      filters: filters,
    }).pipe(
      map((result) => {
        result.articles.forEach((art) => {
          resultDailyInvestmentUpdatesArticles.push({
            body: art.body,
            byLine: art.byLine,
            categories: art.categories,
            excerpt: art.excerpt,
            headline: art.headline,
            imageUrl: art.imageUrl,
            publishDate: art.publishDate,
            title: art.title,
            uri: art.uri,
            author: art.author,
          });
        });
        return resultDailyInvestmentUpdatesArticles;
      })
    );
  }

  public GetAllArticles(filters: any[] = []): Observable<AllArticle[]> {
    let resultOfficeArticles: AllArticle[] = [];
    return this.Post(`${this.settings.ArticleService.AllArticles}`, {
      uri: "/za/news-and-publications/articles",
      limit: 20,
      filters: filters,
    }).pipe(
      map((result) => {
        result.articles.forEach((art) => {
          resultOfficeArticles.push({
            body: art.body,
            byLine: art.byLine,
            categories: art.categories,
            excerpt: art.excerpt,
            headline: art.headline,
            imageUrl: art.imageUrl,
            publishDate: art.publishDate,
            title: art.title,
            uri: art.uri,
            author: art.author,
          });
        });
        return resultOfficeArticles;
      })
    );
  }

  public GetArticle(title: string): Observable<DailyInvestmentUpdatesArticle> {
    return this.Get(`${this.settings.ArticleService.Get}?title=${title}`).pipe(
      map((result) => {
        return result.map(
          (article) => new DailyInvestmentUpdatesArticle(article)
        );
      })
    );
  }

  public SearchAllArticles(filters: any[] = []): Observable<AllArticle[]> {
    let resultOfficeArticles: AllArticle[] = [];
    return this.Post(`${this.settings.ArticleService.AllArticles}`, {
      uri: "/za/news-and-publications/articles",
      limit: null,
      filters: filters,
    }).pipe(
      map((result) => {
        result.articles.forEach((art) => {
          resultOfficeArticles.push({
            body: art.body,
            byLine: art.byLine,
            categories: art.categories,
            excerpt: art.excerpt,
            headline: art.headline,
            imageUrl: art.imageUrl,
            publishDate: art.publishDate,
            title: art.title,
            uri: art.uri,
            author: art.author,
          });
        });
        return resultOfficeArticles;
      })
    );
  }
}
