export class HomePage {
  header: any = {};
  body: any = {};

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    for (var x = 0; x <= this.header.headerBanner.length; x++) {
      if (this.header.headerBanner[x]) {
        console.log(this.header.headerBanner[x]);
        if (
          this.header.headerBanner[x].content != null &&
          this.header.headerBanner[x].content != undefined
        ) {
          this.header.headerBanner[x].content = this.header.headerBanner[
            x
          ].content.replace(
            /<p>/g,
            '<p class="p-length-md text-shadow">'
          );
        }
      }
    }

    if (this.header.content)
      this.header.content = this.header.content.replace(
        /<p>/g,
        '<p class="p-length-sm text-shadow">'
      );
  }
}
