<div class="loader-center-container full-height" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngIf="!isPageLoading">
  <div
    class="container-fluid news-page-hero"
    [style.backgroundImage]="'url(' + content?.header?.image + ')'"
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div class="heading-border-left">
          <h1
            class="mega-title--page-title news-page text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="ml-0">
          <div class="row pl-4">
            <div class="col-md-12">
              <h3
                class="mb-4 text-shadow"
                [ngClass]="
                  content?.header?.subheadingColour
                    ? content?.header?.subheadingColour
                    : 'white'
                "
              >
                {{ content?.header?.subheading }}
              </h3>
              <ng-container *ngFor="let action of content?.header?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }}"
                  [label]="action.label"
                  (click)="DynamicButtonClick(action)"
                  icon="icon-envelope"
                >
                </button-text>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Daliy investment update card row-->
  <div class="container-fluid daliy-investment-card">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-6 mb-3">
          <div
            class="diu--card d-flex h-100"
            [style.backgroundImage]="
              'url(' + content?.header?.dailyInvestmentUpdate?.image + ')'
            "
          >
            <div class="d-flex flex-column justify-content-end">
              <h4 class="cyan">PSG Wealth</h4>
              <h2 class="white">
                {{ content?.header?.dailyInvestmentUpdate?.headline }}
              </h2>
              <p
                class="body-large white"
                [innerHTML]="content?.header?.dailyInvestmentUpdate?.excerpt"
              ></p>
              <div class="mt-2">
                <button-text
                  buttonStyle="solid"
                  label="Read more"
                  (click)="goToURI(content?.header?.dailyInvestmentUpdate?.uri)"
                ></button-text>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-3 mb-3"
          *ngFor="let article of content?.header?.featuredArticles"
        >
          <div class="card news--card header-row">
            <img
              class="card-img-top"
              [src]="article?.image"
              alt="Card image cap"
            />
            <div class="card-body">
              <div class="card-tags pb-2">
                <span
                  *ngFor="let category of article?.categories"
                  role="button"
                  class="basic-badge"
                  data-toggle="modal"
                  data-target="#modal-article-tags"
                  (click)="
                    pageService.SetArticle(category); $event.preventDefault()
                  "
                >
                  {{ category }}
                </span>
              </div>
              <h5 class="small-heading">{{ article?.headline }}</h5>
              <p class="body-small" [innerHTML]="article?.excerpt"></p>
              <a (click)="goToURI(article.uri)" class="textlink">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Large news section -->
  <div class="container-fluid py-5 my-5 svg-bg global--news">
    <div class="container-xl h-100">
      <div class="row d-flex">
        <div class="col-md-8 offset-md-4">
          <div class="heading-border-right">
            <h1 class="section-title--mobile text-right">
              {{ content?.body?.sections[0]?.heading }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card-tags pb-2">
            <span
              *ngFor="
                let category of content?.body?.sections[0]?.article?.categories
              "
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
            >
              {{ category }}</span
            >
          </div>
          <div>
            <h2 class="xlarge-heading">
              {{ content?.body?.sections[0]?.article?.headline }}
            </h2>
            <p
              class="p-length-md"
              [innerHTML]="content?.body?.sections[0]?.article?.excerpt"
            ></p>
            <div class="mt-2">
              <button-text
                buttonStyle="solid"
                label="Read more"
                (click)="goToURI(content?.body?.sections[0]?.article?.uri)"
              ></button-text>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <img
            [src]="content?.body?.sections[0]?.article?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Recommended news  cards section -->
  <div class="container-fluid py-5 my-5">
    <div class="container-xl">
      <div>
        <!-- TODO: this is a workaround (fetches all articles and filters on the frontend) implement correctly -->
        <h2 class="mb-4">{{ content?.body?.sections[1]?.heading }}</h2>
      </div>
      <div class="card-deck">
        <div
          class="card news--card"
          *ngFor="let article of content?.body?.sections[1]?.articles"
        >
          <img
            class="card-img-top"
            [src]="article?.image"
            alt="Card image cap"
          />
          <div class="card-body d-flex flex-column">
            <div class="card-tags pb-2">
              <span
                role="button"
                class="basic-badge"
                data-toggle="modal"
                *ngFor="let category of article?.categories"
                data-target="#modal-article-tags"
                (click)="
                  pageService.SetArticle(category); $event.preventDefault()
                "
                >{{ category }}</span
              >
            </div>
            <h5 class="small-heading">{{ article.headline }}</h5>
            <p class="body-small" [innerHTML]="article.excerpt"></p>
            <a (click)="goToURI(article.uri)" class="textlink mt-auto"
              >Read more</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- news highlight -->
  <div class="container-xl" style="display: none">
    <div class="row">
      <div class="col-md-12"></div>
    </div>
  </div>

  <!-- articles search -->
  <div class="container-fluid py-5 my-5">
    <div class="container-xl">
      <div class="row">
        <!--all  articles-->

        <div class="col-md-7 mb-3" id="view-all">
          <div class="newsSearch--card h-100">
            <h2 class="large-heading">Latest Articles</h2>
            <p [innerHTML]="allArticlesHead?.content"></p>

            <!--search-->
            <div class="row">
              <div class="col-md-6">
                <form-field>
                  <!-- Suffix - needs to be before the input -->
                  <icon-font
                    name="icon-search"
                    colour="cyan"
                    class="suffix clickable"
                  ></icon-font>
                  <!-- Input -->
                  <input
                    placeholder="Search by title or author"
                    (change)="titleOrAuthorSearch($event.target.value)"
                  />
                </form-field>
              </div>
            </div>
            <!--/search-->

            <!--articles and dates-->
            <div class="result-scroll-section">
              <div
                class="article-search-results"
                *ngFor="let latestArticle of searchArticle"
              >
                <div class="row">
                  <div class="col-md-2">
                    <h2 class="large-heading">
                      {{ latestArticle?.publishDate | amDateFormat : "DD" }}
                    </h2>
                    <p>
                      {{ latestArticle?.publishDate | amDateFormat : "MMMM" }}
                    </p>
                  </div>
                  <div class="col-md-10">
                    <div class="card-tags pb-2">
                      <span
                        *ngFor="let category of latestArticle?.categories"
                        role="button"
                        class="basic-badge"
                        data-toggle="modal"
                        data-target="#modal-article-tags"
                        (click)="
                          pageService.SetArticle(category);
                          $event.preventDefault()
                        "
                        >{{ category }}
                      </span>
                    </div>
                    <a
                      class="small-heading black"
                      (click)="goToURI(latestArticle?.uri)"
                      >{{ latestArticle?.headline }}</a
                    >
                    <p class="black">{{ latestArticle?.author?.name }}</p>
                    <p
                      class="slate b-clamp"
                      [innerHTML]="latestArticle?.excerpt"
                    ></p>
                  </div>
                  <hr />
                </div>
              </div>
            </div>

            <!--articles and dates-->
          </div>
        </div>

        <!--market update-->
        <div class="col-md-5 mb-3">
          <div class="newsSearch--card h-100">
            <h2 class="large-heading">Market Update</h2>
            <p>This is a Daily Investment Update</p>

            <!--date input-filter-->
            <form [formGroup]="dailyInvestmentUpdateSearchForm">
              <div class="row no-gutters align-items-end">
                <div class="col-7 pr-1">
                  <form-field>
                    <label class="body-small body-bold">From:</label>
                    <dropdown-select>
                      <select formControlName="fromMonth">
                        <option value="Month">{{ filterMonthFrom }}</option>
                        <option
                          *ngFor="let month of months"
                          [value]="month.value"
                        >
                          {{ month.label }}
                        </option>
                      </select>
                    </dropdown-select>
                  </form-field>
                </div>
                <div class="col-5 pl-1">
                  <form-field>
                    <dropdown-select *ngIf="yearsLoaded == true">
                      <select formControlName="fromYear">
                        <option value="Year">{{ filterYearFrom }}</option>
                        <option *ngFor="let year of years" [value]="year.value">
                          {{ year.label }}
                        </option>
                      </select>
                    </dropdown-select>
                  </form-field>
                </div>
              </div>

              <div class="row no-gutters align-items-end">
                <div class="col-7 pr-1">
                  <form-field>
                    <label class="body-small body-bold">To:</label>
                    <dropdown-select>
                      <select formControlName="toMonth">
                        <option value="Month">{{ filterMonthTo }}</option>
                        <option
                          *ngFor="let month of months"
                          [value]="month.value"
                        >
                          {{ month.label }}
                        </option>
                      </select>
                    </dropdown-select>
                  </form-field>
                </div>
                <div class="col-5 pl-1">
                  <form-field>
                    <dropdown-select *ngIf="yearsLoaded == true">
                      <select formControlName="toYear">
                        <option value="Year">{{ filterYearTo }}</option>
                        <option *ngFor="let year of years" [value]="year.value">
                          {{ year.label }}
                        </option>
                      </select>
                    </dropdown-select>
                  </form-field>
                </div>
              </div>
            </form>
            <!-- </div> -->
            <!--/date input-filter-->

            <!-- diu article -->
            <div class="result-scroll-section-diu">
              <div
                class="diuSearch-results--card"
                *ngFor="let diuArticle of searchDailyInvestmentUpdateArticle"
              >
                <div
                  class="d-flex justify-content-between align-items-start p-2"
                >
                  <div class="mr-4">
                    <h3 class="large-heading mb-0">
                      {{ diuArticle?.publishDate | amDateFormat : "DD" }}
                    </h3>
                    <p class="body-small">
                      {{ diuArticle?.publishDate | amDateFormat : "MMMM" }}
                    </p>
                  </div>
                  <div class="mr-2 mt-1">
                    <h2 class="small-heading mb-2">
                      {{ diuArticle?.headline }}
                    </h2>
                    <p>
                      <span
                        class="body-small body-bold"
                        *ngFor="
                          let author of diuArticle?.author;
                          let last = last
                        "
                      >
                        {{ author?.name }}
                        <span *ngIf="!last">; &nbsp;</span>
                      </span>
                    </p>
                  </div>
                  <div class="mt-1">
                    <button-icon
                      buttonIcon="outline-icon"
                      icon="icon-angle-right"
                      (click)="goToURI(diuArticle?.uri)"
                    ></button-icon>
                  </div>
                </div>
              </div>
            </div>

            <!-- /diu article -->
          </div>
        </div>
        <!--market update-->
      </div>
    </div>
  </div>
  <!-- articles search-->

  <div
    class="container-xl py-5 my-5"
    *ngIf="content?.body?.divisions.length > 0 && !isPageLoading"
  >
    <div class="heading-border-left" id="archive-newsletter">
      <h2 class="mb-4">Archive newsletters</h2>
    </div>
    <div
      class="row mt-5"
      *ngIf="
        content?.body?.divisions[7].forms.categories != null ||
        content?.body?.divisions[7].forms.categories != undefined
      "
    >
      <div
        class="col-md-6"
        *ngFor="let category of content?.body?.divisions[7]?.forms.categories"
      >
        <!-- acccordion -->
        <div
          class="accordion"
          [id]="'accordionwealthLocal' + removeWhiteSpace(category.name)"
        >
          <div class="accordian-inner">
            <div [id]="'wealthLocal' + removeWhiteSpace(category.name)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapsewealthLocal' + removeWhiteSpace(category.name)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapsewealthLocal' + removeWhiteSpace(category.name)
                  "
                >
                  {{ category.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapsewealthLocal' + removeWhiteSpace(category.name)"
              class="collapse"
              [attr.aria-labelledby]="
                'wealthLocal' + removeWhiteSpace(category.name)
              "
              [attr.data-parent]="
                '#accordionwealthLocal' + removeWhiteSpace(category.name)
              "
            >
              <div class="accordian-body">
                <ul class="p-0" *ngFor="let download of category.downloads">
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /acccordion -->
      </div>
    </div>
    <!-- forms -->

    <!-- factSheets -->
    <div
      class="row mt-5"
      *ngIf="
        content?.body?.divisions[7].factSheets.categories != null ||
        content?.body?.divisions[7].factSheets.categories != undefined
      "
    >
      <div class="col-md-12">
        <div>
          <h3 class="small-heading mb-3 pl-1">Fact sheets</h3>
        </div>
      </div>
      <div
        class="col-md-6"
        *ngFor="
          let category of content?.body?.divisions[7]?.factSheets.categories
        "
      >
        <!-- acccordion -->
        <div
          class="accordion"
          [id]="'accordionwealthLocal' + removeWhiteSpace(category.name)"
        >
          <div class="accordian-inner">
            <div [id]="'wealthLocal' + removeWhiteSpace(category.name)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapsewealthLocal' + removeWhiteSpace(category.name)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapsewealthLocal' + removeWhiteSpace(category.name)
                  "
                >
                  {{ category.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapsewealthLocal' + removeWhiteSpace(category.name)"
              class="collapse"
              [attr.aria-labelledby]="
                'wealthLocal' + removeWhiteSpace(category.name)
              "
              [attr.data-parent]="
                '#accordionwealthLocal' + removeWhiteSpace(category.name)
              "
            >
              <div class="accordian-body">
                <ul class="p-0" *ngFor="let download of category.downloads">
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /acccordion -->
      </div>
    </div>
    <!-- factSheets -->
  </div>
</div>
