import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { SEO_FOR_PAGES } from "../models/SEO/seo-page-script";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  private readonly desc: string;
  private readonly main_img: string;
  private readonly og_url: string;
  private readonly og_twitter_site: string;
  private readonly author: string;
  private readonly dataType = "application/ld+json";

  constructor(
    private readonly metaTagService: Meta,
    private readonly titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.desc =
      "Our advice-focused business is founded on personal service, lifelong relationships with clients, integrity, trust and transparency. These values are encapsulated in the group’s core business principles.";
    this.main_img =
      "https://psgpfwcmszaprod.azurewebsites.net/media/zlbn3xva/landing-page-financial-advice.png";
    this.og_url = "https://www.psg.co.za";
    this.og_twitter_site = "@psgkonsult";
    this.author = "PSG";
  }

  //seo page script
  setJsonLd(renderer: Renderer2, data: any): void {
    let headEl = this.document.head.getElementsByTagName("script");

    for (var i = 0, l = headEl.length; i < l; i++) {
      if (headEl[i]?.type === this.dataType) {
        renderer.removeChild(this.document.head, headEl.item(i));
      }
    }

    //check if its the home page..
    if (location.pathname.includes("home")) {
      //dynamic script
      let JSON_LD_DATA: string = SEO_FOR_PAGES.home_script;
      this.appendElement(renderer, JSON_LD_DATA);
    }

    if (data == null) return;

    this.appendElement(renderer, data);
  }

  appendElement(renderer: Renderer2, data: any): void {
    let script = renderer.createElement("script");
    script.type = this.dataType;
    script.class = "schemantra";
    script.text = `${data}`;
    renderer.appendChild(this.document.head, script);
  }

  initialDefaultMetaInformation(): void {
    this.titleService.setTitle(`Home`);

    this.metaTagService.addTags([
      { name: "robots", content: "index, follow" },
      { name: "keywords", content: "" },
      {
        name: "description",
        content: this.desc,
      },
      { name: "author", content: this.author },
      {
        name: "image",
        content: this.main_img,
      },
      { name: "url", content: this.og_url },
      { name: "type", content: "website" },

      //open graph
      { property: "og:title", content: "Home" },
      { property: "og:description", content: this.desc },
      { property: "og:url", content: this.og_url },
      { property: "og:image", content: this.main_img },
      { property: "og:image:type", content: "image/png" },
      { property: "og:image:secure_url", content: this.main_img },
      { property: "og:site_name", content: this.og_url },
      { property: "og:type", content: "website" },

      //twitter
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: this.og_twitter_site },
      { name: "twitter:title", content: this.author },
      { name: "twitter:creator", content: this.author },
      {
        name: "twitter:description",
        content: this.desc,
      },
      {
        name: "twitter:image",
        content: this.main_img,
      },
    ]);
  }

  updateMetaInfoForPage(
    _title: string,
    _url: string,
    _imageUrl: string,
    _description: string,
    _type: string | null = null
  ): void {
    this.titleService.setTitle(_title);
    this.metaTagService.updateTag({
      name: "description",
      content: _description == null ? this.desc : _description,
    });
    this.metaTagService.updateTag({
      name: "image",
      content: _imageUrl == null ? this.main_img : _imageUrl,
    });
    this.metaTagService.updateTag({
      name: "url",
      content: _url == null ? this.og_url : _url,
    });
    this.metaTagService.updateTag({
      name: "type",
      content: _type == null ? "website" : _type,
    });
    //open graph
    this.metaTagService.updateTag({ property: "og:title", content: _title }),
      this.metaTagService.updateTag({
        property: "og:description",
        content: _description == null ? this.desc : _description,
      }),
      this.metaTagService.updateTag({
        property: "og:url",
        content: _url == null ? this.og_url : _url,
      }),
      this.metaTagService.updateTag({
        property: "og:image",
        content: _imageUrl == null ? this.main_img : _imageUrl,
      }),
      this.metaTagService.updateTag({
        property: "og:image:secure_url",
        content: _imageUrl == null ? this.main_img : _imageUrl,
      }),
      this.metaTagService.updateTag({
        property: "og:type",
        content: _type == null ? "website" : _type,
      }),
      //twitter
      this.metaTagService.updateTag({
        name: "twitter:title",
        content: _title == null ? "" : _title,
      });
    this.metaTagService.updateTag({
      name: "twitter:description",
      content: _description == null ? "" : _description,
    });
    this.metaTagService.updateTag({
      name: "twitter:image",
      content: _imageUrl == null ? this.main_img : _imageUrl,
    });
  }
}
