<div class="container-fluid slides-scale">
    <div class="container-xl">
        <div class="row no-gutters ">
            <!-- image left col -->
            <div class="col-md-6  d-none d-md-block">
                <img [src]="content?.body?.products[5]?.image" class="img-cover prod-img" />
            </div>

            <!-- content right col -->
            <div class="col-md-6 pl-md-5 py-3 mb-1">
                <div class="row d-none d-md-block">
                    <div class="col-md-6 mb-5 bottom-border carousel-bb-transform">
                    </div>
                </div>
                <!--/border-->

                <!-- product title -->
                <div class="mb-0 mt-3">
                    <h2>{{content?.body?.products[5]?.name}}</h2>
                </div>

                <!-- product content -->
                <div class="mt-4 pl-md-2" [innerHTML]="content?.body?.products[5]?.content">
                </div>

                <div class="ml-md-2">
                  <button-text *ngFor="let action of content?.body?.products[5]?.actions"
                  buttonStyle="{{action.isPrimary ? 'solid' : 'solid altWhite mt-3 mt-md-0' }}" [label]="action?.label"
                  (click)="DynamicButtonClick(action)">
                </button-text>
                </div>
            </div>
        </div>
    </div>
</div>
