export class DailyInvestmentUpdatesArticle {
  title: string = "";
  byLine: string = "";
  imageUrl: string = "";
  publishDate: Date;
  categories: string[] = [];
  excerpt: string = "";
  headline: string = "";
  body: string = "";
  uri: string = "";
  author: Author = {name: ""}

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    this.excerpt = this.excerpt.replace(/<p>/g, '<p class="mt-3">');
    this.body = this.body.replace(/<p>/g, '<p class="body-large mb-3">');
  }

}

export class Author {
  name:string="";
}
