<!-- article-tags Model -->
<div
  class="modal fade"
  id="modal-article-tags"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <!-- modal container -->
    <div class="modal-content">
      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <!-- modal header expanded-->
        <div class="modal-header expanded">
          <h5 class="modal-heading">{{ this.pageService.article }}</h5>
        </div>
        <!-- / modal header -->
        <!-- modal body expanded-->
        <div class="modal-body expanded">
          <div class="row">
            <div class="col-12">
              <!-- articles search -->
              <div class="container-fluid">
                <div class="container-xl">
                  <div class="row">
                    <!--articles and dates-->
                    <div class="result-scroll-section">
                      <div
                        class="article-search-results"
                        *ngFor="
                          let latestArticle of this.pageService.searchArticle
                        "
                      >
                        <div class="row">
                          <div class="col-md-2">
                            <h2 class="large-heading">
                              {{
                                latestArticle?.publishDate | amDateFormat : "DD"
                              }}
                            </h2>
                            <p>
                              {{
                                latestArticle?.publishDate
                                  | amDateFormat : "MMMM"
                              }}
                            </p>
                          </div>
                          <div class="col-md-10">
                            <div class="card-tags pb-2">
                              <span
                                class="basic-badge"
                                *ngFor="
                                  let category of latestArticle?.categories
                                "
                                >{{ category }}</span
                              >
                            </div>
                            <a
                              class="small-heading black"
                              (click)="goToURI(latestArticle?.uri)"
                              data-dismiss="modal"
                              >{{ latestArticle?.headline }}</a
                            >
                            <p>
                              <span
                                class="body-small body-bold"
                                *ngFor="
                                  let author of latestArticle?.author;
                                  let last = last
                                "
                              >
                                {{ author?.name }}
                                <span *ngIf="!last">; &nbsp;</span>
                              </span>
                            </p>
                            <p
                              class="slate b-clamp"
                              [innerHTML]="latestArticle?.excerpt"
                            ></p>
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>
                    <!--articles and dates-->
                  </div>
                </div>
              </div>
              <!-- articles search-->
            </div>
          </div>
        </div>
        <!-- / modal body -->
        <!-- modal footer expanded-->
        <div class="modal-footer expanded">
          <button
            id="closeModal"
            style="min-width: 120px"
            type="button"
            class="button outline"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
        <!-- / modal footer -->
      </div>
      <!-- / modal body -->
    </div>
    <!-- / modal container -->
  </div>
</div>
<!-- article-tags Model -->
