<div class="container-fluid slate-bg-10 py-4 my-4">
    <div class="container-xl">
        <div class="row">
            <div class="col-12">
              <h2 class="mb-4"  *ngIf="content != null">
                {{content.heading}}
               </h2>
            </div>
        </div>
        <div class="row d-flex">
            <div class="col-md-6 mb-5" *ngFor="let vid of content.videos">
                <div class="row">
                    <div class="col-md-4 col-xs-12 justify-content-start mt-md-5 pt-md-3">
                        <h4 class="small-heading mb-2">
                            {{ vid.heading }}
                        </h4>
                        <p [innerHTML]="vid.content"></p>
                    </div>
                    <div class="col-md-8 col-xs-12">
                        <div class="row d-flex justify-content-end mb-2 text-right">
                            <div class="col-12">
                                <button class="button simple-icon" (click)="shareToMail(vid.heading)">
                                    <icon-font
                                    name="icon-envelope"
                                    colour="cyan"
                                    class="body-large cyan"
                                    ></icon-font>
                                </button>
                                <button class="button simple-icon" (click)="shareToLinkedIn()">
                                    <icon-font
                                    name="icon-linkedin"
                                    colour="cyan"
                                    class="body-large cyan"
                                    ></icon-font>
                                </button>
                                <button class="button simple-icon" (click)="shareToTwitter()">
                                    <icon-font
                                    name="icon-twitter"
                                    colour="cyan"
                                    class="body-large cyan"
                                    ></icon-font>
                                </button>
                                <button class="button simple-icon" (click)="shareToFb()">
                                    <icon-font
                                    name="icon-facebook"
                                    colour="cyan"
                                    class="body-large cyan"
                                    ></icon-font>
                                </button>
                                <button class="button simple-icon" (click)="shareToWhatsApp()">
                                    <icon-font
                                    name="icon-whatsapp"
                                    colour="cyan"
                                    class="body-large cyan"
                                    ></icon-font>
                                </button>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-12">
                                <div class="embed-responsive embed-responsive-16by9 card-round significant-raise">
                                    <iframe
                                        class="embed-responsive-item"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        [src]="vid.url | safe">
                                    </iframe>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>