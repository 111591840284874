import { DOCUMENT } from "@angular/common";
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, finalize, forkJoin, Observable, take } from "rxjs";
import { BranchOfficePage } from "src/app/models/content/financial-advice/branch-office-page.model";
import {
  FormattedBranchAddress,
  TeamDetails,
  TeamPerson,
} from "src/app/services/advisor.models";
import { AdvisorService } from "src/app/services/advisor.service";
import { ExternalRedirectsService } from 'src/app/services/external-redirects/external-redirects.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { PageService } from "src/app/services/page.service";
import { SearchService } from "src/app/services/search.service";
import { SeoService } from "src/app/services/socialLinks.service";
import { environment } from "src/environments/environment";

declare var google: any;

@Component({
  selector: "app-branch-office",
  templateUrl: "./branch-office.component.html",
})
export class BranchOfficeComponent implements OnInit, AfterContentInit {
  public defaultBranchOfficeBannerUrl: string =
    "../../../../assets/images/branchOfficeBanner.jpg";

  public content: any = {};
  public fragment: string;
  public requestForm: FormGroup = this.createRequestForm();
  public branchNameLocation: string;
  public product: string = "";
  public location: string = "";
  public advisers: any = [];

  public apiKey: string = environment.GoogleApiKey;

  public myBaseId: any = [];
  public plus: string = "+";

  //new person variables
  public branchDetail: any = {};
  public teamDetails: TeamDetails;

  public backgroundImage: any;
  public articleData: any;
  public articleDataShown: any;
  public carouselControls: number = 0;
  public isNextButton: boolean = false;
  public isPrevButton: boolean = false;

  public profileImage: any;
  public defualtImages: string;

  public bannerImage: string;

  public articleSection: any;

  public isContactNumberRequired: boolean = true;
  public isEmailAddressRequired: boolean = true;
  public formattedBranchAddress: FormattedBranchAddress;
  public inlineFormattedBranchAddress: string;

  public lat: number = 0;
  public lng: number = 0;
  public zoom: number = 15;
  public isMapLoaded: boolean = false;
  public map: any;
  public officeDirectionUrl: string = "";
  public redirectToGoogleMapsUrl: string = "";

  private imgElement: HTMLImageElement;
  public isPageLoading: boolean = true;

  constructor(
    public pageService: PageService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) document,
    public changeDetectorRef: ChangeDetectorRef,
    private advisorService: AdvisorService,
    private searchService: SearchService,
    public seoService: SeoService,
    private renderer: Renderer2,
    public helper: HelperService,
    private externalRedirects: ExternalRedirectsService
  ) {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {

      if (event instanceof NavigationEnd) {

        const urlExists = this.externalRedirects.checkIfCurrentUrlExists(event.url);
        if (urlExists) {
          window.location.href = environment.advisersUrl + urlExists.to;
        }

      }

    });

  }

  public ngAfterContentInit() {
    this.route.params.subscribe((param) => {
      if (param.branchname != undefined) {
        let branch = param.branchname;

        this.advisorService.selectedBranchId
          .pipe(take(1))
          .subscribe((branchId) => {
            if (branchId != "") {
              this.GetBranchDetails(branchId);
              this.getUmbracoBranchDetails(branchId);
            } else {
              this.getSearchUrl(branch, this.getSearchPerson.bind(this));
            }
          });
      } else {
        this.route.url.subscribe((url) => {
          if (url != null) {
            let searchUrl: string;
            if (url.length == 1) {
              searchUrl = url[0].path;
            }

            if (url.length == 3) {
              if (url[0].path == "contact-us" && url[1].path == "branches") {
                searchUrl = url[2].path;
              } else {
                this.router.navigate(["/home"]);
              }
            }
            this.getSearchUrl(searchUrl, this.getSearchPerson.bind(this));
          }
        });
      }
    });

    this.BindValueChangeEvents();
  }

  private BindValueChangeEvents() {
    this.requestForm.controls.emailAddress.valueChanges.subscribe((value) => {
      if (value != "") {
        this.requestForm.controls.contactNumber.clearValidators();
        this.requestForm.controls.contactNumber.updateValueAndValidity({
          emitEvent: false,
        });
        this.isContactNumberRequired = false;
      } else {
        this.requestForm.controls.contactNumber.setValidators([
          Validators.required,
        ]);
        this.requestForm.controls.contactNumber.updateValueAndValidity({
          emitEvent: false,
        });
        this.isContactNumberRequired = true;
      }
    });

    this.requestForm.controls.contactNumber.valueChanges.subscribe((value) => {
      if (value != "") {
        this.requestForm.controls.emailAddress.clearValidators();
        this.requestForm.controls.emailAddress.updateValueAndValidity({
          emitEvent: false,
        });
        this.isEmailAddressRequired = false;
      } else {
        this.requestForm.controls.emailAddress.setValidators([
          Validators.required,
        ]);
        this.requestForm.controls.emailAddress.updateValueAndValidity({
          emitEvent: false,
        });
        this.isEmailAddressRequired = true;
      }
    });
  }

  private getSearchUrl(searchUrl: string, func: (searchUrl: string) => void) {
    this.advisorService.GetSearchUrl(searchUrl).subscribe((results) => {
      // call api content-->
      if (results.data.data_arr.data.length == 0) {
        func(searchUrl);
      }

      this.GetBranchDetails(results.data.data_arr.data[0]?.team_id);
      this.getUmbracoBranchDetails(results.data.data_arr.data[0]?.team_id);
    });
  }

  private getSearchPerson(searchStr: string) {
    this.advisorService.GetSearchPersonUrl(searchStr).subscribe((response) => {
      // call api content-->
      if (response.data.data_arr.data.length == 0) {
        this.router.navigate(["/home"]);
        return;
      }
      if (response.data.data_arr.data.length > 0) {
        this.router.navigate([
          "financial-advice/adviser-profile",
          response?.data?.data_arr?.data[0]?.person_id,
        ]);
        return;
      }
    });
  }

  private getUmbracoBranchDetails(branchId: string) {
    this.searchService
      .GetSearch(branchId, "")
      .pipe(
        take(1),
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((searchResult) => {
        if ((<Array<any>>searchResult).length > 0) {
          let forkjoin = new Array<Observable<BranchOfficePage>>();
          (<Array<any>>searchResult)
            .filter((x) => x.category == "Branches")
            .forEach((branch) => {
              forkjoin.push(
                this.pageService.GetPage<BranchOfficePage>(branch.uri)
              );
            });

          if (forkjoin.length > 0) {
            forkJoin(forkjoin).subscribe((results) => {
              let branchResult = (<Array<BranchOfficePage>>results).find(
                (x) => x.header.teamId == branchId
              );

              if (branchResult != undefined || branchResult != null) {
                let split: BranchOfficePage = new BranchOfficePage(
                  branchResult
                );
                this.content = split;

                if (branchResult != null) {
                  if (
                    branchResult.header.image != null &&
                    branchResult.header.image != undefined
                  ) {
                    this.bannerImage = `${branchResult.header.image}`;
                  } else {
                    this.bannerImage = this.defaultBranchOfficeBannerUrl;
                  }

                  this.articleSection = (<Array<any>>(
                    this.content.body.sections
                  )).find((x) => x.type == "articles");
                  this.articleData = this.articleSection.articles;
                  if (this.articleData.length > 4) {
                    this.isNextButton = true;
                  }
                  this.setShownData();
                } else {
                  this.bannerImage = this.defaultBranchOfficeBannerUrl;
                }
              } else {
                this.bannerImage = this.defaultBranchOfficeBannerUrl;
              }
            });
          } else {
            this.bannerImage = this.defaultBranchOfficeBannerUrl;
          }
        } else {
          this.bannerImage = this.defaultBranchOfficeBannerUrl;
        }
      });
  }

  public setShownData() {
    this.articleDataShown = this.articleData.slice(
      this.carouselControls * 4,
      (this.carouselControls + 1) * 4
    );
  }

  public previous() {
    if (this.carouselControls != 0) {
      this.carouselControls = this.carouselControls - 1;
      this.setShownData();
      if (this.carouselControls == 0) {
        this.isPrevButton = false;
      }
      if (this.articleDataShown.length >= 4) {
        this.isNextButton = true;
      }
    }
  }

  public next() {
    if ((this.carouselControls + 1) * 4 < this.articleData.length) {
      this.carouselControls = this.carouselControls + 1;
      this.setShownData();

      if (this.articleDataShown.length < 4) {
        this.isNextButton = false;
      }
      if (this.articleData.length > 4) {
        this.isPrevButton = true;
      }
    }
  }

  private GetBranchDetails(branchId: string) {
    this.advisorService
      .getBranchDetails(branchId)
      .subscribe((branchDetailResults) => {
        this.branchDetail = branchDetailResults.data.data_arr.data;
        if (this.branchDetail[0]) {
          this.formattedBranchAddress = new FormattedBranchAddress(
            this.branchDetail[0]?.team_location
              ?.replace("&amp;", "&")
              .split(",")
          );

          this.seoService.updateMetaInfoForPage(
            `${this.branchDetail[0].team_name} | Branch`,
            window.location.href,
            this.content?.header?.image,
            this.branchDetail[0].team_description !== ""
              ? this.branchDetail[0].team_description
              : `At PSG, our clients are the heart of our business. For us, it’s important to get to know you, to understand exactly what you hope to achieve and to build a lasting relationship with you to help you succeed. We specialise in the private client market, which means that you will never take second place to large corporate clients. You have access to a full range of financial products and services, backed by our commitment to professional, personalised advice. This means that we can comfortably tailor your portfolio to your exact needs.`
          );
          this.lat = Number(this.branchDetail[0].office_gps.split(",")[0]);
          this.lng = Number(this.branchDetail[0].office_gps.split(",")[1]);
          this.isMapLoaded = true;
        }
      });

    this.advisorService
      .GetTeamDetails(branchId)
      .subscribe((teamDetailsResult) => {
        this.teamDetails = <TeamDetails>teamDetailsResult.data.data_arr.data;

        if (this.teamDetails.advisors != undefined) {
          this.teamDetails.advisors.forEach((advisor) => {
            this.getProfileImage(advisor);
          });
        }

        if (this.teamDetails.support != undefined) {
          this.teamDetails.support.forEach((support) => {
            this.getProfileImage(support);
          });
        }

        if (this.teamDetails.principles != undefined) {
          this.teamDetails.principles.forEach((principal) => {
            this.getProfileImage(principal);
          });
        }
      });
  }

  public mapLoaded(map: any) {
    this.isMapLoaded = true;

    if (this.formattedBranchAddress != undefined) {
      this.inlineFormattedBranchAddress = `${this.formatInlineAddress(
        this.formattedBranchAddress.AddressLine1,
        true
      )}
      ${this.formatInlineAddress(
        this.formattedBranchAddress.AddressLine2,
        true
      )}
      ${this.formatInlineAddress(
        this.formattedBranchAddress.AddressLine3,
        true
      )}
      ${this.formatInlineAddress(
        this.formattedBranchAddress.AddressLine4,
        true
      )}
      ${this.formatInlineAddress(
        this.formattedBranchAddress.AddressLine5,
        true
      )}
      ${this.formatInlineAddress(
        this.formattedBranchAddress.AddressLine6,
        true
      )}
      ${this.formatInlineAddress(
        this.formattedBranchAddress.AddressLine7,
        true
      )}
      ${this.formatInlineAddress(
        this.formattedBranchAddress.AddressLine8,
        false
      )}`;
    }

    this.officeDirectionUrl = `https://www.google.com/maps/dir/?api=1&destination=${this.lat},${this.lng}`;
    this.redirectToGoogleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${this.lat},${this.lng}`;
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(
      document.getElementById("placecard")
    );
    this.map = map;
  }

  private getProfileImage(person: TeamPerson) {
    this.advisorService
      .GetProfileImage(Number(person.person_id))
      .subscribe((imageDetails) => {
        this.profileImage = imageDetails.data.data_arr.data;

        if (this.profileImage != false && this.profileImage != undefined) {
          person["photo_decoded"] = this.cleanURL(
            "data:image/png;base64," + this.profileImage.image_string
          );
        } else {
          person["photo_decoded"] = this.cleanURL(
            "../../../../assets/images/adviser-profile.svg"
          );
        }
      });
  }

  public sanitizeTeamName(teamName: string) {
    if (teamName == null) {
      return "";
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(
        teamName.replace("&amp;", "&")
      )["changingThisBreaksApplicationSecurity"];
    }
  }

  public cleanURL(oldURL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public sendRequest() {
    if (this.requestForm.invalid) {
      alert("Please fill in all required fields.");
      return;
    }

    if (this.requestForm.controls.emailAddress.value != "") {
      if (
        this.branchDetail.legth != 0 &&
        this.branchDetail[0].team_email != "" &&
        this.branchDetail[0].team_email != "null"
      ) {
        this.advisorService
          .sendComms({
            Name: `${this.requestForm.controls.name.value} ${this.requestForm.controls.surname.value}`,
            Email: this.requestForm.controls.emailAddress.value,
            ContactNumber: this.requestForm.controls.contactNumber.value,
            Message: this.requestForm.controls.message.value,
            Subject: "Message Me",
            ToAddresses: [this.branchDetail[0].team_email],
          })
          .subscribe();
      }
    }
    this.advisorService
      .sendComms({
        Name: `${this.requestForm.controls.name.value} ${this.requestForm.controls.surname.value}`,
        Email: this.requestForm.controls.emailAddress.value,
        ContactNumber: this.requestForm.controls.contactNumber.value,
        Message: this.requestForm.controls.message.value,
        Subject: "Message Me",
        ToAddresses: ["social.leads.reporting@psg.co.za"],
      })
      .subscribe();
    this.advisorService
      .sendComms({
        Name: `${this.requestForm.controls.name.value} ${this.requestForm.controls.surname.value}`,
        Email: this.requestForm.controls.emailAddress.value,
        ContactNumber: this.requestForm.controls.contactNumber.value,
        Message: this.requestForm.controls.message.value,
        Subject: "Message Me",
        ToAddresses: ["social.leads.reporting@psg.co.za"],
      })
      .subscribe();

    alert("Email sent successfully");
  }

  private createRequestForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      surname: [""],
      emailAddress: ["", [Validators.required]],
      contactNumber: ["", [Validators.required]],
      message: [""],
    });
  }

  scrollToContactUs() {
    location.hash = "#contact-us";
  }

  scrollToLatestNews() {
    location.hash = "#latest-news";
  }
  scrollToTeam() {
    location.hash = "#meet-the-team";
  }

  ngAfterViewInit() { }

  navToLink(url) {
    window.open(url);
  }

  private formatInlineAddress(
    addressLine: string,
    appendComma: boolean
  ): string {
    if (addressLine.trim() != "") {
      return appendComma ? addressLine + ", " : addressLine;
    }
    return "";
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
