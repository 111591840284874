<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="container-fluid research-insights--hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div class="row d-flex align-content-start justify-content-center h-100">
      <div class="col-md-12 mt-5">
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title fund-research-header white text-shadow"
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
      </div>
      <div class="col-md-12">
        <div class="pl-md-4">
          <div [innerHTML]="content?.header?.content"></div>
        </div>
        <div class="pl-md-4">
          <ng-container *ngFor="let action of content?.header?.actions">
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }} mr-2"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl research-insights--card-row">
  <div class="row d-flex">
    <div
      class="col-md-12"
      *ngIf="content?.header?.headerBannerContent.length > 0"
    >
      <div class="research-insights-hero-card">
        <h3 class="medium-heading cyan">
          {{ content?.header?.headerBannerContent[0].heading }}
        </h3>
        <p>{{ content?.header?.headerBannerContent[0].headline }}</p>
        <div
          [innerHTML]="content?.header?.headerBannerContent[0].content"
        ></div>
      </div>
    </div>
  </div>
</div>

<app-latest-reports
  *ngIf="latestReports != null"
  [latestReports]="latestReports"
  [keywords]="latestReportsKeywords"
>
</app-latest-reports>

<app-previous-reports
  *ngIf="previousReports != null"
  [previousReports]="previousReports"
>
</app-previous-reports>

<app-previous-publications
  *ngIf="previousPublications != null"
  [previousPublications]="previousPublications"
>
</app-previous-publications>

<div
  *ngIf="content?.body?.sections.length > 0 && webinars != null"
  class="container-fluid mt-5 pb-5 greybg-left h-100"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left">
          <h2 class="section-title--mobile">
            {{ webinars?.heading }}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-xl py-3 my-3 h-100"
    *ngFor="let video of webinars.videos"
  >
    <div
      class="row d-flex align-items-start justify-content-start"
      *ngIf="!isPageLoading"
    >
      <div class="col-md-4 col-sm-12 justify-content-start mt-md-5 pt-md-3">
        <h4 class="small-heading mb-2">{{ video?.heading }}</h4>
        <p [innerHTML]="video?.content"></p>
      </div>
      <!-- social links -->
      <div class="col-md-8 col-sm-12">
        <div class="row d-flex justify-content-end mb-2">
          <div class="p-2">
            <button class="button simple-icon" (click)="shareToMail()">
              <icon-font
                name="icon-envelope"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToLinkedIn()">
              <icon-font
                name="icon-linkedin"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToTwitter()">
              <icon-font
                name="icon-twitter"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToFb()">
              <icon-font
                name="icon-facebook"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToWhatsApp()">
              <icon-font
                name="icon-whatsapp"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
          </div>
        </div>
        <!-- iframe -->
        <div
          class="embed-responsive embed-responsive-16by9 card-round significant-raise"
        >
          <iframe
            width="520"
            height="270"
            [src]="video?.url | safe"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl py-5">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>
