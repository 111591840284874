export class FundOfFundsLandingPage {
    header: any = {};
    body: any = {};

    constructor(init: any = {}) {
        for (const key of Object.keys(init)) {
            this[key] = init[key];
        }

        if (this.header.content)
            this.header.content = this.header.content.replace(/<p>/g, '<p class="white p-length-md text-shadow">');
    }
}