<!--MM local funds TABLE -->
<div class="w-100">
  <div class="significant-raise table-card h-100">
    <!-- tabs -->
    <div
      class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between"
    >
      <div class="pl-2 large-heading">
        <h2>Local funds</h2>
      </div>
      <div>
        <tab-horisontal
          [tabSelected]="currentTab"
          (tabSelectedChange)="selectedTabChanged($event)"
          [tabs]="pageTabs"
          class="tab-nowrap"
        ></tab-horisontal>
      </div>
    </div>

    <!-- tabs -->

    <!--tables-->
    <div class="mt-4" [ngSwitch]="currentTab">
      <!-- overview -->
      <div *ngSwitchCase="'Overview'">
        <div>
          <overview-table
            locality="LOCAL"
            category="MULTI-MANAGER-FUND"
          ></overview-table>
        </div>
      </div>
      <!-- /overview -->

      <!-- Price and performance -->
      <div *ngSwitchCase="'Price and performance'">
        <div>
          <price-and-performance-table
            locality="LOCAL"
            category="MULTI-MANAGER-FUND"
          >
          </price-and-performance-table>
        </div>
      </div>
      <!-- /Price and performance -->
    </div>
    <!--tables-->
    <div [innerHTML]="content?.body?.sections[2]?.disclaimer"></div>

    <button-text
      buttonStyle="solid"
      label="View fact sheets"
      [routerLink]="['/forms-and-Facts-sheets']"
      rowspan=""
    >
    </button-text>
  </div>
</div>
<!--MM local funds TABLE-->
