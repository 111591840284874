<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!--  hero section -->
<div
  class="container-fluid investor-info-hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title home-page mb-1 text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>
<!-- hero section -->

<!-- emptySpace -->
<div class="container my-3 py-3"></div>
<!-- /emptySpace -->

<!-- Shareholder calendar -->
<div class="container-fluid mt-5 pb-4 greybg-right">
  <div class="container-xl">
    <div class="row justify-content-end">
      <div class="col-sm-7 col-lg-6">
        <div
          class="heading-border-right d-flex justify-content-end transformY-25"
        >
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <h3 class="medium-heading pl-2 mb-4">
          {{ content?.body?.sections[0]?.categories[0].heading }}
        </h3>
      </div>
      <div
        class="col-6 col-lg-3 col-md-4 col-sm-6 mb-4"
        *ngFor="
          let event of content?.body?.sections[0]?.categories[0].sections[0]
            ?.events
        "
      >
        <div
          class="white-bg card-round significant-raise d-flex flex-column flex-fill h-100 p-4"
        >
          <div>
            <h3 class="large-heading mb-0 black-80">{{ event?.heading }}</h3>
            <p class="body-small body-bold black-80">{{ event?.subheading }}</p>
          </div>
          <h4 class="small-heading" [innerHTML]="event?.content"></h4>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl mt-4">
  <div class="row">
    <div class="col-lg-12">
      <h3 class="medium-heading pl-2 mb-4">
        {{ content?.body?.sections[0]?.categories[1].heading }}
      </h3>
    </div>
    <div
      class="col-lg-3 col-md-4 col-sm-12 mb-4"
      *ngFor="
        let event of content?.body?.sections[0]?.categories[1].sections[0]
          ?.events
      "
    >
      <div
        class="white-bg card-round significant-raise d-flex flex-column flex-fill h-100 p-4"
      >
        <div>
          <h3 class="large-heading mb-0 black-80">{{ event?.heading }}</h3>
          <p class="body-small body-bold black-80">{{ event?.subheading }}</p>
        </div>
        <h4 class="small-heading" [innerHTML]="event?.content"></h4>
      </div>
    </div>
  </div>
</div>

<div class="container-xl mt-4">
  <div class="row">
    <div class="col-lg-6 col-md-8">
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <div>
          <h3 class="medium-heading pl-2">
            {{ content?.body?.sections[0]?.categories[2]?.heading }}
          </h3>
          <h4 class="small-heading pl-2 mb-4">
            {{
              content?.body?.sections[0]?.categories[2]?.sections[0]?.heading
            }}
          </h4>
        </div>
        <div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[0]?.categories[2]?.actions
            "
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4 mt-sm-0">
    <div
      class="col-6 col-lg-3 col-md-4 col-sm-6 mb-4"
      *ngFor="
        let dividend of content?.body?.sections[0]?.categories[2]?.sections[0]
          ?.events
      "
    >
      <div
        class="white-bg card-round significant-raise d-flex flex-column flex-fill h-100 p-4"
      >
        <div>
          <p class="body-large body-bold black-80">{{ dividend?.heading }}</p>
        </div>
        <h4 class="medium-heading" [innerHTML]="dividend?.content"></h4>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-lg-9">
      <h4 class="small-heading pl-2 mb-4">
        {{ content?.body?.sections[0]?.categories[2]?.sections[1]?.heading }}
      </h4>
    </div>
  </div>
  <div class="row">
    <div
      class="col-6 col-lg-3 col-md-4 col-sm-6 mb-4"
      *ngFor="
        let dividend of content?.body?.sections[0]?.categories[2]?.sections[1]
          ?.events
      "
    >
      <div
        class="white-bg card-round significant-raise d-flex flex-column flex-fill h-100 p-4"
      >
        <div>
          <p class="body-large body-bold black-80">{{ dividend?.heading }}</p>
        </div>
        <h4 class="medium-heading" [innerHTML]="dividend?.content"></h4>
      </div>
    </div>
  </div>
</div>

<!-- Shareholder calendar -->

<!-- Shareholder communication -->
<div class="container-fluid py-5 mt-5 greybg-left">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-6">
        <div class="heading-border-left d-inline-flex">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[1]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-5 offset-lg-5 col-md-8 offset-md-3">
        <div>
          <h3 class="medium-heading">
            {{ content?.body?.sections[1]?.sections[0]?.heading }}
          </h3>
          <div
            [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
          ></div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-5 offset-lg-5 col-md-8 offset-md-3">
        <div>
          <h3 class="medium-heading">
            {{ content?.body?.sections[1]?.sections[1]?.heading }}
          </h3>
          <div
            [innerHTML]="content?.body?.sections[1]?.sections[1]?.content"
          ></div>

          <div
            class="mt-3"
            *ngFor="
              let contacts of content?.body?.sections[1]?.sections[1]?.contact
            "
          >
            <h3>{{ contacts.heading }}</h3>
            <p class="mb-0">{{ contacts.title }}</p>
            <a class="cyan" href="tel:{{ contacts.contactNumber }}">{{
              contacts.contactNumber
            }}</a
            ><span
              ><a class="black ml-1"
                ><strong>{{ contacts.emailAddress }}</strong></a
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Shareholder communication -->

<!-- Corporate information -->
<div class="container-fluid py-5 mt-5 greybg-right">
  <div class="container-xl">
    <div class="row justify-content-end">
      <div class="col-md-8 col-lg-6">
        <div class="heading-border-right d-flex justify-content-end">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[2]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-8 col-md-9">
        <div class="table-responsive">
          <!-- -------------------- -->
          <table class="table table-bordered table-hover">
            <div
              [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
            ></div>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Corporate information -->

<!-- Listing information -->
<div class="container-fluid py-5 mt-5 greybg-left">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-6">
        <div class="heading-border-left d-inline-flex">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[3]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="col-lg-8 offset-lg-4 col-md-9 offset-md-3"
        style="width: 100%"
      >
        <div class="table-responsive">
          <!-- -------------------- -->
          <table class="table table-bordered table-hover">
            <div
              [innerHTML]="content?.body?.sections[3]?.sections[0]?.content"
            ></div>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Listing information -->

<!-- Corporate actions -->
<div class="container-fluid py-5 mt-5 greybg-right">
  <div class="container-xl">
    <div class="row justify-content-end">
      <div class="col-md-6">
        <div class="heading-border-right d-flex justify-content-end">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[4]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-5 offset-lg-2 col-md-7">
        <div>
          <h4 class="small-heading">
            {{ content?.body?.sections[4]?.sections[0]?.subheading }}
          </h4>
          <div
            [innerHTML]="content?.body?.sections[4]?.sections[0]?.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Corporate actions -->
