import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-client-think-big',
  templateUrl: './lead-client-think-big.component.html'
})
export class LeadClientThinkBigComponent implements OnInit {

  url = 'https://forms.office.com/r/yakcUkvFba';

  constructor() { }

  ngOnInit() {
  }

}
