<!--PSG  funds-other-currencies -->
<div class="w-100">
  <div class="significant-raise table-card h-100">
    <!-- tabs -->
    <div
      class="d-flex flex-column flex-md-row align-items-start align-items-md-end justify-content-between"
    >
      <div class="pl-2 large-heading">
        <h2>Invest in other currencies</h2>
        <p class="body-med mb-0">
          The following funds invest internationally using foreign currency
        </p>
      </div>
      <div>
        <tab-horisontal
          [hidden]="showOnlyPriceAndPerformance == true"
          [tabSelected]="currentTab"
          (tabSelectedChange)="selectedTabChanged($event)"
          [tabs]="pageTabs"
          class="tab-nowrap"
        >
        </tab-horisontal>
      </div>
    </div>
    <!-- tabs -->

    <!--tables-->
    <div class="mt-4" [ngSwitch]="currentTab">
      <!-- overview -->
      <div *ngSwitchCase="'Overview'">
        <overview-table
          locality="OTHER-CURRENCIES"
          category="PSG-FUND"
        ></overview-table>
      </div>
      <!-- /overview -->

      <!-- Price and performance -->
      <div *ngSwitchCase="'Price and performance'">
        <div>
          <price-and-performance-table
            locality="OTHER-CURRENCIES"
            category="PSG-FUND"
          >
          </price-and-performance-table>
        </div>
      </div>
      <!-- /Price and performance -->
    </div>
    <!--tables-->
    <div *ngIf="showButton">
      <p
        class="body-small mb-0"
        [innerHTML]="content?.body?.sections[3]?.sections[2]?.content"
      ></p>
      <div class="container">
        <div class="row">
          <ng-container
            *ngFor="
              let action of content?.body?.sections[3]?.sections[2]?.actions
            "
          >
            <button-text
              class="mr-2 mb-2"
              buttonStyle="{{
                action?.buttonColor != null
                  ? action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="!showButton">
      <div [innerHTML]="contentInput?.body?.sections[5]?.disclaimer"></div>
    </div>
  </div>
</div>
<!--/PSG  funds-other-currencies -->
