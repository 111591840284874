<!--diu video-->
<div class="container-fluid my-4 py-4  h-100">
    <div class="container-xl">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/isPLDCWvqlQ"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- breacrumbs -->
            <div class=" col-md-10 offset-md-1 mt-4">
                <ul class="breadcrumb pl-0">
                    <li>
                        <a [routerLink]="['/news-and-publications']">News & publications</a>
                    </li>
                    <icon-font name="icon-angle-right"></icon-font>
                    <li>
                        <a [routerLink]="['/daily-investment-update']">Daily investment update</a>
                    </li>
                    <icon-font name="icon-angle-right"></icon-font>
                    <li class="breadcrumb-active">Recent investment ideas</li>
                </ul>
            </div>
            <!-- /breacrumbs -->

        </div>


    </div>
</div>
<!--diu video-->

<!--article-->
<div class="container-fluid">
    <div class="container-xl h-100">
        <div class="row d-flex align-items-center justify-content-center h-100">
            <div class="col-md-10 offset-md-1">

                <h2 class="cyan">Highlights</h2>

                <ul class="dot-list">
                    <li>We recommend a neutral position for both Naspers and Prosus. Click here to read the full
                        report
                        on our secure site or log in to myBase.
                    </li>

                    <li>Despite the regulatory and political risks that come with investing in Chinese stocks, we
                        acknowledge that, to a large extent, Prosus and Naspers provide an alternative risk and
                        reward
                        profile due to the exposure to structural growth trends, which are not available through
                        alternative local counters.
                    </li>

                    <li>Naspers and Prosus trade at discounts of 23% and 13% respectively.</li>

                    <li>Given that management is determined to reduce the discounts over time, we suspect there will
                        eventually be large value unlock for shareholders.
                    </li>

                    <li>The share price for both Naspers and Prosus moves with a fairly high correlation to the
                        USD/ZAR
                        exchange rate
                    </li>


                </ul>
            </div>

        </div>
    </div>
</div>
<!--article-->

<!-- Recommended news -->
<div class="container-fluid my-5 py-5 slate-bg-10">
    <div class="container-xl h-100">
        <div class="row d-flex align-items-center justify-content-left h-100">

            <h2 class="mb-4">Recommended news</h2>

            <div class="card-deck">
                <!--col 1-->
                <div class="card news--card">
                    <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                        alt="Card image cap">
                    <div class="card-body">
                        <div class="card-tags pb-2">
                            <span class="basic-badge">News</span>
                            <span class="basic-badge">finance</span>
                        </div>
                        <h5 class="small-heading">This is a catchy news article heading</h5>
                        <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
                            sint.
                            Velit officia
                            consequat duis enim velit mollit.</p>
                        <a [routerLink]="['/article']" class="textlink">Read more</a>
                    </div>
                </div>

                <!--col 2-->
                <div class="card news--card">
                    <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                        alt="Card image cap">
                    <div class="card-body">
                        <div class="card-tags pb-2">
                            <span class="basic-badge">News</span>
                            <span class="basic-badge">finance</span>
                        </div>
                        <h5 class="small-heading">This is a catchy news article heading</h5>
                        <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
                            sint.
                            Velit officia
                            consequat duis enim velit mollit.</p>
                        <a href="#" class="textlink">Read more</a>
                    </div>
                </div>

                <!--col 3-->
                <div class="card news--card">
                    <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                        alt="Card image cap">
                    <div class="card-body">
                        <div class="card-tags pb-2">
                            <span class="basic-badge">News</span>
                            <span class="basic-badge">finance</span>
                        </div>
                        <h5 class="small-heading">This is a catchy news article heading</h5>
                        <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
                            sint.
                            Velit officia
                            consequat duis enim velit mollit.</p>
                        <a href="#" class="textlink">Read more</a>
                    </div>
                </div>

                <!--col 4-->
                <div class="card news--card">
                    <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                        alt="Card image cap">
                    <div class="card-body">
                        <div class="card-tags pb-2">
                            <span class="basic-badge">News</span>
                            <span class="basic-badge">finance</span>
                        </div>
                        <h5 class="small-heading">This is a catchy news article heading</h5>
                        <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
                            sint.
                            Velit officia
                            consequat duis enim velit mollit.</p>
                        <a href="#" class="textlink">Read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Recommended news -->