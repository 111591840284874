<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="container-fluid research-insights--hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div class="row d-flex align-content-start justify-content-center h-100">
      <div class="col-md-12 mt-5">
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title equity-research-header white text-shadow"
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl research-insights--card-row">
  <div class="row d-flex">
    <div
      class="col-md-12"
      *ngIf="content?.header?.headerBannerContent.length > 0"
    >
      <div class="research-insights-hero-card">
        <h3 class="medium-heading cyan">
          {{ content?.header?.headerBannerContent[0].heading }}
        </h3>
        <p>{{ content?.header?.headerBannerContent[0].headline }}</p>
        <div
          [innerHTML]="content?.header?.headerBannerContent[0].content"
        ></div>
      </div>
    </div>
  </div>
</div>

<app-latest-reports
  [latestReports]="latestReports"
  [keywords]="latestReportsKeywords"
>
</app-latest-reports>

<app-previous-reports
  *ngIf="previousReports != null"
  [previousReports]="previousReports"
>
</app-previous-reports>

<app-previous-publications
  *ngIf="relatedArticles != null"
  [previousPublications]="relatedArticles"
>
</app-previous-publications>

<div class="container-xl py-5">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>
