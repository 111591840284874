import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { GlossaryPage } from "src/app/models/content/support/glossary-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-glossary",
  templateUrl: "./glossary.component.html",
  styles: [],
})
export class GlossaryComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<GlossaryPage>(pageService.URIs.glossaryPage)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new GlossaryPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }

  public concat(name: string, numberOne: number, numberTwo: number): string {
    return name.concat(numberOne.toString(), numberTwo.toString());
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
