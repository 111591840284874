import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SingleManagedFundsPage } from "src/app/models/content/funds/single-managed-funds-page.model";
import { PageService } from "src/app/services/page.service";
import { DOCUMENT } from "@angular/common";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";
@Component({
  selector: "app-psg-single-managed-funds",
  templateUrl: "./psg-single-managed-funds.component.html",
})
export class PsgSingleManagedFundsComponent implements OnInit, AfterViewInit {
  public content: any = {};
  public fragment: string;
  public isPageLoading: boolean = true;

  constructor(
    @Inject(DOCUMENT) document,
    private route: ActivatedRoute,
    public pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<SingleManagedFundsPage>(pageService.URIs.singleManagedFunds)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new SingleManagedFundsPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });

    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
    });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  ngAfterViewInit() {
    this.scrollTimer();
  }

  public viewQuarterlyCommentary() {
    window.open(
      "https://download.psg.co.za/files/asset-management/quarterly-portfolio-commentary/Combined-Global-Quarterly-Portfolio-Commentaries.pdf"
    );
  }

  public viewLocalFundsQuarterlyCommentary(url: string) {
    window.open(url);
  }

  public async scrollTimer() {
    setTimeout(() => {
      let doc = document.getElementById(this.fragment);
      if (doc != undefined) {
        window.scrollTo(0, doc.offsetTop - 120);
      } else {
        this.scrollTimer();
      }
    }, 1000);
  }
}
