<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- about hero section -->
<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid aboutUs-Psg py-5 py-md-0"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-start justify-content-md-center h-100"
        *ngIf="content.header !== undefined"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
          <h2
            class="large-heading text-shadow"
            [ngClass]="
              content?.header?.subheadingColour
                ? content?.header?.subheadingColour
                : 'white'
            "
          >
            {{ content?.header?.subheading }}
          </h2>
          <div
            [innerHTML]="content?.header?.content"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
          <div>
            <ng-container *ngFor="let action of content?.header?.actions">
              <button-text
                fragment="who-are-we"
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }} mr-2"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- about hero section -->

<div
  *ngIf="!isPageLoading"
  class="container-xl aboutUs-psg-row"
  id="who-are-we"
>
  <div class="row align-items-end">
    <div
      class="col-md-6 col-lg-5 offset-lg-1 mb-4 mb-sm-0"
      *ngIf="content?.header?.cards?.length > 0"
    >
      <div class="aboutUsCard">
        <h2 class="section-title mb-4">
          {{ content?.header?.cards[0]?.heading }}
        </h2>
        <div [innerHTML]="content?.header?.cards[0]?.content"></div>

        <div class="mt-4">
          <ng-container
            *ngFor="let action of content?.header?.cards[0]?.actions"
          >
            <button-text
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-5">
      <div
        class="aboutUsCard about-2 mb-4"
        *ngIf="content?.header?.cards?.length > 0"
      >
        <h3 class="large-heading">{{ content?.header?.cards[1]?.heading }}</h3>
        <div [innerHTML]="content?.header?.cards[1]?.content"></div>
        <div class="mt-4">
          <ng-container
            *ngFor="let action of content?.header?.cards[1]?.actions"
          >
            <button-text
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>

      <div
        class="aboutUsCard about-3"
        *ngIf="content?.header?.cards?.length > 0"
      >
        <h3 class="medium-heading">{{ content?.header?.cards[2]?.heading }}</h3>
        <div [innerHTML]="content?.header?.cards[2]?.content"></div>

        <div class="mt-4">
          <ng-container
            *ngFor="let action of content?.header?.cards[2]?.actions"
          >
            <button-text
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- about hero section -->

<!-- Strategy & values -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 h-100"
  id="strategy-and-values"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="container-xl mt-4">
      <div class="row h-100">
        <div class="col-md-5">
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
          <!-- download link -->
          <div
            class="d-flex align-items-center product-dl"
            *ngFor="
              let download of content?.body?.sections[0]?.sections[0]?.actions
            "
          >
            <icon-font
              name="icon-file-download"
              colour="cyan"
              class="icon-dl mr-2"
            >
            </icon-font>
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              *ngIf="download?.url"
              [href]="download?.url"
              >{{ download?.label }}</a
            >
            <a
              class="textlink body-med body-bold text-dl"
              target="_blank"
              [href]="download?.file"
              *ngIf="download?.file"
              >{{ download?.label }}</a
            >
          </div>
        </div>
        <div class="col-md-3 offset-md-1">
          <div
            class="d-flex flex-column flex-fill card-round white-bg p-4 slight-raise h-100 justify-content-center"
          >
            <h3 class="large-heading cyan mb-3">
              {{ content?.body?.sections[0]?.sections[1]?.cards[0]?.heading }}
            </h3>
            <div
              [innerHTML]="
                content?.body?.sections[0]?.sections[1]?.cards[0]?.content
              "
            ></div>
          </div>
        </div>
        <div class="col-md-3">
          <div
            class="d-flex flex-column flex-fill card-round white-bg p-4 slight-raise h-100 justify-content-center"
          >
            <h3 class="large-heading cyan mb-3">
              {{ content?.body?.sections[0]?.sections[1]?.cards[1]?.heading }}
            </h3>
            <div
              [innerHTML]="
                content?.body?.sections[0]?.sections[1]?.cards[1]?.content
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- empty space -->
<div class="container-fluid py-3 my-3"></div>
<!-- empty space -->

<!-- Our core business principles -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-right">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[1]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row h-100 mt-4">
      <div class="col-lg-12 mb-4">
        <div
          class="d-flex align-items-md-center align-items-start card-round slight-raise p-4 h-100"
        >
          <div>
            <p
              class="section-title--mobile cyan mr-3 mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[0]?.number"
            ></p>
          </div>
          <div>
            <p
              class="medium-heading mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[0]?.heading"
            ></p>
            <p
              class="mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[0]?.content"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 mb-4">
        <div
          class="d-flex align-items-md-center align-items-start card-round slight-raise p-4 h-100"
        >
          <div>
            <p
              class="section-title--mobile cyan mr-3 mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[1]?.number"
            ></p>
          </div>
          <div>
            <p
              class="mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[1]?.content"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 mb-4">
        <div
          class="d-flex align-items-md-center align-items-start card-round slight-raise p-4 h-100"
        >
          <div>
            <p
              class="section-title--mobile cyan mr-3 mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[2]?.number"
            ></p>
          </div>
          <div>
            <p
              class="mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[2]?.content"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 mb-4">
        <div
          class="d-flex align-items-md-center align-items-start card-round slight-raise p-4 h-100"
        >
          <div>
            <p
              class="section-title--mobile cyan mr-3 mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[3]?.number"
            ></p>
          </div>
          <div>
            <p
              class="mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[3]?.content"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 mb-4">
        <div
          class="d-flex align-items-md-center align-items-start card-round slight-raise p-4 h-100"
        >
          <div>
            <p
              class="section-title--mobile cyan mr-3 mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[4]?.number"
            ></p>
          </div>
          <div>
            <p
              class="mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[4]?.content"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 mb-4">
        <div
          class="d-flex align-items-md-center align-items-start card-round slight-raise p-4 h-100"
        >
          <div>
            <p
              class="section-title--mobile cyan mr-3 mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[5]?.number"
            ></p>
          </div>
          <div>
            <p
              class="mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[5]?.content"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 mb-4">
        <div
          class="d-flex align-items-md-center align-items-start card-round slight-raise p-4 h-100"
        >
          <div>
            <p
              class="section-title--mobile cyan mr-3 mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[6]?.number"
            ></p>
          </div>
          <div>
            <p
              class="mb-0"
              [innerHTML]="content?.body?.sections[1]?.cards[6]?.content"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Our core business principles -->
</div>
<!-- Our core business principles -->

<div *ngIf="!isPageLoading" class="container-xl my-5 py-5">
  <blockquote class="blockquote large">
    <span class="cyan">“</span> {{ content?.body?.sections[2]?.content }}
    <span class="cyan">”</span>
  </blockquote>
</div>

<!-- Our clients are our priority  -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5 greybg-right">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-right">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[3]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row h-100 mt-4">
      <div class="col-lg-5">
        <h3 class="medium-heading">
          {{ content?.body?.sections[3]?.sections[0]?.heading }}
        </h3>
        <div
          [innerHTML]="content?.body?.sections[3]?.sections[0]?.content"
        ></div>
      </div>
    </div>
    <div class="row h-100 mt-4">
      <img
        [src]="content?.body?.sections[3]?.image"
        class="img-fluid significant-raise about-us-fw"
      />
    </div>
    <div class="row h-100 mt-4 pt-md-4">
      <div class="col-lg-4">
        <h3 class="medium-heading">
          {{ content?.body?.sections[3]?.sections[1]?.heading }}
        </h3>
        <div
          [innerHTML]="content?.body?.sections[3]?.sections[1]?.content"
        ></div>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <h3 class="medium-heading">
          {{ content?.body?.sections[3]?.sections[2]?.heading }}
        </h3>
        <div
          [innerHTML]="content?.body?.sections[3]?.sections[2]?.content"
        ></div>
      </div>
    </div>
  </div>
</div>
<!-- /Our clients are our priority  -->

<!-- Meet the team -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5"
  id="meet-the-team"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-8">
        <div class="heading-border-left">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[4]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-5">
        <h3 class="medium-heading">
          {{ content?.body?.sections[4]?.sections[0]?.heading }}
        </h3>
        <div
          [innerHTML]="content?.body?.sections[4]?.sections[0]?.content"
        ></div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <h3 class="large-heading cyan">
          {{ content?.body?.sections[4]?.sections[1]?.heading }}
        </h3>
        <div
          [innerHTML]="content?.body?.sections[4]?.sections[1]?.content"
        ></div>
      </div>
    </div>
  </div>

  <!-- team images directors -->
  <div class="container-xl mb-4">
    <div class="row">
      <div
        class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-4"
        *ngFor="
          let director of content?.body?.sections[4]?.sections[1]?.members;
          let i = index
        "
      >
        <!-- team member card -->
        <div class="p-4 p-md-0">
          <div
            data-toggle="modal"
            data-target="#modal-standard-sm"
            class="team-flipCard-bg-img"
            [style.backgroundImage]="'url(' + director?.image + ')'"
            (click)="openTeamMemberModal(director)"
          >
            <div class="card-black-layer">
              <h5 class="white mb-2">{{ director?.name }}</h5>
            </div>
          </div>
          <!-- /team member card -->
        </div>
      </div>
    </div>
    <!-- /team images directors -->

    <!-- team images commitee -->
    <div class="container-xl mb-4">
      <div class="row">
        <div class="col-md-6">
          <h3 class="large-heading cyan">
            {{ content?.body?.sections[4]?.sections[2]?.heading }}
          </h3>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-4"
          *ngFor="
            let manager of content?.body?.sections[4]?.sections[2]?.members;
            let i = index
          "
        >
          <!-- team member card -->
          <div class="p-4 p-md-0">
            <div
              data-toggle="modal"
              data-target="#modal-standard-sm"
              class="team-flipCard-bg-img"
              [style.backgroundImage]="'url(' + manager?.image + ')'"
              (click)="openTeamMemberModal(manager)"
            >
              <div class="card-black-layer">
                <h5 class="white mb-2">{{ manager?.name }}</h5>
              </div>
            </div>
          </div>
          <!-- team member card -->
        </div>
      </div>
      <!-- /team images -->
    </div>
  </div>
</div>

<!-- Our divisions -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid my-5"
  id="business-divisions"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-right">
          <h2 class="mega-title--mobile text-right">
            <a id="divisions"></a> {{ content?.body?.sections[5]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row d-flex mt-5">
      <div class="col-md-4 mb-4">
        <div
          class="card-flat d-flex flex-column justify-content-between h-100 slight-raise"
        >
          <h3 class="medium-heading">
            {{ content?.body?.sections[5]?.cards[0]?.heading }}
          </h3>
          <div
            [innerHTML]="content?.body?.sections[5]?.cards[0]?.content"
          ></div>
          <div>
            <ng-container
              *ngFor="
                let action of content?.body?.sections[5]?.cards[0]?.actions
              "
            >
              <button-text
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div
          class="card-flat d-flex flex-column justify-content-between white-bg h-100 slight-raise"
        >
          <h3 class="medium-heading">
            {{ content?.body?.sections[5]?.cards[1]?.heading }}
          </h3>
          <div
            [innerHTML]="content?.body?.sections[5]?.cards[1]?.content"
          ></div>
          <div>
            <ng-container
              *ngFor="
                let action of content?.body?.sections[5]?.cards[1]?.actions
              "
            >
              <button-text
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4">
        <div
          class="card-flat d-flex flex-column justify-content-between white-bg h-100 slight-raise"
        >
          <h3 class="medium-heading">
            {{ content?.body?.sections[5]?.cards[2]?.heading }}
          </h3>
          <div
            [innerHTML]="content?.body?.sections[5]?.cards[2]?.content"
          ></div>
          <div>
            <ng-container
              *ngFor="
                let action of content?.body?.sections[5]?.cards[2]?.actions
              "
            >
              <button-text
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Our divisions -->

<!--  Recommended/Latest news-cards section -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 slate-bg-10">
  <div class="container-xl">
    <div>
      <h2 class="mb-4">{{ content?.body?.sections[6]?.heading }}</h2>
    </div>
    <div class="card-deck">
      <div
        class="card news--card"
        *ngFor="let news of content?.body?.sections[6]?.articles"
      >
        <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
        <div class="card-body d-flex flex-column">
          <div class="card-tags pb-2">
            <span
              *ngFor="let category of news?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div>
          <h5 class="small-heading">{{ news?.headline }}</h5>
          <p class="body-small" [innerHTML]="news?.excerpt"></p>
          <a [routerLink]="news?.uri" target="_blank" class="textlink mt-auto"
            >Read more</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Recommended/Latest news-cards section -->

<!-- team memmber's modal -->
<div
  *ngIf="!isPageLoading"
  class="modal fade"
  id="modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <!-- modal container -->
    <div class="modal-content">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading"></h5>
      </div>
      <!-- / modal header -->

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <div class="row">
          <div class="col-md-4">
            <div
              class="img-size"
              [style.backgroundImage]="'url(' + teamMember?.image + ')'"
            ></div>
            <!--title -->
            <h4 class="mt-3 mb-0">{{ teamMember?.name }}</h4>
          </div>

          <div class="col-md-8">
            <!--content -->
            <p class="body-med mb-0" [innerHTML]="teamMember?.content"></p>
            <!-- member qualifications list-->
            <h5
              class="cyan"
              *ngIf="
                teamMember?.qualifications != null &&
                teamMember?.qualifications != ''
              "
            >
              Qualifications
            </h5>
            <p class="body-med" [innerHTML]="teamMember?.qualifications"></p>
          </div>
        </div>
      </div>
      <!-- / modal body -->

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button
          id="closeModal"
          type="button"
          class="button outline"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
      <!-- / modal footer -->
    </div>
    <!-- / modal container -->
  </div>
</div>
<!--/ team memmber's modal -->
