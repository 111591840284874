import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RetirementAnnuityPage } from 'src/app/models/content/save-and-invest/retirement-annuity-page.model';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: "app-retirement-annuity",
  templateUrl: "./retirement-annuity.component.html",
})
export class RetirementAnnuityComponent {
  public content: any = {};

  constructor(private pageService: PageService, private router: Router) {
    pageService
      .GetPage<RetirementAnnuityPage>(pageService.URIs.retirementAnnuity)
      .subscribe((content) => {
        this.content = new RetirementAnnuityPage(content);
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }
}
