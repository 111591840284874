<!-- articles search -->

<div class="container-fluid py-5 my-5">
  <div class="container-xl">
    <div class="row">
      <!--office  articles-->

      <h1 class="large-heading"> Latest Articles </h1> 
      <div class="col-md-12 mb-3" id="view-all">
        <div class="newsSearch--card h-100">
          <h2 class="large-heading">{{ officeArticlesHead?.heading }}</h2>
          <p [innerHTML]="officeArticlesHead?.content"></p>

          <!--search-->
          <div class="row">
            <div class="col-md-12">
              <form-field>
                <!-- Suffix - needs to be before the input -->
                <icon-font
                  name="icon-search"
                  colour="cyan"
                  class="suffix clickable"
                ></icon-font>
                <!-- Input -->
                <input
                  placeholder="Search by title or author"
                  (change)="titleOrAuthorSearch($event.target.value)"
                />
              </form-field>
            </div>
          </div>
          <!--/search-->

          <!--articles and dates-->
          <div class="result-scroll-section">
            <div
              class="article-search-results"
              *ngFor="let latestArticle of searchArticle"
            >
              <div class="row">
                <div class="col-md-2">
                  <h2 class="large-heading">
                    {{ latestArticle?.publishDate | amDateFormat: "DD" }}
                  </h2>
                  <p>{{ latestArticle?.publishDate | amDateFormat: "MMMM" }}</p>
                </div>
                <div class="col-md-10">
                  <div class="card-tags pb-2">
                    <span
                      *ngFor="let category of latestArticle?.categories"
                      role="button"
                      class="basic-badge"
                      data-toggle="modal"
                      data-target="#modal-article-tags"
                      (click)="
                        pageService.SetArticle(category);
                        $event.preventDefault()
                      "
                      >{{ category }}
                    </span>
                  </div>
                  <a
                    class="small-heading black"
                    (click)="goToURI(latestArticle?.uri)"
                    >{{ latestArticle?.headline }}</a
                  >
                  <p class="black">{{ latestArticle?.author?.name }}</p>
                  <p
                    class="slate b-clamp"
                    [innerHTML]="latestArticle?.excerpt"
                  ></p>
                </div>
                <hr />
              </div>
            </div>
          </div>

          <!--articles and dates-->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- articles search-->
