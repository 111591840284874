<div class="container-fluid slate-bg-10 py-4 my-4">
    <div class="container-xl">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-4"  *ngIf="faqList != null">
            {{ faqList.heading }}
           </h2>
        </div>
      </div>
      <div
        class="card-deck"
        *ngIf="faqList?.cards?.length > 0"
      >
        <div
          class="card news--card"
          *ngFor="let faq of faqList.cards"
        >
          <div class="card-body d-flex flex-column">
            <h5 class="small-heading mb-3">
              {{ faq.heading }}
            </h5>
            <p>{{ faq.subheading }}</p>
            <p class="body-small" [innerHTML]="faq.content"></p>
            <div class="btn-row mt-auto">
              <button-text
                *ngFor="
                  let action of faq.actions
                "
                class="mr-2"
                buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>