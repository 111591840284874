import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'latestReportFilter',
    pure: false
})

export class LatestReportFilterPipe implements PipeTransform {
    transform(items: any[], filter: string[]): any {          
        if (!items || !filter || filter.length < 1) {
            return items;
        }

     return items.filter((item) => {
           return filter.find((f) => {
            let keyword = f.toLowerCase();
                if (item.content?.toLowerCase().replace(/(<([^>]+)>)/ig, '').includes(keyword)
                    || item.heading?.toLowerCase().includes(keyword)
                    || item.headline?.toLowerCase().includes(keyword)
                    || item.subheading?.toLowerCase().includes(keyword))
                    return item;
            })
        });
    }
}