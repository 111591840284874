<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isPageLoading">
  <!--  hero section -->
  <div
    class="container-fluid start-your-journey-hero"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        *ngIf="content.header !== undefined"
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div class="heading-border-left">
          <h1
            class="mega-title--page-title start-your-journey-header white text-shadow"
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div
          [innerHTML]="content?.header?.content"
          class="pl-md-4 white p-length-xl"
        ></div>
      </div>
    </div>
  </div>
  <!--/  hero section -->

  <!-- content section -->
  <div *ngIf="content.body != null && content.body.sections.length > 0">
    <div class="container-xl py-2 my-2 py-md-4 my-md-4">
      <div class="row">
        <div class="col-md-8 pl-0">
          <div class="heading-border-left">
            <h1
              class="mega-title--page-title start-your-journey-content-heading grey"
            >
              {{ content?.body?.contentHeading }}
            </h1>
          </div>
          <h3>{{ content?.body?.sections[0]?.heading }}</h3>
          <div [innerHTML]="content?.body?.sections[0]?.content"></div>
          <div *ngFor="let action of content?.body?.sections[0]?.actions">
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }} mr-2"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
          <h3 class="mt-4">{{ content?.body?.sections[1]?.heading }}</h3>
          <div [innerHTML]="content?.body?.sections[1]?.content"></div>
          <div *ngFor="let action of content?.body?.sections[1]?.actions">
            <button-text
              buttonStyle="{{ !action.isPrimary ? 'solid dark-blue' : '' }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
        <div class="col-md-4">
          <img
            [src]="content?.body?.contentImage"
            class="img-fluid mt-4 mt-md-0"
          />
        </div>
      </div>
    </div>
    <!-- content section -->
  </div>
</div>
