import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-asset-management-client-adviser',
  templateUrl: './lead-asset-management-client-adviser.component.html'
})
export class LeadAssetManagementClientAdviserComponent implements OnInit {

  url = 'https://forms.office.com/r/2nk66UDWT6';

  constructor() { }

  ngOnInit() {
  }

}
