export class InvestorInformationPage {
  header: any = {};
  body: any = {};

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    if (this.header.content)
      this.header.content = this.header.content.replace(
        /<p>/g,
        '<p class="p-length-sm text-shadow">'
      );

    this.body.sections[0].categories[0].sections[0].events.forEach(event => {
      event.content = event.content.replace(/<p>/g, '');
    });

    this.body.sections[0].categories[1].sections[0].events.forEach(event => {
      event.content = event.content.replace(/<p>/g, '');
    });

    this.body.sections[0].categories[2].sections[0].events.forEach(event => {
      event.content = event.content.replace(/<p>/g, '');
    });

    this.body.sections[0].categories[2].sections[1].events.forEach(event => {
      event.content = event.content.replace(/<p>/g, '');
    });
  }
}
