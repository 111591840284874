<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!--  hero section -->
<div *ngIf="content?.header !== undefined && !isPageLoading">
  <div
    class="container-fluid corp-governance-hero"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title corpG mb-1 text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="pl-4 pl-md-4">
          <div
            [innerHTML]="content?.header?.content"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
          <div class="btn-row">
            <ng-container *ngFor="let action of content?.header?.actions">
              <button-text
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action.isPrimary ? 'solid' : 'outline inverse'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- hero section -->

<div *ngIf="!isPageLoading" class="container-xl corp-governance-hero-card">
  <div
    class="col-md-8 offset-md-4 col-lg-6 offset-lg-6"
    *ngIf="content?.header?.cards?.length > 0"
  >
    <div class="blur-card">
      <h2 class="large-heading">{{ content?.header?.cards[0]?.heading }}</h2>
      <div [innerHTML]="content?.header?.cards[0]?.content"></div>
    </div>
  </div>
</div>

<!-- Community -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 mt-5 greybg-left">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-6">
        <div class="heading-border-left d-inline-flex">
          <h2 class="section-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row align-items-lg-center">
      <div class="col-md-6 col-lg-6 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[0]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-5 offset-lg-1">
        <h2 class="medium-heading">
          {{ content?.body?.sections[0]?.sections[0]?.heading }}
        </h2>
        <div
          [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
        ></div>
        <div class="btn-row">
          <ng-container
            *ngFor="
              let action of content?.body?.sections[0]?.sections[0]?.actions
            "
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{ action.isPrimary ? 'solid' : 'outline inverse' }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Community -->

<!-- quote -->
<div *ngIf="!isPageLoading" class="container-xl my-5">
  <div class="col-md-11 offset-md-1">
    <blockquote class="blockquote large text-right">
      <span class="cyan">“</span> {{ content?.body?.sections[1]?.content }}
      <span class="cyan">”</span>
    </blockquote>
  </div>
</div>
<!-- /quote -->

<!-- Environment -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 mt-5 greybg-right">
  <div class="container-xl">
    <div class="row align-items-lg-center">
      <div class="col-md-6 col-lg-5 order-1 order-md-0">
        <h2 class="medium-heading">
          {{ content?.body?.sections[2]?.sections[0]?.heading }}
        </h2>
        <div
          [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
        ></div>
      </div>
      <div class="col-md-6 col-lg-6 offset-lg-1 order-0 order-md-1 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[2]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Environment -->

<!-- Responsible investment -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 mb-5 greybg-left">
  <div class="container-xl">
    <div class="row align-items-lg-center">
      <div class="col-md-6 col-lg-6 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[3]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-5 offset-lg-1">
        <h2 class="medium-heading">
          {{ content?.body?.sections[3]?.sections[0]?.heading }}
        </h2>
        <div
          [innerHTML]="content?.body?.sections[3]?.sections[0]?.content"
        ></div>
      </div>
    </div>
  </div>
</div>
<!-- /Responsible investment -->

<div *ngIf="!isPageLoading" class="container-fluid py-5 slate-bg-10">
  <div class="container-xl">
    <div>
      <h2 class="mb-4">{{ content?.body?.sections[4]?.heading }}</h2>
    </div>
    <div class="card-deck">
      <div
        class="card news--card"
        *ngFor="let news of content?.body?.sections[4]?.articles"
      >
        <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
        <div class="card-body">
          <div class="card-tags pb-2">
            <span
              *ngFor="let category of news?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div>
          <h5 class="small-heading">{{ news?.headline }}</h5>
          <p class="body-small" [innerHTML]="news?.excerpt"></p>
          <a [routerLink]="news?.uri" class="textlink">Read more</a>
        </div>
      </div>
    </div>
  </div>
</div>
