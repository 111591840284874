<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- stockbroking hero section -->
<div
  class="container-fluid stockbroking-hero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
      *ngIf="content.header !== undefined"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title mb-1 text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>
<!-- stockbroking hero section -->

<!-- cards -->
<div
  class="container-xl h-100 stockbroking-hero--cards"
  *ngIf="content.header !== undefined && !isPageLoading"
>
  <div class="row h-100">
    <div
      class="col-md-5 offset-md-1 mb-4"
      *ngIf="content.header.cards.length > 0"
    >
      <div class="blur-card">
        <div>
          <h2 class="section-title--stockbroking">
            {{ content?.header?.cards[0]?.heading }}
          </h2>
          <div [innerHTML]="content.header.cards[0].content"></div>
        </div>
        <div>
          <button-text
            *ngFor="let action of content?.header?.cards[0]?.actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
    <div class="col-md-5 mb-4">
      <div class="blur-card mb-4" *ngIf="content.header.cards.length > 0">
        <div>
          <h3 class="large-heading">
            {{ content?.header?.cards[1]?.heading }}
          </h3>
          <div [innerHTML]="content?.header?.cards[1]?.content"></div>
        </div>
        <div>
          <button-text
            *ngFor="let action of content?.header?.cards[1]?.actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
      <!-- ----------- -->
      <div class="blur-card" *ngIf="content.header.cards.length > 0">
        <div>
          <h3 class="large-heading">
            {{ content?.header?.cards[2]?.heading }}
          </h3>
          <div [innerHTML]="content?.header?.cards[2]?.content"></div>
        </div>
        <div>
          <button-text
            *ngFor="let action of content?.header?.cards[2]?.actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /cards -->

<!-- Our stockbroking platform  -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid pt-5 pb-5 mb-5 svg-bg-l stock-br"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-8">
        <div class="heading-border-left d-inline-flex justify-content-start">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[0]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>

      <div class="col-md-5 offset-md-1">
        <div>
          <h2 class="large-heading mb-2">
            {{ content?.body?.sections[0]?.sections[0]?.heading }}
          </h2>
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
        </div>
      </div>
    </div>

    <div class="row h-100 mt-4">
      <div class="col-md-6 mb-4">
        <div
          class="card-round noticeable-raise white-bg p-4 p-md-5 h-100 d-flex flex-column justify-content-between"
        >
          <div>
            <h3 class="large-heading">
              {{ content?.body?.sections[0]?.sections[1]?.cards[0]?.heading }}
            </h3>
            <div
              [innerHTML]="
                content?.body?.sections[0]?.sections[1]?.cards[0]?.content
              "
            ></div>
          </div>
          <div>
            <ng-container
              *ngFor="
                let action of content?.body?.sections[0]?.sections[1]?.cards[0]
                  ?.actions
              "
            >
              <button-text
                fragment="who-are-we"
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="card-round noticeable-raise white-bg p-4 p-md-5">
          <h3 class="large-heading">
            {{ content?.body?.sections[0]?.sections[1]?.cards[1]?.heading }}
          </h3>
          <p
            class="mb-1"
            [innerHTML]="
              content?.body?.sections[0]?.sections[1]?.cards[1]?.content
            "
          ></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Our stockbroking platform  -->

<!-- Do your home work -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5 svg-bg-r stock-b">
  <div class="container-xl">
    <div class="row align-items-center">
      <div class="col-md-5 order-1 order-md-0">
        <h3 class="large-heading">
          {{ content?.body?.sections[1]?.sections[0]?.heading }}
        </h3>
        <div
          [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
        ></div>
        <div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[1]?.sections[0]?.actions
            "
          >
            <button-text
              fragment="who-are-we"
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6 offset-md-1 order-0 order-md-1 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[1]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Do your home work -->

<!-- stockbroking options -->
<div *ngIf="!isPageLoading" class="container-fluid py-5">
  <div class="container-xl h-100">
    <div class="row d-flex">
      <div class="col-md-10 offset-1">
        <div class="heading-border-right">
          <h2 class="section-title--mobile text-right">
            A variety of stockbroking options to get you started
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl h-100 mt-5">
    <app-stockbroking-options></app-stockbroking-options>
  </div>
</div>

<!-- /stockbroking options -->

<!-- Managed equity stockbroking portfolio -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid pt-5 pb-5 mb-5 svg-bg-l stock-br2"
  id="managed-equity-stockbroking-portfolio"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-10">
        <div class="heading-border-left d-inline-flex justify-content-start">
          <h2 class="section-title--mobile">
            {{ content?.body?.sections[3]?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[3]?.sections[0]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>

      <div class="col-md-5 offset-md-1">
        <div
          [innerHTML]="
            content?.body?.sections[3]?.sections[0]?.sections[0]?.content
          "
        ></div>
        <div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[3]?.sections[0]?.sections[0]
                ?.actions
            "
          >
            <button-text
              fragment="who-are-we"
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <h3 class="medium-heading">
          {{ content?.body?.sections[3]?.sections[1]?.heading }}
        </h3>
      </div>
    </div>
    <div class="row h-100 mt-4">
      <div
        class="col-6 col-md-3 mb-4"
        *ngFor="let card of content?.body?.sections[3]?.sections[1]?.cards"
      >
        <div
          class="card-round noticeable-raise white-bg p-4 p-md-4 h-100 d-flex flex-column justify-content-around"
        >
          <p class="body-large" [innerHTML]="card?.content"></p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center mt-4">
      <div class="col-md-6 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[3]?.sections[2]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>

      <div class="col-md-5 offset-md-1">
        <h3 class="small-heading">
          {{ content?.body?.sections[3]?.sections[2]?.sections[0]?.heading }}
        </h3>
        <!-- cards -->
        <div class="d-flex flex-row py-4">
          <div
            class="d-flex flex-column align-items-center justify-content-center mt-2"
            *ngFor="
              let card of content?.body?.sections[3]?.sections[2]?.sections[0]
                ?.cards
            "
          >
            <div class="card-round white-bg noticeable-raise p-4 mr-3">
              <h4 class="text-center mb-1">{{ card?.heading }}</h4>
              <p
                class="body-bold body-small text-center cyan mb-0"
                [innerHTML]="card?.content"
              ></p>
            </div>
          </div>
        </div>

        <div>
          <p>
            Please speak to your adviser as these minimum investment
            requirements may differ between PSG offices.
          </p>
        </div>

        <div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[3]?.sections[2]?.sections[0]
                ?.actions
            "
          >
            <button-text
              fragment="who-are-we"
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Managed equity stockbroking portfolio  -->

<!-- Direct equity stockbroking portfolio -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 svg-bg-r stock-b"
  id="direct-equity-stockbroking-portfolio"
>
  <div class="container-xl h-100">
    <div class="row d-flex">
      <div class="col-md-9 offset-2">
        <div class="heading-border-right">
          <h2 class="section-title--mobile text-right">
            {{ content?.body?.sections[4]?.heading }}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl mt-4">
    <div class="row align-items-center">
      <div class="col-md-5 order-1 order-md-0">
        <div
          [innerHTML]="content?.body?.sections[4]?.sections[0].content"
        ></div>
        <div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[4]?.sections[0].actions
            "
          >
            <button-text
              fragment="who-are-we"
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6 offset-md-1 order-0 order-md-1 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[4]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ------------------ -->

<div *ngIf="!isPageLoading" class="container-xl mt-4 mb-5">
  <div class="row">
    <div class="col-md-7">
      <h3 class="section-title--stockbroking">
        {{ content?.body?.sections[5]?.heading }}
      </h3>
    </div>
  </div>
  <div class="mt-4">
    <app-trading-steps></app-trading-steps>
  </div>
</div>
<!-- /Direct equity stockbroking portfolio -->

<!-- Stockbroking accordions -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5">
  <div class="container-xl h-100">
    <div class="row d-flex">
      <div class="col-md-9 offset-2">
        <div class="heading-border-right">
          <h2 class="section-title--mobile text-right">
            {{ content?.body?.sections[6]?.heading }}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <!-- accordion How to invest in Local Shares -->
        <div
          class="accordion"
          [id]="'accordionLocalS' + removeWhiteSpace(item.heading)"
          *ngFor="let item of content?.body?.sections[6]?.items"
        >
          <div class="accordian-inner">
            <div [id]="'LocalS' + removeWhiteSpace(item.heading)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapseLocalS' + removeWhiteSpace(item.heading)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapseLocalS' + removeWhiteSpace(item.heading)
                  "
                >
                  {{ item.heading }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapseLocalS' + removeWhiteSpace(item.heading)"
              class="collapse"
              [attr.aria-labelledby]="'LocalS' + removeWhiteSpace(item.heading)"
              [attr.data-parent]="
                '#accordionLocalS' + removeWhiteSpace(item.heading)
              "
            >
              <div class="accordian-body">
                <div [innerHTML]="item?.content"></div>
                <div>
                  <ng-container *ngFor="let action of item?.actions">
                    <button-text
                      class="{{ action.isPrimary ? '' : 'mr-2' }}"
                      buttonStyle="{{
                        action?.buttonColor
                          ? 'solid ' + action?.buttonColor
                          : action.isPrimary
                          ? 'solid'
                          : 'outline'
                      }}"
                      [label]="action.label"
                      (click)="DynamicButtonClick(action)"
                    >
                    </button-text>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /accordion Local Shares -->
      </div>
    </div>
  </div>
</div>
<!-- /Stockbroking accordions -->
