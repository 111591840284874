export class TeamDetails {
  advisors: Array<TeamPerson>;
  support: Array<TeamPerson>;
  principles: Array<TeamPerson>;
}

export class TeamPerson {
  address: string;
  discipline_name: string;
  office_gps: string;
  office_name: string;
  office_phone: string;
  person_aboutme: string;
  person_dob: string;
  person_emailexch: string;
  person_enddate: string;
  person_gender: string;
  person_id: string;
  person_jobtitle: string;
  person_linkedin: string;
  person_name: string;
  person_phonecell: string;
  person_qualifications: string;
  person_startdatepsg: string;
  person_surname: string;
  role_ref: string;
  secondary_disciplines: Array<string>;
  team_id: string;
  team_name: string;
  web_team_name: string;
}

export class SendMailRequest {
  Name: string;
  Email: string;
  ContactNumber: string;
  Message: string;
  Subject: string;
  ToAddresses: Array<string>;
}


export class FormattedBranchAddress {

  constructor(address: string[]) {
    if (address[2] != "") {
      if (address[1] != "") {
        this.AddressLine1 = `Unit ${address[1]} ${address[2]}${this.determineFloorNumberPostFix(address[2])} Floor`;
      } else {
        this.AddressLine1 = `${address[2]}${this.determineFloorNumberPostFix(address[2])} Floor`;
      }
    }
    else {
      this.AddressLine1 = `${address[1]} ${address[2]}`;
    }
    this.AddressLine2 = address[3];
    this.AddressLine3 = `${address[4]} ${address[5]}`;
    this.AddressLine4 = address[6];
    this.AddressLine5 = address[7];
    this.AddressLine6 = address[8];
    this.AddressLine7 = address[9];
    this.AddressLine8 = address[10];
  }

  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  AddressLine4: string;
  AddressLine5: string;
  AddressLine6: string;
  AddressLine7: string;
  AddressLine8: string;


  private determineFloorNumberPostFix(floorNumber: string): string {
    if (floorNumber == "ground") {
      return "";
    }

    if (floorNumber == "1") {
      return "st";
    }

    if (floorNumber == "2") {
      return "nd";
    }
    if (floorNumber == "3") {
      return "rd";
    }
    if (floorNumber == "4") {
      return "th";
    }

    if (floorNumber == "" || floorNumber == undefined) {
      return "";
    }

    return "";

  }

}
