import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  forwardRef,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  SearchableItems,
  StyleguideSearchType,
} from "../shared/header/searchItems";

import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AdvisorService } from "src/app/services/advisor.service";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: [],
  providers: [
    SearchableItems,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true,
    },
  ],
})
export class SearchComponent implements OnInit {
  itemList = [
    "carrot",
    "banana",
    "apple",
    "potato",
    "tomato",
    "cabbage",
    "turnip",
    "okra",
    "onion",
    "cherries",
    "plum",
    "mango",
  ];

  public searchableItems: Array<StyleguideSearchType>;

  constructor(
    private searchaleItemsData: SearchableItems,
    private formBuilder: FormBuilder,
    private advisorService: AdvisorService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private pageService: PageService,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.seoService.updateMetaInfoForPage(
      "Search | PSG South Africa",
      window.location.href,
      null,
      "Search all psg."
    );

    this.searchableItems = this.searchaleItemsData.searchableItems;
    this.seoService.setJsonLd(this.renderer, null);
  }

  //  Search
  public searchOpen = false;
  public toggleSearch(id): void {
    this.searchOpen = !this.searchOpen;
    if (this.searchOpen) {
      document.getElementById(id).classList.add("show");
      document.getElementById("searchInput").focus();
    } else {
      document.getElementById(id).classList.remove("show");
    }
  }

  public searchValue: string;
  public searchedItems: StyleguideSearchType[] = [
    { category: "none", heading: "yyy", text: "ppp", routerLink: "" },
  ];
  public searchContainsGettingStarted = false;
  public searchContainsBrand = false;
  public searchContainsFoundation = false;
  public searchContainsComponent = false;
  public searchContainsNavigation = false;
  public searchContainsResources = false;

  public onSearchChanged(searchValue: string) {
    setTimeout(() => {
      this.searchedItems = [
        { category: "none", heading: "", text: "", routerLink: "" },
      ];
      this.searchContainsGettingStarted = false;
      this.searchContainsBrand = false;
      this.searchContainsFoundation = false;
      this.searchContainsComponent = false;
      this.searchContainsNavigation = false;
      this.searchContainsResources = false;

      if (searchValue.length > 0) {
        this.searchableItems.forEach((item) => {
          if (
            this.checkIfItemContained(item.category, searchValue) ||
            this.checkIfItemContained(item.heading, searchValue) ||
            this.checkIfItemContained(item.text, searchValue)
          ) {
            switch (item.category) {
              case "Product":
                this.searchContainsGettingStarted = true;
                break;
              case "News & Events":
                this.searchContainsBrand = true;
                break;
              case "Funds":
                this.searchContainsFoundation = true;
                break;
              case "Support":
                this.searchContainsComponent = true;
                break;
              case "Other":
                this.searchContainsNavigation = true;
                break;
              default:
                break;
            }

            if (this.searchedItems[0].category == "none") {
              this.searchedItems[0] = item;
            } else {
              this.searchedItems.push(item);
            }
          }
        });
      }
    });
  }

  private checkIfItemContained(item1, item2) {
    return item1.toLowerCase().includes(item2.toLowerCase());
  }
}
