import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { InvestmentCalculatorsPage } from "src/app/models/content/save-and-invest/investment-calculator.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-investment-calculators",
  templateUrl: "./investment-calculators.component.html",
  styles: [],
})
export class InvestmentCalculatorsComponent implements OnInit {
  public content: any = {};
  public shareHeadline: string;

  constructor(
    private pageService: PageService,
    private router: Router,
    public sanitizer: DomSanitizer,
    private hostElement: ElementRef,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<InvestmentCalculatorsPage>(
        pageService.URIs.investmentCalculatorsPage
      )
      .subscribe((content) => {
        this.content = new InvestmentCalculatorsPage(content);
        this.shareHeadline = this.content?.header?.headline?.replace(
          "&",
          "%26"
        ); // TODO: Temp workaround for & characters

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  public sanitizeURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }
}
