<div
  class="d-flex flex-row col-12 justify-content-center mb-5"
  *ngIf="!this.overViewData"
>
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="table-responsive" [hidden]="!this.overViewData">
  <!-- -------------------- -->
  <table class="table table-bordered table-hover">
    <thead>
      <tr>
        <th scope="col" class="overview-1">
          {{ tableCategory }}
        </th>
        <th scope="col" class="overview-2">Fund fact sheet</th>
        <th scope="col" *ngIf="showclass" class="overview-4 text-center">
          Class
        </th>
        <th scope="col" class="overview-4">Reg 28 compliant</th>
        <th scope="col" class="overview-4">Available for TFIP* investment</th>
        <th scope="col" class="overview-4">Only available via PSG advisers</th>
        <th scope="col" class="overview-6 text-center">TIC %</th>
      </tr>
    </thead>
    <tbody *ngFor="let row of this.overViewData?.AsisaCategories">
      <tr>
        <td class="body-small" [rowSpan]="row.Rows.length">
          {{ row.Category }}
        </td>
        <td>
          <a
            [href]="row.Rows[0].FundUrl"
            target="_blank"
            class="textlink"
            rowspan="1"
          >
            {{ row.Rows[0].FundName }}</a
          >
        </td>

        <td *ngIf="showclass" class="text-center">{{ row.Rows[0].Class }}</td>
        <td class="text-center">
          <icon-font
            *ngIf="row.Rows[0].Reg28Compliant"
            name="icon-check-circle"
            class="icon-md"
          >
          </icon-font>
        </td>
        <td class="text-center">
          <icon-font
            *ngIf="row.Rows[0].TFIPAvailability"
            name="icon-check-circle"
            class="icon-md"
          >
          </icon-font>
        </td>
        <td class="text-center">
          <icon-font
            *ngIf="row.Rows[0].PSGAdviserOnly"
            name="icon-check-circle"
            class="icon-md"
          >
          </icon-font>
        </td>
        <td class="text-center">
          {{ row.Rows[0].TIC }}
        </td>
      </tr>
      <tr *ngFor="let innerRow of row.Rows; let i = index" [hidden]="i == 0">
        <td>
          <a
            [href]="innerRow.FundUrl"
            target="_blank"
            class="textlink"
            rowspan="1"
          >
            {{ innerRow.FundName }}</a
          >
        </td>
        <td *ngIf="showclass" class="text-center">{{ innerRow.Class }}</td>
        <td class="text-center">
          <icon-font
            *ngIf="innerRow.Reg28Compliant"
            name="icon-check-circle"
            class="icon-md"
          >
          </icon-font>
        </td>
        <td class="text-center">
          <icon-font
            *ngIf="innerRow.TFIPAvailability"
            name="icon-check-circle"
            class="icon-md"
          >
          </icon-font>
        </td>
        <td class="text-center">
          <icon-font
            *ngIf="innerRow.PSGAdviserOnly"
            name="icon-check-circle"
            class="icon-md"
          >
          </icon-font>
        </td>
        <td class="text-center">
          {{ innerRow.TIC }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
