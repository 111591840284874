import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-client',
  templateUrl: './lead-client.component.html'
})
export class LeadClientComponent implements OnInit {

  url = 'https://forms.office.com/r/gjQ1r18QSb';

  constructor() { }

  ngOnInit() {
  }

}
