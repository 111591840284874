import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-all-funds-table',
  templateUrl: './all-funds-table.component.html',
  styles: []
})
export class AllFundsTableComponent implements OnInit {

  public pageTabs = ["Overview", "Price and performance"];
  public currentTab = "Overview";

  constructor(private router:Router) { }

  ngOnInit() {
  }

  public selectedTabChanged(tab:string){
    this.currentTab = tab;
  }
}
