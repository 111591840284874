import {
  Component,
  OnInit,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  Renderer2,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { PageService } from "src/app/services/page.service";
import { SearchableItems } from "../shared/header/searchItems";
import { SearchService } from "src/app/services/search.service";
import { AdvisorService } from "src/app/services/advisor.service";
import { Router, Route } from "@angular/router";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-search-results",
  templateUrl: "./search-results.component.html",
  styles: [],
  providers: [
    SearchableItems,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchResultsComponent),
      multi: true,
    },
  ],
})
export class SearchResultsComponent implements OnInit {
  getTotalpagesRemiander: number;
  getTotalResultsWholeNumber: number;
  getTotalNumberOfPages: number;

  @Input() pageSizeOptions: any;
  @Input() currentPage: number;
  @Input() tableLength: number;
  @Input() pages = [];

  @Input() selectedPage: number;
  @Output() onSelectedPageChange: EventEmitter<number> =
    new EventEmitter<number>();

  @Input() selectedPageSizeOption: number = 5;
  @Input() selectedPageNumber: number = 1;
  @Output() selectedPageSizeOptionChange: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() onSelectedPageSizeOptionChange: EventEmitter<any> =
    new EventEmitter<any>();

  public get isShowingString() {
    return typeof this.selectedPageSizeOption === "string";
  }

  public searchableItems: Array<StyleguideSearchType> = [];
  public searchableItemsTemp: Array<StyleguideSearchType>;
  public searchableItemsFull: Array<StyleguideSearchType> = [];
  public searchedItemsFull: StyleguideSearchType[] = [];
  exampleTabs = [
    "All Items",
    "Articles",
    "Branches",
    "Funds",
    "Products",
    "Other",
  ];

  public selectedTab = "All Items";
  selectedItemsList = [];
  checkedIDs = [];
  public searchValue: string;
  public searchedItems: StyleguideSearchType[] = [
    {
      category: "none",
      heading: "",
      text: "",
      routerLink: "",
      isExternal: false,
      fragment: "",
    },
  ];

  public storedSearchedItems: StyleguideSearchType[];
  public searchContainsProducts = false;
  public searchContainsArticles = false;
  public searchContainsFunds = false;
  public searchContainsAdvisers = false;
  public searchContainsOther = false;
  public searchContainsBranches = false;
  public searched_items: boolean = false;
  private siteRoutes: siteObj[];

  constructor(
    private searchaleItemsData: SearchableItems,
    private pageService: PageService,
    private searchService: SearchService,
    private adviserService: AdvisorService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    this.searchValue = "";
    this.siteRoutes = [{ url: "", name: "", path: "" }];
    this.storedSearchedItems = [
      {
        category: "none",
        heading: "",
        text: "",
        routerLink: "",
        isExternal: false,
        fragment: "",
      },
    ];

    this.searchableItemsTemp = [];
  }

  ngOnInit(): void {
    this.seoService.updateMetaInfoForPage(
      "Search | PSG South Africa",
      window.location.href,
      null,
      "Search all psg."
    );

    this.printpath(window.location.origin, this.router.config);
    this.seoService.setJsonLd(this.renderer, null);
  }

  printpath(parent: String, config: Route[]) {
    if (window.location.pathname === "/search-results") {
      for (let i = 0; i < config.length; i++) {
        const route = config[i];

        if (route.path.lastIndexOf(":") == -1)
          this.siteRoutes = [
            {
              url: `${parent}/${route.path}`,
              name: this.replaceAll(`${parent}/${route.path}`, "-", " "),
              path: route.path,
            },
            ...this.siteRoutes,
          ];

        if (route.children) {
          const currentPath = route.path ? parent + "/" + route.path : parent;
          this.printpath(currentPath, route.children);
        }
      }
    }
  }

  replaceAll(string: string, search: string, replace: string) {
    return string.split(search).join(replace);
  }

  assignCategory(category: string) {
    switch (category.toLowerCase()) {
      case "products":
        return "Products";
      case "articles":
        return "Articles";
      case "funds":
        return "Funds";
      case "advisers":
        return "Advisers";
      case "branches":
        return "Branches";
      default:
        return "Other";
    }
  }

  selectedPageChange(event: number) {
    this.resetCategories();
    this.storedSearchedItems = [];

    this.currentPage = event;

    let getIndex = this.selectedPageSizeOption * event; // 5 * 2
    let getIndex1 = getIndex - this.selectedPageSizeOption; // 10 - 5
    let getIndex2 = getIndex - 1; // 9 == / 5 - 9
    if (getIndex1 < 0) {
      getIndex1 = 1;
      getIndex2 = 5;
    }
    this.searchedItems.splice(0, this.searchedItems.length);

    let results: Array<StyleguideSearchType> = [];

    if (this.selectedTab != "All Items") {
      this.searchableItemsFull.forEach((item) => {
        if (item.category == this.selectedTab) {
          results.push(item);
        }
      });
    } else {
      results = this.searchableItemsFull;
    }

    this.storedSearchedItems = results;

    if (this.storedSearchedItems.length > 0) this.searched_items = true;

    for (let x = getIndex1; x <= getIndex2; x++) {
      let item = this.storedSearchedItems[x];

      if (x < this.tableLength) this.searchedItems.push(item);

      //check item categories
      switch (item.category) {
        case "Products":
          this.searchContainsProducts = true;
          break;
        case "Articles":
          this.searchContainsArticles = true;
          break;
        case "Funds":
          this.searchContainsFunds = true;
          break;
        case "Advisers":
          this.searchContainsAdvisers = true;
          break;
        case "Branches":
          this.searchContainsBranches = true;
          break;
        case "Other":
          this.searchContainsOther = true;
          break;
        default:
          break;
      }
    }
  }

  public IsActive(page: number) {
    return this.currentPage == page;
  }

  public Previous(): void {
    var currentIndex = this.pages.findIndex((x) => x === this.currentPage);

    if (this.currentPage > 1) {
      currentIndex--;
      this.selectedPageChange(currentIndex + 1);
    }
  }

  public Next(): void {
    var currentIndex = this.pages.findIndex((x) => x === this.currentPage);

    if (this.currentPage < this.getTotalNumberOfPages) {
      currentIndex++;
      this.selectedPageChange(currentIndex + 1);
    }
  }

  //  Search
  public searchOpen = false;
  public toggleSearch(id): void {
    this.searchOpen = !this.searchOpen;
    if (this.searchOpen) {
      document.getElementById(id).classList.add("show");
      document.getElementById("searchInput").focus();
    } else {
      document.getElementById(id).classList.remove("show");
    }
  }

  public onSearchChanged(searchValue: string) {
    this.searchedItemsFull = [];
    this.selectedTab = "All Items";
    this.currentPage = 1;
    this.selectedPageNumber = 1;
    let foundItems: siteObj[];

    if (searchValue != "") {
      foundItems = this.siteRoutes.filter(
        (site) =>
          site.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          site.path.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    this.searchService.GetSearch<any>(searchValue, "").subscribe((result) => {
      this.searchableItems = [];
      this.searchableItemsTemp = [];
      this.storedSearchedItems = [];
      this.searchedItemsFull = [];

      result.forEach((item) => {
        this.searchableItems.push({
          category: this.assignCategory(item.category),
          heading: item.headline,
          routerLink: item.uri,
          text: item.excerpt,
          isExternal: item.isExternal,
          fragment: "",
        });
      });

      if (foundItems) {
        foundItems.map((item) => {
          this.searchableItemsTemp.push({
            category: "Other",
            heading: item.name.split("/")[item.url.split("/").length - 1],
            routerLink: `/${item.path}`,
            text: item.name.split("/")[3],
            isExternal: true,
            fragment: "",
          });
        });
      }

      this.searchableItems = [
        ...this.searchableItems,
        ...this.searchableItemsTemp,
      ].sort((a, b) =>
        a.category > b.category ? 1 : b.category > a.category ? -1 : 0
      );

      this.searchableItemsFull = this.searchableItems;

      this.pages = [];
      setTimeout(() => {
        this.searchedItems = [
          {
            category: "none",
            heading: "",
            text: "",
            routerLink: "",
            isExternal: false,
            fragment: "",
          },
        ];

        this.resetCategories();
        this.searched_items = false;

        let byProduct: boolean = false;
        let byArticles: boolean = false;
        let byFunds: boolean = false;
        let byAdvisers: boolean = false;
        let byBranches: boolean = false;
        let byProducts: boolean = false;
        let byOther: boolean = false;

        if (searchValue != "") {
          this.searchableItems.forEach((item) => {
            this.searched_items = true;

            switch (item.category) {
              case "Products":
                byProduct = true;
                break;
              case "Articles":
                byArticles = true;
                break;
              case "Funds":
                byFunds = true;
                break;
              case "Advisers":
                byAdvisers = true;
                break;
              case "Branches":
                byBranches = true;
                break;
              case "Other":
                byOther = true;
                break;
              default:
                break;
            }

            if (this.searchedItems[0].category == "none") {
              this.searchedItems[0] = item;

              this.storedSearchedItems[0] = item;
            } else {
              this.searchedItems.push(item);
              this.searchedItemsFull.push(item);
              this.storedSearchedItems.push(item);
            }
          });

          if (byProducts) {
            this.searchableItems.forEach((product) => {
              if (product.category == "Products") {
                let position = product.routerLink.lastIndexOf("/");
                let route = product.routerLink.substring(0, position);
                let ending = product.routerLink.substring(position + 1);
                product.routerLink = route;
                product.fragment = ending;
              }
            });
          }
          if (byFunds) {
            this.searchableItems.forEach((fund) => {
              if (fund.category == "Funds") {
                fund.routerLink = "/all-funds";
              }
            });
          }
          if (byBranches) {
            this.searchableItems.forEach((branch) => {
              if (branch.category == "Branches") {
                let branchname = branch.heading.replace(/ /g, "-");
                let routerLink = branch.routerLink.substring(43);
                let route = "/branch-office/" + routerLink;
                branch.routerLink = route;
              }
            });
          }

          this.resetPagination();

          this.searchedItems.forEach((item) => {
            switch (item.category) {
              case "Products":
                this.searchContainsProducts = true;
                break;
              case "Articles":
                this.searchContainsArticles = true;
                break;
              case "Funds":
                this.searchContainsFunds = true;
                break;
              case "Advisers":
                this.searchContainsAdvisers = true;
                break;
              case "Branches":
                this.searchContainsBranches = true;
                break;
              case "Other":
                this.searchContainsOther = true;
                break;
              default:
                break;
            }
          });
        }
      });
    });
  }

  tabSelected(event: any) {
    this.resetCategories();

    this.selectedTab = event;

    let results: Array<StyleguideSearchType> = [];

    if (event != "All Items") {
      this.searchableItemsFull.forEach((item) => {
        if (item.category == event) {
          results.push(item);
        }
      });

      this.searchedItems = [];
      this.searchedItems = results;

      //check available items for displaying
      this.searchedItems.forEach((item) => {
        switch (item.category) {
          case "Products":
            this.searchContainsProducts = true;
            break;
          case "Articles":
            this.searchContainsArticles = true;
            break;
          case "Funds":
            this.searchContainsFunds = true;
            break;
          case "Advisers":
            this.searchContainsAdvisers = true;
            break;
          case "Branches":
            this.searchContainsBranches = true;
            break;
          case "Other":
            this.searchContainsOther = true;
            break;
          default:
            break;
        }
      });
    } else {
      this.onSearchChanged(this.searchValue);
    }

    if (this.searchedItems.length === 0) {
      this.searchedItems.push({
        category: "none",
        heading: "",
        text: "",
        routerLink: "",
        isExternal: false,
        fragment: "",
      });
    }
    this.resetPagination();
  }

  resetPagination() {
    this.currentPage = 1;

    this.tableLength = this.searchedItems.length;

    this.getTotalpagesRemiander =
      this.tableLength % this.selectedPageSizeOption;
    this.getTotalResultsWholeNumber =
      this.tableLength - this.getTotalpagesRemiander;
    this.getTotalNumberOfPages =
      this.getTotalResultsWholeNumber / this.selectedPageSizeOption;
    if (this.getTotalNumberOfPages > 0 && this.getTotalpagesRemiander > 0) {
      this.getTotalNumberOfPages = this.getTotalNumberOfPages + 1;
    }

    for (let x = 0; x < this.getTotalNumberOfPages; x++) {
      this.pages.push(x + 1);
    }

    this.searchedItems.splice(
      this.selectedPageSizeOption,
      this.searchedItems.length
    );

    if (this.getTotalNumberOfPages == 0) {
      this.getTotalNumberOfPages = 1;
    }
  }

  resetCategories() {
    this.searchContainsProducts = false;
    this.searchContainsArticles = false;
    this.searchContainsFunds = false;
    this.searchContainsAdvisers = false;
    this.searchContainsOther = false;
    this.searchContainsBranches = false;
  }

  QuickSearch(searchtext: string) {
    this.searchValue = searchtext;
    this.onSearchChanged(searchtext);
  }

  private checkIfItemContained(item1, item2) {
    if (item1 != null && item2 != null) {
      return item1.toLowerCase().includes(item2.toLowerCase());
    } else {
      return false;
    }
  }
}

export interface StyleguideSearchType {
  category: string;
  heading: string;
  text: string;
  routerLink: string;
  fragment: string;
  isExternal: boolean;
}

export class siteObj {
  url: string;
  name: string;
  path: string;

  constructor(url: string, name: string, path: string) {
    this.url = url;
    this.name = name;
    this.path = path;
  }
}
