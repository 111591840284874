import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { MacroEconomicInsightsLandingPage } from "src/app/models/content/news-and-publications/macro-economic-insights-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-macro-economic-insights",
  templateUrl: "./macro-economic-insights.component.html",
  styleUrls: [],
})
export class MacroEconomicInsightsComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;
  public latestReports: any = [];
  public latestReportsKeywords: any = [];
  public previousReports: any = [];
  public relatedArticles: any = {};

  constructor(
    public pageService: PageService,
    private router: Router,
    public seoService: SeoService
  ) {
    pageService
      .GetPage<MacroEconomicInsightsLandingPage>(
        pageService.URIs.macroEconomicResearch
      )
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new MacroEconomicInsightsLandingPage(content);

        this.latestReports = this.content.body.sections
          .find((val) => val.type == "reports" &&
            val.heading.toLowerCase().includes("latest"));

        this.previousReports = this.content.body.sections
          .find((val) => val.type == "reports" &&
            val.heading.toLowerCase().includes("previous"));

        this.relatedArticles = this.content.body.sections
          .find((val) => val.type == "articles" &&
            val.heading.toLowerCase().includes("previous"));

        this.latestReportsKeywords = [
          ...new Set(this.content.header.tags.map((item) => item.label)),
        ].map((keyword) => {
          return {
            label: keyword,
            selected: false,
          };
        });

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  ngOnInit(): void { }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public NavigateToSearch() {
    this.router.navigateByUrl("/search-results");
  }
}
