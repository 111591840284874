import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { ResearchInsightsLandingPage } from "src/app/models/content/news-and-publications/research-insights-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-research-insights",
  templateUrl: "./research-insights.component.html",
  styleUrls: [],
})
export class ResearchInsightsComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;
  public latestReports: any = {};
  public latestReportsKeywords: any = [];

  constructor(
    public pageService: PageService,
    private router: Router,
    public seoService: SeoService
  ) {
    pageService
      .GetPage<ResearchInsightsLandingPage>(pageService.URIs.researchInsights)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new ResearchInsightsLandingPage(content);
        this.latestReports = this.content.body.sections[0];
        this.latestReportsKeywords = [
          ...new Set(this.content.header.tags.map((item) => item.label)),
        ].map((keyword) => {
          return {
            label: keyword,
            selected: false,
          };
        });

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  ngOnInit(): void { }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public NavigateToSearch() {
    this.router.navigateByUrl("/search-results");
  }
}
