  <marquee>
      <strong
        *ngFor="let sharePrice of MarketIndicators"
        class="pl-2">
          {{sharePrice?.DisplayName}}
          <span
            class="slate body-med pl-2">
              {{formattingService.tryFormatDecimal(sharePrice?.CurrentLevel,2)}}
          </span>
          <span
            [ngClass]="{
              'green' : sharePrice?.D1 > 0,
              'red' : sharePrice?.D1 < 0
            }"
            class="body-med pl-2 pr-4">
              {{formattingService.tryFormatDecimal(sharePrice?.D1,2)}}
          </span>
      </strong>
  </marquee>
