import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-adviser',
  templateUrl: './lead-adviser.component.html'
})
export class LeadAdviserComponent implements OnInit {

  url = 'https://forms.office.com/r/JeHGJcpJGF';

  constructor() { }

  ngOnInit() {
  }

}
