import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DuiArticlePage } from 'src/app/models/content/news-and-publications/dui-article-page.model';
import { DailyInvestmentService } from 'src/app/services/dailyInvestment/daily-investment.service';
import { FundsPricingPerformanceResponse } from 'src/app/services/dailyInvestment/daily-ivestment.model';
import { PageService } from 'src/app/services/page.service';
import { FormattingService } from 'src/app/services/formatting.service';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-sm-funds-local',
  templateUrl: './sm-funds-local.component.html',
  styles: []
})
export class SmFundsLocalComponent implements OnInit, OnDestroy {

  public pageTabs = ["Overview", "Price and performance"];
  public currentTab = "Overview";
  public alive: boolean = true;
  public fundPerformances: Array<FundsPricingPerformanceResponse>;
  @Input() content: any;

  constructor(
    private dailyInvestmentService: DailyInvestmentService,
    private route: ActivatedRoute,
    private pageService: PageService,
    private router: Router,
    public formattingService: FormattingService) { }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public getFundPricesAndPerformance() {
    let today = new Date(Date.now());
    this.dailyInvestmentService.getFundPricesAndPerformances({
      date: "",
      fundCategory: "SINGLE-MANAGER-FUND",
      locality: "LOCAL",
      pageNumber: 1,
      pageSize: 50
    }).pipe(
      takeWhile(() => this.alive))
      .subscribe((result) => {
        this.fundPerformances = result;
      })
  }

  public selectedTabChanged(tab: string) {
    this.currentTab = tab;
    this.getFundPricesAndPerformance();
  }
}
