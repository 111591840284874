import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { MoneyMarketFunds } from 'src/app/models/content/save-and-invest/money-market-funds.model';
import { PageService } from "src/app/services/page.service";
import { SaveInvestService } from 'src/app/services/save-invest/save-invest.service';
import { SeoService } from "src/app/services/socialLinks.service";
import * as moment from "moment";

@Component({
  selector: 'app-money-market-funds',
  templateUrl: './money-market-funds.component.html'
})
export class MoneyMarketFundsComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;
  enhancedInterestFofResponse: any;

  constructor(
    private saveInvestService: SaveInvestService,
    private pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<MoneyMarketFunds>(pageService.URIs.moneyMarketFunds)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new MoneyMarketFunds(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });

    this.saveInvestService.getEnhancedInterestFof().subscribe({

      next: (response: any) => {

        for (let item of response) {
          item.date = moment(item?.date).format('YYYY-MM-DD');
        }

        this.enhancedInterestFofResponse = response;

      },
      error: (error) => {
        console.error(error);
      }

    });

  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public Split(str: string): string {
    if (str != null) return str.replace(/\s/g, "<br />");
    else return str;
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
