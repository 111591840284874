import { Component, Input } from "@angular/core";
import { ContactUsDownloadModel } from "src/app/models/content/contact-us/contact-us.model";

@Component({
  selector: 'contact-us-downloads',
  templateUrl: './downloads.component.html',
  styles: []
})
export class DownloadsComponent {
  public accordionControlId:string;
  public accordionHeaderId:string;
  public collapseId:string;

  @Input() downloads:Array<ContactUsDownloadModel>;
  @Input() heading:string;

  constructor(){
    this.accordionControlId = this.createUniqueId();
    this.accordionHeaderId = this.createUniqueId();
    this.collapseId = this.createUniqueId();
  }


  private createUniqueId():string{
    return 'acc_' + 'xxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return  v.toString(16);
    });
  }

}
