import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StockbrokingLandingPage } from 'src/app/models/content/save-and-invest/stockbroking-landing-page.model';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: "app-trading-steps",
  templateUrl: "./trading-steps.component.html",
})
export class TradingStepsComponent implements OnInit {
  public content: any = {};

  public pageTabs = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"];

  public currentTab = "Step 1";

  constructor(private pageService: PageService, private router: Router) {
    pageService
      .GetPage<StockbrokingLandingPage>(pageService.URIs.stockbroking)
      .subscribe((content) => {
        this.content = new StockbrokingLandingPage(content);
      });
}

  ngOnInit() {}
}
