<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!--ESG hero section -->
<div *ngIf="!isPageLoading" class="container-fluid zoomout-banner-m">
  <div
    class="container-fluid falcon-hero"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div class="heading-border-left col-11">
          <h1
            class="mega-title--mobile falconHeading text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="col-md-9 text-center mx-auto"></div>
      </div>
    </div>
  </div>
</div>
<!--ESG hero section -->

<!-- single card -->
<div
  class="container campaign-hero--cards"
  *ngIf="
    content?.header?.subheading != null ||
    (content?.header?.subheading != undefined && !isPageLoading)
  "
>
  <div class="row d-flex justify-content-center">
    <div class="col-md-9 offset-1">
      <div class="blur-card">
        <h5>{{ content?.header?.subheading }}</h5>
        <p [innerHTML]="content?.header?.excerpt"></p>
      </div>
    </div>
  </div>
</div>
<!-- /single cards -->

<div *ngIf="!isPageLoading" class="container-fluid py-4 my-4">
  <div class="container-xl">
    <p [innerHTML]="content?.header?.content"></p>
  </div>
</div>

<!-- content-row -->
<div
  class="container-fluid slate-bg-10 p-0 h-100 py-4 my-4"
  *ngIf="content?.body?.content.length > 0 && !isPageLoading"
>
  <div
    class="container-xl py-3 h-100"
    *ngFor="let bodyContent of content?.body?.content; let index = index"
  >
    <div class="row" *ngIf="(0 === index % 2) == false">
      <div
        class="col-lg-6 offset-lg-1 col-md-6 position-relative fit-content-height"
      >
        <div class="blue-line col-md-5 d-none d-lg-block"></div>
        <img
          src="{{ bodyContent.image }}"
          class="img-cover significant-raise card-round"
        />
      </div>
      <!-- Records -->
      <div class="col-lg-5 col-md-6 py-4">
        <h4 class="small-heading mb-2">{{ bodyContent.heading }}</h4>
        <p [innerHTML]="bodyContent.content"></p>
        <p [innerHTML]="bodyContent.Headline"></p>
        <div class="row pl-3">
          <button-text
            class="mr-1 mb-2"
            buttonStyle="{{
              bodyContent.actions[1].isPrimary ? 'solid' : 'outline'
            }}"
            [label]="bodyContent.actions[1]?.label"
            (click)="DynamicButtonClick(bodyContent.actions[1])"
          >
          </button-text>
          <div class="accordion w-50" [id]="'accordionVotingRecords'">
            <div class="accordian-inner">
              <div [id]="'votingRecords'">
                <h2 class="mb-0">
                  <button
                    class="accordian-button collapsed py-7px"
                    type="button"
                    data-toggle="collapse"
                    [attr.data-target]="'#collapseVotingRecords'"
                    aria-expanded="false"
                    [attr.aria-controls]="'collapseVotingRecords'"
                  >
                    {{ content?.body?.divisions[0]?.forms.categories[1].name }}
                    <icon-font
                      name="icon-angle-up"
                      class="icon-accordian"
                    ></icon-font>
                  </button>
                </h2>
              </div>
              <div
                [id]="'collapseVotingRecords'"
                class="collapse"
                [attr.aria-labelledby]="'votingRecords'"
                [attr.data-parent]="'#accordionVotingRecords'"
              >
                <div class="accordian-body">
                  <ul
                    class="p-0"
                    *ngFor="
                      let download of content?.body?.divisions[0]?.forms
                        .categories[1].downloads
                    "
                  >
                    <li style="list-style: none">
                      <a
                        class="textlink"
                        target="_blank"
                        [href]="download?.url"
                        *ngIf="download?.url"
                        >{{ download?.label }}</a
                      >
                      <a
                        class="textlink"
                        target="_blank"
                        [href]="download?.file"
                        *ngIf="download?.file"
                        >{{ download?.label }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Reports -->
    <div class="row mb-5" *ngIf="(0 === index % 2) == true">
      <div class="col-lg-5 col-md-6 py-4">
        <h4 class="small-heading mb-2">{{ bodyContent.heading }}</h4>
        <p [innerHTML]="bodyContent.content"></p>
        <div class="row pl-3">
          <button-text
            class="mr-1 mb-2"
            buttonStyle="{{
              bodyContent.actions[0].isPrimary ? 'solid' : 'outline'
            }}"
            [label]="bodyContent.actions[0]?.label"
            (click)="DynamicButtonClick(bodyContent.actions[0])"
          >
          </button-text>
          <div
            *ngIf="index == 0"
            class="accordion w-50"
            [id]="'accordionArchiveReports'"
          >
            <div class="accordian-inner">
              <div [id]="'archivedReports'">
                <h2 class="mb-0">
                  <button
                    class="accordian-button collapsed py-7px"
                    type="button"
                    data-toggle="collapse"
                    [attr.data-target]="'#collapseArchivedReports'"
                    aria-expanded="false"
                    [attr.aria-controls]="'collapseArchivedReports'"
                  >
                    {{ content?.body?.divisions[0]?.forms.categories[0].name }}
                    <icon-font
                      name="icon-angle-up"
                      class="icon-accordian"
                    ></icon-font>
                  </button>
                </h2>
              </div>
              <div
                [id]="'collapseArchivedReports'"
                class="collapse"
                [attr.aria-labelledby]="'archivedReports'"
                [attr.data-parent]="'#accordionArchiveReports'"
              >
                <div class="accordian-body">
                  <ul
                    class="p-0"
                    *ngFor="
                      let download of content?.body?.divisions[0]?.forms
                        .categories[0].downloads
                    "
                  >
                    <li style="list-style: none">
                      <a
                        class="textlink"
                        target="_blank"
                        [href]="download?.url"
                        *ngIf="download?.url"
                        >{{ download?.label }}</a
                      >
                      <a
                        class="textlink"
                        target="_blank"
                        [href]="download?.file"
                        *ngIf="download?.file"
                        >{{ download?.label }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-lg-6 offset-lg-1 col-md-6 position-relative fit-content-height"
      >
        <div class="blue-line col-md-5 d-none d-lg-block"></div>
        <img
          src="{{ bodyContent.image }}"
          class="img-cover significant-raise card-round"
        />
      </div>
    </div>
  </div>
</div>
<!-- /content-row -->

<!-- Case studies -->
<div *ngIf="!isPageLoading" class="container-fluid py-4 my-4">
  <div class="container-xl">
    <div>
      <h2
        class="mb-4"
        *ngIf="
          content?.body?.infoCards[0].heading != null ||
          content?.body?.infoCards[0].heading != undefined
        "
      >
        {{ content?.body?.infoCards[0].heading }}
      </h2>
    </div>

    <div
      class="card-deck"
      *ngIf="content?.body?.infoCards[0]?.cards.length > 0"
    >
      <div class="row">
        <div
          class="col-md-6 mt-5"
          *ngFor="let cardInfo of content?.body?.infoCards[0].cards"
        >
          <div class="card news--card">
            <img
              class="card-img-top"
              [src]="cardInfo.image"
              alt="Card image cap"
            />
            <div class="card-body">
              <div class="card-tags pb-2"></div>
              <h5 class="small-heading">{{ cardInfo.heading }}</h5>
              <p
                class="calculators body-small"
                [innerHTML]="cardInfo.content"
              ></p>
              <ul class="cards-list" *ngFor="let action of cardInfo?.actions">
                <li>
                  <a
                    class="textlink"
                    target="_blank"
                    (click)="DynamicButtonClick(action)"
                    >Read more</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <div class="accordion" [id]="'accordionCaseStudies'">
          <div class="accordian-inner">
            <div [id]="'caseStudiesRecords'">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed py-7px"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseCaseStudies'"
                  aria-expanded="false"
                  [attr.aria-controls]="'collapseCaseStudies'"
                >
                  {{ content?.body?.divisions[0]?.forms.categories[2].name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapseCaseStudies'"
              class="collapse"
              [attr.aria-labelledby]="'caseStudiesRecords'"
              [attr.data-parent]="'#accordionCaseStudies'"
            >
              <div class="accordian-body">
                <ul
                  class="p-0"
                  *ngFor="
                    let download of content?.body?.divisions[0]?.forms
                      .categories[2].downloads
                  "
                >
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Case studies -->

<!-- Recommended news  cards section -->
<div
  class="container-fluid py-4 my-4 slate-bg-10"
  *ngIf="
    content?.body?.articles[0].articles != null ||
    (content?.body?.articles[0].articles != undefined && !isPageLoading)
  "
>
  <div class="container-xl">
    <div>
      <h2 class="mb-4">Recommended Articles</h2>
    </div>

    <div class="card-deck">
      <div
        class="card news--card"
        *ngFor="let news of content?.body?.articles[0].articles"
      >
        <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
        <div class="card-body">
          <div class="card-tags pb-2">
            <span
              *ngFor="let category of news?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              >{{ category }}</span
            >
          </div>
          <h5 class="small-heading">{{ news?.headline }}</h5>
          <p class="body-small" [innerHTML]="news?.excerpt"></p>
          <a [routerLink]="news?.uri" class="textlink">Read more</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Recommended news  cards section -->

<!-- VIDEO CAPABILITY section -->
<div
  class="container-xl py-4 my-4 h-100"
  *ngIf="content?.body?.video_sections.length > 0 && !isPageLoading"
>
  <div
    class="container-xl py-4 my-4 h-100"
    *ngFor="let video of content?.body?.video_sections; let index = index"
    style="display: none"
  >
    <div
      class="row d-flex align-items-start justify-content-start"
      *ngIf="(0 === index % 2) == true"
    >
      <div class="col-md-6 col-sm-12 justify-content-start">
        <h4 class="large-heading">
          {{ video.heading }}
        </h4>
        <div [innerHTML]="video.content"></div>
      </div>
      <div class="col-md-6 col-sm-12 greybg-right-large-event-webinairs">
        <div class="row d-flex justify-content-end mb-2">
          <div class="p-2">
            <button class="button simple-icon" (click)="shareToMail()">
              <icon-font
                name="icon-envelope"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToLinkedIn()">
              <icon-font
                name="icon-linkedin"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToTwitter()">
              <icon-font
                name="icon-twitter"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToFb()">
              <icon-font
                name="icon-facebook"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToWhatsApp()">
              <icon-font
                name="icon-whatsapp"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
          </div>
        </div>
        <div
          class="embed-responsive embed-responsive-16by9 card-round significant-raise"
        >
          <iframe
            width="1131"
            height="636"
            [src]="video?.url | safe"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>

    <div
      class="row mt-3 d-flex align-items-start justify-content-start"
      *ngIf="(0 === index % 2) == false"
    >
      <div class="col-md-6 col-sm-12 greybg-right-large-event-webinairs">
        <div class="row d-flex justify-content-end mb-2">
          <div class="p-2">
            <button class="button simple-icon" (click)="shareToMail()">
              <icon-font
                name="icon-envelope"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToLinkedIn()">
              <icon-font
                name="icon-linkedin"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToTwitter()">
              <icon-font
                name="icon-twitter"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToFb()">
              <icon-font
                name="icon-facebook"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToWhatsApp()">
              <icon-font
                name="icon-whatsapp"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
          </div>
        </div>
        <div
          class="embed-responsive embed-responsive-16by9 card-round significant-raise"
        >
          <iframe
            width="1131"
            height="636"
            [src]="video?.url | safe"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 justify-content-start">
        <h4 class="large-heading">
          {{ video.heading }}
        </h4>
        <div [innerHTML]="video.content"></div>
      </div>
    </div>
  </div>
</div>
<!-- VIDEO CAPABILITY section -->

<!-- Frequently asked questions -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid slate-bg-10 py-4 my-4"
  style="display: none"
>
  <div class="container-xl">
    <div>
      <h2
        class="mb-4"
        *ngIf="
          content?.body?.infoCards[3]?.heading != null ||
          content?.body?.infoCards[3]?.heading != undefined
        "
      >
        {{ content?.body?.infoCards[3].heading }}
      </h2>
    </div>
    <div
      class="card-deck"
      *ngIf="content?.body?.infoCards[3]?.cards.length > 0"
    >
      <div
        class="card news--card"
        *ngFor="let cardInfo of content?.body?.infoCards[3].cards"
      >
        <div class="card-body">
          <div class="card-tags pb-2"></div>
          <h5 class="small-heading">
            {{ cardInfo.heading }}
          </h5>

          <p class="calculators body-small" [innerHTML]="cardInfo.content"></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Frequently asked questions -->

<!-- Retirement annuity highlights -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-4 my-4"
  style="display: none"
>
  <div class="container-xl">
    <div>
      <h2
        class="mb-4"
        *ngIf="
          content?.body?.infoCards[1]?.heading != null ||
          content?.body?.infoCards[1]?.heading != undefined
        "
      >
        {{ content?.body?.infoCards[1].heading }}
      </h2>
    </div>
    <div
      class="card-deck"
      *ngIf="content?.body?.infoCards[1]?.cards.length > 0"
    >
      <div
        class="card news--card"
        *ngFor="let cardInfo of content?.body?.infoCards[1].cards"
      >
        <img class="card-img-top" [src]="cardInfo.image" alt="Card image cap" />
        <div class="card-body">
          <h5 class="small-heading">{{ cardInfo.heading }}</h5>
          <p class="calculators body-small" [innerHTML]="cardInfo.content"></p>

          <ul class="cards-list" *ngFor="let action of cardInfo?.actions">
            <li>
              <a
                class="textlink"
                target="_blank"
                (click)="DynamicButtonClick(action)"
                >{{ action.label }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Retirement annuity highlights -->
