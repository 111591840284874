import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { NewsAndPublicationsPage } from "src/app/models/content/news-and-publications/news-and-publications.model";
import { ArticleService } from "src/app/services/article.service";
import { PageService } from "src/app/services/page.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { finalize, takeWhile } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { SeoService } from "src/app/services/socialLinks.service";
import { ContentFilter, DateRange } from "src/app/services/dailyInvestment/daily-ivestment.model";
import { MonthsDropdown, YearsDropdown } from "src/app/models/allArticle.model";

@Component({
  templateUrl: "./news-and-publications.component.html",
})
export class NewsAndPublicationsComponent
  implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit {
  public content: any = {};

  // All Articles
  public allArticlesHead: any = {};
  public totalArticles: any = {};
  public allArticles: any = {};

  // Daily Investment Updates
  public marketUpdate: any = {};
  public marketUpdateHead: any = {};

  // Search All Articles
  public searchArticle: Array<any> = [];

  // Search All Daily Investment Updates
  public dailyInvestmentUpdateSearchForm =
    this.buildDailyInvestmentUpdateSearchForm();
  public alive: boolean = true;
  public months: MonthsDropdown[] = [
    new MonthsDropdown("January", "01"),
    new MonthsDropdown("February", "02"),
    new MonthsDropdown("March", "03"),
    new MonthsDropdown("April", "04"),
    new MonthsDropdown("May", "05"),
    new MonthsDropdown("June", "06"),
    new MonthsDropdown("July", "07"),
    new MonthsDropdown("August", "08"),
    new MonthsDropdown("September", "09"),
    new MonthsDropdown("October", "10"),
    new MonthsDropdown("November", "11"),
    new MonthsDropdown("December", "12"),
  ];

  public years: YearsDropdown[] = [];
  public monthAndYears: Array<number> = new Array<number>();
  public yearsLoaded: boolean = false;
  public searchDailyInvestmentUpdateArticle: Array<any> = [];

  //Latest Article
  public latestArticleURL = "";
  public isPageLoading: boolean = true;
  dateFrom: Date;
  dateTo: Date;
  mainContentFilters: ContentFilter[];

  //market updates
  public filterMonthFrom: string;
  public filterYearFrom: string;
  public filterMonthTo: string;
  public filterYearTo: string;
  marketUpdatesHistoryYears: number;

  monthNames: string[] = [];
  filterToDate: Date;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public diuArticleService: ArticleService,
    public allArticleSerivce: ArticleService,
    public pageService: PageService,
    public datepipe: DatePipe,
    private ref: ChangeDetectorRef,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    let no_of_months = 12;
    this.dateTo = new Date();
    this.dateFrom = new Date();
    this.dateFrom.setMonth(new Date().getMonth() - no_of_months);
    this.filterToDate = new Date(
      this.dateTo.getFullYear(),
      this.dateTo.getMonth() + 1,
      0
    );
    this.mainContentFilters = [
      new ContentFilter({
        field: "publishDate",
        value: "",
        dateRange: new DateRange({
          startDate: this.dateFrom,
          endDate: new Date(
            this.dateTo.getFullYear(),
            this.dateTo.getMonth() + 1,
            0
          ),
        }),
      }),
    ];

    this.monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.marketUpdatesHistoryYears = 4;
    this.filterYearFrom = (new Date().getFullYear() - 1).toString();
    this.filterYearTo = new Date().getFullYear().toString();
    this.filterMonthFrom = new Date().toString();

    this.pageService
      .GetPage<NewsAndPublicationsPage>(
        this.pageService.URIs.newsAndPublications
      )
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new NewsAndPublicationsPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description,
          "article"
        );
      });

    let currentDate = new Date();

    //setting default filtered dates
    for (let y = this.marketUpdatesHistoryYears; y >= 0; y--) {
      this.years.push(
        new YearsDropdown(
          (currentDate.getFullYear() - y).toString(),
          currentDate.getFullYear() - y
        )
      );
    }

    this.years.sort((a, b) => {
      return b.value - a.value;
    });

    this.filterMonthFrom = this.monthNames[new Date().getMonth()];
    this.filterMonthTo = this.monthNames[new Date().getMonth()];

    diuArticleService
      .GetAllDailyInvestmentUpdatesArticles(this.mainContentFilters)
      .subscribe((diuArticles) => {
        diuArticles = diuArticles.sort((a, b) =>
          a.publishDate < b.publishDate ? 1 : -1
        );
        this.latestArticleURL = diuArticles[0]?.uri;
        this.marketUpdate = diuArticles;
        this.searchDailyInvestmentUpdateArticle = (<Array<any>>(
          this.marketUpdate
        )).slice(0, (<Array<any>>this.marketUpdate).length);

        this.searchDailyInvestmentUpdateArticle.forEach((x) => {
          let date = new Date(x.publishDate);
          let dates = this.datepipe.transform(date, "yyyy");
        });
        this.yearsLoaded = true;
      });

    allArticleSerivce.GetAllArticles().subscribe((totalArticles) => {
      totalArticles = totalArticles.sort((a, b) =>
        Date.parse(a.publishDate) < Date.parse(b.publishDate) ? 1 : -1
      );
      this.totalArticles = totalArticles;
      this.searchArticle = (<Array<any>>this.totalArticles).slice(
        0,
        (<Array<any>>this.totalArticles).length
      );
    });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }
  public removeWhiteSpace(str: string): string {
    return str.replace(/\s/g, "");
  }

  public goToURI(uri: string) {
    this.router.navigateByUrl(uri);
  }

  public ViewArticle() {
    this.router.navigateByUrl("article");
  }

  public titleOrAuthorSearch(event) {
    let allArticles = this.allArticlesHead.articles;

    if (event == "") {
      this.allArticleSerivce.GetAllArticles().subscribe((totalArticles) => {
        totalArticles = totalArticles.sort((a, b) =>
          Date.parse(a.publishDate) < Date.parse(b.publishDate) ? 1 : -1
        );
        this.totalArticles = totalArticles;
        this.searchArticle = (<Array<any>>this.totalArticles).slice(
          0,
          (<Array<any>>this.totalArticles).length
        );
      });
    } else {
      this.totalArticles = [];

      this.allArticleSerivce
        .SearchAllArticles(null)
        .subscribe((totalArticles) => {
          totalArticles = totalArticles.sort((a, b) =>
            Date.parse(a.publishDate) < Date.parse(b.publishDate) ? 1 : -1
          );
          this.searchArticle = totalArticles.filter(
            (x) =>
              (x.headline == null
                ? false
                : x.headline.toLowerCase().includes(event.toLowerCase())) ||
              (x.excerpt == null
                ? false
                : x.excerpt.toLowerCase().includes(event.toLowerCase())) ||
              (x.author == null
                ? false
                : x.author.find((x) =>
                  x.name.toLowerCase().includes(event.toLowerCase())
                )) ||
              (x.categories == null
                ? false
                : x.categories.find((x) =>
                  x.toLowerCase().includes(event.toLowerCase())
                ))
          );
        });
    }
  }

  public categorySearch(event) { }

  public dateSearch(event) { }

  ngAfterViewInit() {
    this.dailyInvestmentUpdateSearchForm.controls.fromMonth.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.filterMonthFrom =
          this.dailyInvestmentUpdateSearchForm.controls.fromMonth.value;

        let contentFilters: ContentFilter[] = [
          new ContentFilter({
            field: "publishDate",
            value: "",
            dateRange: new DateRange({
              startDate: `${this.filterYearFrom === "Year"
                ? new Date().getFullYear() - 1
                : this.filterYearFrom
                }-${this.filterMonthFrom}-01`,
              endDate: this.filterToDate,
            }),
          }),
        ];

        this.diuArticleService
          .GetAllDailyInvestmentUpdatesArticles(contentFilters)
          .subscribe((diuArticles) => {
            diuArticles = diuArticles.sort((a, b) =>
              a.publishDate < b.publishDate ? 1 : -1
            );

            this.marketUpdate = diuArticles;
            this.searchDailyInvestmentUpdateArticle = (<Array<any>>(
              this.marketUpdate
            )).slice(0, (<Array<any>>this.marketUpdate).length);
          });
      });

    this.dailyInvestmentUpdateSearchForm.controls.fromYear.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.filterYearFrom =
          this.dailyInvestmentUpdateSearchForm.controls.fromYear.value.toString();

        let contentFilters: ContentFilter[] = [
          new ContentFilter({
            field: "publishDate",
            value: "",
            dateRange: new DateRange({
              startDate: `${this.filterYearFrom === "Year"
                ? new Date().getFullYear() - 1
                : this.filterYearFrom
                }-${this.filterMonthFrom}-01`,
              endDate: this.filterToDate,
            }),
          }),
        ];

        this.diuArticleService
          .GetAllDailyInvestmentUpdatesArticles(contentFilters)
          .subscribe((diuArticles) => {
            diuArticles = diuArticles.sort((a, b) =>
              a.publishDate < b.publishDate ? 1 : -1
            );

            this.marketUpdate = diuArticles;
            this.searchDailyInvestmentUpdateArticle = (<Array<any>>(
              this.marketUpdate
            )).slice(0, (<Array<any>>this.marketUpdate).length);
          });
      });

    this.dailyInvestmentUpdateSearchForm.controls.toMonth.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.filterMonthTo =
          this.dailyInvestmentUpdateSearchForm.controls.toMonth.value;

        let newDate = new Date(`${this.filterYearTo}-${this.filterMonthTo}-01`);
        this.filterToDate = new Date(
          newDate.getFullYear(),
          newDate.getMonth() + 1,
          0
        );

        let contentFilters: ContentFilter[] = [
          new ContentFilter({
            field: "publishDate",
            value: "",
            dateRange: new DateRange({
              startDate: `${this.filterYearFrom === "Year"
                ? new Date().getFullYear() - 1
                : this.filterYearFrom
                }-${this.filterMonthFrom}-01`,
              endDate: this.filterToDate,
            }),
          }),
        ];

        this.diuArticleService
          .GetAllDailyInvestmentUpdatesArticles(contentFilters)
          .subscribe((diuArticles) => {
            diuArticles = diuArticles.sort((a, b) =>
              a.publishDate < b.publishDate ? 1 : -1
            );

            this.marketUpdate = diuArticles;
            this.searchDailyInvestmentUpdateArticle = (<Array<any>>(
              this.marketUpdate
            )).slice(0, (<Array<any>>this.marketUpdate).length);
          });
      });

    this.dailyInvestmentUpdateSearchForm.controls.toYear.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.filterYearTo =
          this.dailyInvestmentUpdateSearchForm.controls.toYear.value;

        let newDate = new Date(`${this.filterYearTo}-${this.filterMonthTo}-01`);
        this.filterToDate = new Date(
          newDate.getFullYear(),
          newDate.getMonth() + 1,
          0
        );

        let contentFilters: ContentFilter[] = [
          new ContentFilter({
            field: "publishDate",
            value: "",
            dateRange: new DateRange({
              startDate: `${this.filterYearFrom === "Year"
                ? new Date().getFullYear() - 1
                : this.filterYearFrom
                }-${this.filterMonthFrom}-01`,
              endDate: this.filterToDate,
            }),
          }),
        ];

        this.diuArticleService
          .GetAllDailyInvestmentUpdatesArticles(contentFilters)
          .subscribe((diuArticles) => {
            diuArticles = diuArticles.sort((a, b) =>
              a.publishDate < b.publishDate ? 1 : -1
            );

            this.marketUpdate = diuArticles;
            this.searchDailyInvestmentUpdateArticle = (<Array<any>>(
              this.marketUpdate
            )).slice(0, (<Array<any>>this.marketUpdate).length);
          });
      });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  public searchByDates() {
    let fromMonth =
      this.dailyInvestmentUpdateSearchForm.controls.fromMonth.value;
    let fromYear = this.dailyInvestmentUpdateSearchForm.controls.fromYear.value;
    let toMonth = this.dailyInvestmentUpdateSearchForm.controls.toMonth.value;
    let toYear = this.dailyInvestmentUpdateSearchForm.controls.toYear.value;

    if (
      fromMonth != "Month" &&
      fromYear != "Year" &&
      toMonth != "Month" &&
      toYear != "Year"
    ) {
      let dateFrom = new Date(`01 ${fromMonth} ${fromYear}`);
      let dateTo = new Date(`01 ${toMonth} ${toYear}`);

      this.searchDailyInvestmentUpdateArticle =
        this.searchDailyInvestmentUpdateArticle.filter(
          (x) =>
            new Date(x.publishDate) >= dateFrom &&
            new Date(x.publishDate) <= dateTo
        );
    } else {
      this.searchDailyInvestmentUpdateArticle = <Array<any>>this.marketUpdate;
    }
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  private buildDailyInvestmentUpdateSearchForm(): FormGroup {
    return this.formBuilder.group({
      fromMonth: ["Month"],
      fromYear: ["Year"],
      toMonth: ["Month"],
      toYear: ["Year"],
    });
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
}
