<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="container-fluid employee-page-hero pt-3"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100" *ngIf="content.header !== undefined">
    <div class="row">
      <div class="col-12">
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title financial-advice-header text-shadow"
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="pl-md-4 white">
          <h3 class="white text-shadow">{{ content?.header?.subheading }}</h3>
          <div [innerHTML]="content?.header?.content"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <div class="pl-md-4">
          <ng-container *ngFor="let action of content?.header?.actions">
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }} mr-3"
              buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="
    content.body != null && content.body.sections.length > 0 && !isPageLoading
  "
>
  <div class="container-fluid py-5 my-5 greybg-right h-100">
    <div class="container-xl h-100">
      <div class="row">
        <div class="col-md-10 offset-md-2">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content?.body?.sections[0]?.heading }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row h-100 pt-5 mt-4">
        <div
          class="col-md-3 mb-4"
          *ngFor="let card of content?.body?.sections[0].cards"
        >
          <div
            class="finacial-advisor card-round noticeable-raise white-bg p-3 h-100 d-flex flex-column justify-content-around"
          >
            <div>
              <h4 class="large-heading">{{ card?.heading }}</h4>
              <div class="mt-4" [innerHTML]="card?.content"></div>
            </div>
            <div>
              <ng-container *ngFor="let action of card?.actions">
                <div class="btn-row">
                  <button-text
                    class="{{ action.isPrimary ? '' : '' }} mr-3"
                    buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                    [label]="action?.label"
                    (click)="jumpToSection(action.url)"
                  >
                  </button-text>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Card Sections -->
  <div
    *ngFor="
      let card of content?.body?.sections[1].cards;
      let even = even;
      let i = index
    "
  >
    <div
      *ngIf="even"
      class="container-fluid my-5 py-5 greybg-right"
      [id]="'section-' + (i + 1)"
    >
      <div class="container-xl">
        <div class="row">
          <div class="col-md-12">
            <div class="heading-border-right">
              <h2 class="mega-title--mobile text-right">
                {{ card.heading }}
              </h2>
            </div>
          </div>
        </div>

        <div
          class="row"
          [ngClass]="{ 'align-items-start': card.stackedImage != null }"
        >
          <div class="col-md-6 offset-lg-1 py-4 order-0 order-md-1">
            <div class="w-100 h-100">
              <img class="img-cover significant-raise" [src]="card.image" />
              <img
                *ngIf="card.stackedImage != null"
                class="img-cover significant-raise mt-2"
                [src]="card.stackedImage"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-5 py-md-5">
            <h2 class="large-heading">
              {{ card.headline }}
            </h2>
            <p class="cyan small-heading mb-2">
              {{ card.subheading }}
            </p>
            <div [innerHTML]="card.content"></div>
            <div class="btn-row">
              <button-text
                *ngFor="let action of card.actions"
                class="mr-2"
                buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!even"
      class="container-fluid my-5 py-5 greybg-left"
      [id]="'section-' + (i + 1)"
    >
      <div class="container-xl">
        <div class="row">
          <div class="col-md-12">
            <div class="heading-border-left transformY-25">
              <h2 class="mega-title--mobile">
                {{ card.heading }}
              </h2>
            </div>
          </div>
        </div>

        <div
          class="row"
          [ngClass]="{ 'align-items-start': card.stackedImage != null }"
        >
          <div class="col-md-6 py-4">
            <div class="w-100 h-100">
              <img class="img-cover significant-raise" [src]="card.image" />
              <img
                *ngIf="card.stackedImage != null"
                class="img-cover significant-raise mt-2"
                [src]="card.stackedImage"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-5 offset-lg-1 py-md-5">
            <h2 class="large-heading">
              {{ card.headline }}
            </h2>
            <p class="cyan small-heading mb-2">
              {{ card.subheading }}
            </p>
            <div [innerHTML]="card.content"></div>
            <div class="btn-row">
              <button-text
                *ngFor="let action of card.actions"
                class="mr-2"
                buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                [label]="action.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Embedded video -->
  <div class="container-fluid my-5 py-5 greybg-right">
    <div
      class="container-xl py-3 my-3 h-100"
      *ngFor="let video of content?.body?.sections[3].videos"
    >
      <div
        class="row d-flex align-items-start justify-content-start"
        *ngIf="!isPageLoading"
      >
        <div class="col-md-4 col-sm-12 justify-content-start mt-md-5 pt-md-3">
          <h4 class="small-heading mb-2">{{ video?.heading }}</h4>
          <p [innerHTML]="video?.content"></p>
        </div>
        <!-- social links -->
        <div class="col-md-8 col-sm-12">
          <div class="row d-flex justify-content-end mb-2">
            <div class="p-2">
              <button class="button simple-icon" (click)="shareToMail()">
                <icon-font
                  name="icon-envelope"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToLinkedIn()">
                <icon-font
                  name="icon-linkedin"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToTwitter()">
                <icon-font
                  name="icon-twitter"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToFb()">
                <icon-font
                  name="icon-facebook"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToWhatsApp()">
                <icon-font
                  name="icon-whatsapp"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
            </div>
          </div>
          <!-- iframe -->
          <div
            class="embed-responsive embed-responsive-16by9 card-round significant-raise"
          >
            <iframe
              width="520"
              height="270"
              [src]="video?.url | safe"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Testimonials -->
  <div class="container-fluid my-5 py-5 greybg-left">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-left transformY-25">
            <h2 class="mega-title--mobile">
              {{ content?.body?.sections[2]?.heading }}
            </h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="card-deck">
          <div
            class="card news--card-calculators"
            *ngFor="let card of content?.body?.sections[2]?.cards"
          >
            <img
              *ngIf="card.image"
              class="card-img-top"
              [src]="card?.image"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 class="cyan small-heading mb-3">
                {{ card.headline }}
              </h4>
              <h6>{{ card?.heading }}</h6>
              <div
                class="calculators body-small"
                [innerHTML]="card?.content"
              ></div>
              <ul class="cards-list">
                <li *ngFor="let action of card?.actions">
                  <a
                    class="textlink"
                    href="{{ action?.url }}"
                    target="_blank"
                    >{{ action?.label }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Frequently asked questions - FAQs -->
  <div class="container-fluid slate-bg-10 py-4 my-4">
    <div class="container-xl">
      <div>
        <h2
          class="mb-4"
          *ngIf="
            content?.body?.sections[4]?.heading != null ||
            content?.body?.sections[4]?.heading != undefined
          "
        >
          {{ content?.body?.sections[4].heading }}
        </h2>
      </div>
      <div
        class="card-deck"
        *ngIf="content?.body?.sections[4]?.cards.length > 0"
      >
        <div
          class="card news--card"
          *ngFor="let faq of content?.body?.sections[4]?.cards"
        >
          <div class="card-body">
            <div class="card-tags pb-2"></div>
            <h5 class="small-heading mb-3">
              {{ faq.heading }}
            </h5>
            <p>{{ faq.subheading }}</p>
            <p class="body-small" [innerHTML]="faq.content"></p>
            <div class="btn-row">
              <button-text
                *ngFor="let action of faq.actions"
                class="mr-2"
                buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Articles -->
  <div class="container-fluid py-5 my-5">
    <div class="container-xl">
      <div>
        <h2 class="mb-4">{{ content?.body?.sections[5]?.heading }}</h2>
      </div>
      <div class="card-deck">
        <div
          class="card news--card"
          *ngFor="let article of content?.body?.sections[5]?.articles"
        >
          <img
            alt="Card image cap"
            class="card-img-top"
            [src]="article.image"
          />
          <div class="card-body">
            <div class="card-tags pb-2">
              <span
                *ngFor="let category of article.categories"
                role="button"
                class="basic-badge"
                data-toggle="modal"
                data-target="#modal-article-tags"
                (click)="
                  pageService.SetArticle(category); $event.preventDefault()
                "
                >{{ category }}</span
              >
            </div>
            <h5 class="small-heading">{{ article.headline }}</h5>
            <p class="body-small" [innerHTML]="article.excerpt"></p>
            <a [routerLink]="article.uri" class="textlink">Read more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
