import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs';
import { DailyInvestmentService } from 'src/app/services/dailyInvestment/daily-investment.service';
import { FundsPricingPerformanceResponse } from 'src/app/services/dailyInvestment/daily-ivestment.model';
import { FormattingService } from 'src/app/services/formatting.service';

@Component({
  selector: 'app-mm-funds-global',
  templateUrl: './mm-funds-global.component.html',
  styles: []
})
export class MmFundsGlobalComponent implements OnInit, OnDestroy {

  public pageTabs = ["Overview", "Price and performance"];
  public currentTab = "Overview";
  public alive: boolean = true;
  public fundPerformances: Array<FundsPricingPerformanceResponse>;
  @Input() content: any;

  constructor(private dailyInvestmentService: DailyInvestmentService, public formattingService: FormattingService) { }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public getFundPricesAndPerformance() {
    let today = new Date(Date.now());
    this.dailyInvestmentService.getFundPricesAndPerformances({
      date: "",
      fundCategory: "MULTI-MANAGER-FUND",
      locality: "GLOBAL",
      pageNumber: 1,
      pageSize: 50
    }).pipe(
      takeWhile(() => this.alive))
      .subscribe((result) => {
        this.fundPerformances = result;
      })
  }

  public selectedTabChanged(tab: string) {
    this.currentTab = tab;
    this.getFundPricesAndPerformance();
  }



}
