<div
  id="carouselExampleIndicators"
  class="carousel banner-carousel slide"
  data-ride="carousel"
>
  <!-- carousel-indicators -->
  <ol class="carousel-indicators">
    <li
      data-target="#carouselExampleIndicators"
      *ngFor="let data of content?.header?.headerBanner; let i = index"
      attr.data-slide-to="{{ i }}"
      [ngClass]="i == 0 ? 'active' : ''"
    ></li>
  </ol>
  <!-- carousel-indicators -->

  <!-- carousel-content & image -->
  <div class="carousel-inner landing-p-carousel">
    <div
      data-interval="7000"
      class="carousel-item banner-carousel-item"
      *ngFor="
        let data of content?.header?.headerBanner;
        let index = index;
        let last = last
      "
      [ngClass]="{ active: index === 0 }"
    >
      <img [src]="data.image" class="img-cover" />
      <div
        class="carousel-caption landing-p-carousel-caption ml-1 ml-sm-3 py-4"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-11 heading-border-left offset-md-1">
              <h1
                class="landing-p-carousel-title"
                [ngClass]="data?.headingColour ? data?.headingColour : 'black'"
              >
                {{ data.heading }}
              </h1>
            </div>
          </div>
        </div>
        <div class="container pl-3 pl-md-4">
          <div class="col-md-11 offset-md-1">
            <div class="row">
              <div class="col-md-12">
                <h2
                  [ngClass]="
                    data?.headlineColour ? data?.headlineColour : 'black'
                  "
                >
                  {{ data.headline }}
                </h2>
                <div
                  [innerHTML]="data.content"
                  [ngClass]="
                    data?.contentColour ? data?.contentColour : 'black'
                  "
                ></div>
              </div>
            </div>

            <div class="btn-row">
              <ng-container *ngFor="let action of data.actions">
                <button-text
                  class="primary"
                  buttonStyle="{{
                    action.isPrimary ? 'solid' : 'outline inverse'
                  }}"
                  [label]="action?.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- carousel-content & image -->

    <a
      class="carousel-control-prev banner-carousel-ctr-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next banner-carousel-ctr-next {"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
