import { Injectable } from "@angular/core";
import { BaseService } from "./base/base.service";
import { SettingsService } from "./base/settings.service";
import { LoggerService } from "./base/logger.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SendMailRequest } from "./advisor.models";

@Injectable()
export class AdvisorService extends BaseService {
  private branchBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject(
    ""
  );
  public selectedBranchId: Observable<string> =
    this.branchBehaviorSubject.asObservable();

  constructor(
    public settings: SettingsService,
    loggerService: LoggerService,
    http: HttpClient
  ) {
    super(loggerService, http);

    var lastSelectedBranch = sessionStorage?.getItem("LastSelectedBranch");

    if (lastSelectedBranch) {
      this.branchBehaviorSubject.next(lastSelectedBranch);
    }
  }

  public sendComms(sendMailRequest: SendMailRequest) {
    return this.Post(this.settings.CommsService.CommsApi, sendMailRequest).pipe(
      map((result) => {
        return true;
      })
    );
  }

  public GetAdviserDetails(personId: number) {
    var searchUrl = "";
    searchUrl += `&person_id_arr=[${personId}]`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.AdviserDetails + searchUrl,
      options
    ).pipe(
      map((result) => {
        return result.data.data_arr.data;
      })
    );
  }

  public GetTeamDetails(teamId: string) {
    var teamIdUrl = "";
    teamIdUrl += `&team_id=${teamId}`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.GetTeamDetails + teamIdUrl,
      options
    ).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  public Search(
    location: string = "",
    discipline: string = "",
    country: number = 0,
    adviser: string = ""
  ): Observable<any> {
    var searchUrl = "";

    if (location != "") searchUrl += `&location=${location}`;
    if (discipline != "") searchUrl += `&discipline=${discipline}`;

    searchUrl += `&country=${country}`;

    if (adviser != "") searchUrl += `&adviser=${adviser}`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.searchAdviser + searchUrl,
      options
    ).pipe(
      map((result) => {
        return result.data.data_arr.data;
      })
    );
  }

  public GetProfileImage(personId: number): Observable<any> {
    let personIdUrl = `person_id=${personId}`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.GetProfileImage + personIdUrl,
      options
    ).pipe(
      map((result) => {
        return result;
      })
    );
  }

  public GetSearchPersonUrl(personstr: string): Observable<any> {
    let url = `url=${personstr}`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      `${this.settings.FindAdvisorService.SearchPersonUrl}${url}`,
      options
    ).pipe(
      map((result) => {
        return result;
      })
    );
  }

  public GetSearchUrl(branchName: string): Observable<any> {
    let url = `url=${branchName}`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.GetSearchUrl + url,
      options
    ).pipe(
      map((result) => {
        return result;
      })
    );
  }

  public SearchAllMen(
    product: string = "FP",
    city: string = "",
    language: string = "",
    ageStart: string = "",
    ageEnd: string = "",
    gender: string = "Male"
  ): Observable<any> {
    var searchUrl = "";
    searchUrl += `&${product}=TRUE`;
    searchUrl += `&gender=${gender}`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.Search +
        (searchUrl === "" ? "&person_id=1" : searchUrl),
      options
    ).pipe(
      map((result) => {
        return result.data.data_arr.data;
      })
    );
  }
  public SearchAllWomen(
    product: string = "FP",
    city: string = "",
    language: string = "",
    ageStart: string = "",
    ageEnd: string = "",
    gender: string = "Female"
  ): Observable<any> {
    var searchUrl = "";
    searchUrl += `&${product}=TRUE`;
    searchUrl += `&gender=${gender}`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.Search +
        (searchUrl === "" ? "&person_id=1" : searchUrl),
      options
    ).pipe(
      map((result) => {
        return result.data.data_arr.data;
      })
    );
  }

  public GetBranchesAndPersons() {
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.GetBranchesAndPersons,
      options
    ).pipe(map((result) => result.data.data_arr));
  }

  public GetCities(): Observable<any> {
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(this.settings.FindAdvisorService.GetCities, options).pipe(
      map((result) => result.data.data_arr)
    );
  }

  public GetBranches(): Observable<any> {
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(this.settings.FindAdvisorService.Branches, options).pipe(
      map((result) => result.data.data_arr)
    );
  }

  public GetBranchOffice(officeId: number): Observable<any> {
    let branchOfficeUrl = `office_id=${officeId}`;
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.GetBranchOffice + branchOfficeUrl,
      options
    ).pipe(map((result) => result.data.data_arr));
  }

  getAllBranches(country_ID: any) {
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.AllBranches + country_ID,
      options
    ).pipe(map((result) => result.data.data_arr));
  }

  getContries() {
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.get_countries,
      options
    ).pipe(map((result) => result.data.data_arr));
  }

  public GetProducts(): Observable<any> {
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(this.settings.FindAdvisorService.GetProducts, options).pipe(
      map((result) => result.data.data_arr.data)
    );
  }

  public GetToken(): Observable<any> {
    var body = {
      id: environment.leadServiceAccount.id,
      secret: environment.leadServiceAccount.secret,
      username: environment.leadServiceAccount.username,
      password: environment.leadServiceAccount.password,
    };

    return this.Post(this.settings.SendLeadAdvisorService.GetToken, body).pipe(
      map((result) => result.access_token)
    );
  }

  public GetTitles(token: string): Observable<any> {
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    return this.Get(
      this.settings.SendLeadAdvisorService.GetTitles,
      options
    ).pipe(map((result) => result.items));
  }

  public GetGenders(token: string): Observable<any> {
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    return this.Get(
      this.settings.SendLeadAdvisorService.GetGender,
      options
    ).pipe(map((result) => result.items));
  }

  public CheckAllowedWebLeads(iBaseId: string, token: string): Observable<any> {
    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    return this.Get(
      `${this.settings.SendLeadAdvisorService.CheckAllowedWebLeads}?ibase_id=${iBaseId}`,
      options
    ).pipe(map((result) => result.external_lead));
  }

  public SendLead(sendLeadObject: any, token: string): Observable<any> {
    var options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + token,
      }),
    };
    return this.Post(
      this.settings.SendLeadAdvisorService.SendLead,
      sendLeadObject,
      options
    ).pipe(map((result) => result.access_token));
  }

  // New Api Calls
  public getPersonDetails(personId: number) {
    var personIdUrl = "";
    personIdUrl += `&person_id=${personId}`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };
    return this.Get(
      this.settings.FindAdvisorService.GetPesonDetails + personIdUrl,
      options
    ).pipe(
      map((result) => {
        return result;
      })
    );
  }

  public getBranchDetails(branchId: string): Observable<any> {
    var qbranchId = "";
    qbranchId += `&team_id=${branchId}`;

    var options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic cGZ3X215YmFzZTpwZndfbXliYXNlXzM1MzU=",
      }),
    };

    return this.Get(
      this.settings.FindAdvisorService.GetBranchDetails + qbranchId,
      options
    ).pipe(
      map((result) => {
        return result;
      })
    );
  }

  public setSelectedBranch(branchId: string) {
    sessionStorage?.setItem("LastSelectedBranch", branchId);
    this.branchBehaviorSubject.next(branchId);
  }
}
