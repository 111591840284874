<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid greybg-right py-5 my-5"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl">
      <div class="row">
        <div class="col-md-11 offset-md-1">
          <div *ngIf="!isPageLoading" class="heading-border-left">
            <h2 class="mega-title--mobile">{{ content?.header?.heading }}</h2>
          </div>
        </div>
      </div>

      <div class="row py-5">
        <div class="col-md-6 py-md-5 col-lg-5 offset-lg-1">
          <div class="mb-4">
            <h4 class="cyan">{{ content?.body?.categories[0]?.name }}</h4>
          </div>
          <div
            *ngFor="
              let year of content?.body?.categories[0]?.categories;
              let i = index
            "
          >
            <div class="accordion" [id]="concat('accordionPsg', i, i)">
              <div class="accordian-inner">
                <div [id]="concat('heading', i, i)">
                  <h2 class="mb-0">
                    <button
                      class="accordian-button collapsed"
                      type="button"
                      data-toggle="collapse"
                      [attr.data-target]="concat('#collapse', i, i)"
                      aria-expanded="false"
                      [attr.aria-controls]="concat('collapse', i, i)"
                    >
                      {{ year.name }}
                      <icon-font
                        name="icon-angle-up"
                        class="icon-accordian"
                      ></icon-font>
                    </button>
                  </h2>
                </div>
                <div
                  [id]="concat('collapse', i, i)"
                  class="collapse"
                  [attr.aria-labelledby]="concat('heading', i, i)"
                  [attr.data-parent]="concat('#accordionPsg', i, i)"
                >
                  <div class="accordian-body">
                    <ul>
                      <li
                        style="list-style: none"
                        *ngFor="let download of year.downloads"
                      >
                        <a
                          class="textlink"
                          target="_blank"
                          *ngIf="download?.url"
                          [href]="download?.url"
                          >{{ download?.label }}</a
                        >
                        <a
                          class="textlink"
                          target="_blank"
                          [href]="download?.file"
                          *ngIf="download?.file"
                          >{{ download?.label }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 mb-4">
          <img
            alt="retirement card img "
            class="img-fluid significant-raise"
            [src]="content?.body?.categories[0]?.image"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5 greybg-left h-100">
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-6 col-lg-6 mb-3">
        <img
          alt="retirement card img "
          class="img-fluid significant-raise"
          [src]="content?.body?.categories[1]?.image"
        />
      </div>

      <div class="col-md-6 py-md-5">
        <div class="mb-3">
          <h4 class="cyan">{{ content?.body?.categories[1]?.name }}</h4>
        </div>
        <!-- 2020 -->
        <div
          *ngFor="
            let year of content?.body?.categories[1]?.categories;
            let sI = index
          "
        >
          <div class="accordion" [id]="concat('accordionPsg1', sI, sI)">
            <div class="accordian-inner">
              <div [id]="concat('heading1', sI, sI)">
                <h2 class="mb-0">
                  <button
                    class="accordian-button collapsed"
                    type="button"
                    data-toggle="collapse"
                    [attr.data-target]="concat('#collapse1', sI, sI)"
                    aria-expanded="false"
                    [attr.aria-controls]="concat('collapse1', sI, sI)"
                  >
                    {{ year.name }}
                    <icon-font
                      name="icon-angle-up"
                      class="icon-accordian"
                    ></icon-font>
                  </button>
                </h2>
              </div>
              <div
                [id]="concat('collapse1', sI, sI)"
                class="collapse"
                [attr.aria-labelledby]="concat('heading1', sI, sI)"
                [attr.data-parent]="concat('#accordionPsg1', sI, sI)"
              >
                <div class="accordian-body">
                  <ul>
                    <li
                      style="list-style: none"
                      *ngFor="let download of year.downloads"
                    >
                      <a
                        class="textlink"
                        target="_blank"
                        *ngIf="download?.url"
                        [href]="download?.url"
                        >{{ download?.label }}</a
                      >
                      <a
                        class="textlink"
                        target="_blank"
                        [href]="download?.file"
                        *ngIf="download?.file"
                        >{{ download?.label }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-xl h-100">
  <div class="row d-flex align-items-center">
    <div class="col-md-12 mb-3">
      <h4 class="cyan">{{ content?.body?.categories[2]?.name }}</h4>
    </div>
    <div class="col-md-6">
      <div
        *ngFor="
          let year of content?.body?.categories[2]?.categories;
          let pI = index
        "
      >
        <div class="accordion" [id]="concat('accordionPsg2', pI, pI)">
          <div class="accordian-inner">
            <div [id]="concat('heading1', pI, pI)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="concat('#collapse2', pI, pI)"
                  aria-expanded="false"
                  [attr.aria-controls]="concat('collapse2', pI, pI)"
                >
                  {{ year.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="concat('collapse2', pI, pI)"
              class="collapse"
              [attr.aria-labelledby]="concat('heading2', pI, pI)"
              [attr.data-parent]="concat('#accordionPsg2', pI, pI)"
            >
              <div class="accordian-body">
                <ul>
                  <li
                    style="list-style: none"
                    *ngFor="let download of year.downloads"
                  >
                    <a
                      class="textlink"
                      target="_blank"
                      *ngIf="download?.url"
                      [href]="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
