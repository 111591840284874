export class FAQPage {
  header: any = {};
  body: any = {};
  seo: any = {};

  constructor(init: any = {}) {
      for (const key of Object.keys(init)) {
          this[key] = init[key];
      }
  }
}
