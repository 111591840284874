import { Component, Input, OnInit } from "@angular/core";
import { take } from "rxjs";
import { DailyInvestmentService } from "src/app/services/dailyInvestment/daily-investment.service";
import { FundTablesOverviewModel } from "src/app/services/dailyInvestment/daily-ivestment.model";

@Component({
  selector: 'price-and-performance-table',
  templateUrl: './price-and-performance-table.component.html',
  styles: []
})
export class PriceAndPerformanceTableComponent implements OnInit{
  @Input() locality:string;
  @Input() category:string;
  @Input() showclass:boolean = false;

  public overViewData:FundTablesOverviewModel;

  constructor(private dailyInvestmentService:DailyInvestmentService){}

  public ngOnInit(){
    this.dailyInvestmentService.getFundsOverviewData({
      date:"",
      fundCategory:this.category,
      locality:this.locality,
      pageNumber:1,
      pageSize:1000
    }).pipe(
    take(1))
    .subscribe((result) => {
      this.overViewData = result;
    })
  }
}
