<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- For your business header -->
<div *ngIf="!isPageLoading" class="container-fluid mt-5 h-100">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <h2 class="mega-title--mobile">{{ content?.header?.heading }}</h2>
      </div>
    </div>
  </div>
</div>
<!-- For your business header -->

<!-- For your business insurance -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 mb-5 greybg-left h-100"
  id="business-insurance"
>
  <div class="container-xl">
    <!--border-->
    <div class="row">
      <div class="col-md-4 offset-md-4 bottom-border"></div>
    </div>
    <!--/border-->

    <div class="row pt-5 pb-4">
      <div *ngIf="!isPageLoading" class="col-md-6 mb-4">
        <img
          alt=""
          class="img-cover significant-raise"
          [ngStyle]="{ visibility: isPageLoading ? 'hidden' : 'visible' }"
          [src]="content?.body?.sections[0]?.image"
        />
      </div>
      <div class="col-md-5 offset-md-1 py-md-5">
        <div>
          <h2>{{ content?.body?.sections[0]?.sections[0]?.heading }}</h2>
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
        </div>
        <div>
          <button-text
            *ngFor="
              let action of content?.body?.sections[0]?.sections[0]?.actions
            "
            buttonStyle="{{
              action.isPrimary ? 'solid' : 'solid altWhite mt-3 mt-md-0'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- For your business insurance -->

<!--specialist-insurance  section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 greybg-right"
  id="specialist-insurance"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-5 py-md-5 order-1 order-md-0">
        <h2>{{ content?.body?.sections[1]?.sections[0]?.heading }}</h2>
        <div
          [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
        ></div>
        <div>
          <button-text
            *ngFor="
              let action of content?.body?.sections[1]?.sections[0]?.actions
            "
            buttonStyle="{{
              action.isPrimary ? 'solid' : 'solid altWhite mt-3 mt-md-0'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>

      <div
        *ngIf="!isPageLoading"
        class="col-md-6 offset-md-1 order-0 order-md-1 mb-4"
      >
        <img
          alt=""
          class="img-cover significant-raise"
          [ngStyle]="{ visibility: isPageLoading ? 'hidden' : 'visible' }"
          [src]="content?.body?.sections[1]?.image"
        />
      </div>
    </div>
  </div>
</div>
<!-- specialist-insurance  section -->
