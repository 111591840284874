import { Component, OnInit, Input, OnDestroy, Renderer2 } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { RouterModule } from "@angular/router";
import { InvestmentProductsPage } from "src/app/models/content/save-and-invest/investment-products.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";
import { SEO_FOR_PAGES } from "src/app/models/SEO/seo-page-script";
@Component({
  selector: "app-investment-products",
  templateUrl: "./investment-products.component.html",
})
export class InvestmentProductsComponent implements OnInit, OnDestroy {
  @Input() dataHash: string;
  public content: any = {};
  public isPageLoading: boolean = true;

  //dynamic script
  JSON_LD_DATA: string = SEO_FOR_PAGES.investment_products_script;

  customOptions: OwlOptions = {
    loop: true,
    animateOut: "",
    animateIn: "",
    slideTransition: "ease-in-out",
    URLhashListener: true,
    startPosition: "URLHash",
    autoHeight: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="icon icon-angle-left"></i>',
      '<i class="icon icon-angle-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private RouterModule: RouterModule,
    private pageService: PageService,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<InvestmentProductsPage>(pageService.URIs.investmentProducts)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new InvestmentProductsPage(content);

        this.seoService.updateMetaInfoForPage(
          "Investment products",
          window.location.href,
          this.content?.body?.products[0]?.image,
          "You can withdraw from your investment in part or in total, at any time during the investment period and there is no fixed investment term."
        );
      });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, this.JSON_LD_DATA);
  }

  ngOnDestroy() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
