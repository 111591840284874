<!-- Market indicators -->
<div class="w-100">
  <div class="slight-raise table-card h-100">
    <!-- tabs -->

    <div
      class="d-flex flex-column flex-md-row align-items-start justify-content-between"
    >
      <div class="p-2 large-heading">
        <h2>Market indicators</h2>
        <p class="body-small slate">select a tab to navigate</p>
      </div>
      <div>
        <tab-horisontal
          [tabSelected]="currentTab"
          [tabs]="pageTabs"
          (tabSelectedChange)="changeSelectedTab($event)"
          class="tab-nowrap"
        ></tab-horisontal>
      </div>
    </div>

    <!-- tabs -->

    <!--tables-->
    <div class="mt-4" [ngSwitch]="currentTab">
      <!-- Local markets -->
      <div *ngSwitchCase="'Local Markets'">
        <div>
          <div class="table-responsive">
            <!-- -------------------- -->
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" style="width: 25%; min-width: 140px">Date</th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    Index
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    Current Level
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    1 Day Move
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    1 Month Move
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    6 Month Move
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    1 Year Move
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let marketIndicator of MarketIndicators">
                  <td>
                    {{ marketIndicator?.Date | amDateFormat: "YYYY-MM-DD" }}
                  </td>
                  <td>{{ marketIndicator?.DisplayName }}</td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(
                        marketIndicator?.CurrentLevel,
                        2
                      )
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(marketIndicator?.D1, 2)
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(marketIndicator?.M1, 2)
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(marketIndicator?.M6, 2)
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(
                        marketIndicator?.M12,
                        2
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- -------------------- -->
          </div>
        </div>
      </div>
      <!-- /Local markets -->

      <!-- International markets -->
      <div *ngSwitchCase="'International Markets'">
        <div>
          <div class="table-responsive">
            <!-- -------------------- -->
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" style="width: 25%; min-width: 140px">Date</th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    Index
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    Current Level
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    1 Day Move
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    1 Month Move
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    6 Month Move
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    1 Year Move
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let marketIndicator of MarketIndicators">
                  <td>
                    {{ marketIndicator?.Date | amDateFormat: "YYYY-MM-DD" }}
                  </td>
                  <td>{{ marketIndicator?.DisplayName }}</td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(
                        marketIndicator?.CurrentLevel,
                        2
                      )
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(marketIndicator?.D1, 2)
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(marketIndicator?.M1, 2)
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(marketIndicator?.M6, 2)
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(
                        marketIndicator?.M12,
                        2
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- -------------------- -->
          </div>
        </div>
      </div>
      <!-- /International markets -->

      <!-- Other markets -->
      <div *ngSwitchCase="'Other Markets'">
        <div>
          <div class="table-responsive">
            <!-- -------------------- -->
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" style="width: 25%; min-width: 140px">Date</th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    Index
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    Current Level
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    1 Day Move
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    1 Month Move
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    6 Month Move
                  </th>
                  <th scope="col" style="width: 25%; min-width: 140px">
                    1 Year Move
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let marketIndicator of MarketIndicators">
                  <td>
                    {{ marketIndicator?.Date | amDateFormat: "YYYY-MM-DD" }}
                  </td>
                  <td>{{ marketIndicator?.DisplayName }}</td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(
                        marketIndicator?.CurrentLevel,
                        2
                      )
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(marketIndicator?.D1, 2)
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(marketIndicator?.M1, 2)
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(marketIndicator?.M6, 2)
                    }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(
                        marketIndicator?.M12,
                        2
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- -------------------- -->
          </div>
        </div>
      </div>
      <!-- /Other markets -->
    </div>
    <!--tables-->
    <div>
      <p class="body-small mb-0">
        Some data may be delayed, the above table reflects the latest
        information available from Morningstar.
      </p>
      <p class="body-small">
        Please note performance reported during the first week of each month may
        be impacted by distributions. Distributions are fully accounted for by
        the second week of each month.
      </p>
      <div class="container mb-2">
        <div class="row">
          <ng-container
            *ngFor="
              let action of content?.body?.sections[0]?.sections[0]?.actions
            "
          >
            <button-text
              class="mr-2 mb-2"
              buttonStyle="{{
                action?.buttonColor != null
                  ? action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Market indicators -->
