export const SEO_FOR_PAGES = {
  home_script: `
          {
            "@context": "https://schema.org/",
            "@type": "Organization",
            "url": "https://www.psg.co.za/home",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg"
            },
            "name": "PSG Financial Services",
            "description": "PSG is a A Leading Financial Services Group thats is founded on personal service, lifelong relationships with clients, trust and transparency.",
            "address": "Marshalltown 2107 South Africa  Physical Address Building 1, The Ingress Corner of Magwa and Lone, Creek Crescents, Waterfall City, Waterfall, 2090, Gauteng, South Africa",
            "telephone": "+27 (11) 996 5200",
            "brand": {
              "@type": "Brand",
              "logo": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg"
            },
            "sameAs": [
              "https://www.instagram.com/psgfinancialservices/",
              "https://www.youtube.com/user/psgkonsultltd",
              "https://www.facebook.com/PSGFinancialServices",
              "https://twitter.com/PSGfinservices",
              "https://www.linkedin.com/company/psg-konsult/"
            ]
          }`,
  psg_asset_management_script: `
            {
              "@context": "https://schema.org",
              "@type": "FinancialService",
              "@id": "https://www.psg.co.za/about-us/psg-asset-management",
              "name": "PSG Asset Management",
              "description": "PSG Asset Management is a wholly owned subsidiary of PSG Konsult Group. We offer a range of local and global funds, with corresponding feeder funds available from South Africa.",
              "url": "https://www.psg.co.za/about-us/psg-asset-management",
              "logo": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "PO Box 68277",
                "addressLocality": "Centurion",
                "postalCode": "0157",
                "addressCountry": "South Africa"
              },
              "telephone": "08616101179",
              "email": "info@psg.co.za",
              "currenciesAccepted": "ZAR",
              "areaServed": "South Africa",
              "sameAs": [
                "https://www.instagram.com/psgfinancialservices/",
                "https://www.facebook.com/PSGFinancialServices",
                "https://www.linkedin.com/company/psg-konsult/"
              ],
              "openingHours": "Mo-Fr 09:00-17:00",
              "actionableFeedbackPolicy": "https://download.psg.co.za/files/wealth/forms/Legal/Group-privacy-policy_PSG.pdf",
              "correctionsPolicy": "https://www.psg.co.za/support/legal"
            }
`,
  retirement_planning_script: `{
            "@context": "https://schema.org",
            "@type": "FinancialService",
            "@id": "https://www.psg.co.za/save-and-invest/retirement-planning",
            "name": "PSG Retirement Planning",
            "description": "For most people, their retirement savings are not enough to retire comfortably. That is why you should start early but even if you haven’t, investing in your future is a life decision that is never too late to make.",
            "url": "https://www.psg.co.za/save-and-invest/retirement-planning",
            "logo": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "PO Box 68277",
              "addressLocality": "Centurion",
              "postalCode": "0157",
              "addressCountry": "South Africa"
            },
            "telephone": "08616101179",
            "email": "info@psg.co.za",
            "currenciesAccepted": "ZAR",
            "areaServed": "South Africa",
            "sameAs": [
              "https://www.instagram.com/psgfinancialservices/",
              "https://www.facebook.com/PSGFinancialServices",
              "https://www.linkedin.com/company/psg-konsult/"
            ],
            "openingHours": "Mo-Fr 09:00-17:00",
            "actionableFeedbackPolicy": "https://download.psg.co.za/files/wealth/forms/Legal/Group-privacy-policy_PSG.pdf",
            "correctionsPolicy": "https://www.psg.co.za/support/legal"
          }
`,
  stock_broking_script: `{
          "@context": "https://schema.org",
          "@type": "FinancialService",
          "@id": "https://www.psg.co.za/save-and-invest/stockbroking",
          "name": "PSG Stockbroking",
          "description": "Owning and trading shares can be very exciting. It can, however, be daunting if you don’t know what you’re doing, especially during uncertain times. We therefore offer you options: you can either do your own trading, take advantage of our stockbroking services through our PSG Wealth securities team, or opt for a managed portfolio through a skilled wealth manager.",
          "url": "https://www.psg.co.za/save-and-invest/stockbroking",
          "logo": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "PO Box 68277",
            "addressLocality": "Centurion",
            "postalCode": "0157",
            "addressCountry": "South Africa"
          },
          "telephone": "08616101179",
          "email": "info@psg.co.za",
          "currenciesAccepted": "ZAR",
          "areaServed": "South Africa",
          "sameAs": [
            "https://www.instagram.com/psgfinancialservices/",
            "https://www.facebook.com/PSGFinancialServices",
            "https://www.linkedin.com/company/psg-konsult/"
          ],
          "openingHours": "Mo-Fr 09:00-17:00",
          "actionableFeedbackPolicy": "https://download.psg.co.za/files/wealth/forms/Legal/Group-privacy-policy_PSG.pdf",
          "correctionsPolicy": "https://www.psg.co.za/support/legal"
        }
`,
  retirement_products_script: `{
        "@context": "https://schema.org",
        "@type": "FinancialService",
        "@id": "https://www.psg.co.za/save-and-invest/retirement-products",
        "name": "PSG Retirement Annuity",
        "description": "The PSG Wealth Equity Linked Living Annuity provides you with an annual income of between 2.5% and 17.5% of the value of your investment after retirement.",
        "url": "https://www.psg.co.za/save-and-invest/retirement-products",
        "logo": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "PO Box 68277",
          "addressLocality": "Centurion",
          "postalCode": "0157",
          "addressCountry": "South Africa"
        },
        "telephone": "08616101179",
        "email": "info@psg.co.za",
        "currenciesAccepted": "ZAR",
        "areaServed": "South Africa",
        "sameAs": [
          "https://www.instagram.com/psgfinancialservices/",
          "https://www.facebook.com/PSGFinancialServices",
          "https://www.linkedin.com/company/psg-konsult/"
        ],
        "openingHours": "Mo-Fr 09:00-17:00",
        "actionableFeedbackPolicy": "https://download.psg.co.za/files/wealth/forms/Legal/Group-privacy-policy_PSG.pdf",
        "correctionsPolicy": "https://www.psg.co.za/support/legal"
      }
`,
  investment_products_script: `{
        "@context": "https://schema.org",
        "@type": "FinancialService",
        "@id": "https://www.psg.co.za/save-and-invest/investment-products",
        "name": "PSG Investment Products",
        "description": "Find the best investment solutions to help you on your investment journey. Our investment solutions are specialised to cater to your specific needs. Tax-Free Investment.",
        "url": "https://www.psg.co.za/save-and-invest/investment-products",
        "logo": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "PO Box 68277",
          "addressLocality": "Centurion",
          "postalCode": "0157",
          "addressCountry": "South Africa"
        },
        "telephone": "08616101179",
        "email": "info@psg.co.za",
        "currenciesAccepted": "ZAR",
        "areaServed": "South Africa",
        "sameAs": [
          "https://www.instagram.com/psgfinancialservices/",
          "https://www.facebook.com/PSGFinancialServices",
          "https://www.linkedin.com/company/psg-konsult/"
        ],
        "openingHours": "Mo-Fr 09:00-17:00",
        "actionableFeedbackPolicy": "https://download.psg.co.za/files/wealth/forms/Legal/Group-privacy-policy_PSG.pdf",
        "correctionsPolicy": "https://www.psg.co.za/support/legal"
      }
`,
  insurance_script: `{
        "@context": "https://schema.org",
        "@type": "FinancialService",
        "@id": "https://www.psg.co.za/insurance",
        "name": "PSG Insurance",
        "description": "We can assist you with insurance for your things, yourself, your business and your employees.",
        "url": "https://www.psg.co.za/insurance",
        "logo": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "PO Box 68277",
          "addressLocality": "Centurion",
          "postalCode": "0157",
          "addressCountry": "South Africa"
        },
        "telephone": "08616101179",
        "email": "info@psg.co.za",
        "currenciesAccepted": "ZAR",
        "areaServed": "South Africa",
        "sameAs": [
          "https://www.instagram.com/psgfinancialservices/",
          "https://www.facebook.com/PSGFinancialServices",
          "https://www.linkedin.com/company/psg-konsult/"
        ],
        "openingHours": "Mo-Fr 09:00-17:00",
        "actionableFeedbackPolicy": "https://download.psg.co.za/files/wealth/forms/Legal/Group-privacy-policy_PSG.pdf",
        "correctionsPolicy": "https://www.psg.co.za/support/legal"
      }
`,
  save_and_invest_script: `{
        "@context": "https://schema.org",
        "@type": "FinancialService",
        "@id": "https://www.psg.co.za/save-and-invest",
        "name": "PSG Save & Invest",
        "description": "Both saving and investing are used to achieve your financial goals and each have their own role to play in your holistic financial plan.",
        "url": "https://www.psg.co.za/save-and-invest",
        "logo": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "PO Box 68277",
          "addressLocality": "Centurion",
          "postalCode": "0157",
          "addressCountry": "South Africa"
        },
        "telephone": "08616101179",
        "email": "info@psg.co.za",
        "currenciesAccepted": "ZAR",
        "areaServed": "South Africa",
        "sameAs": [
          "https://www.instagram.com/psgfinancialservices/",
          "https://www.facebook.com/PSGFinancialServices",
          "https://www.linkedin.com/company/psg-konsult/"
        ],
        "openingHours": "Mo-Fr 09:00-17:00",
        "actionableFeedbackPolicy": "https://download.psg.co.za/files/wealth/forms/Legal/Group-privacy-policy_PSG.pdf",
        "correctionsPolicy": "https://www.psg.co.za/support/legal"
      }
`,
  for_your_business_script: `{
        "@context": "https://schema.org",
        "@type": "FinancialService",
        "@id": "https://www.psg.co.za/insurance/for-your-business",
          "name": "PSG Insurance For Your business",
        "description": "Protect your business against commercial business interruptions, costs of property damage, theft and claims made against your business by others. You can take out cover against natural disasters, accidental losses, cyber, wilful damage and costs of employees failing in their duties.",
        "url": "https://www.psg.co.za/insurance/for-your-business",
        "logo": "https://www.psg.co.za/assets/images/logo/PSG-Financial-Services.svg",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "PO Box 68277",
          "addressLocality": "Centurion",
          "postalCode": "0157",
          "addressCountry": "South Africa"
        },
        "telephone": "08616101179",
        "email": "info@psg.co.za",
        "currenciesAccepted": "ZAR",
        "areaServed": "South Africa",
        "sameAs": [
          "https://www.instagram.com/psgfinancialservices/",
          "https://www.facebook.com/PSGFinancialServices",
          "https://www.linkedin.com/company/psg-konsult/"
        ],
        "openingHours": "Mo-Fr 09:00-17:00",
        "actionableFeedbackPolicy": "https://download.psg.co.za/files/wealth/forms/Legal/Group-privacy-policy_PSG.pdf",
        "correctionsPolicy": "https://www.psg.co.za/support/legal"
      }
`,
};
