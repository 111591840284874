<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- psg-multi-managed-funds main content section -->
<div *ngIf="content.header !== undefined">
  <div
    class="container-fluid psg-multi-managed-funds pt-5 pt-md-0"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-start justify-content-sm-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title mm-funds text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
          <div
            [innerHTML]="content?.header?.content"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--/psg-multi-managed-funds main content section -->

<!-- psg-multi-managed-funds card -->
<div
  *ngIf="!isPageLoading"
  class="container-xl h-100 psg-multi-managed-funds-MT"
>
  <div class="row d-flex">
    <div
      class="col-sm-7 col-md-7 col-lg-6 offset-lg-1 mb-4"
      *ngIf="content?.header?.cards?.length > 0"
    >
      <div class="psg-multi-managed-funds-card">
        <div>
          <h2 class="large-heading">
            {{ content?.header?.cards[0]?.heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[0]?.content"></div>
        </div>
        <div>
          <ng-container
            *ngFor="let action of content?.header?.cards[0]?.actions"
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-sm-5 col-md-5 col-lg-4 mb-4">
      <div class="psg-multi-managed-funds-card">
        <div>
          <h2 class="large-heading">
            {{ content?.header?.cards[1]?.heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[1]?.content"></div>
        </div>
        <div>
          <ng-container
            *ngFor="let action of content?.header?.cards[1]?.actions"
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
      <div class="psg-multi-managed-funds-card">
        <div>
          <h2 class="large-heading">
            {{ content?.header?.cards[2]?.heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[2]?.content"></div>
        </div>
        <div>
          <ng-container
            *ngFor="let action of content?.header?.cards[2]?.actions"
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/psg-multi-managed-funds card -->

<div *ngIf="!isPageLoading" class="container-xl">
  <div class="row">
    <div class="col-md-12 mt-5">
      <h1 class="section-title--mobile text-align-right">
        <span class="cyan">“</span> {{ content?.body?.sections[0]?.content
        }}<span class="cyan">”</span>
      </h1>
    </div>
  </div>
</div>

<!--MM local funds table -->
<div *ngIf="!isPageLoading" class="container-fluid py-3 my-3">
  <div class="container-xl h-100">
    <div class="local-funds-text-mobile">
      <h2 class="large-heading">Collective investment schemes</h2>
    </div>
  </div>

  <div class="container-xl mt-5 px-0" id="local-multi-managed-funds">
    <app-mm-funds-local [content]="content"> </app-mm-funds-local>
  </div>
</div>
<!--/MM local funds table -->

<!--MM Global funds table -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-3 my-3"
  id="global-multi-managed-funds"
>
  <div class="container-xl px-0 h-100">
    <app-mm-funds-global [content]="content"> </app-mm-funds-global>
  </div>
</div>
<!--/MM Global funds table -->

<!--mm-funds-other-currencies table -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-3 my-3"
  id="other-multi-managed-funds"
>
  <div class="container-xl px-0 h-100">
    <app-mm-funds-other-currencies [content]="content">
    </app-mm-funds-other-currencies>
  </div>
</div>
<!--/mm-funds-other-currencies table -->

<!--mm-funds-house-view table-->
<div *ngIf="!isPageLoading" class="container-fluid py-3 my-3">
  <div class="container-xl h-100">
    <div class="local-funds-text-mobile">
      <h2 class="large-heading">Non-Collective investment schemes</h2>
    </div>
  </div>
  <div class="container-xl mt-5 px-0 h-100">
    <app-mm-funds-house-view> </app-mm-funds-house-view>
  </div>
</div>
<!--/mm-funds-house-view table-->

<!-- The fine print-->
<div *ngIf="!isPageLoading" class="container-fluid">
  <div class="container-xl p-3">
    <div class="row">
      <div class="col-md-12">
        <p
          class="p-length-md"
          [innerHTML]="content?.body?.sections[6]?.content"
        ></p>
      </div>
    </div>
  </div>
</div>
<!-- The fine print -->

<!-- Fund performance -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 svg-bg-l details--fundP h-100"
  id="fund-performance-multi-managed-funds"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-7 ml-md-4">
        <div class="large-heading">
          <h2>{{ content?.body?.sections[7]?.sections[0]?.heading }}</h2>
        </div>
        <div
          [innerHTML]="content?.body?.sections[7]?.sections[0]?.content"
        ></div>
      </div>
    </div>
  </div>
</div>
<!-- Fund performance -->
