import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class SettingsService {
  private umbracoApiUrl: string = environment.umbracoApiUrl;
  private findAdvisorApiUrl: string = environment.findAdvisorApiUrl;
  private sendLeadApiUrl: string = environment.sendLeadApiUrl;

  //Umbraco Services
  public get ArticleService() {
    return {
      Search: `${this.umbracoApiUrl}/content/from-uri`,
      AllArticles: `${this.umbracoApiUrl}/content/from-uri`,
      Get: `${this.umbracoApiUrl}/articles`,
    };
  }
  public get PageService() {
    return {
      GetPage: `${this.umbracoApiUrl}/content/from-uri`,
    };
  }
  public get SearchService() {
    return {
      GetPage: `${this.umbracoApiUrl}/content/from-query`,
    };
  }

  public get CommsService() {
    return {
      CommsApi: `${this.umbracoApiUrl}/psgcomms/SendMail`,
    };
  }

  // PSG API Services
  public get FindAdvisorService() {
    return {
      Search: `${this.findAdvisorApiUrl}.ideal_adviser`,
      GetCities: `${this.findAdvisorApiUrl}.cities`,
      GetProducts: `${this.findAdvisorApiUrl}.disciplines`,
      GetBranchesAndPersons: `${this.findAdvisorApiUrl}.office_team_persons`,
      AdviserDetails: `${this.findAdvisorApiUrl}.ideal_adviser_details`,
      Branches: `${this.findAdvisorApiUrl}.branches`,

      // New Api Calls
      GetPesonDetails: `${this.findAdvisorApiUrl}.person_details`,
      GetProfileImage: `${this.findAdvisorApiUrl}.profile_image&`,
      AllBranches: `${this.findAdvisorApiUrl}.branches&country=`,
      get_countries: `${this.findAdvisorApiUrl}.countries`,
      searchAdviser: `${this.findAdvisorApiUrl}.search`,
      getPersonDetails: `${this.findAdvisorApiUrl}.person_details`,
      GetBranchDetails: `${this.findAdvisorApiUrl}.branch_team`,
      GetBranchOffice: `${this.findAdvisorApiUrl}.branch_office&`,
      GetSearchUrl: `${this.findAdvisorApiUrl}.search_url&`,
      GetTeamDetails: `${this.findAdvisorApiUrl}.team`,

      //advisor
      SearchPersonUrl: `${this.findAdvisorApiUrl}.search_person_url&`,
    };
  }

  public get SendLeadAdvisorService() {
    return {
      GetToken: `${this.sendLeadApiUrl}/v1/service/token/password`,
      GetTitles: `${this.sendLeadApiUrl}/v1/person/lookup/title`,
      GetGender: `${this.sendLeadApiUrl}/v1/person/lookup/gender`,
      SendLead: `${this.sendLeadApiUrl}/v1/person/lead`,
      CheckAllowedWebLeads: `${this.sendLeadApiUrl}/v1/person/user/lead_preference`,
    };
  }
}
