<!-- savings-page hero section -->
<div class="container-fluid zoomout-banner-m">
  <div class="container-fluid educationlCalc-hero">
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column h-100 align-content-start justify-content-start justify-content-sm-center pt-4 pt-sm-0"
      >
        <div class="heading-border-left mt-5 pt-3">
          <h1
            class="mega-title--page-title savings"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- savings-page hero section -->

<!-- breadcrumbs -->
<div class="container-xl py-3 my-2">
  <div class="row">
    <div class="col-md-12">
      <div>
        <ul class="breadcrumb pl-0">
          <li>
            <a href="/savings">Tax free savings</a>
          </li>
          <icon-font name="icon-angle-right">
            <span class="icon icon-angle-right"></span>
          </icon-font>
          <li class="breadcrumb-active">
            {{ content?.header?.heading }} calculator
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- /breadcrumbs -->

<!-- calculator section-->
<div class="container-xl calcRow_ mb-5">
  <div class="row">
    <!-- calculator -->
    <div class="col-lg-5 offset-lg-1 col-md-6 col-sm-12 mb-4">
      <div class="blur-card">
        <div class="row">
          <div class="col-md-12">
            <h4 class="cyan">{{ content?.header?.heading }}</h4>
          </div>
        </div>
        <form class="mt-3" [formGroup]="eductationCalculatorForm">
          <!-- How would you like to invest? -->
          <form-field class="remove-form-spacing">
            <label class="required">How would you like to invest?</label>
          </form-field>

          <div class="row flex-column flex-md-row">
            <button-radio (click)="onLumpsumChanged($event)">
              <input
                type="radio"
                value="lumpsum"
                name="inv_pattern_input"
                formControlName="inv_pattern_input"
              />
              <label>Lump sum</label>
            </button-radio>
            <button-radio (click)="onLumpsumChanged($event)">
              <input
                type="radio"
                value="yearly"
                name="inv_pattern_input"
                formControlName="inv_pattern_input"
              />
              <label>Annually</label>
            </button-radio>
            <button-radio (click)="onLumpsumChanged($event)">
              <input
                type="radio"
                value="monthly"
                name="inv_pattern_input"
                formControlName="inv_pattern_input"
              />
              <label>Monthly</label>
            </button-radio>
          </div>
          <form-field>
            <label class="required"
              >How much do you intend to invest per payment?
            </label>
            <span class="prefix">R</span>
            <input
              type="number"
              placeholder="0"
              min="0"
              step="1000"
              class="text-align-right"
              formControlName="amount_input"
              class="text-align-left"
              [ngClass]="{ 'is-invalid': formValidation.amount_input.errors }"
            />
            <div
              *ngIf="
                formValidation.amount_input.errors &&
                formValidation.amount_input.touched
              "
              class="invalid-feedback"
            >
              <form-error
                *ngIf="
                  formValidation.amount_input.errors.required &&
                  !resetFormClicked
                "
                >This field is required</form-error
              >
              <form-error *ngIf="formValidation.amount_input.errors.pattern"
                >Please enter a positive investment amount
              </form-error>
            </div>
          </form-field>
          <!--Compound time, will decide on setting it up in the back end-->

          <!-- How many years do you want to invest for? -->
          <form-field class="remove-form-spacing">
            <div class="d-flex align-items-center">
              <label
                class="required"
                [ngClass]="{
                  ' mt-3': formValidation?.amount_input?.errors?.pattern
                }"
                >How many years do you want to invest for? (Drag to select)
              </label>
              <p class="slider-value body-bold body-med text-center">
                {{ yearsInvested }}
              </p>
            </div>
          </form-field>

          <div class="d-flex justify-content-between">
            <P class="pl-1 body-med">0</P>
            <P class="pr-1 body-med">50</P>
          </div>
          <range-slider-single
            class="remove-range-spacing"
            min="0"
            max="50"
            step="1"
            [value]="yearsInvested"
            (onChange)="investedYearsValue($event)"
          >
          </range-slider-single>
          <form-error *ngIf="invalidYearsInvested && !resetFormClicked"
            >Please select the number of years invested
          </form-error>
          <!-- today's / future value  -->
          <form-field class="remove-form-spacing">
            <label
              class="required"
              [ngClass]="{
                'mt-5': invalidYearsInvested === true && !resetFormClicked
              }"
              >Do you want to see your future returns in today's value or the
              value that it will be in the future?</label
            >
          </form-field>
          <div class="row flex-column flex-md-row">
            <button-radio (click)="triggerReturnsRadioChange($event)">
              <input
                type="radio"
                name="returns_in_value_input"
                value="today"
                formControlName="returns_in_value_input"
              />
              <label>Today’s value</label>
            </button-radio>
            <button-radio (click)="triggerReturnsRadioChange($event)">
              <input
                type="radio"
                name="returns_in_value_input"
                value="future"
                formControlName="returns_in_value_input"
              />
              <label>Future value</label>
            </button-radio>
          </div>

          <!-- Will you increase your investment instalments -->
          <div *ngIf="lumbsum_selected === false">
            <form-field class="remove-form-spacing">
              <label class="required"
                >Will you increase your investment instalments every
                year?</label
              >
            </form-field>

            <div class="row flex-column flex-md-row">
              <button-radio (click)="triggerRateRadioChange($event)">
                <input
                  type="radio"
                  name="inc_rate_value_input"
                  value="yes"
                  formControlName="inc_rate_value_input"
                />
                <label>Yes, with inflation</label>
              </button-radio>
              <button-radio (click)="triggerRateRadioChange($event)">
                <input
                  type="radio"
                  name="inc_rate_value_input"
                  value="no"
                  formControlName="inc_rate_value_input"
                />
                <label>No, I will not</label>
              </button-radio>
            </div>
          </div>

          <!--Calculate & Find an adviser buttons -->
          <div class="row no-gutters mb-2 mt-4">
            <div class="col-6 pr-1">
              <button-text
                buttonStyle="solid"
                fill="true"
                label="Calculate"
                (click)="calculateSavings()"
              >
              </button-text>
            </div>
            <div class="col-6 pl-1">
              <button-text
                buttonStyle="outline"
                fill="true"
                label="Reset"
                (click)="resetForm(); $event.preventDefault()"
              ></button-text>
            </div>
          </div>

          <!--call me back & Send me my results -->
          <div class="row no-gutters mb-2 mt-4">
            <div class="col-6 pr-1">
              <button-text
                buttonStyle="outline"
                fill="true"
                label="Call me back"
                data-toggle="modal"
                data-target="#modal-call-me-back"
                (click)="$event.preventDefault()"
              ></button-text>
            </div>
            <div class="col-6 pl-1">
              <button-text
                buttonStyle="solid"
                fill="true"
                label="Send me my results"
                (click)="toggleResultsForm(); $event.preventDefault()"
                [disabled]="!final_results_saving_amount"
              >
              </button-text>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- /calculator -->

    <!-- results card -->
    <div class="col-lg-5 col-md-6">
      <div class="row flex-column-reverse flex-md-row">
        <div class="blur-card mb-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-7">
              <p class="body-bold body-med mb-1 slate">Total money invested:</p>
              <p class="calcValue">R{{ total_inv_out }}</p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-5">
              <p class="body-bold body-med mb-1 slate">In:</p>
              <p class="calcValue">{{ yearsInvested }} years</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-7">
              <p class="body-bold body-med mb-1 slate">
                Will have an estimated value of:
              </p>
              <p class="calcValue">R{{ final_results_saving_amount_out }}</p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-5 align-self-end">
              <p class="body-bold body-small slate">
                *read more
                <a
                  href=""
                  class="textlink"
                  data-toggle="modal"
                  data-target="#modal-standard-lg-assumptions"
                >
                  about calculations
                </a>
              </p>
            </div>
          </div>
        </div>
        <!-- /results card -->

        <!-- result email form -->
        <div
          class="blur-card"
          *ngIf="sendResults && final_results_saving_amount > 0"
        >
          <div>
            <h3 class="small-heading mb-4">
              Fill in the form to receive your results
            </h3>
          </div>
          <form [formGroup]="sendMeResultsForm">
            <form-field>
              <label>First name</label>
              <input
                type="text"
                placeholder="Enter your first name"
                formControlName="firstName"
              />
              <div
                *ngIf="
                  resultsFormValidation.firstName.touched &&
                  resultsFormValidation.firstName.errors
                "
              >
                <form-error
                  *ngIf="sendMeResultsForm.controls.firstName?.errors?.required"
                  >This field is required
                </form-error>
              </div>
            </form-field>

            <form-field>
              <label>Last name</label>
              <input
                type="text"
                placeholder="Enter your last name"
                formControlName="surname"
              />
            </form-field>

            <form-field>
              <label>Email</label>
              <icon-font name="icon-envelope" class="suffix"></icon-font>
              <input
                type="email"
                placeholder="Enter your email"
                formControlName="email"
              />
              <div
                *ngIf="
                  resultsFormValidation.email.touched &&
                  resultsFormValidation.email.errors
                "
              >
                <form-error
                  *ngIf="sendMeResultsForm.controls.email?.errors?.required"
                  >This field is required
                </form-error>
                <form-error
                  *ngIf="sendMeResultsForm.controls.email?.errors?.pattern"
                  >Invalid email address</form-error
                >
              </div>
            </form-field>
            <div class="mt-4">
              <button-text
                buttonStyle="solid"
                fill="true"
                label="Submit"
                [disabled]="sendMeResultsForm.invalid"
                (click)="sendMyResults()"
              >
              </button-text>
            </div>
          </form>
        </div>
        <!-- /result email form -->
      </div>
    </div>
  </div>
</div>

<!-- calculator section-->

<!-- assumptions modal -->
<div
  class="modal fade"
  id="modal-standard-lg-assumptions"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <!-- modal container -->
    <div class="modal-content">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading">Calculator Disclaimer</h5>
      </div>
      <!-- / modal header -->

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <p>
          The PSG Investment and Retirement Tool is for information purposes
          only and does not constitute advice.
        </p>
        <p>
          Using standard financial calculations, it provides an estimated
          savings target in order to understand the savings requirements that
          must be met to achieve the estimated target. The tool is based on
          multiple assumptions:
        </p>
        <ul class="dot-list mb-0">
          <li>Inflation: 5%</li>
          <li>
            Salary inflation for retirement investing: 6% (inflation plus 1%
            allowing for career growth)
          </li>
          <ul lass="dot-list mb-0">
            <p>Returns:</p>
            <li>Short-term returns: 1 - 2 years, 6% return (1% real return)</li>
            <li>
              Medium-term returns: 3 - 5 years, 8% return (3% real return)
            </li>
            <li>
              Long-term returns: 6 years or more, 10% return (5% real return)
            </li>
          </ul>
        </ul>
        <p>
          In line with these assumptions, the tool does not take the following
          factors into consideration:
        </p>
        <ul lass="dot-list mb-0">
          <li>Tax implications of investments for investors.</li>
          <li>
            Investment or product fees (i.e. all estimates are based on gross
            returns).
          </li>
          <li>
            Advantages or limitations relating to specific investment products.
          </li>
          <li>
            Volatility in investment returns due to asset mix and time horizons.
          </li>
          <li>
            Different inflation rates applicable to various items and time
            horizons.
          </li>
          <li>
            Should you wish to rely on the calculations from this tool, you are
            advised to check the calculations
          </li>
          <li>
            before using them as PSG will not be liable for any inaccuracy in
            the calculation.
          </li>
        </ul>
        <p>
          Speak to your financial adviser before making an investment decision.
          We don't guarantee that the information or any investment product is
          suitable or valuable to any investors. You should consult a registered
          financial adviser to get advice about whether our investment plans and
          the funds are suitable for your personal investment needs.
        </p>
      </div>
      <!-- / modal body -->

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button type="button" class="button solid" data-dismiss="modal">
          Close
        </button>
      </div>
      <!-- / modal footer -->
    </div>
    <!-- / modal container -->
  </div>
</div>

<!-- email sent confirmation modal -->
<div
  class="modal fade"
  id="result-modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <!-- modal container -->
    <div class="modal-content text-center" *ngIf="isInitLoading">
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-grow gold" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div class="modal-content" *ngIf="!isInitLoading">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading">Email Status</h5>
      </div>

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <h4>{{ contactUsResponseMsg }}</h4>
      </div>

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button
          id="closeModal"
          type="button"
          class="button outline"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!--  -->

<!-- call-me-back Model -->
<app-call-me-back-modal></app-call-me-back-modal>
