import { Injectable, isDevMode } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { BaseService } from './base/base.service';
import { SettingsService } from './base/settings.service';
import { LoggerService } from './base/logger.service';

@Injectable()
export class SearchService extends BaseService {

  public isGlobal: boolean = false;

  constructor(
    public settings: SettingsService,
    loggerService: LoggerService,
    http: HttpClient
  ) {
    super(loggerService, http);
  }

  public GetSearch<TObject>(searchTerm: string, category: string): Observable<TObject> {
    if (isDevMode){
      console.log("Entering")
    }

    var body  = {
        index: this.isGlobal?"Global":"ZA",
        searchTerm: searchTerm,
        category: category
      };
    if (isDevMode){
      console.log(body)
    }
    return this.Post(this.settings.SearchService.GetPage, body).pipe(map((result) => {
      return result as TObject;
    }));
  }

}
