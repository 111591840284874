import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DuiArticlePage } from "src/app/models/content/news-and-publications/dui-article-page.model";
import { ArticleService } from "src/app/services/article.service";
import { DailyInvestmentService } from "src/app/services/dailyInvestment/daily-investment.service";
import { FundsPricingPerformanceResponse } from "src/app/services/dailyInvestment/daily-ivestment.model";
import { FormattingService } from "src/app/services/formatting.service";
import { PageService } from "src/app/services/page.service";
import { takeWhile } from "rxjs";

@Component({
  selector: "app-psg-funds-local-static",
  templateUrl: "./psg-funds-local-static.component.html",
})
export class PsgFundsLocalStaticComponent implements OnInit {
  public pageTabs = ["Overview", "Price and performance"];
  public currentTab = "Overview";
  public alive: boolean = true;
  public fundPerformances: Array<FundsPricingPerformanceResponse>;

  @Input() showOnlyPriceAndPerformance: boolean = false;

  public showButton: boolean = false;
  public content: any = {};
  public newsArticle: string;

  //Latest Article
  public latestArticleURL = "";

  constructor(
    private dailyInvestmentService: DailyInvestmentService,
    private route: ActivatedRoute,
    private pageService: PageService,
    private router: Router,
    public formattingService: FormattingService,
    public diuArticleService: ArticleService
  ) { }

  public ngOnInit() {
    this.pageService
      .GetPage<DuiArticlePage>(this.pageService.URIs.investmentResearch)

      .subscribe((content) => {
        this.content = new DuiArticlePage(content);
      });

    if (this.showOnlyPriceAndPerformance == true) {
      this.currentTab = "Price and performance";
      this.showButton = true;
      this.getFundPricesAndPerformance();
    }
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public getFundPricesAndPerformance() {
    let today = new Date(Date.now());
    this.dailyInvestmentService
      .getFundPricesAndPerformances({
        date: "",
        fundCategory: "PSG-FUND",
        locality: "LOCAL",
        pageNumber: 1,
        pageSize: 50,
      })
      .pipe(takeWhile(() => this.alive))
      .subscribe((result) => {
        this.fundPerformances = result;
      });
  }

  public selectedTabChanged(tab: string) {
    this.currentTab = tab;
    this.getFundPricesAndPerformance();
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }
}
