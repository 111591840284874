<div class="container-xl">
    <div>
        <h2 class="mb-4 text-right heading-left">Latest news/posts/events from our branch</h2>
    </div>


    <div class="container-fluid">
        <div id="carouselControls3" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" style="overflow: unset;">
                <div class="carousel-item active">
                    <div class="row">
                        <div class="col-md-3 multi">
                            <div class="card news--card mb-4">
                                <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                                    alt="Card image cap">
                                <div class="card-body">
                                    <div class="card-tags pb-2">
                                        <span class="basic-badge">News</span>
                                        <span class="basic-badge">finance</span>
                                    </div>
                                    <h5 class="small-heading">This is a catchy news article heading</h5>
                                    <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                        amet sint.
                                        Velit
                                        officia
                                        consequat duis enim velit mollit.</p>
                                    <a [routerLink]="['/article']" class="textlink">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 multi">
                            <div class="card news--card mb-4">
                                <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                                    alt="Card image cap">
                                <div class="card-body">
                                    <div class="card-tags pb-2">
                                        <span class="basic-badge">News</span>
                                        <span class="basic-badge">finance</span>
                                    </div>
                                    <h5 class="small-heading">This is a catchy news article heading</h5>
                                    <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                        amet sint.
                                        Velit
                                        officia
                                        consequat duis enim velit mollit.</p>
                                    <a [routerLink]="['/article']" class="textlink">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 multi">
                            <div class="card news--card mb-4">
                                <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                                    alt="Card image cap">
                                <div class="card-body">
                                    <div class="card-tags pb-2">
                                        <span class="basic-badge">News</span>
                                        <span class="basic-badge">finance</span>
                                    </div>
                                    <h5 class="small-heading">This is a catchy news article heading</h5>
                                    <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                        amet sint.
                                        Velit
                                        officia
                                        consequat duis enim velit mollit.</p>
                                    <a [routerLink]="['/article']" class="textlink">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 multi">
                            <div class="card news--card mb-4">
                                <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                                    alt="Card image cap">
                                <div class="card-body">
                                    <div class="card-tags pb-2">
                                        <span class="basic-badge">News</span>
                                        <span class="basic-badge">finance</span>
                                    </div>
                                    <h5 class="small-heading">This is a catchy news article heading</h5>
                                    <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                        amet sint.
                                        Velit
                                        officia
                                        consequat duis enim velit mollit.</p>
                                    <a [routerLink]="['/article']" class="textlink">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-md-3 multi">
                            <div class="card news--card mb-4">
                                <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                                    alt="Card image cap">
                                <div class="card-body">
                                    <div class="card-tags pb-2">
                                        <span class="basic-badge">News</span>
                                        <span class="basic-badge">finance</span>
                                    </div>
                                    <h5 class="small-heading">This is a catchy news article heading</h5>
                                    <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                        amet sint.
                                        Velit
                                        officia
                                        consequat duis enim velit mollit.</p>
                                    <a [routerLink]="['/article']" class="textlink">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 multi">
                            <div class="card news--card mb-4">
                                <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                                    alt="Card image cap">
                                <div class="card-body">
                                    <div class="card-tags pb-2">
                                        <span class="basic-badge">News</span>
                                        <span class="basic-badge">finance</span>
                                    </div>
                                    <h5 class="small-heading">This is a catchy news article heading</h5>
                                    <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                        amet sint.
                                        Velit
                                        officia
                                        consequat duis enim velit mollit.</p>
                                    <a [routerLink]="['/article']" class="textlink">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 multi">
                            <div class="card news--card mb-4">
                                <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                                    alt="Card image cap">
                                <div class="card-body">
                                    <div class="card-tags pb-2">
                                        <span class="basic-badge">News</span>
                                        <span class="basic-badge">finance</span>
                                    </div>
                                    <h5 class="small-heading">This is a catchy news article heading</h5>
                                    <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                        amet sint.
                                        Velit
                                        officia
                                        consequat duis enim velit mollit.</p>
                                    <a [routerLink]="['/article']" class="textlink">Read more</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 multi">
                            <div class="card news--card mb-4">
                                <img class="card-img-top" src="../../../assets/images/home-page/talk-to-adviser.png"
                                    alt="Card image cap">
                                <div class="card-body">
                                    <div class="card-tags pb-2">
                                        <span class="basic-badge">News</span>
                                        <span class="basic-badge">finance</span>
                                    </div>
                                    <h5 class="small-heading">This is a catchy news article heading</h5>
                                    <p class="body-small">Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                        amet sint.
                                        Velit
                                        officia
                                        consequat duis enim velit mollit.</p>
                                    <a [routerLink]="['/article']" class="textlink">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="control-buttons">
                <button class="button outline-icon" href="#carouselControls3" role="button" data-slide="prev">
                    <icon-font name="icon-angle-left"></icon-font>
                    <span class="sr-only">Previous</span>
                </button>
                <button class="button outline-icon ml-2" href="#carouselControls3" role="button" data-slide="next">
                    <icon-font name="icon-angle-right"></icon-font>
                    <span class="sr-only">Next</span>
                </button>
            </div>
        </div>
    </div>
</div>