<div class="row no-gutters">

    <!-- tabs left side desktop -->
    <div class="col-md-auto d-none d-md-block">
        <tab-vertical [(tabSelected)]="currentTab" [tabs]="pageTabs" class=""></tab-vertical>
    </div>

    <!-- tabs top mobile -->
    <div class="col-md-12 mb-2 d-block d-md-none">
        <div class="tabScroll overflow-auto">
            <tab-horisontal [(tabSelected)]="currentTab" [tabs]="pageTabs" class="tab-nowrap-space">
            </tab-horisontal>
        </div>
    </div>
    <!-- /tabs top mobile -->

    <div class="col-md-9" [ngSwitch]="currentTab">
        <!-- Step 1 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 1'">
            <div class="col-md-9 pl-md-2">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[5]?.items[0]?.name}}</h2>
                    <h4 class="small-heading">{{content?.body?.sections[5]?.items[0]?.heading}}</h4>
                    <p [innerHTML]="content?.body?.sections[5]?.items[0]?.content"></p>
                </div>
            </div>
        </div>
        <!-- /Step 1 -->

        <!-- Step 2 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 2'">
            <div class="col-md-9 pl-md-2">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[5]?.items[1]?.name}}</h2>
                    <h4 class="small-heading">{{content?.body?.sections[5]?.items[1]?.heading}}</h4>
                    <p [innerHTML]="content?.body?.sections[5]?.items[1]?.content"></p>
                </div>
            </div>
        </div>
        <!-- /Step 2 -->

        <!-- Step 3 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 3'">
            <div class="col-md-9 pl-md-2">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[5]?.items[2]?.name}}</h2>
                    <h4 class="small-heading">{{content?.body?.sections[5]?.items[2]?.heading}}</h4>
                    <p [innerHTML]="content?.body?.sections[5]?.items[2]?.content"></p>
                </div>
            </div>
        </div>
        <!-- /Step 3 -->

        <!-- Step 4 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 4'">
            <div class="col-md-9 pl-md-2">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[5]?.items[3]?.name}}</h2>
                    <h4 class="small-heading">{{content?.body?.sections[5]?.items[3]?.heading}}</h4>
                    <p [innerHTML]="content?.body?.sections[5]?.items[3]?.content"></p>
                </div>
            </div>
        </div>
        <!-- /Step 4 -->

        <!-- Step 5 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 5'">
            <div class="col-md-9 pl-md-2">
                <div class="slate-bg-10 p-4 overflow-auto h-100 info-stockb">
                    <h2 class="large-heading">{{content?.body?.sections[5]?.items[4]?.name}}</h2>
                    <h4 class="small-heading">{{content?.body?.sections[5]?.items[4]?.heading}}</h4>
                    <p [innerHTML]="content?.body?.sections[5]?.items[4]?.content"></p>
                </div>
            </div>
        </div>
        <!-- /Step 5 -->
    </div>
</div>
