<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-fluid factSheet-hero">
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div class="heading-border-left">
        <h1 class="mega-title--page-title text-shadow">Fund fact sheet</h1>
      </div>
    </div>
  </div>
</div>

<!-- fund heading -->
<div *ngIf="!isPageLoading" class="container-xl mt-5 mb-0">
  <div class="col-md-9 offset-md-3">
    <div class="heading-border-right">
      <h1 class="section-title--mobile text-right">
        This is heading will be the funds name
      </h1>
    </div>
  </div>
</div>
<!-- /fund heading -->

<div
  *ngIf="!isPageLoading"
  class="container-fluid mb-5 pb-5 svg-bg-l fund--factSheets h-100"
>
  <div class="container-xl pt-5 mb-5">
    <div class="row d-flex align-items-center justify-content-center">
      <!-- fund details -->
      <div class="col-md-6 order-1 order-md-0">
        <div class="table-card slight-raise">
          <div class="table-responsive">
            <!-- -------------------- -->
            <table class="table table-bordered table-hover">
              <tbody>
                <tr>
                  <td class="body-bold">Inception date</td>
                  <td>2007/07/02</td>
                </tr>
                <tr>
                  <td class="body-bold">Investment adviser</td>
                  <td>Adriaan Pask</td>
                </tr>
                <tr>
                  <td class="body-bold">ASISA sector</td>
                  <td>South African ­Equity General</td>
                </tr>
                <tr>
                  <td class="body-bold">Benchmark</td>
                  <td>(ASISA) South African EQ General Mean</td>
                </tr>
                <tr>
                  <td class="body-bold">Regulation 28 compliant</td>
                  <td>No</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /fund details -->

      <!-- Investment objective -->
      <div class="col-md-5 offset-md-1 order-0 order-md-1">
        <h2 class="medium-heading">Investment objective</h2>
        <p>
          The PSG Wealth Creator Fund of Funds' investment objective is to
          achieve capital growth over the long term. Income is not the main
          objective. Investments will, apart from cash in liquid form, mainly
          consist of investments in equity markets (no more than 95%) via
          participatory interests in Collective Investment Schemes. The asset
          allocation will be actively managed to reﬂect the investment manager's
          view of the relative attractiveness of cash, ﬁxed interest, equity
          portfolios and property markets.
        </p>
      </div>
      <!-- /Investment objective -->
    </div>
  </div>

  <div *ngIf="!isPageLoading" class="container-xl">
    <div class="row d-flex align-items-center justify-content-start">
      <!-- Investment Charge -->
      <div class="col-md-6 order-1 order-md-0">
        <div class="table-card slight-raise">
          <div class="table-responsive">
            <!-- -------------------- -->
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <td colspan="2" class="body-bold">Total Investment Charge</td>
                </tr>
                <tr>
                  <td colspan="2" class="body-small">
                    Total investment charge annualised for the period 01/04/2019
                    - 31/03/2020
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="body-bold">Total expense ratio % (incl. Vat)</td>
                  <td>2.51</td>
                </tr>
                <tr>
                  <td class="body-small font-italic">
                    Annnual Management Fee % (Incl. Vat)<br />
                    Other Costs excl. transaction costs % (incl. Vat)
                  </td>
                  <td class="body-small font-italic">
                    1.73<br />
                    0.79
                  </td>
                </tr>
                <tr>
                  <td class="body-bold">Transaction costs % (Incl. Vat)</td>
                  <td>0.27</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td class="body-bold">
                    Total investment Charge % (Incl. Vat)
                  </td>
                  <td>2.78</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <!-- /Investment Charge -->

      <!-- Who should consider investing? -->
      <div class="col-md-5 offset-md-1 order-0 order-md-1">
        <h3 class="small-heading">Who should consider investing?</h3>
        <p>
          Speciﬁc Fund Risks: The fund sits at the top end of the risk/reward
          spectrum and investors should be comfortable with ﬂuctuations in
          shares on stock markets and be prepared to accept the risk of capital
          loss. The portfolio is primarily invested in local and foreign
          equities. Equity valuations can be very volatile, especially over the
          short term. Property shares may be included in the portfolio which can
          carry the same risk as investing directly in real estate and is
          subject to economic and political conditions, interest rates and tax
          considerations. Oﬀshore securities expose the portolio to currency
          risk and currency movements may adversely aﬀect the value of your
          investment. Investments may be concentrated in speciﬁc countries,
          geographical regions and/or industry sectors and may mean that the
          resulting value may decrease whilst portfolios more broadly invested
          might grow.
        </p>
      </div>
      <!-- /Who should consider investing? -->

      <!-- Risk/reward profile -->
      <div class="col-md-6 order-3 order-md-3 mt-5">
        <div class="table-card slight-raise">
          <h4 class="small-heading mb-3">Risk/reward profile</h4>
          <img
            src="../../../assets/images/home-page/riskFund.svg"
            class="img-cover"
          />
        </div>
      </div>
      <!-- /Risk/reward profile -->

      <!-- Who should consider investing? -->
      <div class="col-md-4 offset-md-1 order-4 order-md-4 mt-5">
        <h4 class="small-heading">The fund is suitable for investors who:</h4>
        <div
          class="d-flex align-baseline align-items-center justify-content-start mb-4"
        >
          <icon-font class="icon-xl" name="icon-bar-graph"></icon-font>
          <p class="mb-2 ml-3 body-med">Seeking long-term wealth creation</p>
        </div>
        <div
          class="d-flex align-baseline align-items-center justify-content-start mb-4"
        >
          <icon-font class="icon-xl" name="icon-line-graph"></icon-font>
          <p class="mb-2 ml-3 body-med">
            Want exposure to equity markets and are comfortable with stock
            market ﬂuctuations
          </p>
        </div>
        <div
          class="d-flex align-baseline align-items-center justify-content-start mb-4"
        >
          <icon-font class="icon-xl" name="icon-calendar"></icon-font>
          <p class="mb-2 ml-3 body-med">
            Long-term investment horizon of at least ﬁve years
          </p>
        </div>
      </div>
      <!-- /Who should consider investing? -->
    </div>
  </div>
</div>

<!-- downloads section-->
<div *ngIf="!isPageLoading" class="container-xl my-5">
  <div class="row d-flex align-items-center justify-content-center">
    <!-- historical DL dropdown -->
    <div class="col-md-6 order-1 order-md-0">
      <div class="table-card slight-raise">
        <div>
          <h3 class="medium-heading mb-1">Historical reports</h3>
          <p class="body-small">Select a date to download report</p>
        </div>
        <dropdown-select>
          <select>
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
          </select>
        </dropdown-select>
        <button-text
          buttonStyle="solid"
          fill="true"
          label="download"
        ></button-text>
      </div>
    </div>
    <!-- /historical DL dropdown -->

    <!-- Fund literature -->
    <div class="col-md-5 offset-md-1 order-0 order-md-1">
      <h2 class="medium-heading">Fund literature</h2>
      <div
        class="d-flex align-baseline align-items-center justify-content-start mb-4"
      >
        <icon-font class="icon-xl" name="icon-file-download"></icon-font>

        <div>
          <p class="mb-0 ml-3 body-bold">Download Fund Fact Sheet</p>
          <p class="mb-2 ml-3 body-small">20 july 2020</p>
        </div>
      </div>

      <div
        class="d-flex align-baseline align-items-center justify-content-start mb-4"
      >
        <icon-font class="icon-xl" name="icon-message"></icon-font>

        <div>
          <p class="mb-0 ml-3 body-bold">Latest Commentary</p>
          <p class="mb-2 ml-3 body-small">20 july 2020</p>
        </div>
      </div>
    </div>
    <!-- /Fund literature -->
  </div>
</div>
