import { Injectable, isDevMode } from '@angular/core';
import { HttpServiceRequestType } from '../../models/base/http-service-request-types.enum';

@Injectable()
export class LoggerService {
    constructor() { }

    public Log(message: string, optionalParams?: any): void {
      if (isDevMode){
        console.log(message, optionalParams ? optionalParams : '');
      }

    }

    public LogError(message: string, optionalParams?: any): void {
      if (isDevMode){
        console.error(message, optionalParams ? optionalParams : '');
      }

    }

    public LogHttpRequest(type: HttpServiceRequestType, url: string, parameter?: any): void {
        this.Log(`${type} Service Call`);
        this.Log('Service Path: ', url);

        if (parameter) {
            if (type === HttpServiceRequestType.POST ||
                type === HttpServiceRequestType.PATCH ||
                type === HttpServiceRequestType.PUT) {
                this.Log('Service Body: ', parameter);
                return;
            }

            this.Log('Service Query: ', parameter);
            return;
        }
    }

    public LogHttpResponse(type: HttpServiceRequestType, url: any, body: any, response: any): void {
        this.Log('Service Response: ', { response: response });
    }

    public LogHttpError(response: any): void {
        this.LogError('Service Response: ', { response: response });
    }
}
