<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- Financial advice hero section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid financial-advice-hero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
      *ngIf="content.header !== undefined"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title financial-advice-header text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
        <h3
          class="text-shadow"
          [ngClass]="
            content?.header?.subheadingColour
              ? content?.header?.subheadingColour
              : 'white'
          "
        >
          {{ content?.header?.subheading }}
        </h3>
      </div>
      <div class="pl-md-4">
        <div
          [innerHTML]="content?.header?.content"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
      </div>
      <div class="pl-md-4">
        <ng-container *ngFor="let action of content?.header?.actions">
          <button-text
            class="{{ action.isPrimary ? '' : 'mr-2' }}"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }} mr-2"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!--/ Financial advice hero section -->

<!-- Your long-term financial partners section -->
<div
  *ngIf="
    content.body != null && content.body.sections.length > 0 && !isPageLoading
  "
>
  <div class="container-fluid py-5 my-5 greybg-right h-100">
    <div class="container-xl h-100">
      <div class="row">
        <div class="col-md-10 offset-md-2">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content?.body?.sections[0]?.heading }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center mt-5">
        <div class="col-lg-5 offset-lg-1 col-md-6">
          <div
            class="p-length-sm pr-lg-3"
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
        </div>
        <div class="col-lg-6 col-md-6">
          <img
            alt="retirement card img "
            class="img-cover significant-raise"
            [src]="content?.body?.sections[0]?.image"
          />
        </div>
      </div>

      <div class="row h-100 pt-5 mt-4">
        <div class="col-md-4 mb-4" *ngFor="let card of content?.body?.cards">
          <div
            class="finacial-advisor card-round noticeable-raise white-bg p-5 p-md-5 h-100 d-flex flex-column justify-content-around"
          >
            <div>
              <h4 class="large-heading">{{ card?.heading }}</h4>
              <div class="mt-4" [innerHTML]="card?.content"></div>
            </div>
            <div>
              <ng-container *ngFor="let action of card?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : '' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }}"
                  [label]="action?.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Your long-term financial partners section -->
</div>

<!-- Recommended news -->
<div
  class="container-fluid my-5 py-5 slate-bg-10"
  *ngIf="content?.body?.sections[1]?.heading != null && !isPageLoading"
>
  <div class="container-xl h-100">
    <div class="row d-flex align-items-center justify-content-left h-100">
      <h2 class="mb-4">{{ content?.body?.sections[1]?.heading }}</h2>

      <div class="card-deck">
        <div
          class="card news--card"
          *ngFor="let news of content?.body?.sections[1]?.articles"
        >
          <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
          <div class="card-body d-flex flex-column">
            <div class="card-tags pb-2">
              <span
                *ngFor="let category of news?.categories"
                role="button"
                class="basic-badge"
                data-toggle="modal"
                data-target="#modal-article-tags"
                (click)="
                  pageService.SetArticle(category); $event.preventDefault()
                "
                >{{ category }}</span
              >
            </div>
            <h5 class="small-heading">{{ news?.headline }}</h5>
            <p class="body-small" [innerHTML]="news?.excerpt"></p>
            <a [routerLink]="news?.uri" class="textlink mt-auto">Read more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Recommended news -->
