<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="container-fluid fund-of-funds-hero mb-5 pb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div class="row d-flex align-content-start justify-content-center h-100">
      <div class="col-md-12 mt-5">
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title fund-of-funds-header white text-shadow"
          >
            {{ content?.header?.heading }}
          </h1>
          <h3 class="white text-shadow">
            {{ content?.header?.subheading }}
          </h3>
        </div>
      </div>
      <div class="col-md-12 mb-5">
        <div class="pl-md-4">
          <div [innerHTML]="content?.header?.content"></div>
        </div>
        <div class="pl-md-4">
          <ng-container *ngFor="let action of content?.header?.actions">
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }} mr-2"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
        <!-- <div class="row">
                <div class="col-md-7 col-xs-12">
                    <div class="pl-md-4">
                        <div [innerHTML]="content?.header?.content"></div>
                    </div>
                    <div class="pl-md-4">
                        <ng-container *ngFor="let action of content?.header?.actions">
                        <button-text
                            class="{{ action.isPrimary ? '' : 'mr-2' }}"
                            buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }} mr-2"
                            [label]="action?.label"
                            (click)="DynamicButtonClick(action)"
                        >
                        </button-text>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-5 col-xs-5"
                    *ngIf="content?.header?.headerBannerContent.length > 0">
                    <div class="fund-of-funds-hero-card">
                    <h3 class="medium-heading cyan">
                        {{ content?.header?.headerBannerContent[0].heading }}
                    </h3>
                    <p>{{ content?.header?.headerBannerContent[0].headline }}</p>
                    <div [innerHTML]="content?.header?.headerBannerContent[0].content"></div>
                    <div>
                        <ng-container
                        *ngFor="let action of content?.header?.headerBannerContent[0].actions"
                        >
                        <button-text
                            class="{{ action.isPrimary ? '' : 'mr-2' }}"
                            buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }} mr-2"
                            [label]="action?.label"
                            (click)="DynamicButtonClick(action)"
                        >
                        </button-text>
                        </ng-container>
                    </div>
                    </div>
                </div>
            </div> -->
      </div>
    </div>
  </div>
</div>

<div class="container-xl banner-card">
  <div class="row d-flex">
    <div
      class="col-md-12"
      *ngIf="content?.header?.headerBannerContent.length > 0"
    >
      <div class="research-insights-hero-card">
        <h3 class="medium-heading cyan">
          {{ content?.header?.headerBannerContent[0].heading }}
        </h3>
        <p>{{ content?.header?.headerBannerContent[0].headline }}</p>
        <div
          [innerHTML]="content?.header?.headerBannerContent[0].content"
        ></div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid pb-5 h-100">
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-6 col-xs-12 mb-4">
        <div class="row d-flex">
          <div
            class="col-md-6 col-xs-12 mb-4 d-flex flex-column"
            *ngFor="
              let card of content?.body?.sections
                | slice : 0 : content?.body?.sections.length - 3
            "
          >
            <h5 class="small-heading mb-3">
              {{ card.heading }}
            </h5>
            <p class="body-small" [innerHTML]="card.content"></p>
            <div class="btn-row mt-auto">
              <button-text
                *ngFor="let action of card.actions"
                class="mr-2"
                [buttonStyle]="action.isPrimary ? 'solid' : 'outline'"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row">
          <div class="col-12">
            <h2 class="mb-4">
              {{
                content?.body?.sections[content?.body?.sections.length - 3]
                  .heading
              }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div
            *ngFor="
              let video of content?.body?.sections[
                content?.body?.sections.length - 3
              ].videos
            "
            class="col-12"
          >
            <h4 class="small-heading mb-2">{{ video.heading }}</h4>
            <div class="p-2 text-right">
              <button class="button simple-icon" (click)="shareToMail()">
                <icon-font
                  name="icon-envelope"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToLinkedIn()">
                <icon-font
                  name="icon-linkedin"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToTwitter()">
                <icon-font
                  name="icon-twitter"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToFb()">
                <icon-font
                  name="icon-facebook"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToWhatsApp()">
                <icon-font
                  name="icon-whatsapp"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
            </div>
            <div
              class="embed-responsive embed-responsive-16by9 card-round significant-raise"
            >
              <iframe
                width="520"
                height="270"
                [src]="video?.url | safe"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div class="mt-3" [innerHTML]="video?.content"></div>
          </div>
          <div class="col-12">
            <div class="btn-row mt-auto">
              <button-text
                *ngFor="
                  let action of content?.body?.sections[
                    content?.body?.sections.length - 3
                  ].actions
                "
                class="mr-2"
                [buttonStyle]="action.isPrimary ? 'solid' : 'outline'"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-avoiding-mistakes [content]="avoidingMistakesContent">
</app-avoiding-mistakes>

<app-frequently-asked-questions [faqList]="faqList">
</app-frequently-asked-questions>

<div class="container-xl py-5">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>
