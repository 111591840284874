import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-client-services',
  templateUrl: './lead-client-services.component.html'
})
export class LeadClientServicesComponent implements OnInit {

  url = 'https://forms.office.com/r/8sb0TQULLE';

  constructor() { }

  ngOnInit() {
  }

}
