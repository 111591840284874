import { Injectable } from "@angular/core";

@Injectable()
export class FormattingService{

  public tryFormatDecimal(value:string,decimalPoints:number){
    let returnValue;
    if (parseFloat(value)){
      return Number(value).toFixed(decimalPoints);
    }else{
      return value;
    }
  }
}
