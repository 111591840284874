<div class="container-fluid slides-scale">
    <div class="container-xl">
        <div class="row no-gutters ">
            <!-- image left col -->
            <div class="col-md-6  d-none d-md-block">
                <img [src]="content?.body?.products[2]?.image" class="img-cover prod-img" />
            </div>

            <!-- content right col -->
            <div class="col-md-6 pl-md-5 py-3 mb-1">
                <!--border-->
                <div class="row d-none d-md-block">
                    <div class="col-md-6 mb-5 bottom-border carousel-bb-transform">
                    </div>
                </div>
                <!--/border-->

                <!-- product title -->
                <div class="mb-0 mt-3">
                    <h2>{{content?.body?.products[2]?.name}}</h2>
                </div>


                <!-- product content -->
                <div class="mt-4 pl-md-2" [innerHTML]="content?.body?.products[2]?.content">

                </div>

                <div class="row mt-3 pl-md-3">
                    <div class="col-md-12 pl-md-2 btn-100-sm">
                      <ng-container *ngFor="let action of content?.body?.products[1]?.actions">
                        <button-text class="{{action.isPrimary ? '': 'mr-2'}}"
                          buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action?.label"
                          (click)="DynamicButtonClick(action)">
                        </button-text>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
