import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ForYourselfPage } from 'src/app/models/content/financial-advice/for-yourself.model';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: "app-advice-process-steps",
  templateUrl: "./advice-process-steps.component-archive.html",
})
export class AdviceProcessStepsComponent  {
  public content: any = {};

  public pageTabs = [
    "Step 1",
    "Step 2",
    "Step 3",
    "Step 4",
    "Step 5",
    "Step 6",
  ];

  public currentTab = "Step 1";

  constructor(private pageService: PageService, private router: Router) {
    pageService
      .GetPage<ForYourselfPage>(pageService.URIs.financialAdviceForYourself)
      .subscribe((content) => {
        this.content = new ForYourselfPage(content);
      });
  }
  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }
}
