<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid Advice-forYourEstate mb-5"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="d-flex flex-column align-content-start justify-content-md-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
          <h3
            class="large-heading mt-3 text-shadow"
            [ngClass]="
              content?.header?.subheadingColour
                ? content?.header?.subheadingColour
                : 'white'
            "
          >
            {{ content?.header?.subheading }}
          </h3>
        </div>
        <div class="pl-4 pl-md-4">
          <div
            [innerHTML]="content?.header?.content"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="container-xl Advice-forYourEstate--cards mb-5"
  *ngIf="content.header !== undefined && !isPageLoading"
>
  <div class="row d-flex">
    <div class="col-lg-6 mb-4">
      <div class="blur-card mb-4">
        <div>
          <h2 class="medium-heading">
            {{ content?.header?.cards[0]?.heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[0]?.content"></div>
        </div>
      </div>
      <div class="blur-card">
        <div>
          <h2 class="medium-heading">
            {{ content?.header?.cards[1].heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[1]?.content"></div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="blur-card">
        <div>
          <h2 class="medium-heading">
            {{ content?.header?.cards[2]?.heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[2]?.content"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ hero section -->

<!-- Local & offshore trusts -->
<div *ngIf="!isPageLoading" class="container-fluid mt-5 pb-5 greybg-left h-100">
  <div class="container-xl pt-4">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-6 mb-4 md-md-0">
        <div class="w-100 h-100">
          <img
            class="img-cover significant-raise"
            [src]="content?.body?.sections[0]?.image"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-5 offset-lg-1 py-md-5">
        <div>
          <h2 class="medium-heading">
            {{ content?.body?.sections[0]?.sections[0]?.heading }}
          </h2>
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
        </div>

        <div class="mt-5">
          <h2 class="medium-heading">
            {{ content?.body?.sections[0]?.sections[1]?.heading }}
          </h2>
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[1]?.content"
          ></div>

          <p class="body-large body-bold mb-0">
            {{ content?.body?.sections[0]?.sections[2]?.heading }}
          </p>
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[1]?.content"
          ></div>
          <div>
            <ng-container
              *ngFor="
                let action of content?.body?.sections[0]?.sections[1]?.actions
              "
            >
              <button-text
                fragment="who-are-we"
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Local & offshore trusts -->

<!-- Deceased estates -->
<div *ngIf="!isPageLoading" class="container-fluid mb-5 py-5 greybg-right">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-right">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[1]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-5 py-md-5 order-1 order-md-0">
        <div
          [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
        ></div>
        <div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[1]?.sections[0]?.actions
            "
          >
            <button-text
              fragment="who-are-we"
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>

      <div class="col-md-7 mb-4 mb-md-0 order-0 order-md-1">
        <div class="w-100 h-100">
          <img
            class="img-cover significant-raise"
            [src]="content?.body?.sections[1]?.image"
          />
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-8 col-lg-6">
        <h3 class="small-heading pl-2">
          {{ content?.body?.sections[1]?.sections[1]?.heading }}
        </h3>
        <div class="d-flex flex-column flex-md-row mt-4">
          <div
            class="col-sm mb-4 p-0 p-md-3"
            *ngFor="let card of content?.body?.sections[1]?.sections[1]?.cards"
          >
            <div
              class="card-round p-3 white-bg noticeable-raise d-flex flex-column align-items-center justify-content-center text-center"
            >
              <p class="body-large body-bold" [innerHTML]="card?.heading"></p>
              <div
                class="card-for-your-estate"
                [innerHTML]="card?.content"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Deceased estates -->

<!-- Cards -->
<div *ngIf="!isPageLoading" class="container-xl mb-5">
  <div class="row">
    <div class="col-lg-4 col-md-4 mb-4">
      <div class="blur-card">
        <h3 class="medium-heading">{{ content?.body?.cards[0].heading }}</h3>
        <div [innerHTML]="content?.body?.cards[0].content"></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6">
      <div class="blur-card">
        <h3 class="medium-heading">{{ content?.body?.cards[1].heading }}</h3>
        <div [innerHTML]="content?.body?.cards[1].content"></div>
      </div>
    </div>
  </div>
</div>
