import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map } from "rxjs";
import { SensArticle } from "./sens-trade.models";

@Injectable()
export class SensTradeService{

  constructor(private http:HttpClient){
    this.currentSensArticle = new  BehaviorSubject<SensArticle>(null);
    this.$currentSensArticle = this.currentSensArticle.asObservable();
  }

  public currentSensArticle:BehaviorSubject<SensArticle>;
  public $currentSensArticle:Observable<SensArticle>;

  public getSENSArticleData():Observable<any>{
    let header = new HttpHeaders();

    return this.http.get("https://irhosted.profiledata.co.za/psgkonsult/2020_feeds/sens_media.xml",{headers:header,responseType:'text'}).pipe(
    map((result) => {
      return result;
    }));

    }

    public getAllTradesData():Observable<any>{
      let header = new HttpHeaders();

      return this.http.get("https://irhosted.profiledata.co.za/psgkonsult/2020_feeds/t05_intraday.xml",{headers:header,responseType:'text'})
      .pipe((result) => {
        return result;
      });

    }

    public getGraphData(){
      let header = new HttpHeaders();

      return this.http.get("https://irhosted.profiledata.co.za/psgkonsult/2020_feeds/Intraday_Graph.xml",{headers:header,responseType:'text'}).pipe(
      map((result) => {
        return result;
      }));
    }
  }

