import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avoiding-mistakes',
  templateUrl: './avoiding-mistakes.component.html',
  styleUrls: []
})
export class AvoidingMistakesComponent implements OnInit {
  @Input() content: any;

  constructor() { }

  ngOnInit(): void {
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
        window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }

  shareToMail(heading: string) {
    let shareHeadline = heading.replace(
      "&",
      "%26"
    );

    window.open(
      `mailto:?subject=${shareHeadline}&body=` + window.location.href
    );
  }
  
}
