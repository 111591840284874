<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- psg wealth hero section -->
<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid psg-wealth"
    id="who-are-we"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title about-wealth text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div
          class="pl-md-4"
          [innerHTML]="content?.header?.content"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
        <div class="pl-md-4">
          <ng-container *ngFor="let action of content?.header?.actions">
            <button-text
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!--/psg wealth hero section -->

<!-- Our funds & Find an adviser cards section -->
<div *ngIf="!isPageLoading" class="container-xl OurFund-FindAdviser-mt">
  <div class="row d-flex">
    <div class="col-md-5 offset-md-3 mb-4">
      <div class="blur-card">
        <h3 class="medium-heading">{{ content?.header?.cards[0]?.heading }}</h3>
        <div [innerHTML]="content?.header?.cards[0]?.content"></div>
        <ng-container *ngFor="let action of content?.header?.cards[0]?.actions">
          <button-text
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </ng-container>
      </div>
    </div>

    <div class="col-md-4 mb-4">
      <div class="blur-card">
        <h3 class="medium-heading">{{ content?.header?.cards[1]?.heading }}</h3>
        <div [innerHTML]="content?.header?.cards[1]?.content"></div>
        <ng-container *ngFor="let action of content?.header?.cards[1]?.actions">
          <button-text
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!--/Our funds & Find an adviser cards section -->

<!-- Investment  philosophy section -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5 greybg-left h-100">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left">
          <h2
            class="mega-title--mobile"
            [innerHTML]="Split(content?.body?.sections[0]?.heading)"
          ></h2>
        </div>
        <div class="pl-2 pl-md-4">
          <h4
            class="small-heading cyan p-length-sm"
            [innerHTML]="content?.body?.sections[0]?.headline"
          ></h4>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl mt-5">
    <div class="row">
      <div
        class="col-md-3 mb-4"
        style="height: 10rem"
        *ngFor="let card of content?.body?.sections[0]?.cards"
      >
        <div
          class="card-round noticeable-raise white-bg p-4 py-md-4 px-md-4 h-100 d-flex flex-column justify-content-end align-items-center"
        >
          <h4>{{ card?.heading }}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Investment  philosophy section -->

<!-- Wealth advice section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 h-100"
  id="wealth-advice"
>
  <div class="container-xl">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 mb-4">
        <img
          alt=""
          class="img-cover significant-raise"
          [src]="content?.body?.sections[1]?.image"
        />
      </div>
      <div class="col-md-5 offset-md-1">
        <div>
          <h2>{{ content?.body?.sections[1]?.sections[0]?.heading }}</h2>
          <p
            class="p-length-sm"
            [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
          ></p>
        </div>

        <div class="row mt-3 pl-md-3">
          <div class="col-md-12 pl-md-2 btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[1]?.sections[0]?.actions
              "
            >
              <button-text
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Wealth advice section -->

<!-- Meet the Wealth team section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 greybg-right-small h-100"
  id="meet-the-wealth-team"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-8 offset-md-4 text-right">
        <div class="heading-border-right">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[2]?.heading }}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <!-- team images commitee -->
  <div class="container-xl h-100">
    <div class="row">
      <div
        class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-4"
        *ngFor="
          let member of content?.body?.sections[2]?.members;
          let i = index
        "
      >
        <!-- team member card -->
        <div class="p-4 p-md-0">
          <div
            data-toggle="modal"
            data-target="#modal-standard-sm"
            class="team-flipCard-bg-img"
            [style.backgroundImage]="'url(' + member?.image + ')'"
            (click)="openTeamMemberModal(member)"
          >
            <div class="card-black-layer">
              <h5 class="white mb-2">{{ member?.name }}</h5>
            </div>
          </div>
        </div>
        <!-- team member card -->
      </div>
    </div>
  </div>
  <!--/ team images commitee -->
</div>
<!-- Meet the Wealth team section -->

<!-- Wealth platform -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 greybg-left h-100"
  id="wealth-platform"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12 transformY-75">
        <div class="heading-border-left">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[3]?.heading }}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl">
    <div class="row d-flex align-items-center">
      <div class="col-md-4 mb-4">
        <p
          class="p-length-sm"
          [innerHTML]="content?.body?.sections[3]?.sections[0]?.content"
        ></p>

        <ng-container
          *ngFor="
            let action of content?.body?.sections[3]?.sections[0]?.actions
          "
        >
          <button-text
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </ng-container>
      </div>
      <div class="col-md-8">
        <img
          alt=""
          class="img-cover significant-raise"
          [src]="content?.body?.sections[3]?.image"
        />
      </div>
    </div>
  </div>
</div>
<!-- Wealth platform -->

<!-- Stockbroking -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5 h-100">
  <div class="container-xl">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 mb-4">
        <img
          alt=""
          class="img-cover significant-raise"
          [src]="content?.body?.sections[4]?.image"
        />
      </div>
      <div class="col-md-5 offset-md-1">
        <div>
          <h2>{{ content?.body?.sections[4]?.sections[0]?.heading }}</h2>
          <p
            class="p-length-sm"
            [innerHTML]="content?.body?.sections[4]?.sections[0]?.content"
          ></p>
        </div>
        <div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[4]?.sections[0]?.actions
            "
          >
            <button-text
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Stockbroking -->

<!-- Recommended/Latest news-cards section -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 slate-bg-10">
  <div class="container-xl">
    <div>
      <h2 class="mb-4">{{ content?.body?.sections[5]?.heading }}</h2>
    </div>
    <div class="card-deck">
      <div
        class="card news--card"
        *ngFor="let news of content?.body?.sections[5]?.articles"
      >
        <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
        <div class="card-body">
          <div class="card-tags pb-2">
            <span
              *ngFor="let category of news?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div>
          <h5 class="small-heading">{{ news?.headline }}</h5>
          <p class="body-small" [innerHTML]="news?.excerpt"></p>
          <a [routerLink]="news?.uri" class="textlink">Read more</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Recommended/Latest news-cards section -->

<!-- team memmber's modal -->
<div
  *ngIf="!isPageLoading"
  class="modal fade"
  id="modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <!-- modal container -->
    <div class="modal-content">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading"></h5>
      </div>
      <!-- / modal header -->

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <div class="row">
          <div class="col-md-4">
            <div
              class="img-size"
              [style.backgroundImage]="'url(' + teamMember?.image + ')'"
            ></div>
            <!--title -->
            <h4 class="mt-3 mb-0">{{ teamMember?.name }}</h4>
          </div>

          <div class="col-md-8">
            <!--content -->
            <p class="body-med mb-0" [innerHTML]="teamMember?.content"></p>
            <!-- member qualifications list-->
            <h5
              class="cyan"
              *ngIf="
                teamMember?.qualifications != null &&
                teamMember?.qualifications != ''
              "
            >
              Qualifications
            </h5>
            <p class="body-med" [innerHTML]="teamMember?.qualifications"></p>
          </div>
        </div>
      </div>
      <!-- / modal body -->

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button
          id="closeModal"
          type="button"
          class="button outline"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
      <!-- / modal footer -->
    </div>
    <!-- / modal container -->
  </div>
</div>
<!--/ team memmber's modal -->
