<!-- advisor-profile hero section -->
<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isPageLoading" class="mb-5">
  <div class="container-fluid mb-5 pb-5">
    <div class="container-xl h-100">
      <div class="row pt-4">
        <div class="col-md-12">
          <div>
            <ul class="breadcrumb p-0">
              <li>
                <a href="financial-advice">Financial Advice</a>
              </li>
              <icon-font name="icon-angle-right">
                <span class="icon icon-angle-right"></span>
              </icon-font>
              <li>
                <a href="financial-advice/find-an-adviser">Find an Adviser</a>
              </li>
              <icon-font name="icon-angle-right">
                <span class="icon icon-angle-right"></span>
              </icon-font>
              <li class="breadcrumb-active">
                {{ advisorDetail?.person_name }}
                {{ advisorDetail?.person_surname }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- advisor-profile card -->
    <div class="container-xl h-100 mt-5">
      <div class="row d-flex">
        <div class="col-md-3 col-sm-6 mb-4 mb-md-0">
          <div
            class="advisor-profile-card-bg-img card-round significant-raise adviser-b-w"
            *ngIf="profileImage != undefined"
            [style.background-image]="profileImage"
          ></div>
          <div
            class="advisor-profile-card-bg-img card-round significant-raise adviser-b-w"
            *ngIf="profileImage == undefined"
            [style.background-image]="defualtImages"
          ></div>
        </div>
        <div class="col-md-9 col-sm-12 mt-md-3">
          <h4 class="medium-heading">Hello, I’m</h4>
          <div style="display: inline">
            <h1 class="xlarge-heading">
              {{ advisorDetail?.person_name }}
              {{ advisorDetail?.person_surname }}
              <span class="small-heading">{{
                designations?.join(", ").toString()
              }}</span>
            </h1>
            <p class="small-heading">
              {{ sanitizeJobTitle(advisorDetail?.person_jobtitle) }}
            </p>
          </div>
          <div class="row d-flex mt-4">
            <div class="col-md-7">
              <div>
                <p class="small-heading">I specialise in</p>
              </div>
              <div class="row no-gutters">
                <span class="colour-badge mr-2 mb-2">{{
                  sanitizeJobTitle(advisorDetail?.discipline_name)
                }}</span>
              </div>
              <div class="row no-gutters">
                <div
                  *ngFor="let product of advisorDetail?.secondary_disciplines"
                >
                  <span class="basic-badge mr-2 mb-2">{{ product }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-5 pl-md-0">
              <div *ngIf="qualifications.length >= 1 && hasQualifications">
                <p class="small-heading">My Qualifications</p>
                <div>
                  <ul class="p-length-md" *ngFor="let item of qualifications">
                    <li *ngIf="item !== ''" class="p-length-md">{{ item }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/advisor-profile card -->
  </div>

  <!--advisor-profile savings-page hero section -->

  <!--advisor-profile savings-page hero section -->
  <div class="container-fluid adviser-contact">
    <div class="container-xl">
      <div class="row d-flex mb-md-4 pt-md-5">
        <div class="col-md-8 pr-md-4">
          <div *ngIf="advisorDetail?.person_aboutme !== ''">
            <h2 class="large-heading mb-3">About Me</h2>
            <div class="mb-3" style="text-align: justify">
              {{ advisorDetail?.person_aboutme }}
            </div>
          </div>
        </div>
        <div class="col-md-4 pl-md-4 col-sm-12">
          <div class="card-round noticeable-raise white-bg p-4">
            <div>
              <h4 class="medium-heading">My Contact Details</h4>
              <button
                class="button simple-icon"
                (click)="GoToLinkedIn()"
                [hidden]="
                  advisorDetail?.person_linkedin === '' ||
                  advisorDetail?.person_linkedin === 'null'
                "
              >
                <icon-font
                  name="icon-linkedin"
                  colour="black"
                  class="body-large"
                ></icon-font>
              </button>
              <div
                *ngIf="
                  RemoveWhitespace(advisorDetail?.office_phone) !== '' &&
                  advisorDetail?.office_phone !== 'null'
                "
              >
                <a
                  class="button small nav-left-icon pt-1"
                  style="color: #00afef !important"
                  href="tel:{{ '+' + advisorDetail?.office_phone }}"
                >
                  <icon-font
                    name="icon-phone"
                    colour="black"
                    class="body-large"
                  ></icon-font>
                  {{
                    RemoveWhitespace(advisorDetail?.office_phone) !== "" &&
                    advisorDetail?.office_phone !== "null"
                      ? "+" + advisorDetail?.office_phone
                      : ""
                  }}
                </a>
              </div>
              <div
                *ngIf="
                  RemoveWhitespace(advisorDetail?.person_phonecell) !== '' &&
                  advisorDetail?.person_phonecell !== 'null'
                "
              >
                <a
                  class="button small nav-left-icon pt-1"
                  style="color: #00afef !important"
                  href="tel:{{ advisorDetail?.person_phonecell }}"
                >
                  <icon-font
                    name="icon-phone"
                    colour="black"
                    class="body-large"
                  ></icon-font>
                  {{
                    RemoveWhitespace(advisorDetail?.person_phonecell) !== "" &&
                    advisorDetail?.person_phonecell !== "null"
                      ? "+" + advisorDetail?.person_phonecell
                      : ""
                  }}
                </a>
              </div>
              <div
                *ngIf="
                  RemoveWhitespace(advisorDetail?.person_emailexch) !== '' &&
                  advisorDetail?.person_emailexch !== 'null'
                "
              >
                <button class="button small nav-left-icon pt-1">
                  <icon-font
                    name="icon-envelope"
                    colour="black"
                    class="body-large"
                  ></icon-font>
                  <a [href]="'mailto:' + advisorDetail?.person_emailexch">
                    {{ advisorDetail?.person_emailexch }}
                  </a>
                </button>
              </div>
              <div class="btn-row mt-4">
                <button-text
                  buttonStyle="shaded"
                  label="View my office"
                  (click)="getBranch(advisorDetail)"
                  [disabled]="isLoadingOffice"
                ></button-text>
                <button-icon
                  buttonIcon="solid-icon-text"
                  icon="icon-envelope"
                  labelIcon="message me"
                  data-toggle="modal"
                  data-target="#modal-standard-sm"
                  (click)="OpenAdviserLeadDialog(advisorDetail)"
                >
                </button-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-md">
    <!-- modal container -->
    <div class="modal-content text-center" *ngIf="isInitLoading">
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-grow gold" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div class="modal-content" *ngIf="!isInitLoading">
      <form [formGroup]="leadForm">
        <!-- modal header expanded-->
        <div class="modal-header expanded">
          <h5 class="modal-heading">Contact adviser</h5>
        </div>
        <!-- / modal header -->

        <!-- modal body expanded-->
        <div class="modal-body expanded">
          <div class="row">
            <div class="col-6">
              <!-- Label -->
              <form-field>
                <label class="required">Title</label>
                <dropdown-select *ngIf="titles?.length > 0">
                  <select formControlName="title">
                    <option value="">Title</option>
                    <option *ngFor="let title of titles" [value]="title?.id">
                      {{ title?.name }}
                    </option>
                  </select>
                </dropdown-select>
                <form-error
                  *ngIf="
                    leadForm?.controls?.title?.invalid &&
                    leadForm?.controls?.title?.touched
                  "
                >
                  Title is required
                </form-error>
              </form-field>
            </div>
            <div class="col-6">
              <form-field>
                <label class="required">Initials</label>
                <input formControlName="initials" placeholder="Initials" />
                <form-error
                  *ngIf="
                    leadForm?.controls?.initials?.invalid &&
                    leadForm?.controls?.initials?.touched
                  "
                >
                  Initials are required
                </form-error>
              </form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <!-- Name -->
              <form-field>
                <label class="required">Name</label>
                <input placeholder="Name" formControlName="firstName" />
                <form-error
                  *ngIf="
                    leadForm?.controls?.firstName?.invalid &&
                    leadForm?.controls?.firstName?.touched
                  "
                >
                  Name is required
                </form-error>
              </form-field>

              <!-- Surname -->
              <form-field>
                <label class="required">Surname</label>
                <input placeholder="Surname" formControlName="lastName" />
                <form-error
                  *ngIf="
                    leadForm?.controls?.lastName?.invalid &&
                    leadForm?.controls?.lastName?.touched
                  "
                >
                  Surname is required
                </form-error>
              </form-field>

              <!-- Email Address -->
              <form-field>
                <label class="required">Email address</label>
                <input
                  placeholder="Email Address"
                  formControlName="emailAddress"
                />
                <form-error
                  *ngIf="
                    leadForm?.controls?.emailAddress?.invalid &&
                    leadForm?.controls?.emailAddress?.touched
                  "
                >
                  Email address is required
                </form-error>
              </form-field>

              <!-- Contact Number -->
              <form-field>
                <label class="required">Contact number</label>
                <input
                  placeholder="Contact Number"
                  formControlName="cellNumber"
                />
                <form-error
                  *ngIf="
                    leadForm?.controls?.cellNumber?.invalid &&
                    leadForm?.controls?.cellNumber?.touched
                  "
                >
                  Contact number is required
                </form-error>
              </form-field>

              <!-- ID Number -->
              <form-field>
                <label class="">ID number</label>
                <input placeholder="ID Number" formControlName="idNumber" />
                <form-error
                  *ngIf="
                    leadForm?.controls?.idNumber?.invalid &&
                    leadForm?.controls?.idNumber?.touched
                  "
                >
                  ID number is required
                </form-error>
              </form-field>

              <!-- Province -->
              <form-field>
                <label class="">Province</label>
                <dropdown-select>
                  <select formControlName="province">
                    <option value="" disabled>Province</option>
                    <option value="Gauteng">Gauteng</option>
                    <option value="Western Cape">Western Cape</option>
                    <option value="Kwa-Zulu Natal">Kwa-Zulu Natal</option>
                    <option value="North-West">North-West</option>
                    <option value="Northern Cape">Northern Cape</option>
                    <option value="Mpumalanga">Mpumalanga</option>
                    <option value="Limpopo">Limpopo</option>
                    <option value="Free State">Free State</option>
                    <option value="Eastern Cape">Eastern Cape</option>
                  </select>
                </dropdown-select>
                <form-error
                  *ngIf="
                    leadForm?.controls?.province?.invalid &&
                    leadForm?.controls?.province?.touched
                  "
                >
                  Province is required
                </form-error>
              </form-field>

              <!-- Closest City/Town -->
              <form-field>
                <autocomplete-dropdown
                  [formGroup]="leadForm"
                  name="closestCityTown"
                  placeholder="Closest City/Town"
                  label="Closest city/town"
                  [items]="filteredCities"
                >
                </autocomplete-dropdown>
                <form-error
                  *ngIf="
                    leadForm?.controls?.closestCityTown?.invalid &&
                    leadForm?.controls?.closestCityTown?.touched
                  "
                >
                  Closest City/Town is required
                </form-error>
              </form-field>

              <!-- Message -->
              <form-field>
                <label class="">Message</label>
                <textarea
                  maxlength="80"
                  placeholder="Message"
                  formControlName="message"
                ></textarea>
                <form-error
                  *ngIf="
                    leadForm?.controls?.message?.invalid &&
                    leadForm?.controls?.message?.touched
                  "
                >
                  Message is required
                </form-error>
              </form-field>

              <!-- Recaptcha -->
              <re-captcha
                (resolved)="resolved($event)"
                siteKey="6LfnAeIZAAAAAB_pV7XDUG_l6SgpK5QjtDQsk9wg"
              >
              </re-captcha>
            </div>
          </div>
        </div>
        <!-- / modal body -->

        <!-- modal footer expanded-->
        <div class="modal-footer expanded">
          <button
            id="closeModal"
            hidden
            type="button"
            class="button outline"
            data-dismiss="modal"
          >
            Close
          </button>
          <button-text
            buttonStyle="solid"
            [label]="'Request Advice'"
            fill="true"
            (click)="Save()"
            [disabled]="isLoading"
          >
            <span
              class="spinner-grow spinner-grow-sm mr-1"
              role="status"
              aria-hidden="true"
              *ngIf="isLoading"
            ></span>
            Request advice
          </button-text>
        </div>
        <!-- / modal footer -->
      </form>
    </div>
    <!-- / modal container -->
  </div>
</div>
<!-- </div>
</div> -->

<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  data-keyboard="false"
  aria-hidden="true"
  id="email-response-modal"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content text-center">
      <div class="modal-header expanded">
        <p class="modal-heading text-center">
          {{ emailResponseMessage }}
        </p>
      </div>
      <div class="modal-footer expanded pt-0">
        <button type="button" class="btn btn-primary" data-dismiss="modal">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
