<!-- Cards -->
<div *ngIf="GetSectionType(section) === 'Cards'">
    Cards

    <div class="container-fluid py-5 my-5" *ngIf="section.cards[0].image == null">
        <div class="container-xl h-100">
            <div class="row">
                <div class="col-md-9 offset-md-3">
                    <div class="heading-border-right d-inline-flex justify-content-end">
                        <h1 class="mega-title--mobile text-right">{{section.heading}}</h1>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center align-items-center">
                <div class="col-md-4 pl-md-0 mb-4" *ngFor="let card of section.cards">
                    <div class="card-flat"
                        [ngClass]="{'noticeable-raise': card.isPrimary, 'slight-raise': !card.isPrimary}">
                        <h3 class="small-heading">{{card.heading}}</h3>
                        <div [innerHTML]="card.content"></div>
                        <div>
                            <button-text *ngFor="let action of card.actions" class="mr-2"
                            [routerLink]="action?.url"
                                buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                                (click)="DynamicButtonClick(action)">
                            </button-text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid py-5 my-5 svg-bg-l savings h-100" *ngIf="section.cards[0].image !== null">
        <div class="container-xl">
            <div class="row">
                <div class="col-md-12 savings-header">
                    <div class="heading-border-left">
                        <h2 class="mega-title--mobile">{{section.heading}}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-xl mt-4">
            <div class="row ">
                <div class="col-md-4" *ngFor="let card of section.cards">
                    <div class="saving-card-bg-img" [ngClass]="{'saving-card-bg-img-height': card.isPrimary}"
                        [style.backgroundImage]="'url(' + card.image + ')'">
                        <div class="saving--card">
                            <h4 class="white">{{card.heading}}</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="mx-auto">
                    <button-text *ngFor="let action of section.actions" class="mr-2"
                    [routerLink]="action?.url"
                        buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                        (click)="DynamicButtonClick(action)">
                    </button-text>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Cards -->

<!--Testimonials-->
<div class="container-fluid py-5 my-5 svg-bg-r reviews-bg" *ngIf="GetSectionType(section) === 'Testimonials'">
    Testimonials

    <div *ngIf="IsLeft()">
        <div class="container-xl h-100">
            <div class="row">
                <div class="col-md-9 offset-md-3">
                    <div class="heading-border-right d-inline-flex justify-content-end">
                        <h1 class="mega-title--mobile text-right">{{section.heading}}</h1>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4 mb-4" *ngFor="let testimonial of section.testimonials">
                    <div class="d-flex flex-row">
                        <div>
                            <span class="cyan large-heading mr-2">“</span>
                        </div>
                        <div>
                            <div [innerHTML]="testimonial.content"></div>
                            <p class="font-italic cyan">{{testimonial.client}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="IsRight()">
        <div class="container-xl h-100">
            <div class="row">
                <div class="col-md-9 offset-md-3">
                    <div class="heading-border-right d-inline-flex justify-content-end">
                        <h1 class="mega-title--mobile text-right">{{section.heading}}</h1>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4 mb-4" *ngFor="let testimonial of section.testimonials">
                    <div class="d-flex flex-row">
                        <div>
                            <span class="cyan large-heading mr-2">“</span>
                        </div>
                        <div>
                            <div [innerHTML]="testimonial.content"></div>
                            <p class="font-italic cyan">{{testimonial.client}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Testimonials-->

<!--Content Action -->
<div *ngIf="GetSectionType(section) === 'ContentAction'">
    Content Action
    <div class="container-fluid py-5" *ngIf="isFromContentGroup">
        <div class="container-xl h-100">
            <div class="row justify-content-center align-items-center">
                <div class="col-md-6">
                    <div>
                        <img [src]="section.image" class="img-cover significant-raise mb-4" />
                    </div>
                </div>
                <div class="col-md-5 offset-md-1">
                    <div>
                        <h2 class="xlarge-heading">{{section.heading}}</h2>
                        <div [innerHTML]="section.content"></div>
                    </div>
                    <div>
                        <button-text *ngFor="let action of section.actions" class="mr-2"
                        [routerLink]="action?.url"
                            buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                            (click)="DynamicButtonClick(action)">
                        </button-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isFromContentGroup">
        <div class="container-fluid pt-5 pt-md-0 pb-5 my-5 svg-bg-l financialAdvice" *ngIf="IsLeft()">
            LEFT
            <div class="container-xl h-100">
                <div class="row">
                    <div class="col-md-12">
                        <div class="heading-border-left d-inline-flex justify-content-start transformY-25">
                            <h2 class="mega-title--mobile">{{section.heading}}</h2>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center align-items-center">
                    <div class="col-md-6 mb-4">
                        <div><img [src]="section.image" class="img-cover significant-raise" /></div>
                    </div>
                    <div class="col-md-5 offset-md-1">
                        <h2 class="large-heading">{{section.subheading}}</h2>
                        <div [innerHTML]="section.content"></div>
                        <div>
                            <button-text *ngFor="let action of section.actions" class="mr-2"
                            [routerLink]="action?.url"
                                buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                                (click)="DynamicButtonClick(action)">
                            </button-text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-5 pt-md-0 pb-5 my-5 svg-bg-r financialAdvice" style="background-position: 8vw 0;
            background-size: cover;" *ngIf="IsRight()">
            RIGHT
            <div class="container-xl h-100">
                <div class="row">
                    <div class="col-md-12 text-right">
                        <div class="heading-border-right d-inline-flex justify-content-end transformY-25">
                            <h2 class="mega-title--mobile">{{section.heading}}</h2>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5">
                        <h2 class="large-heading">{{section.subheading}}</h2>
                        <div [innerHTML]="section.content"></div>
                        <div>
                            <button-text *ngFor="let action of section.actions" class="mr-2"
                            [routerLink]="action?.url"
                                buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                                (click)="DynamicButtonClick(action)">
                            </button-text>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4 offset-md-1">
                        <div><img [src]="section.image" class="img-cover significant-raise" /></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Content Action-->

<!--Content Group-->
<div *ngIf="GetSectionType(section) === 'ContentGroup'">
    Content Group
    <div class="container-fluid py-5 my-5 svg-bg-r">
        <div class="container-xl">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="heading-border-right d-inline-flex justify-content-end">
                        <h1 class="mega-title--mobile text-right">{{section.heading}}</h1>
                    </div>
                </div>
            </div>
            <app-dynamic-content-template [section]='section.sections[0]' [index] [isFromContentGroup]="true">
            </app-dynamic-content-template>
        </div>
    </div>
    <div *ngFor="let sectionNested of contentGroupSections; let i = index">

        <div class="container-fluid py-5 my-md-5"
            [ngClass]="{'svg-bg-l financialAdvice': IsLeftFromIndex(i), 'svg-bg-r far': IsRightFromIndex(i)}">
            <div class="container-xl">
                <app-dynamic-content-template [section]='sectionNested' [index]="i" [isFromContentGroup]="true">
                </app-dynamic-content-template>
            </div>
        </div>
    </div>
</div>
<!--Content Group-->

<!--Content-->
<div *ngIf="GetSectionType(section) === 'Content'">
    Content
    <div *ngIf="isFromContentGroup">
        <div *ngIf="IsLeft()">
            LEFT

            <div class="row align-items-center">
                <div class="col-md-6 col-lg-5 py-md-5 order-0 order-md-1">
                    <h3 class="medium-heading">{{section.heading}}</h3>
                    <div [innerHTML]="section.content">
                    </div>
                </div>
                <div class="col-md-6 mb-4 order-1 order-md-0">
                    <div class="w-100 h-100">
                        <img class="img-cover significant-raise" [src]="section.image">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="IsRight()">
            RIGHT

            <div class="row align-items-center">
                <div class="col-md-6 offset-lg-1 mb-4 order-0 order-md-1">
                    <div class="w-100 h-100">
                        <img class="img-cover significant-raise" [src]="section.image">
                    </div>
                </div>
                <div class="col-md-6 col-lg-5 py-md-5 order-1 order-md-0">
                    <h3 class="medium-heading">{{section.heading}}</h3>
                    <div [innerHTML]="section.content">
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="!isFromContentGroup">
        <div class="row">
            <div class="col-md-10 offset-md-2 text-right">
                <div class="heading-border-right">
                    <h2 class="mega-title--mobile">{{section.heading}}</h2>
                </div>
            </div>
        </div>
        <div class="row d-flex align-items-center">
            <div class="col-md-5 offset-md-1">
                <div [innerHTML]="section.content">
                </div>
            </div>
            <div class="col-md-6">
                <img alt="retirement card img " class="img-cover" [src]="section.image">
            </div>
        </div>
    </div>
</div>
<!--Content-->

<!--Content Cards-->
<div *ngIf="GetSectionType(section) === 'ContentCards'">
    Content Cards

    <div class="container-fluid  mt-5 py-5" *ngIf="IsLeft()">
        <div class="container-xl">
            <div class="row">
                <div class="col-md-11 offset-md-1">
                    <div class="heading-border-left">
                        <h2 class="mega-title--mobile">{{section.heading}}</h2>
                    </div>
                    <div class="mt-4">
                        <div class="body-large p-length-sm" [innerHTML]="section.content"></div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-3 offset-md-1 mb-4" *ngFor="let card of section.cards">
                    <div class="card-round slight-raise white-bg p-4 p-md-5 h-100">
                        <h3 class="small-heading">{{card.heading}}</h3>
                        <div [innerHTML]="card.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  mt-5 py-5" *ngIf="IsRight()">
        <div class="container-xl">
            <div class="row">
                <div class="col-md-12">
                    <div class="heading-border-right">
                        <h2 class="mega-title--mobile text-right">{{section.heading}}</h2>
                    </div>
                    <div class="col-md-5 offset-md-6 mt-4">
                        <div class="body-large p-length-sm" [innerHTML]="section.content"></div>
                    </div>
                </div>
            </div>

            <div class="row mt-4 justify-content-end">
                <div class="col-md-3 offset-md-1 mb-4" *ngFor="let card of section.cards">
                    <div class="card-round slight-raise white-bg p-4 p-md-5 h-100">
                        <h3 class="small-heading">{{card.heading}}</h3>
                        <div [innerHTML]="card.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Content Cards-->

<!--Content Image Cards-->
<div *ngIf="GetSectionType(section) === 'ContentImageCards'">
    Content Image Cards
    <div class="container-fluid py-5 mt-5 greybg-left">
        <div class="container-xl">
            <div class="row">
                <div class="col-md-12">
                    <div class="heading-border-left">
                        <h2 class="mega-title--mobile">{{section.heading}}</h2>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-5 offset-lg-5 col-md-8 offset-md-3">
                    <div [innerHTML]="section.content"></div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                </div>
                <div class="col-md-4" *ngFor="let card of section.cards">
                    <div class="saving-card-bg-img" [style.backgroundImage]="'url(' + card.image + ')'">
                        <div class="saving--card">
                            <div class="d-flex flex-column">
                                <p class="body-large body-bold">{{card.heading}}</p>
                                <div [innerHTML]="card.content">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Content Image Cards-->

<!--Content Action Cards-->
<div *ngIf="GetSectionType(section) === 'ContentActionCards'">
    Content Action Cards

    <div *ngIf="isFromContentGroup">
        <div class="row">
            <div class="col-md-4">
                <div>
                    <h2 class="large-heading">{{section.heading}}</h2>
                    <div [innerHTML]="section.content"></div>
                </div>
                <div>
                    <button-text *ngFor="let action of section.actions" class="mr-2"
                    [routerLink]="action?.url"
                        buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                        (click)="DynamicButtonClick(action)">
                    </button-text>
                </div>
            </div>
        </div>

        <div class="row d-flex mt-5">
            <div class="col-md-4 mb-4" *ngFor="let card of section.cards">
                <div class="card-flat d-flex flex-column justify-content-between h-100"
                    [ngClass]="{'noticeable-raise': card.isPrimary, 'slight-raise': !card.isPrimary}">
                    <h3 class="medium-heading">{{card.heading}}</h3>
                    <div [innerHTML]="card.content"></div>
                    <div>
                        <button-text *ngFor="let action of card.actions" class="mr-2"
                        [routerLink]="action?.url"
                            buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                            (click)="DynamicButtonClick(action)">
                        </button-text>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isFromContentGroup">
        <div *ngIf="section.image !== null">
            <div class="container-fluid py-5 my-5 svg-bg-l" style="background-position: -10vw 0" *ngIf="IsLeft()">
                LEFT
                <div class="container-xl">
                    <div class="row d-flex">
                        <div class="col-md-8 ">
                            <div class="heading-border-left">
                                <h2 class="mega-title--mobile text-right">{{section.heading}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-xl mt-4">
                    <div class="row d-flex align-items-center">
                        <div class="col-md-6">
                            <div class="row">
                                <h3 class="small-heading pl-2" *ngIf="section.cardsHeading != null">{{section.cardsHeading}}</h3>
                                <div class="col-md-6 mb-4" *ngFor="let card of section.cards">
                                    <div class="investement--card">
                                        <div>
                                            <h4 class="black">{{card.heading}}</h4>
                                            <div [innerHTML]="card.content" class="body-small"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p class="cyan small-heading mb-2">{{section.headline}}</p>
                            <h2 class="large-heading">{{section.subheading}}</h2>
                            <div class="mt-5" [innerHTML]="section.content"></div>
                            <div>
                                <button-text *ngFor="let action of section.actions" class="mr-2"
                                [routerLink]="action?.url"
                                    buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                                    (click)="DynamicButtonClick(action)">
                                </button-text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid py-5 my-5 svg-bg Investments" *ngIf="IsRight()">
                RIGHT
                <div class="container-xl">
                    <div class="row d-flex">
                        <div class="col-md-8 offset-md-4">
                            <div class="heading-border-right">
                                <h2 class="mega-title--mobile text-right">{{section.heading}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-xl mt-4">
                    <div class="row d-flex align-items-center">
                        <div class="col-md-6">
                            <p class="cyan small-heading mb-2">{{section.headline}}</p>
                            <h2 class="large-heading">{{section.subheading}}</h2>
                            <div class="mt-5" [innerHTML]="section.content"></div>
                            <div>
                                <button-text *ngFor="let action of section.actions" class="mr-2"
                                [routerLink]="action?.url"
                                    buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                                    (click)="DynamicButtonClick(action)">
                                </button-text>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <h3 class="small-heading pl-2" *ngIf="section.cardsHeading != null">{{section.cardsHeading}}</h3>
                                <div class="col-md-6 mb-4" *ngFor="let card of section.cards">
                                    <div class="investement--card">
                                        <div>
                                            <h4 class="black">{{card.heading}}</h4>
                                            <div [innerHTML]="card.content" class="body-small"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="section.image == null">
            <div *ngIf="IsLeft()">
                <div class="container-fluid py-5 my-5 svg-bg-l">
                    <div class="container-xl">
                        <div class="row">
                            <div class="col-md-8 offset-3 text-left For-your-things-header">
                                <div class="heading-border-left ">
                                    <h2 class="mega-title--mobile">{{section.heading}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-xl h-100">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6">
                                <img alt="retirement card img " class="img-cover" [src]="section.image">
                            </div>

                            <div class="col-md-5 offset-md-1">
                                <div class="p-length-sm" [innerHTML]="section.content">
                                </div>
                                <div class="mb-4">
                                    <button-text *ngFor="let action of section.actions" class="mr-2"
                                    [routerLink]="action?.url"
                                        buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                                        (click)="DynamicButtonClick(action)">
                                    </button-text>
                                </div>
                            </div>
                        </div>

                        <div class="row h-100 mt-5">
                            <div class="col">
                                <div class="row mb-4" *ngIf="section.cardsHeading != null">
                                    <div class="col-md-8 col-lg-6">
                                        <h3 class="small-heading pl-2">{{section.cardsHeading}}</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 mb-4" *ngFor="let card of section.cards">
                                        <div
                                            class="card-round noticeable-raise white-bg p-4 p-md-4 h-100 d-flex flex-column justify-content-around">
                                            <h4>{{card.heading}}</h4>
                                            <div [innerHTML]="card.content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="section.secondaryActions != null">
                            <div class="col-md-6 offset-md-5">
                                <button-text *ngFor="let action of section.secondaryActions" class="mr-2"
                                [routerLink]="action?.url"
                                    buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                                    (click)="DynamicButtonClick(action)">
                                </button-text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="IsRight()">
                <div class="container-fluid py-5 my-5  svg-bg  for-yourself h-100">
                    <div class="container-xl">
                        <div class="row">
                            <div class="col-md-8 offset-md-4 text-right For-your-things-header">
                                <div class="heading-border-right ">
                                    <h2 class="mega-title--mobile">{{section.heading}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-xl h-100">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-5 offset-md-1">
                                <div class="p-length-sm" [innerHTML]="section.content">
                                </div>
                                <div class="mb-4">
                                    <button-text *ngFor="let action of section.actions" class="mr-2"
                                    [routerLink]="action?.url"
                                        buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                                        (click)="DynamicButtonClick(action)">
                                    </button-text>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <img alt="retirement card img " class="img-cover" [src]="section.image">
                            </div>
                        </div>

                        <div class="row h-100 mt-5">
                            <div class="col">
                                <div class="row mb-4" *ngIf="section.cardsHeading != null">
                                    <div class="col-md-8 col-lg-6">
                                        <h3 class="small-heading pl-2">{{section.cardsHeading}}</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 mb-4" *ngFor="let card of section.cards">
                                        <div
                                            class="card-round noticeable-raise white-bg p-4 p-md-4 h-100 d-flex flex-column justify-content-around">
                                            <h4>{{card.heading}}</h4>
                                            <div [innerHTML]="card.content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="section.secondaryActions != null">
                            <div class="col-md-6 offset-md-5">
                                <button-text *ngFor="let action of section.secondaryActions" class="mr-2"
                                [routerLink]="action?.url"
                                    buttonStyle="{{action.isPrimary ? 'solid' : 'outline' }}" [label]="action.label"
                                    (click)="DynamicButtonClick(action)">
                                </button-text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Content Action Cards-->

<!--Content List-->
<div class="container-fluid py-5 my-5" *ngIf="GetSectionType(section) === 'ContentList'">
    Content List
    <div class="container-xl h-100">
        <div class="row" *ngIf="IsRight()">
            <div class="col-md-8 offset-md-4">
                <div class="heading-border-right justify-content-end">
                    <h2 class="mega-title--mobile text-right">{{section.heading}}</h2>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="IsLeft()">
            <div class="col-md-8">
                <div class="heading-border-left justify-content-start">
                    <h2 class="mega-title--mobile text-left">{{section.heading}}</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <!-- accordion tfip -->
                <div class="accordion" *ngFor="let item of section.items" id="{{GetNameWithoutWhiteSpace(item.heading)}}">
                    <div class="accordian-inner">
                        <div id="Tfip">
                            <h2 class="mb-0">
                                <button class="accordian-button collapsed" type="button" data-toggle="collapse"
                                    [attr.data-target]="'#collapse' + GetNameWithoutWhiteSpace(item.heading)" aria-expanded="false"
                                    [attr.aria-controls]="'collapse' + GetNameWithoutWhiteSpace(item.heading)">
                                    {{item.heading}}
                                    <icon-font name="icon-angle-up" class="icon-accordian"></icon-font>
                                </button>
                            </h2>
                        </div>
                        <div id="{{'collapse' + GetNameWithoutWhiteSpace(item.heading)}}" class="collapse" aria-labelledby="Tfip"
                            [attr.data-parent]="'#' + GetNameWithoutWhiteSpace(item.heading)">
                            <div class="accordian-body" [innerHTML]="item.content"></div>
                        </div>
                    </div>
                </div>
                <!-- /accordion tfip -->
            </div>
        </div>
    </div>
</div>
<!--Content List-->

<!-- Newsfeed Section -->
<div class="container-fluid slate-bg-10 py-5 my-5" *ngIf="GetSectionType(section) === 'NewsFeed'">
    Newsfeed
    <div class="container-xl">
        <div>
            <h2 class="mb-4">{{section.header}}</h2>
        </div>
        <div class="card-deck">
            <div class="card news--card" *ngFor="let article of section.articles">
                <img class="card-img-top" [src]="article.image" alt="Card image cap">
                <div class="card-body">
                    <div class="card-tags pb-2">
                        <span class="basic-badge" *ngFor="let category of article.categories">{{category.title}}</span>
                    </div>
                    <h5 class="small-heading">{{article.header}}</h5>
                    <p class="body-small">{{article.body}}</p>
                    <a [routerLink]="['/article/'+ article.header]" class="textlink">Read more</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Newsfeed Section -->

<!--Quote-->
<div *ngIf="GetSectionType(section) === 'Quote'">
    Quote
    <div class="container-xl my-5 py-5">
        <div class="col-md-10 offset-md-2">
            <blockquote class="blockquote xlarge text-align-center">
                <span class="cyan">“ </span>{{section.content}}<span class="cyan"> ”</span>
            </blockquote>
        </div>
    </div>
</div>
<!--Quote-->

<!--Wizard-->
<div *ngIf="GetSectionType(section) === 'Wizard'">
    Wizard

    <div class="container-fluid mb-5 py-5 greybg-right">
        <div class="container-xl">
            <div class="row pb-4">
                <div class="col-md-6">
                    <h3 class="small-heading mb-3">{{section.heading}}</h3>
                    <div class="row no-gutters">
                        <div class="col-md-12 mb-2">
                            <div class="tabScroll overflow-auto">
                                <tab-horisontal [(tabSelected)]="currentItem" [tabs]="itemTabs" class="tab-nowrap-space">
                                </tab-horisontal>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div *ngFor="let item of section.items">
                                <div class="row no-gutters" *ngIf="currentItem == item.name">
                                    <div>
                                        <div class="slate-bg-10 p-4 overflow-auto h-100 advice-steps-process">
                                            <p class="body-bold cyan">{{item.name}}</p>
                                            <h2 class="small-heading">{{item.heading}}</h2>
                                            <div [innerHTML]="item.content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="w-100 h-100">
                        <img class="img-cover" [src]="section.image">
                    </div>
                </div>
            </div>
            <div class="row mt-lg-n3">
                <div class="col-md-6">
                    <div [innerHTML]="section.content"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Wizard-->
