<div class="loader-center-container full-height" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isPageLoading">
  <!-- home hero section -->
  <div class="container-fluid p-0">
    <app-landing-p-banner-carousel
      [content]="content"
    ></app-landing-p-banner-carousel>
  </div>
  <!-- home hero section -->

  <!-- Campaign Messaging cards carausel-->
  <div
    class="container-fluid py-5 my-5"
    *ngIf="
      content?.body?.sections[7]?.cards[0]?.content != null ||
      content?.body?.sections[7]?.cards[1]?.content != null ||
      content?.body?.sections[7]?.cards[2]?.content != null ||
      content?.body?.sections[7]?.cards[3]?.content != null
    "
  >
    <div class="container-xl h-100">
      <div class="row">
        <div class="col-md-12">
          <div
            class="heading-border-left d-inline-flex justify-content-start"
            *ngIf="content.body.sections[7].heading != null"
          >
            <h2 class="mega-title--mobile">
              {{ content?.body?.sections[7]?.heading }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-start align-items-center">
        <div
          class="col-md-4 pl-md-0 mb-4"
          *ngFor="let card of content?.body?.sections[7]?.cards; let i = index"
        >
          <div
            class="card-flat"
            *ngIf="card.content != null || card.content != undefined"
            [ngClass]="{
              'noticeable-raise': card.isPrimary,
              'slight-raise': !card.isPrimary
            }"
          >
            <h3 class="small-heading landingPage">{{ card.heading }}</h3>
            <div [innerHTML]="card.content"></div>

            <div class="btn-row">
              <ng-container *ngFor="let action of card.actions">
                <button-text
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }}"
                  [label]="action?.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- home main content section -->
  <div class="container-fluid py-5 my-5">
    <div class="container-xl h-100">
      <div class="row">
        <div class="col-md-9 offset-md-3">
          <div class="heading-border-right d-inline-flex justify-content-end">
            <h1 class="mega-title--mobile text-right">
              {{ content?.body?.sections[0]?.heading }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div
          class="col-md-4 pl-md-0 mb-4"
          *ngFor="let card of content?.body?.sections[0]?.cards; let i = index"
        >
          <div
            class="card-flat"
            [ngClass]="{
              'noticeable-raise': card.isPrimary,
              'slight-raise': !card.isPrimary
            }"
          >
            <h3 class="small-heading landingPage">{{ card.heading }}</h3>
            <div [innerHTML]="card.content"></div>
            <ng-container *ngFor="let action of card.actions">
              <button-text
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- testimonial -->
  <div class="container-fluid py-5 my-5 svg-bg-r reviews-bg">
    <div class="container-xl h-100">
      <div class="row">
        <div class="col-md-9 offset-md-3">
          <div class="heading-border-right d-inline-flex justify-content-end">
            <h1 class="mega-title--mobile text-right">
              {{ content?.body?.sections[1]?.heading }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-md-4 mb-4"
          *ngFor="let testimonial of content?.body?.sections[1]?.testimonials"
        >
          <div class="d-flex flex-row">
            <div>
              <span class="cyan large-heading mr-2">“</span>
            </div>
            <div>
              <div [innerHTML]="testimonial?.content"></div>
              <div
                class="font-italic cyan"
                [innerHTML]="testimonial?.client"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /testimonial -->

  <!-- blank-space -->
  <div class="container-fluid pb-md-5 my-md-5"></div>
  <!-- /blank-space -->
  <!-- financial advice  -->
  <div class="container-fluid pt-5 pt-md-0 pb-5 my-5 svg-bg-l financialAdvice">
    <div class="container-xl h-100">
      <div class="row">
        <div class="col-md-12">
          <div
            class="heading-border-left d-inline-flex justify-content-start transformY-25"
          >
            <h2 class="mega-title--mobile">
              {{ content?.body?.sections[2]?.heading }}
            </h2>
          </div>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-md-6 mb-4">
          <div>
            <img
              [src]="content?.body?.sections[2]?.image"
              class="img-cover significant-raise"
            />
          </div>
        </div>
        <div class="col-md-5 offset-md-1">
          <div>
            <div
              [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
            ></div>
          </div>
          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[2]?.sections[0]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /financial advice  -->

  <!-- blank-space -->
  <div class="container-fluid pb-md-5 my-md-5"></div>
  <!-- /blank-space -->

  <!-- what are my options -->
  <div class="container-fluid py-5 my-5 svg-bg-r options">
    <div class="container-xl h-100">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="heading-border-right d-inline-flex justify-content-end">
            <h1 class="mega-title--mobile text-right">
              {{ content?.body?.sections[3]?.heading }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div>
            <h2 class="large-heading">
              {{ content?.body?.sections[3]?.sections[0]?.heading }}
            </h2>
            <div
              [innerHTML]="content?.body?.sections[3]?.sections[0]?.content"
            ></div>
          </div>
          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[3]?.sections[0]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row d-flex mt-5">
        <div
          class="col-md-4 mb-4"
          *ngFor="let card of content?.body?.sections[3]?.sections[1]?.cards"
        >
          <div
            class="card-flat d-flex flex-column justify-content-between h-100 slight-raise"
          >
            <h2 class="medium-heading">{{ card?.heading }}</h2>
            <div [innerHTML]="card?.content"></div>
            <div>
              <ng-container *ngFor="let action of card?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }}"
                  [label]="action?.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /what are my options -->

  <!-- blank-space -->
  <div class="container-fluid pb-md-5 my-md-5"></div>
  <!-- /blank-space -->

  <!-- insurance  -->
  <div class="container-fluid py-5">
    <div class="container-xl h-100">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6">
          <div>
            <img
              [src]="content?.body?.sections[4]?.image"
              class="img-cover significant-raise mb-4"
            />
          </div>
        </div>
        <div class="col-md-5 offset-md-1">
          <div>
            <h2 class="xlarge-heading">
              {{ content?.body?.sections[4]?.sections[0]?.heading }}
            </h2>
            <div
              [innerHTML]="content?.body?.sections[4]?.sections[0]?.content"
            ></div>
          </div>
          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[4]?.sections[0]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /insurance  -->

  <!-- divisions -->
  <div class="container-fluid my-5">
    <div class="container-xl">
      <div class="row d-flex mt-5">
        <div
          class="col-md-4 mb-4"
          *ngFor="let card of content?.body?.sections[5]?.cards"
        >
          <div
            class="card-flat d-flex flex-column justify-content-between h-100 slight-raise"
          >
            <h3 class="medium-heading">{{ card?.heading }}</h3>
            <div [innerHTML]="card?.content"></div>
            <div>
              <ng-container *ngFor="let action of card?.actions">
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }}"
                  [label]="action?.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TODO: Latest news section  -->
  <div class="container-fluid slate-bg-10 py-5 my-5">
    <div class="container-xl">
      <div>
        <h2 class="mb-4">{{ content?.body?.sections[6]?.heading }}</h2>
      </div>
      <div class="card-deck">
        <div
          class="card news--card"
          *ngFor="let news of content?.body?.sections[6]?.articles"
        >
          <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
          <div class="card-body d-flex flex-column">
            <div class="card-tags pb-2">
              <span
                *ngFor="let category of news?.categories"
                role="button"
                class="basic-badge"
                data-toggle="modal"
                data-target="#modal-article-tags"
                (click)="
                  pageService.SetArticle(category); $event.preventDefault()
                "
                >{{ category }}</span
              >
            </div>
            <h5 class="small-heading">{{ news?.headline }}</h5>
            <p class="body-small" [innerHTML]="news?.excerpt"></p>
            <a [routerLink]="news?.uri" class="textlink mt-auto">Read more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
