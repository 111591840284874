<!-- call-me-back Model -->
<div
  class="modal fade"
  id="modal-call-me-back"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <!-- modal container -->
    <div class="modal-content">
      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <form [formGroup]="callmeBackForm">
          <!-- modal header expanded-->
          <div class="modal-header expanded">
            <h5 class="modal-heading">Contact me</h5>
          </div>
          <!-- / modal header -->

          <!-- modal body expanded-->
          <div class="modal-body expanded">
            <div class="row">
              <div class="col-12">
                <!-- Name -->
                <form-field>
                  <label for="name" class="required">Name</label>
                  <input placeholder="name" formControlName="name" />
                  <form-error
                    *ngIf="
                      formValidation?.name?.errors?.required &&
                      formValidation?.name?.touched
                    "
                  >
                    Name is required
                  </form-error>
                </form-field>

                <!-- Surname -->
                <form-field>
                  <label class="required">Surname</label>
                  <input placeholder="surname" formControlName="surname" />
                  <form-error
                    *ngIf="
                      formValidation?.surname?.errors?.required &&
                      formValidation?.surname?.touched
                    "
                  >
                    Surname is required
                  </form-error>
                </form-field>

                <!-- Contact Number -->
                <form-field>
                  <label class="required">Contact number</label>
                  <input
                    placeholder="contact number"
                    formControlName="contactNumber"
                  />
                  <form-error
                    *ngIf="
                      formValidation?.contactNumber?.errors?.required &&
                      formValidation?.contactNumber?.touched
                    "
                  >
                    Contact number is required
                  </form-error>
                  <form-error
                    *ngIf="
                      formValidation?.contactNumber?.errors?.pattern &&
                      formValidation?.contactNumber?.touched
                    "
                  >
                    Invalid contact number
                  </form-error>
                </form-field>
                <!-- Email Address -->
                <form-field>
                  <label class="required">Email address</label>
                  <input placeholder="email address" formControlName="email" />
                  <form-error
                    *ngIf="
                      formValidation?.email?.errors?.required &&
                      formValidation?.email?.touched
                    "
                  >
                    Email address is required
                  </form-error>
                  <form-error
                    *ngIf="
                      formValidation?.email?.errors?.pattern &&
                      formValidation?.email?.touched
                    "
                  >
                    Invalid email address
                  </form-error>
                </form-field>
              </div>
            </div>
          </div>
          <!-- / modal body -->

          <div class="row py-4 card-blue-border">
            <div class="col-md-12">
              <button-check (click)="changeEvent($event)">
                <input
                  type="checkbox"
                  name="iagree"
                  formControlName="iagree"
                />
                I allow PSG to contact me
              </button-check>
              <form-error
                *ngIf="
                  formValidation?.email?.errors?.required &&
                  formValidation?.email?.touched
                "
              >
                Invalid email address
              </form-error>
            </div>
          </div>

          <!-- modal footer expanded-->
          <div class="modal-footer expanded">
            <button
              id="closeModal"
              style="min-width: 120px"
              type="button"
              class="button outline"
              data-dismiss="modal"
            >
              Close
            </button>

            <button-text
              buttonStyle="solid"
              label="Contact me"
              fill="true"
              [disabled]="callmeBackForm.invalid || !isConsetCheckBoxChecked"
              (click)="submitForm()"
            >
            </button-text>
          </div>
          <!-- / modal footer -->
        </form>
      </div>
      <!-- / modal body -->
    </div>
    <!-- / modal container -->
  </div>
</div>
<!-- call-me-back Model -->

<!--Sent email confirmation-->
<div
  class="modal fade"
  id="modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <!-- modal container -->
    <div class="modal-content text-center" *ngIf="isInitLoading">
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-grow gold" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div class="modal-content" *ngIf="!isInitLoading">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading">Email Status</h5>
      </div>

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <h4>{{ contactUsResponseMsg }}</h4>
      </div>

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button
          id="closeModal"
          type="button"
          class="button outline"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
