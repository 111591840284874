<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- header banner -->
<div
  class="container-fluid educate-yourself-hero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title mb-1"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>
<!-- header banner -->

<!-- embedded videos -->
<div *ngIf="!isPageLoading" class="container-fluid py-3 my-3 h-100">
  <div class="container-xl py-3 my-3 h-100">
    <div class="row d-flex align-items-start justify-content-start">
      <div
        class="mt-5 col-md-6 col-sm-12"
        *ngFor="let video of content?.body?.videos; let index = index"
      >
        <div class="row d-flex justify-content-start">
          <h3 class="cyan pl-3">{{ video?.heading }}</h3>
        </div>

        <div class="pr-3 greybg-right-large-event-webinairs">
          <div class="row d-flex justify-content-end mb-2">
            <div class="p-2">
              <button class="button simple-icon" (click)="shareToMail()">
                <icon-font
                  name="icon-envelope"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToLinkedIn()">
                <icon-font
                  name="icon-linkedin"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToTwitter()">
                <icon-font
                  name="icon-twitter"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToFb()">
                <icon-font
                  name="icon-facebook"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
              <button class="button simple-icon" (click)="shareToWhatsApp()">
                <icon-font
                  name="icon-whatsapp"
                  colour="cyan"
                  class="body-large cyan"
                ></icon-font>
              </button>
            </div>
          </div>
          <div
            class="embed-responsive embed-responsive-16by9 card-round significant-raise"
          >
            <iframe
              width="520"
              height="270"
              [src]="video?.url | safe"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /embedded videos -->
