import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EducateYourselfPage } from "src/app/models/content/save-and-invest/educate-yourself-page-model";
import { PageService } from "src/app/services/page.service";
import { DOCUMENT } from "@angular/common";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";

@Component({
  selector: "app-educate-yourself",
  templateUrl: "./educate-yourself.component.html",
  styles: [],
})
export class EducateYourselfComponent implements OnInit {
  public content: any = {};
  public fragment: string;
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    private route: ActivatedRoute,
    public seoService: SeoService,
    @Inject(DOCUMENT) document,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<EducateYourselfPage>(pageService.URIs.educateYourself)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new EducateYourselfPage(content);
        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });

    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
    });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
        window.location.href
    );
  }

  shareToMail() {
    window.open(
      "mailto:?subject=PSG%20Daily%20Investment%20Update&body=" +
        window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }
}
