<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- all funds section -->
<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid all-funds"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="pl-4 pl-md-4">
          <div
            [innerHTML]="content?.header?.content"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- all funds main content section -->

<!-- multi-managed funds -->
<div *ngIf="!isPageLoading" class="container-fluid py-3 my-3">
  <div class="container-xl px-0 h-100">
    <div class="mt-5">
      <app-all-funds-table> </app-all-funds-table>
    </div>
  </div>
</div>
<!-- Multi-managed funds -->
<!-- The fine print-->
<div *ngIf="!isPageLoading" class="container-fluid">
  <div class="container-xl slight-raise card-rounder p-5">
    <div class="row">
      <div class="col-md-12">
        <h2 class="small-heading">{{ content?.body?.sections[1]?.heading }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div [innerHTML]="content?.body?.sections[1]?.content"></div>
      </div>
    </div>
  </div>
</div>
<!-- The fine print-->

<!-- Fund performance -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 svg-bg-l details--fundP h-100"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-7 ml-md-4">
        <div class="large-heading">
          <h2>{{ content?.body?.sections[2]?.sections[0]?.heading }}</h2>
        </div>
        <div
          [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
        ></div>
      </div>
    </div>
  </div>
</div>
<!-- Fund performance -->
