import { Injectable } from "@angular/core";

export interface StyleguideSearchType {
  category: string;
  heading: string;
  text: string;
  routerLink: string;
}

@Injectable()
export class SearchableItems {
  public searchableItems: StyleguideSearchType[] =
    [
      {
        category: "Product",
        heading: "Product",
        text: "This guide explains how to setup your PSG project to begin using the PSG style components. It includes information on prerequisite, installing and getting the PSG scaffold up and running.",
        routerLink: "/financial-advice/overview"
      },

      {
        category: "Funds",
        heading: "All PSG Funds",
        text: "The PSG logo has been carefully designed to represent the integrity and reputation of the PSG brand. This section explains how to use it correctly. There are two types of logos that may be required when producing branded items. When using the logo on a photographic background always choose a clear, flat area within the image to ensure it is legible and stands out.On dark or black areas, use the gold Fleur-de-lis and white logotype. If the gold fleur-de-lis is not legible, use it in white.",
        routerLink: "/all-funds"
      },
      {
        category: "Funds",
        heading: "PSG Multi-managed funds",
        text: "Our personality establishes the foundation of the PSG brand as an approachable corporate brand.  It is a product of our mission, and a reflection of our culture, values, and promise to customers. We make sure that these traits come through in all of our customer-facing communications and content.",
        routerLink: "/psg-multi-managed-funds"
      },
      {
        category: "Funds",
        heading: "PSG Single-managed funds",
        text: "While we’re conservative in our approach to managing money, we’re not conservative in our communications. We speak with confidence and boldness, to cut through the clutter but are humble and never gloat. We’re direct, and avoid fluff. We’re also an intellectual brand, that recognises how fraught with emotion financial decisions can be.",
        routerLink: "/psg-single-managed-funds"
      },
      {
        category: "All Funds",
        heading: "Our events",
        text: "While we’re conservative in our approach to managing money, we’re not conservative in our communications. We speak with confidence and boldness, to cut through the clutter but are humble and never gloat. We’re direct, and avoid fluff. We’re also an intellectual brand, that recognises how fraught with emotion financial decisions can be.",
        routerLink: "/all-funds"
      },


      {
        category: "News & Events",
        heading: "Our news",
        text: "The primary palette is the most prominent on the system which will be used most of the time with any two of the colours always paired together. We use Cyan for primary actions and buttons, links and for indicating progress and interactivity. Black is used primarily for body text and headings, Slate is used for borders, deviders and subtle variations in backgrounds, Gold is used sparingly and White is used for page backgrounds. The semantic palette is used to indicate the state of the system.",
        routerLink: "/news-and-publications"
      },
      {
        category: "News & Events",
        heading: "Daily Investment Update",
        text: "Any element that does not sit directly on the background will cast a shadow. A shadow’s appearance is determined by it’s parent’s depth & colour. Border radii use the same multiplication of 4 to align to the 4px grid. Border Radius. Corner Radius. ",
        routerLink: "/news-and-publications"
      },
      {
        category: "News & Events",
        heading: "Our events",
        text: "PSG’s grid structure forms the basis for the arrangement of onscreen elements. Designing for the grid helps to create an interface that is a harmonious and consistent throughout PSG product offerings. The margins in between elements have a fixed width and only scale when the text size scales to maintain a distinct and legible separation of content. There are three different margin sizes to allow for different types of content spacing: close, medium, and far. Padding is used to ensure there is sufficient visual clarity by introducing white space inside elements and also scales according to the browser text size. There are three different padding sizes to allow for different types of content spacing: tight, medium, and roomy. Layouts in PSG use a 12 column configuration with a maximum container width of 1170px on desktop and a 4 column layout on mobile. This layout can be broken for very specific use cases. Responsive breakpoints are defined by bootstrap’s standard breakpoint configurations. Icon Library",
        routerLink: "/news-and-publications/events-and-webinars"
      },

      {
        category: "Support",
        heading: "FAQ",
        text: "We design to reduce the friction between people and their tools and to ultimately help them to achieve their goals. To accomplish this across our brand, we’ve chosen a full font stack that transitions from friendly and warm in marketing to simple and focused in product. Page Heading. Should appear no more than once per page. Section Heading. Used to define main sections of content. Body text. Used for paragraph text. Input labels. Paragraph text should never exceed 100 characters or be less than 80 characters in width.",
        routerLink: "/support/faq"
      },
      {
        category: "Support",
        heading: "Legal",
        text: "Accordions are used to expand multiple blocks of content",
        routerLink: "/support/legal"
      },
      {
        category: "Support",
        heading: "Glossary",
        text: "Alerts are popups which display in the center of the screen above an overlay.",
        routerLink: "/glossary"
      },
      {
        category: "Support",
        heading: "Tools",
        text: "An avatar acts as a representation of a user or entity with initials or an image.",
        routerLink: "/support/financial-tools"
      },
      {
        category: "other",
        heading: "Financial",
        text: "A badge is used to indicate notification or for labeling content",
        routerLink: "/financial-advice/overview"
      },
      {
        category: "other",
        heading: "Wills, trusts and estate planning",
        text: "Our comprehensive services include estate planning and drafting of your will to ensure your assets are distributed efficiently and according to your wishes when you pass away. We also offer local and offshore trust services, to manage the assets in your estate efficiently.",
        routerLink: "/financial-advice/for-your-estate"
      },
      {
        category: "other",
        heading: "financial-advice",
        text: "With more than 250 adviser offices across South African and Namibia, you can benefit from objective and expert advice from a PSG adviser, wherever you are.",
        routerLink: "/financial-advice/find-an-adviser"
      },
      {
        category: "other",
        heading: "Savings",
        text: "A “rainy day” or emergency fund should cover your expenses for at least six months in case you cannot earn an income. Making consistent contributions into an appropriate investment product is the best way to build your emergency fund.",
        routerLink: "/save-and-invest/savings"
      },
      {
        category: "other",
        heading: "Investment products",
        text: "By offering retirement fund benefits to your employees, you help them to invest in their future and it makes your business more attractive to quality staff. We offer a broad range of employee benefits to businesses of various sizes.",
        routerLink: "/save-and-invest/compare-investment-products"
      },
      {
        category: "other",
        heading: "For your employees",
        text: "For most people, their retirement savings are not enough to retire comfortably. That is why you should start early but even if you haven’t, investing in your future is a life decision that is never too late to make.",
        routerLink: "save-and-invest/retirement-planning"
      },
      {
        category: "other",
        heading: "Dropdown Selects",
        text: "By offering retirement fund benefits to your employees, you help them to invest in their future and it makes your business more attractive to quality staff. We offer a broad range of employee benefits to businesses of various sizes.",
        routerLink: "/components/dropdown-select"
      },
      {
        category: "other",
        heading: "Retirement planning",
        text: "The file upload allows users to select files from their device.",
        routerLink: "/components/file-upload"
      },
      {
        category: "other",
        heading: "Stockbroking",
        text: "Owning and trading shares can be very exciting. It can be daunting if you don’t know what you’re doing, especially during uncertain times. We therefore offer you options: you can either do your own trading, take advantage of our stockbroking services through our PSG Wealth securities team, or opt for a managed portfolio through a skilled wealth manager.",
        routerLink: "/save-and-invest/stockbroking"
      },
      {
        category: "other",
        heading: "PSG Wealth",
        text: "We offer a range of wealth management services for individuals, families or businesses.",
        routerLink: "/about-us/psg-wealth"
      },
      {
        category: "other",
        heading: "PSG Asset Management",
        text: "PSG Asset Management is a wholly owned subsidiary of PSG Konsult Group. We offer a range of local and global funds, with corresponding feeder funds available from South Africa. Our long-term track record affirms the success of our proven process and offers important diversification and alpha generation benefits to patient investors. We also administer a range of PSG Wealth Solutions on the PSG Collective Investments (RF) Limited administration platform",
        routerLink: "/about-us/psg-asset-management"
      },
      {
        category: "other",
        heading: "PSG Insure",
        text: "We offer access to a full range of short-term insurance products and advice to protect you and your business from unforeseen events. With specialist insurance advisers throughout the country, we comfortably cater to your everyday, commercial and specialist insurance needs.",
        routerLink: "/about-us/psg-insure"
      },
      {
        category: "other",
        heading: "About our company",
        text: "Through three operating divisions, the group delivers a range of financial services and a suite of financial products. The group services individuals and companies in South Africa and Namibia.",
        routerLink: "/about-us"
      },
      {
        category: "other",
        heading: "Working with us",
        text: "Our people are our greatest strength. We believe that building a great business that gives excellent service to its clients is a team effort. Here, 'we' and 'our' are more important than 'me' and 'my'.",
        routerLink: "/careers"
      },
      {
        category: "other",
        heading: "Work for us",
        text: "Our people are our greatest strength. We believe that building a great business that gives excellent service to its clients is a team effort. Here, 'we' and 'our' are more important than 'me' and 'my'.",
        routerLink: "/careers"
      },
      {
        category: "other",
        heading: "PSG Wealth",
        text: "for all your needs",
        routerLink: "/contact-us"
      },
      {
        category: "other",
        heading: "Tables",
        text: "Table cells can be organised in many different ways to suit the needs of the specific data contained in it. Headers and footer can be included where needed but aren’t a requirement.",
        routerLink: "/components/table"
      },
      {
        category: "other",
        heading: "Tabs",
        text: "Tabs are an easy way to organize content by grouping similar information on the same page. This allows content to be viewed without having to navigate away from that page.",
        routerLink: "/components/tabs"
      },
      {
        category: "other",
        heading: "Toggle",
        text: "A toggle is used to view or switch between enabled or disabled states. Use toggles when your intent is to turn something on or off instantly.",
        routerLink: "/components/toggle"
      },
      {
        category: "other",
        heading: "Tooltips",
        text: "A tooltip is a floating, non-actionable label used to explain a user interface element or feature.",
        routerLink: "/components/tooltip"
      },
      {
        category: "other",
        heading: "Header",
        text: "The header is the foundation for navigating and orienting your user to the through the UI and is made up of three components; the Top Bar, Main Nav bar, and a Mega Nav dropdown.",
        routerLink: "/navigation/header"
      },
      {
        category: "other",
        heading: "Footer",
        text: "The footer assists site users who arrive at the bottom of a page. The footer contains secondary menu items, contact details, social media links and supplementary information such as copyright.",
        routerLink: "/navigation/footer"
      },
      {
        category: "other",
        heading: "Mobile Header",
        text: "The header is the foundation for navigating and orienting your user to the through the UI and is made up of three components; the Top Bar, Main Nav bar, and a Mega Nav dropdown.",
        routerLink: "/navigation/header-mobile"
      },
      {
        category: "other",
        heading: "Mobile Footer",
        text: "The footer assists site users who arrive at the bottom of a page. The footer contains secondary menu items, contact details, social media links and supplementary information such as copyright.",
        routerLink: "/navigation/footer-mobile"
      },
      {
        category: "other",
        heading: "Resources",
        text: "Everything you need availible for download. Fonts, Icons & Figma design components.",
        routerLink: "/resources"
      }
    ]
}