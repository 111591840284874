<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid financial-tools--hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="d-flex flex-column align-content-start justify-content-md-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1 class="section-title--mobile mb-0 text-shadow">
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>
<!--/ hero section -->

<!-- Wealth tools -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 mt-5 greybg-left">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-6 col-md-6 mb-4">
        <img
          class="img-fluid significant-raise"
          [src]="content?.body?.sections[0]?.image"
        />
      </div>

      <div class="col-lg-5 offset-lg-1 col-md-6">
        <!-- -->
        <div>
          <h3 class="large-heading">
            {{ content?.body?.sections[0]?.sections[0]?.heading }}
          </h3>
          <h4 class="small-heading mt-4">
            {{ content?.body?.sections[0]?.sections[0]?.subheading }}
          </h4>
          <p [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"></p>
          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[0]?.sections[0]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>

        <div class="mt-5">
          <h3 class="small-heading">
            {{ content?.body?.sections[0]?.sections[1]?.heading }}
          </h3>
          <p [innerHTML]="content?.body?.sections[0]?.sections[1]?.content"></p>
          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[0]?.sections[1]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>

        <!-- -->

        <div class="mt-5">
          <h3 class="small-heading">
            {{ content?.body?.sections[0]?.sections[2]?.heading }}
          </h3>
          <p [innerHTML]="content?.body?.sections[0]?.sections[2]?.content"></p>

          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[0]?.sections[2]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Wealth tools -->

<!-- Investment planning -->
<div *ngIf="!isPageLoading" class="container-fluid mt-5 py-5 greybg-right">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-right">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[1]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 offset-lg-1 mb-4 order-0 order-md-1">
        <img
          class="img-fluid significant-raise"
          [src]="content?.body?.sections[1]?.image"
        />
      </div>
      <div class="col-md-6 col-lg-5 py-md-5 order-1 order-md-0">
        <!-- ----- -->
        <div>
          <h3 class="medium-heading">
            {{ content?.body?.sections[1]?.sections[0]?.heading }}
          </h3>
          <p [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"></p>
          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[1]?.sections[0]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
        <!-- ----- -->
        <div class="mt-5">
          <h3 class="medium-heading">
            {{ content?.body?.sections[1]?.sections[1]?.heading }}
          </h3>
          <p [innerHTML]="content?.body?.sections[1]?.sections[1]?.content"></p>
          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[1]?.sections[1]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
        <!-- ----- -->
        <div class="mt-5">
          <h3 class="medium-heading">
            {{ content?.body?.sections[1]?.sections[2]?.heading }}
          </h3>
          <p [innerHTML]="content?.body?.sections[1]?.sections[2]?.content"></p>
          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[1]?.sections[2]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Investment planning -->

<!-- Asset Management tools -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 mt-5 greybg-left">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-10">
        <div class="heading-border-left">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[2]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-6 col-md-6 mb-4">
        <img
          class="img-fluid significant-raise"
          [src]="content?.body?.sections[2]?.image"
        />
      </div>

      <div class="col-lg-5 offset-lg-1 col-md-6">
        <!-- -->
        <div>
          <h3 class="large-heading">
            {{ content?.body?.sections[2]?.sections[0]?.heading }}
          </h3>

          <p [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"></p>
          <div class="btn-row">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[2]?.sections[0]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>

        <!-- -->

        <div class="mt-5">
          <p [innerHTML]="content?.body?.sections[2]?.sections[1]?.content"></p>
          <div>
            <ng-container
              *ngFor="
                let action of content?.body?.sections[2]?.sections[1]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </ng-container>
          </div>
        </div>

        <!-- -->

        <div class="mt-5 pb-5">
          <p [innerHTML]="content?.body?.sections[2]?.sections[2]?.content"></p>
          <div>
            <ng-container
              *ngFor="
                let action of content?.body?.sections[2]?.sections[2]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Asset Management tools -->
