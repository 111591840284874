<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  class="container-fluid partner-with-psg--hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="d-flex flex-column align-content-start justify-content-md-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>

<!-- cards -->
<div class="container-xl partner-with-psg--card-row">
  <div class="row d-flex">
    <div
      class="col-md-7 offset-md-1"
      *ngFor="let card of content?.header?.cards"
    >
      <div class="blur-card">
        <div class="px-md-3 pt-md-3 pb-md-2">
          <h2 class="large-heading">{{ card?.heading }}</h2>
          <div [innerHTML]="card?.content"></div>
        </div>
        <div class="px-md-3 pb-md-2">
          <button-text
            *ngFor="let action of card?.actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ hero section -->

<div *ngIf="content.body != null && content.body.sections.length > 0">
  <!-- blockquote -->
  <div class="container-xl my-5 py-5">
    <div class="row justify-content-around">
      <div class="col-md-10">
        <blockquote class="blockquote large text-center-md">
          <span class="cyan">“ </span>{{ content?.body?.sections[0]?.content
          }}<span class="cyan"> ”</span>
        </blockquote>
      </div>
    </div>
  </div>
  <!-- /blockquote -->

  <!-- adviser support services -->
  <div class="container-fluid mt-5 py-5 greybg-right">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-11 offset-md-1">
          <div class="heading-border-left">
            <h2 class="section-title--mobile">
              {{ content?.body?.sections[1]?.heading }}
            </h2>
          </div>
          <div class="mt-4 pl-4">
            <div
              class="body-large p-length-sm"
              [innerHTML]="content?.body?.sections[1]?.content"
            ></div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="offset-md-1 col-10">
          <div class="row">
            <div
              class="col-md-4 mb-4"
              *ngFor="
                let card of content?.body?.sections[1]?.cards;
                let i = index
              "
            >
              <div class="card-round slight-raise white-bg p-4 h-100">
                <div class="py-md-2 px-md-2">
                  <h3 class="small-heading">{{ card?.heading }}</h3>
                  <div class="mb-0" [innerHTML]="card?.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /adviser support services-->
</div>
