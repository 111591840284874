import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-latest-reports',
  templateUrl: './latest-reports.component.html',
  styleUrls: []
})
export class LatestReportsComponent implements OnInit {
  @Input() latestReports: any;
  @Input() keywords: any;

  public selectedKeywords: string[] = [];

  constructor(private router: Router) {
   }

  ngOnInit(): void {
  }

  public FilterReports(keyword: any)
  {
    keyword.selected = !keyword.selected;

    let index = this.selectedKeywords.indexOf(keyword.label);
    if(index > -1)
      this.selectedKeywords.splice(index, 1);
    else
      this.selectedKeywords.push(keyword.label);  
  }

  isExternalUrl(url: any) {
    try {
      new URL('', url);
      return true;
    } 
    catch (error) {
      return false;
    }
  }

  public OpenReport(report: any) {
    let url = report.reportUrl != null ? report.reportUrl : report.uri;
    if (this.isExternalUrl(url)) {
      window.open(url, "_blank");
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
