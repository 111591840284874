import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { PageService } from 'src/app/services/page.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-article-tags-modal',
  templateUrl: './article-tags-modal.component.html',
  styles: []
})

export class ArticleTagsModalComponent {

  constructor(
    public allArticleSerivce: ArticleService,
    public pageService: PageService,
    private router: Router
  ) {

  }

  public goToURI(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
}
