<!-- branch-office hero section -->
<div class="container-fluid pt-5 pb-4 branch-office">
    <div class="container-xl h-100">
        <div class="row">

        </div>

    </div>
</div>
<!-- /branch-office hero section -->

<div class="container-xl mt-4">
    <!-- breadcrumbs -->
    <div class="row">
        <div class="col-md-12">
            <div>
                <ul class="breadcrumb pl-0 ">
                    <li>
                        <a href="/financial-advice">Financial Advice</a>
                    </li>
                    <icon-font name="icon-angle-right">
                        <span class="icon icon-angle-right"></span>
                    </icon-font>
                    <li>
                        <a href="financial-advice/find-an-adviser">Find an Adviser</a>
                    </li>
                    <icon-font name="icon-angle-right">
                        <span class="icon icon-angle-right"></span>
                    </icon-font>
                    <li>
                        <a href="/branch-office">Klerksdorp, Buffelspark</a>
                    </li>
                    <icon-font name="icon-angle-right">
                        <span class="icon icon-angle-right"></span>
                    </icon-font>
                    <li class="breadcrumb-active">
                        News & publications
                    </li>

                </ul>
            </div>
        </div>
    </div>
    <!-- /breadcrumbs -->

    <div class="row mt-4">
        <!-- what we offer -->
        <div class="col-md-3 mb-4 order-1 order-md-0">
            <h3 class="medium-heading cyan mb-3">What we offer</h3>
            <p class="body-small">Employee Benefits</p>
            <p class="body-small">Wealth Management</p>
            <p class="body-small">Wealth Management: Securities</p>
            <p class="body-small">Employee Benefits</p>
        </div>
        <div class="col-md-5 mb-4 order-0 order-md-1">
            <h2 class="large-heading">Klerksdorp,
                Buffelspark</h2>
            <p>At PSG, our clients are the heart of our business. For us, it’s important to get to know you, to
                understand exactly what you hope to achieve and to build a lasting relationship with you to help you
                succeed</p>
        </div>
        <div class="col-md-4 order-3 order-md-3 transformY-50--branchMap">
            <div class="d-flex flex-fill significant-raise card-round">
                <img src="../../../assets/images/home-page/map.png" class="img-cover card-round" />
            </div>
            <div class="btn-row mt-4">
                <button-text buttonStyle="outline" label="Meet our advisers" class="mr-2"
                    [routerLink]="['/branch-office']" fragment="meet-the-team"></button-text>
                <button-text buttonStyle="solid" label="Contact branch" [routerLink]="['/branch-office']"
                    fragment="contact-us"></button-text>
            </div>
        </div>
    </div>
</div>


<!-- articles search -->
<div class="container-fluid mb-5 mt-4 mt-md-n5">
    <div class="container-xl">
        <div class="row">
            <!--all  articles-->

            <div class="col-md-8 mb-3">
                <div class="newsSearch--card h-100">
                    <h2 class="large-heading">All Articles</h2>
                    <p>Add description here. Explain search functionality below.</p>

                    <!-- filter inputs -->
                    <div class="row">
                        <div class="col-md-4">
                            <form-field>
                                <!-- Suffix - needs to be before the input -->
                                <icon-font name="icon-search" colour="cyan" class="suffix clickable"></icon-font>
                                <!-- Input -->
                                <input placeholder="Search by title or author">
                            </form-field>
                        </div>
                        <div class="col-md-4">
                            <dropdown-select class="dropdown-select-container">
                                <select placeholder>
                                    <option value="3">Offshore forms</option>
                                    <option value="2">Offshore forms</option>
                                    <option value="1"> Global</option>
                                </select>
                            </dropdown-select>
                        </div>
                        <div class="col-md-4">
                            <form-field>
                                <!-- Suffix - needs to be before the input -->
                                <icon-font name="icon-calendar" colour="cyan" class="suffix clickable"></icon-font>
                                <!-- Input -->
                                <input placeholder="Select date range">
                            </form-field>
                        </div>
                    </div>
                    <!-- /filter inputs -->

                    <!-- articles and dates -->
                    <div class="overflow-auto" style="height: 468px;">
                        <div class="largeArticle-cards mx-1" *ngFor="let article of articles; let i = index">
                            <div class="row no-gutters d-flex flex-row justify-content-between">
                                <div class="col-md-2">
                                    <h2 class="large-heading mb-2">{{article.day}}</h2>
                                    <p class="body-bold body-small">{{article.month}} {{article.year}}</p>
                                </div>
                                <div class="col-md-10">
                                    <div class="card-tags mb-3">
                                        <span class="basic-badge">Category 1</span>
                                        <span class="basic-badge">Category 2</span>
                                    </div>
                                    <h3 class="small-heading">{{article.title}}</h3>
                                    <p class="mb-2">{{article.author}}</p>
                                    <p class="slate body-small">{{article.snippet}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--articles and dates-->
                </div>
            </div>



            <!--presentations-->
            <div class="col-md-4 mb-3">
                <div class="newsSearch--card h-100">
                    <h2 class="medium-heading">Latest presentations</h2>
                    <p>Add description here. Explain search functionality below.</p>
                    <div class="overflow-auto" style="height: 468px;">
                        <!-- presentation -->
                        <div class="card presentation--card mb-4 mx-1"
                            *ngFor="let presentation of presentations; let i = index">
                            <img class="card-img-top" src="{{presentation.image}}" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="small-heading text-center">{{presentation.title}}</h5>
                                <div class="text-center mt-3">
                                    <button-text buttonStyle="shaded" label="view presentation"></button-text>
                                </div>

                            </div>
                        </div>
                        <!-- /presentation -->
                    </div>




                </div>
            </div>
        </div>
    </div>
</div>
<!-- articles search-->
