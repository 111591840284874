<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isPageLoading" class="mx-auto">
  <owl-carousel-o [options]="customOptions" *ngIf="!isPageLoading">
    <ng-template carouselSlide dataHash="tax-free-investment-plan">
      <app-tax-free-investment-plan></app-tax-free-investment-plan>
    </ng-template>

    <ng-template carouselSlide dataHash="voluntary-investment-plan">
      <app-voluntary-investment-plan></app-voluntary-investment-plan>
    </ng-template>

    <ng-template carouselSlide dataHash="offshore-voluntary-investment-plan">
      <app-offshore-voluntary-investment-plan></app-offshore-voluntary-investment-plan>
    </ng-template>

    <ng-template carouselSlide dataHash="endowment-investment-plan">
      <app-endowment-investment-plan></app-endowment-investment-plan>
    </ng-template>
  </owl-carousel-o>
</div>
