import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-previous-reports',
  templateUrl: './previous-reports.component.html',
})
export class PreviousReportsComponent implements OnInit {

  @Input() previousReports: any;

  constructor(public pageService: PageService,
    private router: Router) { }

  ngOnInit(): void {
  }

  public DynamicButtonClick(report: any) {
    let url = report.reportUrl != null ? report?.reportUrl : report.uri;
    if (this.isExternalUrl(url)) {
      window.open(url, "_blank");
    } else {
      this.router.navigateByUrl(url);
    }
  }

  isExternalUrl(url: any) {
    try {
      new URL('', url);
      return true;
    } 
    catch (error) {
      return false;
    }
  }

  public OpenArticle(article: string) {
    this.pageService.SetArticle(article); 
  }
  
}
