<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- FAQ section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-md-0 faq-hero-section"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div class="row align-items-center h-100">
      <div class="col-md-9">
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title sm-funds text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
      </div>
      <div class="col-md-3">
        <div>
          <h2 class="white">COVID-19</h2>
          <p class="p-length-sm white">
            To learn more about COVID-19 in South Africa, please visit
            <a class="cyan" href="https://sacoronavirus.co.za/" target="_blank"
              >sacoronavirus.co.za</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ FAQ section -->

<div *ngIf="!isPageLoading" class="container-xl mb-5 faq-hero-section--cards">
  <div class="row d-flex">
    <div class="col-md-8 mb-5">
      <div class="card-round pt-5 pb-3 px-4 extreme-raise white-bg">
        <!-- General FAQ -->
        <div>
          <h2 class="large-heading">
            {{ content?.body?.categories[0]?.name }}
          </h2>
          <ul class="mt-3">
            <li
              class="cyan"
              style="list-style: none"
              *ngFor="let download of content?.body?.categories[0]?.downloads"
            >
              <a
                class="textlink"
                target="_blank"
                [href]="download?.url"
                *ngIf="download?.url"
                >{{ download?.label }}</a
              >
              <a
                class="textlink"
                target="_blank"
                [href]="download?.file"
                *ngIf="download?.file"
                >{{ download?.label }}</a
              >
            </li>
          </ul>
        </div>
        <!-- General FAQ -->
      </div>
    </div>
    <div class="col-md-4">
      <!-- Trade FAQs -->
      <div class="card-round pt-5 pb-3 px-4 extreme-raise white-bg mb-4">
        <div>
          <h2 class="large-heading">
            {{ content?.body?.categories[1]?.name }}
          </h2>
          <ul class="mt-3">
            <li
              class="cyan"
              style="list-style: none"
              *ngFor="let download of content?.body?.categories[1]?.downloads"
            >
              <a
                class="textlink"
                target="_blank"
                [href]="download?.url"
                *ngIf="download?.url"
                >{{ download?.label }}</a
              >
              <a
                class="textlink"
                target="_blank"
                [href]="download?.file"
                *ngIf="download?.file"
                >{{ download?.label }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- Trade FAQs -->

      <!-- PSG Invest FAQs -->
      <div class="card-round pt-5 pb-3 px-4 extreme-raise white-bg mb-4">
        <div>
          <h4>{{ content?.body?.categories[2]?.name }}</h4>
          <ul class="mt-3">
            <li
              class="cyan"
              style="list-style: none"
              *ngFor="let download of content?.body?.categories[2]?.downloads"
            >
              <a
                class="textlink"
                target="_blank"
                *ngIf="download?.url"
                [href]="download?.url"
                >{{ download?.label }}</a
              >
              <a
                class="textlink"
                target="_blank"
                [href]="download?.file"
                *ngIf="download?.file"
                >{{ download?.label }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- PSG Invest FAQs -->

      <!-- PSG Asset Management FAQs -->
      <div class="card-round pt-5 pb-3 px-4 extreme-raise white-bg mb-4">
        <div>
          <h4>{{ content?.body?.categories[3]?.name }}</h4>
          <ul class="mt-3">
            <li
              class="cyan"
              style="list-style: none"
              *ngFor="let download of content?.body?.categories[3]?.downloads"
            >
              <a
                class="textlink"
                target="_blank"
                [href]="download?.url"
                *ngIf="download?.url"
                >{{ download?.label }}</a
              >
              <a
                class="textlink"
                target="_blank"
                [href]="download?.file"
                *ngIf="download?.file"
                >{{ download?.label }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- PSG Asset Management FAQs -->
    </div>
  </div>
</div>
