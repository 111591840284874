

<div class="mb-5 container-fluid">
  <div class="container-xl">
    <div class="row">
      <div class="accordion col-md-12" [id]="this.accordionControlId">
        <div class="accordian-inner">
            <div [id]="this.accordionHeaderId">
                <h2 class="mb-0">
                    <button class="accordian-button collapsed" type="button" data-toggle="collapse"
                        [attr.data-target]="'#' + this.collapseId" aria-expanded="false"
                        [attr.aria-controls]="this.collapseId">
                        {{this.heading}}
                        <icon-font name="icon-angle-up" class="icon-accordian"></icon-font>
                    </button>
                </h2>
            </div>
            <div [id]="this.collapseId" class="collapse"
                [attr.aria-labelledby]="this.accordionHeaderId"
                [attr.data-parent]="'#' + this.accordionControlId">
                <div class="accordian-body">
                    <ul>
                        <li style="list-style: none;" *ngFor="let download of downloads;">
                            <a class="textlink" target="_blank" *ngIf="download?.url"
                                [href]="download?.url">{{download?.label}}</a>
                            <a class="textlink" target="_blank" [href]="download?.file"
                                *ngIf="download?.file">{{download?.label}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </div>
  </div>
</div>
