import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DuiArticlePage } from 'src/app/models/content/news-and-publications/dui-article-page.model';
import { takeWhile } from 'rxjs-compat/operator/takeWhile';
import { DailyInvestmentService } from 'src/app/services/dailyInvestment/daily-investment.service';
import { MarketIndicatorResponse } from 'src/app/services/dailyInvestment/daily-ivestment.model';
import { FormattingService } from 'src/app/services/formatting.service';
import { PageService } from 'src/app/services/page.service';
import { ArticleService } from 'src/app/services/article.service';

@Component({
  selector: 'app-market-indicator',
  templateUrl: './market-indicator.component.html',

})
export class MarketIndicatorComponent implements OnInit, OnDestroy {

  public pageTabs = ["Local Markets", "International Markets", "Other Markets"];
  public currentTab = "Local Markets";
  public locality: string = "LOCAL";
  public alive: boolean = true;
  public MarketIndicators: Array<MarketIndicatorResponse>;
  public content: any = {};
  public newsArticle: string;

  //Latest Article
  public latestArticleURL = '';

  constructor(
    private dailyInvestmentService: DailyInvestmentService,
    public formattingService: FormattingService,
    private pageService: PageService,
    private route: ActivatedRoute,
    public diuArticleService: ArticleService,
    private router: Router) { }

  public ngOnInit() {

    this.route.params.subscribe((parms: Params) => {
      this.newsArticle = parms.newsArticle;
      this.diuArticleService.GetAllDailyInvestmentUpdatesArticles().subscribe((diuArticles) => {
        diuArticles = diuArticles.sort((a, b) =>
          a.publishDate < b.publishDate ? 1 : -1);
        this.latestArticleURL = diuArticles[0].uri;

        var n = this.latestArticleURL.lastIndexOf('/');
        this.newsArticle = this.latestArticleURL.substring(n + 1);

        this.pageService
          .GetPage<DuiArticlePage>(this.pageService.URIs.dialyInvestmentArticleBase +
            "/" +
            this.newsArticle)

          .subscribe((content) => {
            this.content = new DuiArticlePage(content);
          });
      });
    });

    this.getMarketIndicators();
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, '_blank');
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public getMarketIndicators() {
    this.dailyInvestmentService.getMarketIndicators({
      category: "MARKET-INDEX",
      date: "",
      locality: this.locality,
      pageNumber: 1,
      pageSize: 50
    })
      .subscribe((result) => {
        this.MarketIndicators = result;

      })
  }

  public changeSelectedTab(tab: string) {
    this.currentTab = tab;
    switch (tab) {
      case "Local Markets": {

        this.locality = "LOCAL"
        break;
      }
      case "International Markets": {
        this.locality = "INTERNATIONAL";
        break;
      }
      case "Other Markets": {
        this.locality = "OTHER-MARKET";
        break;
      }
    }

    this.getMarketIndicators();
  }

}
