import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { FundOfFundsLandingPage } from "src/app/models/content/news-and-publications/fund-of-funds-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-fund-of-funds",
  templateUrl: "./fund-of-funds.component.html",
  styleUrls: [],
})
export class FundOfFundsComponent implements OnInit {
  public avoidingMistakesContent: any = {};
  public content: any = {};
  public faqList: any = {};
  public isPageLoading: boolean = true;
  public shareHeadline: string;

  constructor(
    public pageService: PageService,
    private router: Router,
    public seoService: SeoService
  ) {
    pageService
      .GetPage<FundOfFundsLandingPage>(pageService.URIs.fundOfFunds)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new FundOfFundsLandingPage(content);
        this.avoidingMistakesContent =
          this.content.body.sections[content?.body?.sections.length - 2];
        this.faqList =
          this.content.body.sections[content?.body?.sections.length - 1];
        this.shareHeadline = this.content?.header?.headline?.replace(
          "&",
          "%26"
        );

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  ngOnInit(): void { }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public NavigateToSearch() {
    this.router.navigateByUrl("/search-results");
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }
}
