import { DOCUMENT, Location } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DuiArticlePage } from "src/app/models/content/news-and-publications/dui-article-page.model";
import { PageService } from "src/app/services/page.service";
import { ArticleService } from "src/app/services/article.service";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";

@Component({
  selector: "app-diu-article-static",
  templateUrl: "./diu-article-static.component.html",
})
export class DiuArticleStaticComponent implements OnInit, AfterViewInit {
  public content: any = {};
  public fragment: string;
  public newsArticle: string;
  public goToURL: string;
  public timeDate: Date;
  public currentURL: string;
  public shareHeadline: string;

  public types: Array<any> = new Array<any>();
  public sections: Array<any> = new Array<any>();

  //Latest Article
  public latestArticleURL: string = "";
  private calendarUrl: string = "/news-and-publications/economic-calendar";
  public isPageLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public pageService: PageService,
    public sanitizer: DomSanitizer,
    private hostElement: ElementRef,
    @Inject(DOCUMENT) document,
    private router: Router,
    private location: Location,
    public diuArticleService: ArticleService,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    this.route.params.subscribe((parms: Params) => {
      this.pageService
        .GetPage<DuiArticlePage>(this.pageService.URIs.investmentResearch)
        .pipe(
          finalize(() => {
            this.isPageLoading = false;
          })
        )
        .subscribe((content) => {
          this.content = new DuiArticlePage(content);
          this.shareHeadline = this.content.header.headline.replace("&", "%26");
          this.seoService.updateMetaInfoForPage(
            this.content.seo.title,
            window.location.href,
            this.content?.header?.image,
            this.content.seo.description
          );
        });
    });

    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
  }

  public goToAdvisorProfile(id) {
    if (id == null) {
      this.router.navigateByUrl("/");
    }
    else {
      this.router.navigateByUrl(`financial-advice/adviser-profile/${id}`);
    }
  }

  public sanitizeURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }

  ngAfterViewInit() {
    this.scrollTimer();
  }

  public async scrollTimer() {
    setTimeout(() => {
      let doc = document.getElementById(this.fragment);
      if (doc != undefined) {
        doc.focus();
        doc.scrollIntoView();
      } else {
        this.scrollTimer();
      }
    }, 3000);
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
