import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { FundFactSheetPage } from "src/app/models/content/funds/fund-fact-sheet-page.model";
import { PageService } from "src/app/services/page.service";

@Component({
  selector: "app-fund-fact-sheet",
  templateUrl: "./fund-fact-sheet.component.html",
})
export class FundFactSheetComponent {
  public content: any = {};
  public isPageLoading: boolean = true;

  constructor(private pageService: PageService, private router: Router) {
    pageService
      .GetPage<FundFactSheetPage>(pageService.URIs.preservationFund)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new FundFactSheetPage(content);
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }
}
