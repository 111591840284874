import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-landing-p-banner-carousel",
  templateUrl: "./landing-p-banner-carousel.component.html",
})
export class LandingPBannerCarouselComponent implements OnInit {
  @Input("content") content: any;

  constructor(private router: Router) {}

  ngOnInit() {}

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }
}
