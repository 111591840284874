import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { ArticleService } from "src/app/services/article.service";
import { DatePipe } from "@angular/common";
import { PageService } from "src/app/services/page.service";
import { ArticlesPage } from "src/app/models/content/news-and-publications/articles.model";

@Component({
  selector: 'app-office-articles',
  templateUrl: "./office-articles.component.html",
})
export class OfficeArticlesComponent
  implements OnInit, OnDestroy, AfterContentChecked {
  public content: any = {};

  public alive: boolean = true;

  // All Articles
  public officeArticlesHead: any = {};
  public totalArticles: any = {};
  public officeArticles: any = {};

  // Search All Articles
  public searchArticle: Array<any> = [];

  //Latest Article
  public latestArticleURL = "";

  constructor(
    private router: Router,
    public articleService: ArticleService,
    public datepipe: DatePipe,
    public pageService: PageService,
    private ref: ChangeDetectorRef
  ) {
    articleService.GetAllArticles()
      .subscribe((totalArticles) => {
        totalArticles = totalArticles.sort((a, b) =>
          Date.parse(a.publishDate) < Date.parse(b.publishDate) ? 1 : -1
        );
        // TODO: filter functionality should not happen here this is a workaround fix when possible
        this.totalArticles = totalArticles.filter(x => x.uri.startsWith('/news-and-publications/articles/office'));

        this.searchArticle = (<Array<any>>this.totalArticles).slice(
          0,
          (<Array<any>>this.totalArticles).length
        );
      });

    // this.pageService
    //   .GetPage<ArticlesPage>(this.pageService.URIs.officeArticlesBase)
    //   .subscribe((content) => {
    //     this.officeArticlesHead = new ArticlesPage(content);
    //   });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }

  public goToURI(uri: string) {
    this.router.navigateByUrl(uri);
  }

  public ViewArticle() {
    this.router.navigateByUrl("office");
  }

  public titleOrAuthorSearch(event) {
    let officeArticles = this.searchArticle;

    if (event == "") {
      this.searchArticle = (<Array<any>>officeArticles).slice(
        0,
        (<Array<any>>officeArticles).length
      );
    } else {
      this.searchArticle = (<Array<any>>officeArticles).filter(
        (x) =>
        (x.headline == null
          ? false
          : x.headline.toLowerCase().includes(event.toLowerCase())) // ||
        /// There is no Author for office articles
        // (x.author.name == null
        //   ? false
        //   : x.author.name.toLowerCase().includes(event.toLowerCase()))
      );
    }
  }


  public ngOnInit() {
    return;
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
}
