<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="container-fluid week-ahead-hero mb-5 pb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div class="row d-flex align-content-start justify-content-center h-100">
      <div class="col-md-12 mt-5">
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title week-ahead-header white text-shadow"
          >
            {{ content?.header?.heading }}
          </h1>
          <h3 class="white text-shadow">
            {{ content?.header?.subheading }}
          </h3>
        </div>
      </div>
      <div class="col-md-12 mb-5">
        <div class="pl-md-4">
          <div [innerHTML]="content?.header?.content"></div>
        </div>
        <div class="pl-md-4">
          <ng-container *ngFor="let action of content?.header?.actions">
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }} mr-2"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl banner-card">
  <div class="row d-flex">
    <div
      class="col-md-12"
      *ngIf="content?.header?.headerBannerContent.length > 0"
    >
      <div class="research-insights-hero-card">
        <h3 class="medium-heading cyan">
          {{ content?.header?.headerBannerContent[0].heading }}
        </h3>
        <p>{{ content?.header?.headerBannerContent[0].headline }}</p>
        <div
          [innerHTML]="content?.header?.headerBannerContent[0].content"
        ></div>
      </div>
    </div>
  </div>
</div>

<!-- Expected releases -->
<div class="container-fluid pb-5 h-100">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <h2 class="mb-4">Expected Releases</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-12 mb-4">
        <div class="card-deck d-block" *ngIf="expectedReleases?.length > 0">
          <div
            class="card-round significant-raise white-bg pt-5 pb-3 px-5 mb-3"
            *ngFor="let card of expectedReleases"
          >
            <h4>{{ card.heading }}</h4>
            <p class="body-small cyan">{{ card.headline }}</p>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Company</th>
                  <th scope="col">Market expectations</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td [innerHTML]="card.excerpt"></td>
                  <td [innerHTML]="card.content"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row">
          <div
            class="col-md-6 col-xs-12 mb-3 d-flex flex-column"
            *ngFor="let content of content?.body?.sections"
          >
            <h3 class="small-heading">{{ content.heading }}</h3>
            <div class="mb-3">
              <img alt="image" [src]="content.image" class="img-fluid" />
            </div>
            <div class="btn-row text-center mt-auto">
              <button-text
                *ngFor="let action of content.actions"
                class="mr-2"
                buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Weekly event calendar -->
<div class="container-fluid mt-5 pb-5 greybg-left h-100">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left transformY-25">
          <h2 class="section-title--mobile">Monthly Calendar</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="container-xl h-100">
    <div class="row pb-3">
      <div class="col-12">
        <h3 class="large-heading">
          {{ currentMonth }}
        </h3>
      </div>
    </div>
    <div
      class="row card-deck mr-3 week-ahead-cards"
      *ngIf="eventsCalendar?.length > 0"
    >
      <div
        class="col-sm-2 col-xs-6 card week--card mb-4"
        *ngFor="let card of eventsCalendar"
      >
        <div class="card-body d-flex flex-column">
          <h5 class="small-heading mb-3">
            {{ card.heading }}
          </h5>
          <p>{{ card.headline }}</p>

          <p class="body-small" [innerHTML]="card.content"></p>
          <div class="btn-row mt-auto">
            <button-text
              *ngFor="let action of card.actions"
              class="mr-2"
              buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="eventsCalendar?.length < 1" class="row">
      <div class="col-12">
        <p>There are no upcoming events.</p>
      </div>
    </div>
  </div>
</div>

<div class="container-xl py-5">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>
