<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isPageLoading" class="mx-auto">
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide dataHash="retirement-annuity">
      <app-retirement-annuity></app-retirement-annuity>
    </ng-template>

    <ng-template carouselSlide dataHash="living-annuity">
      <app-living-annuity></app-living-annuity>
    </ng-template>

    <ng-template carouselSlide dataHash="preservation-fund">
      <app-preservation-fund></app-preservation-fund>
    </ng-template>
  </owl-carousel-o>
</div>
