<!--  hero section -->
<div class="container-fluid sens-hero">
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div class="heading-border-left">
        <h1 class="mega-title--page-title home-page mb-1 text-shadow">
          SENS & share data
        </h1>
      </div>
      <div>
        <p class="white pl-4 p-length-sm pb-5">
          Up to date information is valuable to all shareholders. Share trades,
          price statistics, SENS information as well as OTC trading and our
          dividend history are available below.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- hero section -->
<div class="container-xl mt-4">
  <!-- breadcrumbs -->
  <div class="row">
    <div class="col-md-12">
      <div>
        <ul class="breadcrumb pl-0">
          <li>
            <a href="/investor-relations/sens-and-share-data"
              >SENS & share data</a
            >
          </li>
          <icon-font name="icon-angle-right">
            <span class="icon icon-angle-right"></span>
          </icon-font>

          <li class="breadcrumb-active">
            {{ article.Title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- /breadcrumbs -->
</div>
<div class="container-xl mt-5">
  <div class="row">
    <div class="col-md-6">
      <h2 class="medium-heading mb-4">{{ article.Title }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <pre class="sens-article" [innerHTML]="article.Article"></pre>
    </div>
  </div>
</div>
