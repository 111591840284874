import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { SensArticle } from "src/app/services/sens/sens-trade.models";
import { SensTradeService } from "src/app/services/sens/sens-trade.service";
import { Title, Meta } from '@angular/platform-browser';
import { takeWhile } from "rxjs";
@Component({
  selector: "sens-article",
  templateUrl: "./sens-articles.component.html"
})
export class SensArticlesComponent implements OnInit, OnDestroy {

  public alive: boolean = true;
  public article: SensArticle;
  constructor(private sensTradeService: SensTradeService, private sanatizer: DomSanitizer, private title: Title,
    private metaTagService: Meta) { }

  public ngOnInit() {

    this.sensTradeService.$currentSensArticle.pipe(
      takeWhile(() => this.alive))
      .subscribe((result) => {
        this.article = result;
      })
  }

  public ngOnDestroy() {
    this.alive = false;
  }

}
