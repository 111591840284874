<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- find and adviser hero section -->
<div
  class="container-fluid mb-5 find-an-adviser-hero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-md-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 mb-4 mb-md-0">
        <div
          class="pl-md-4"
          [innerHTML]="content?.header?.content"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
      </div>
      <div class="col-md-6 offset-md-1 findAdviser-searchCard">
        <div
          class="white-bg card-round extreme-raise px-3 px-md-4 px-lg-5 py-4 py-md-5"
        >
          <div class="mb-3">
            <h5 class="body-large text-center mb-0">
              Get the right advice from the right adviser for you.
            </h5>
            <p class="body-small text-center">
              Tell us what you are looking for and we’ll find your matching
              adviser
            </p>
          </div>
          <form autocomplete="off" autocorrect="off" spellcheck="false">
            <form-field>
              <div class="row justify-content-center align-items-center pt-2">
                <button-radio>
                  <!-- Radio Input -->
                  <input
                    class="radioButton-input"
                    type="radio"
                    [(ngModel)]="radioSearchSelected"
                    value="searchByProduct"
                    name="radioSearchSelected"
                    (change)="onSearchItemChange(radioSearchSelected)"
                  />
                  <!-- Radio Label -->
                  <p class="body-small">Search by product</p>
                </button-radio>

                <button-radio>
                  <input
                    class="radioButton-input"
                    type="radio"
                    [(ngModel)]="radioSearchSelected"
                    value="adviserName"
                    name="radioSearchSelected"
                    (change)="onSearchItemChange(radioSearchSelected)"
                  />
                  <p class="body-small">Adviser name</p>
                </button-radio>

                <button-radio>
                  <!-- Radio Input -->
                  <input
                    class="radioButton-input"
                    type="radio"
                    [(ngModel)]="radioSearchSelected"
                    value="searchByLocation"
                    name="radioSearchSelected"
                    (change)="onSearchItemChange(radioSearchSelected)"
                  />
                  <!-- Radio Label -->
                  <p class="body-small">Search by location</p>
                </button-radio>
              </div>
            </form-field>

            <div *ngIf="radioSearchSelected == 'searchByProduct'">
              <!-- Products -->
              <form-field>
                <label class="required">Products</label>
                <dropdown-select *ngIf="products.length > 0">
                  <select
                    [(ngModel)]="product"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="">All advice lines</option>
                    <option
                      *ngFor="let product of products"
                      [value]="product.discipline_name"
                    >
                      {{ product?.discipline_name }}
                    </option>
                  </select>
                </dropdown-select>
              </form-field>
              <!--/ Products -->

              <!-- location -->
              <form-field>
                <label class="required">Location</label>
                <autocomplete-dropdown
                  [formGroup]="SearchFormGroup"
                  name="city"
                  placeholder="Start typing your location.."
                  [items]="filteredCities"
                >
                </autocomplete-dropdown>
              </form-field>
              <!--/ location -->
            </div>

            <div *ngIf="radioSearchSelected == 'adviserName'">
              <!-- adviserName input -->
              <form-field>
                <label class="required">Adviser Name</label>
                <input
                  type="text"
                  placeholder="Name and Surname"
                  name="adviserName"
                  [(ngModel)]="adviserName"
                  #name="ngModel"
                  required
                  maxlength="90"
                  minlength="3"
                  [ngClass]="{ 'red-border-class': name.errors }"
                  id="name"
                />
              </form-field>
              <!-- adviserName input -->
            </div>

            <div *ngIf="radioSearchSelected == 'searchByLocation'">
              <!-- select Country-->
              <form-field>
                <div class="row justify-content-start align-items-start pt-2">
                  <p class="pl-3 body-small bold">
                    <strong>
                      Tell us what, who and where you are looking for and we'll
                      find your match</strong
                    >
                  </p>

                  <button-radio *ngFor="let country of countriesArray">
                    <!-- Radio Input -->
                    <input
                      class="radioButton-input"
                      type="radio"
                      [(ngModel)]="countrySelected"
                      value="{{ country.country_name }}"
                      name="countrySelected"
                      (change)="onRadioBtnChange(country)"
                    />
                    <!-- Radio Label -->
                    <p class="body-small">{{ country.country_name }}</p>
                  </button-radio>
                </div>
              </form-field>
              <!-- select Country-->

              <!-- location -->
              <form-field>
                <label class="required">Location</label>
                <autocomplete-dropdown
                  [formGroup]="SearchFormGroup"
                  name="city"
                  placeholder="Start typing your location.."
                  [items]="filteredCountryCities"
                >
                </autocomplete-dropdown>
              </form-field>
              <!--/ location -->
            </div>
          </form>

          <div>
            <button-text
              buttonStyle="solid"
              label="Find my adviser"
              fill="true"
              fragment="results"
              (click)="onSearchAdviser()"
              [disabled]="isLoading"
            >
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
                *ngIf="isLoading"
              ></span>
            </button-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /find and adviser hero section -->

<!-- search results -->
<div class="container-xl py-5 my-5" id="results">
  <div class="loader-center-container" *ngIf="isLoading">
    <div class="spinner-grow gold" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="loactionResults">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <p class="text-center">
          We could not find results for<br /><span
            class="cyan body-bold body-large"
            >{{ previousProductSelected }}</span
          >
        </p>
        <p class="text-center">
          Please see all available results from <br /><span
            class="cyan body-bold body-large"
            >{{ location }}</span
          >
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="noResultsForLocation">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <p class="text-center">
          No Results for <br /><span class="cyan body-bold body-large">{{
            displayLocation
          }}</span>
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="noResultsForLocationAndProduct">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <p class="text-center">
          No Results for<br /><span class="cyan body-bold body-large">{{
            previousProductSelected
          }}</span>
        </p>
        <p class="text-center">
          No Results for <br /><span class="cyan body-bold body-large">{{
            displayLocation
          }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <!-- adviser - profile -->
    <div
      class="col-md-4 d-flex flex-column flex-fill mb-4"
      *ngFor="let adviser of advisers; let i = index"
    >
      <div class="profile-card flex-fill">
        <!-- image / name / job title -->
        <div class="d-flex flex-row mb-3">
          <img
            *ngIf="adviser.photo_decoded != undefined"
            [src]="adviser.photo_decoded"
            alt="Avatar"
            class="image-large-avatar adviser-b-w mr-3"
          />
          <img
            *ngIf="adviser.photo_decoded == undefined"
            src="../../../../assets/images/adviser-profile.svg"
            alt="Loader"
            class="image-large-avatar adviser-b-w mr-3"
          />
          <div class="d-flex flex-column pt-2">
            <p class="medium-heading mb-0">
              {{ adviser.person_name }} {{ adviser.person_surname }}
            </p>
            <p class="mb-0 slate body-med">
              {{ this.sanitizeJobTitle(adviser.person_jobtitle) }}
            </p>
          </div>
        </div>
        <!-- products -->
        <div>
          <p class="body-med body-bold mb-2">How can I help?</p>
          <p class="body-small mb-0">{{ adviser.primary_discipline }}</p>
          <p
            class="body-small mb-0"
            *ngFor="let product of adviser.secondary_disciplines; let i = index"
          >
            {{ product }}
          </p>
        </div>

        <div>
          <p class="body-small mb-0 mt-2">
            Adviser at
            <a
              class="textlink"
              (click)="
                helper.redirectTo(
                  advisersUrl +
                    '/branch-office/' +
                    helper.convertToSlug(adviser.team_name),
                  '_blank'
                )
              "
              [class.disabled]="isLoadingOffice ? true : false"
            >
              {{ this.sanitizeJobTitle(adviser.team_name) }}
            </a>
          </p>
        </div>

        <div class="btn-100-fA mt-3">
          <button-icon
            buttonIcon="solid-icon-text"
            icon="icon-adviser"
            labelIcon="My profile"
            class="mr-md-2"
            (click)="
              helper.redirectTo(
                advisersUrl +
                  '/financial-advice/adviser-profile/' +
                  adviser.person_name +
                  '-' +
                  adviser.person_surname,
                '_blank'
              )
            "
          >
          </button-icon>
          <button-icon
            buttonIcon="shaded-icon-text"
            icon="icon-message"
            labelIcon="Message me"
            data-toggle="modal"
            data-target="#modal-standard-sm"
            (click)="OpenAdviserLeadDialog(adviser)"
          >
          </button-icon>
        </div>
      </div>
    </div>
    <!-- /adviser - profile -->
  </div>
</div>

<!-- /search results -->

<div
  class="modal fade"
  id="modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-md">
    <!-- modal container -->
    <div class="modal-content text-center" *ngIf="isInitLoading">
      <div class="spinner-grow gold" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="modal-content" *ngIf="!isInitLoading">
      <form [formGroup]="leadForm">
        <!-- modal header expanded-->
        <div class="modal-header expanded">
          <h5 class="modal-heading">Contact adviser</h5>
        </div>
        <!-- / modal header -->

        <!-- modal body expanded-->
        <div class="modal-body expanded">
          <div class="row">
            <div class="col-6">
              <!-- Label -->
              <form-field>
                <label class="required">Title</label>
                <dropdown-select *ngIf="titles.length > 0">
                  <select formControlName="title">
                    <option value="" disabled>Title</option>
                    <option *ngFor="let title of titles" [value]="title.id">
                      {{ title.name }}
                    </option>
                  </select>
                </dropdown-select>
                <form-error
                  *ngIf="
                    leadForm.controls.title.invalid &&
                    leadForm.controls.title.touched
                  "
                >
                  Title is required
                </form-error>
              </form-field>
            </div>
            <div class="col-6">
              <form-field>
                <label class="required">Initials</label>
                <input formControlName="initials" placeholder="Initials" />
                <form-error
                  *ngIf="
                    leadForm.controls.initials.invalid &&
                    leadForm.controls.initials.touched
                  "
                >
                  Initials are required
                </form-error>
              </form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <!-- Name -->
              <form-field>
                <label class="required">Name</label>
                <input placeholder="Name" formControlName="firstName" />
                <form-error
                  *ngIf="
                    leadForm.controls.firstName.invalid &&
                    leadForm.controls.firstName.touched
                  "
                >
                  Name is required
                </form-error>
              </form-field>

              <!-- Surname -->
              <form-field>
                <label class="required">Surname</label>
                <input placeholder="Surname" formControlName="lastName" />
                <form-error
                  *ngIf="
                    leadForm.controls.lastName.invalid &&
                    leadForm.controls.lastName.touched
                  "
                >
                  Surname is required
                </form-error>
              </form-field>

              <!-- Email Address -->
              <form-field>
                <label class="required">Email address</label>
                <input
                  placeholder="Email Address"
                  formControlName="emailAddress"
                />
                <form-error
                  *ngIf="
                    leadForm.controls.emailAddress.invalid &&
                    leadForm.controls.emailAddress.touched
                  "
                >
                  Email address is required
                </form-error>
              </form-field>

              <!-- Contact Number -->
              <form-field>
                <label class="required">Contact number</label>
                <input
                  placeholder="Contact Number"
                  formControlName="cellNumber"
                />
                <form-error
                  *ngIf="
                    leadForm.controls.cellNumber.invalid &&
                    leadForm.controls.cellNumber.touched
                  "
                >
                  Contact Number is required
                </form-error>
              </form-field>

              <!-- ID Number -->
              <form-field>
                <label class="">ID number</label>
                <input placeholder="ID Number" formControlName="idNumber" />
                <form-error
                  *ngIf="
                    leadForm.controls.idNumber.invalid &&
                    leadForm.controls.idNumber.touched
                  "
                >
                  ID number is required
                </form-error>
              </form-field>

              <!-- Province -->
              <form-field>
                <label class="">Province</label>
                <dropdown-select>
                  <select formControlName="province">
                    <option value="" disabled>Province</option>
                    <option value="Gauteng">Gauteng</option>
                    <option value="Western Cape">Western Cape</option>
                    <option value="Kwa-Zulu Natal">Kwa-Zulu Natal</option>
                    <option value="North-West">North-West</option>
                    <option value="Northern Cape">Northern Cape</option>
                    <option value="Mpumalanga">Mpumalanga</option>
                    <option value="Limpopo">Limpopo</option>
                    <option value="Free State">Free State</option>
                    <option value="Eastern Cape">Eastern Cape</option>
                  </select>
                </dropdown-select>
                <form-error
                  *ngIf="
                    leadForm.controls.province.invalid &&
                    leadForm.controls.province.touched
                  "
                >
                  Province is required
                </form-error>
              </form-field>

              <!-- Closest City/Town -->
              <form-field>
                <autocomplete-dropdown
                  [formGroup]="leadForm"
                  name="closestCityTown"
                  placeholder="Closest City/Town"
                  label="Closest city/town"
                  [items]="filteredCities"
                >
                </autocomplete-dropdown>
                <form-error
                  *ngIf="
                    leadForm.controls.closestCityTown.invalid &&
                    leadForm.controls.closestCityTown.touched
                  "
                >
                  Closest city/town is required
                </form-error>
              </form-field>

              <!-- Message -->
              <form-field>
                <label class="">Message</label>
                <textarea
                  maxlength="80"
                  placeholder="Message"
                  formControlName="message"
                ></textarea>
                <form-error
                  *ngIf="
                    leadForm.controls.message.invalid &&
                    leadForm.controls.message.touched
                  "
                >
                  Message is required
                </form-error>
              </form-field>

              <!-- Recaptcha -->
              <re-captcha
                (resolved)="resolved($event)"
                siteKey="6LfnAeIZAAAAAB_pV7XDUG_l6SgpK5QjtDQsk9wg"
              ></re-captcha>
            </div>
          </div>
        </div>
        <!-- / modal body -->

        <!-- modal footer expanded-->
        <div class="modal-footer expanded">
          <button
            id="closeModal"
            hidden
            type="button"
            class="button outline"
            data-dismiss="modal"
          >
            Close
          </button>
          <button-text
            buttonStyle="solid"
            [label]="'Request Advice'"
            fill="true"
            (click)="Save()"
            [disabled]="isLoading"
          >
            <span
              class="spinner-grow spinner-grow-sm mr-1"
              role="status"
              aria-hidden="true"
              *ngIf="isLoading"
            ></span>
            Request advice
          </button-text>
        </div>
        <!-- / modal footer -->
      </form>
    </div>
    <!-- / modal container -->
  </div>
</div>

<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  data-keyboard="false"
  aria-hidden="true"
  id="email-response-modal"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content text-center">
      <div class="modal-header expanded">
        <p class="modal-heading text-center">
          {{ emailResponseMessage }}
        </p>
      </div>
      <div class="modal-footer expanded pt-0">
        <button type="button" class="btn btn-primary" data-dismiss="modal">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
