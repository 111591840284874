import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { CompareInvestmentProductsPage } from "src/app/models/content/save-and-invest/compare-investment-products-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-compare-investment-products",
  templateUrl: "./compare-investment-products.component.html",
})
export class CompareInvestmentProductsComponent implements OnInit {
  public content: any = {};

  selectedItemsList = [];
  checkedIDs = [];
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<CompareInvestmentProductsPage>(
        pageService.URIs.compareInvestmentProducts
      )
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new CompareInvestmentProductsPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }

  ngOnInit(): void {
    this.fetchSelectedItems();
    this.fetchCheckedIDs();
    this.seoService.setJsonLd(this.renderer, null);
  }

  public changeSelection() {
    this.fetchSelectedItems();
  }

  public fetchSelectedItems() {
    if (this.content.body === undefined || this.content.body === null) {
    } else {
      this.selectedItemsList = this.content.body.options.filter(
        (value, index) => {
          return value.isChecked;
        }
      );
    }
  }

  public fetchCheckedIDs() {
    this.checkedIDs = [];
    if (this.content.body === undefined || this.content.body === null) {
    } else {
      this.content.body.options.forEach((value, index) => {
        if (value.isChecked) {
          this.checkedIDs.push(value.name);
        }
      });
    }
  }
}
