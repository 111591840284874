import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { SEO_FOR_PAGES } from "src/app/models/SEO/seo-page-script";
import { InsuranceLandingPage } from "src/app/models/content/insurance/landing-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
@Component({
  selector: "app-insurance",
  templateUrl: "./insurance.component.html",
  styles: [],
})
export class InsuranceComponent implements OnInit, OnDestroy {
  public content: any = {};
  public isPageLoading: boolean = true;

  //dynamic script
  JSON_LD_DATA: string = SEO_FOR_PAGES.insurance_script;

  constructor(
    public pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<InsuranceLandingPage>(pageService.URIs.insurance)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new InsuranceLandingPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, this.JSON_LD_DATA);
  }

  ngOnDestroy() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
