import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-previous-publications',
  templateUrl: './previous-publications.component.html',
  styleUrls: []
})
export class PreviousPublicationsComponent implements OnInit {
  @Input() previousPublications: any;

  constructor(public pageService: PageService,
    private router: Router) { }

  ngOnInit(): void {
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public OpenArticle(article: string) {
    this.pageService.SetArticle(article); 
  }

}
