import { Content } from "@angular/compiler/src/render3/r3_ast";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-about-section",
  templateUrl: "./about-section.component.html",
  styles: [],
})
export class AboutSectionComponent implements OnInit {
  @Input("content") content: any;

  constructor(private router: Router) { }

  ngOnInit() { }

  public NavigateToFindAdviser() {
    this.router.navigate(["/financial-advice/find-an-adviser"]);
  }

  afrikkaansDoc(action: string) {
    window.open(action, "_blank");
  }

  englishDoc(action: string) {
    window.open(action, "_blank");
  }

  DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }
}
