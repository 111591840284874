import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { ResearchHubReportsLandingPage } from 'src/app/models/content/news-and-publications/research-hub-reports.model';
import { PageService } from 'src/app/services/page.service';
import { SeoService } from 'src/app/services/socialLinks.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
})
export class ReportsComponent implements OnInit {
  public report: string;
  public avoidingMistakesContent: any = {};
  public content: any = {};
  public faqList: any = {};
  public isPageLoading: boolean = true;
  public keywords: any;
  public selectedNewsletter: any = [];
  public shareHeadline: string;

  constructor(
    public pageService: PageService,
    private route: ActivatedRoute,
    private router: Router,
    public seoService: SeoService
  ) {
    this.route.params.subscribe((params: Params) => {
      this.report = params.report;

      pageService
        .GetPage<ResearchHubReportsLandingPage>(
          pageService.URIs.researchHubReportBase + this.report
        )
        .pipe(
          finalize(() => {
            this.isPageLoading = false;
          })
        )
        .subscribe((content) => {
          this.content = new ResearchHubReportsLandingPage(content);
          this.shareHeadline = this.content?.header?.headline?.replace(
            "&",
            "%26"
          );

          this.toggleNewsletterContent(
            this.content.body.sections[0].newsletter[0].heading
          );

          this.avoidingMistakesContent = content.body.sections[1];
          this.faqList = content.body.sections[2];

          this.seoService.updateMetaInfoForPage(
            this.content.seo.title,
            window.location.href,
            this.content?.header?.image,
            this.content.seo.description
          );
        });
    });
  }

  ngOnInit(): void { }

  public AccessCompleteNewsletter(url: string) {
    window.open(url, "_blank");
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public NavigateToSearch() {
    this.router.navigateByUrl("/search-results");
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToMail(heading: string) {
    let shareHeadline = heading.replace("&", "%26");

    window.open(
      `mailto:?subject=${shareHeadline}&body=` + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }

  toggleNewsletterContent(keyword: string) {
    let newsletters = this.content.body.sections[0].newsletter;

    this.keywords = newsletters.map((k) => {
      return {
        label: k.heading,
        selected: k.heading === keyword,
      };
    });

    this.selectedNewsletter = newsletters.filter((newsletter) => {
      return newsletter.heading === keyword;
    });
  }
}
