import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { finalize } from "rxjs";
import { MarketsManualLandingPage } from "src/app/models/content/news-and-publications/markets-manual.model";
import { MonthlyEvents } from "src/app/models/content/news-and-publications/week-ahead-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
declare var $: any;

@Component({
  selector: "app-markets-manual",
  templateUrl: "./markets-manual.component.html",
  styleUrls: [],
})
export class MarketsManualComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;
  public currentMonth: string;
  public eventsCalendar: any;
  public shareHeadline: string;

  constructor(
    public pageService: PageService,
    private router: Router,
    public seoService: SeoService
  ) {

    this.currentMonth = moment(new Date()).format("MMMM");

    pageService
      .GetPage<MarketsManualLandingPage>(pageService.URIs.marketsManual)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new MarketsManualLandingPage(content);
        this.shareHeadline = this.content.header.heading;

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });

    pageService
      .GetAllCalendarEvents<MonthlyEvents>(pageService.URIs.monthlyCalendar)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.eventsCalendar = pageService.GetCurrentEvents(content?.events, 'week');
      });
  }

  ngOnInit(): void { }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  private GetImageSrc() {
    return $('.market-manual-carousel-item.active > img')[0].src;
  }

  public NavigateToSearch() {
    this.router.navigateByUrl("/search-results");
  }

  public PrintSlide() {
    var win = window.open(this.GetImageSrc());
    win.print();
    win.focus();
  }

  public RouteToWeekAhead() {
    this.router.navigateByUrl("research-hub/week-ahead");
  }

  shareToFb() {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${this.GetImageSrc()}`
    );
  }

  shareToTwitter() {
    window.open(
      `https://twitter.com/intent/tweet?text=${this.GetImageSrc()}`
    );
  }

  shareToLinkedIn() {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${this.GetImageSrc()}`
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=${this.GetImageSrc()}`
    );
  }

  shareToWhatsApp() {
    window.open(`https://api.whatsapp.com/send?text=${this.GetImageSrc()}`);
  }

  public ViewSlide() {
    window.open(this.GetImageSrc(), '_blank');
  }
}
