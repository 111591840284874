<div class="container-fluid my-5">
    <div class="container-xl">
        <div class="row">
            <div class="col-md-12">
                <h2 class="mb-4">
                    {{latestReports.heading}}
                </h2>
            </div>
        </div>
        <div *ngIf="keywords > 0 || keywords != null" 
              class="row">
            <div class="col-md-12 text-right">
                <button-text
                    *ngFor="let keyword of keywords"
                    class="mr-2"
                    [buttonStyle]="[keyword.selected ? 'solid' : 'outline', 'mb-2 ']"
                    [label]="keyword.label"
                    (click)="FilterReports(keyword)">
                </button-text>
            </div>
        </div>
        <div class="row d-flex">
            <div class="col-md-6 col-xs-12 my-4" 
                *ngFor="let report of (latestReports.reports | latestReportFilter:selectedKeywords)">
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-3">
                        <img
                        alt="image"
                        [src]="report.image"
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <h5 class="small-heading mb-3">
                            {{ report.heading }}
                        </h5>
                        <p class="body-small" [innerHTML]="report.content"></p>
                        <button-text
                            class="mr-2"
                            buttonStyle="solid"
                            label="Read more"
                            (click)="OpenReport(report)">
                        </button-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>