<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- business-investment-services hero section -->
<div
  class="container-fluid business-investment-services"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
      <div class="pl-4 pl-md-4">
        <div
          [innerHTML]="content?.header?.content"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
      </div>
      <div class="pl-4 pl-md-4">
        <button-text
          *ngFor="let action of content?.header?.actions"
          buttonStyle="{{
            action?.buttonColor
              ? 'solid ' + action?.buttonColor
              : action.isPrimary
              ? 'solid'
              : 'outline inverse'
          }}"
          [label]="action?.label"
          (click)="DynamicButtonClick(action)"
        >
        </button-text>
      </div>
    </div>
  </div>
</div>
<!--/ business-investment-services hero section -->

<!-- business-investment-services card -->
<div
  class="container-xl h-100 business-investment-services-card-MT"
  *ngIf="content.header !== undefined && !isPageLoading"
>
  <div class="row d-flex">
    <div class="col-md-7"></div>
    <div class="col-md-5" *ngIf="content.header.cards.length > 0">
      <div class="business-investment-services-card">
        <div>
          <h2 class="large-heading">{{ content.header.cards[0].heading }}</h2>
          <div [innerHTML]="content.header.cards[0].content"></div>
        </div>
        <div>
          <button-text
            *ngFor="let action of content.header.cards[0].actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ business-investment-services card -->

<!-- Retirement fund -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 svg-bg-l retirement-fund h-100"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl h-100">
    <div class="row d-flex align-items-center">
      <div class="col-md-7">
        <img
          alt=""
          class="img-cover significant-raise"
          [src]="content?.body?.sections[0]?.image"
        />
      </div>
      <div class="col-md-4 offset-md-1">
        <div
          [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
        ></div>
        <ng-container
          *ngFor="
            let action of content?.body?.sections[0]?.sections[0]?.actions
          "
        >
          <button-text
            class="{{ action.isPrimary ? '' : 'mr-2' }}"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="container-xl py-5 my-5 h-100">
    <div class="row h-100">
      <div
        class="col-md-4 mb-4"
        *ngFor="let card of content?.body?.sections[0]?.sections[1]?.cards"
      >
        <div class="retirement-fund-card">
          <h4>{{ card?.heading }}</h4>
          <div [innerHTML]="card?.content"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ Retirement fund -->
