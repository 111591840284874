export class SensArticle{
  constructor(record:any){
    this.Title = (<Array<any>>record.field).find(x => x.$.name == "Title").$.value;
    this.DateStamp = this.formatDate((<Array<any>>record.field).find(x => x.$.name == "date_stamp").$.value);
    this.TimeStamp = (<Array<any>>record.field).find(x => x.$.name == "time_stamp").$.value;
    this.Article = (<Array<any>>record.field).find(x => x.$.name == "article").$.value;
    this.ArticleType = (<Array<any>>record.field).find(x => x.$.name == "article_type").$.value;
    this.Url = record.url[0];
  }

  public Title:string;
  public DateStamp:Date;
  public TimeStamp:string;
  public Article:string;
  public ArticleType:string;
  public Url:string;

  private formatDate(date:string):Date{
    var dateParts = date.split('/');

    var newDate = new Date(`${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`)

    return newDate;

  }
}

export class SharePriceFeed{
    constructor(tradeData:any){
      this.JseCode = tradeData.feed.$.jsecode;
      this.CompanyName = tradeData.feed.$.companyname;
      this.Date = tradeData.feed.Intraday[0].Date[0];
      this.Description = tradeData.feed.Intraday[0].Description[0];
      this.Trades = new Array<Trade>();
      if (tradeData.feed.Intraday[0].Trades[0] !== "No trades yet"){
        (<Array<any>>tradeData.feed.Intraday[0].Trades[0].Trade).forEach(x => {
          this.Trades.push(new Trade(x));
        });
      }


    }

    public JseCode:string;
    public CompanyName:string;
    public Date:string;
    public Description:string;
    public Trades:Array<Trade>;
}

export class Trade{
  constructor(trade:any){
    this.Price = trade.Price[0];
    this.Time = trade.Time[0];
    this.Volume = trade.Volume[0];
  }

  Price:string;
  Time:string;
  Volume:string;

}

export class GraphData{
  constructor(price:any){
    this.JseCode = price.$.jsecode;
    this.Name = price.$.name;
    this.LastUpdated = new Array<string>();

    if (price.LastUpdated != undefined){
      (<Array<string>>price.LastUpdated ).forEach(x => {
        this.LastUpdated.push(x.substring(10,x.length));
        this.DateUpdated = x.substring(0,10)
      })
    }
    this.LastPrice = new Array<number>();
    if (price.LastPrice != undefined){
      (<Array<string>>price.LastPrice).forEach(x => {
        this.LastPrice.push(Number(x.replace('c','')))
      })
    }
    this.Volume = new Array<number>();
    if (price.Volume != undefined){
      (<Array<number>>price.Volume).forEach(x => {
        this.Volume.push(Number(x));
      })
    }


  }

  public JseCode:string;
  public Name:string;
  public LastPrice:Array<number>;
  public LastUpdated:Array<string>;
  public DateUpdated: string;
  public Volume:Array<number>;


}
