import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-subscriptions',
  templateUrl: './lead-subscriptions.component.html'
})
export class LeadSubscriptionsComponent implements OnInit {

  url = 'https://forms.office.com/r/gHQte6vLxh';

  constructor() { }

  ngOnInit() {
  }

}
