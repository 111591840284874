<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  class="container-fluid forms-and-factSheet--hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div class="d-flex flex-column align-content-start h-100">
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="section-title--mobile text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
      <div class="pl-3 pl-md-4">
        <div
          [innerHTML]="content?.header?.excerpt"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
      </div>
    </div>
  </div>
</div>
<!-- cards -->
<div *ngIf="!isPageLoading" class="container-xl forms-and-factSheet--card-row">
  <div class="row">
    <div class="col-md-4 mb-4">
      <div class="blur-card d-flex flex-column justify-content-between h-100">
        <div class="">
          <h2 class="medium-heading">
            {{ content?.header?.cards[0]?.heading }}
          </h2>
          <p [innerHTML]="content?.header?.cards[0]?.content"></p>
        </div>
        <div>
          <ng-container
            *ngFor="let action of content?.header?.cards[0]?.actions"
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-md-4 mb-4">
      <div class="blur-card d-flex flex-column justify-content-between h-100">
        <div class="">
          <h2 class="medium-heading">
            {{ content?.header?.cards[1]?.heading }}
          </h2>
          <p [innerHTML]="content?.header?.cards[1]?.content"></p>
        </div>
        <div>
          <ng-container
            *ngFor="let action of content?.header?.cards[1]?.actions"
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-md-4 mb-4">
      <div class="blur-card d-flex flex-column justify-content-between h-100">
        <div class="">
          <h2 class="medium-heading">
            {{ content?.header?.cards[2]?.heading }}
          </h2>
          <p [innerHTML]="content?.header?.cards[2]?.content"></p>
        </div>
        <div>
          <ng-container
            *ngFor="let action of content?.header?.cards[2]?.actions"
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ hero section -->

<!-- Wealth -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left">
          <h2 class="large-heading">
            <a id="wealth"></a>{{ content?.body?.divisions[0]?.name }}
          </h2>
        </div>
      </div>
    </div>

    <!-- Wealth Facts Sheets local & global-->

    <div class="row mt-5" id="wealth-trading-and-investment-forms">
      <div class="col-md-12">
        <div>
          <h3 class="small-heading mb-3 pl-1">Trading and Investment Forms:</h3>
        </div>
      </div>
      <div
        class="col-md-6"
        *ngFor="let category of content?.body?.divisions[0]?.forms.categories"
      >
        <!-- acccordion -->
        <div
          class="accordion"
          [id]="'accordionwealthLocal' + removeWhiteSpace(category.name)"
        >
          <div class="accordian-inner">
            <div [id]="'wealthLocal' + removeWhiteSpace(category.name)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapsewealthLocal' + removeWhiteSpace(category.name)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapsewealthLocal' + removeWhiteSpace(category.name)
                  "
                >
                  {{ category.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapsewealthLocal' + removeWhiteSpace(category.name)"
              class="collapse"
              [attr.aria-labelledby]="
                'wealthLocal' + removeWhiteSpace(category.name)
              "
              [attr.data-parent]="
                '#accordionwealthLocal' + removeWhiteSpace(category.name)
              "
            >
              <div class="accordian-body">
                <ul *ngFor="let download of category.downloads">
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /acccordion -->
      </div>
    </div>

    <!-- /Wealth Facts Sheets local & global-->

    <!-- Trading and Investment Forms:-->

    <div class="row mt-5" id="wealth-fact-sheets">
      <div class="col-md-12">
        <div>
          <h3 class="small-heading mb-3 pl-1">Fact Sheets:</h3>
        </div>
      </div>
      <div
        class="col-md-6"
        *ngFor="
          let category of content?.body?.divisions[0]?.factSheets.categories
        "
      >
        <!-- acccordion -->
        <div
          class="accordion"
          [id]="'accordionwealthLocal' + removeWhiteSpace(category.name)"
        >
          <div class="accordian-inner">
            <div [id]="'wealthLocal' + removeWhiteSpace(category.name)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapsewealthLocal' + removeWhiteSpace(category.name)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapsewealthLocal' + removeWhiteSpace(category.name)
                  "
                >
                  {{ category.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapsewealthLocal' + removeWhiteSpace(category.name)"
              class="collapse"
              [attr.aria-labelledby]="
                'wealthLocal' + removeWhiteSpace(category.name)
              "
              [attr.data-parent]="
                '#accordionwealthLocal' + removeWhiteSpace(category.name)
              "
            >
              <div class="accordian-body">
                <ul *ngFor="let download of category.downloads">
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /acccordion -->
      </div>

      <!-- /Trading and Investment Forms:-->
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button-text
          buttonStyle="outline"
          label="Local quarterly commentary"
          (click)="onLocal()"
        >
        </button-text>
      </div>
      <div class="col-6">
        <button-text
          buttonStyle="outline"
          label="Offshore quarterly commentary"
          (click)="onGlobal()"
        >
        </button-text>
      </div>
    </div>
  </div>
</div>
<!-- Wealth -->

<!-- Asset Management -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left">
          <h2 class="large-heading">
            <a id="asset-management"></a>{{ content?.body?.divisions[1]?.name }}
          </h2>
        </div>
      </div>
    </div>

    <!-- Asset Management Facts Sheets local & global-->
    <div class="row mt-5" id="asset-management-trading-and-investment-forms">
      <div class="col-md-12">
        <div>
          <h3 class="small-heading mb-3 pl-1">Trading and Investment Forms:</h3>
        </div>
      </div>
      <div
        class="col-md-6"
        *ngFor="let category of content?.body?.divisions[1]?.forms.categories"
      >
        <!-- acccordion -->
        <div
          class="accordion"
          [id]="'accordionwealthForm' + removeWhiteSpace(category.name)"
        >
          <div class="accordian-inner">
            <div [id]="'wealthForm' + removeWhiteSpace(category.name)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapsewealthForm' + removeWhiteSpace(category.name)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapsewealthForm' + removeWhiteSpace(category.name)
                  "
                >
                  {{ category.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapsewealthForm' + removeWhiteSpace(category.name)"
              class="collapse"
              [attr.aria-labelledby]="
                'wealthForm' + removeWhiteSpace(category.name)
              "
              [attr.data-parent]="
                '#accordionwealthForm' + removeWhiteSpace(category.name)
              "
            >
              <div class="accordian-body">
                <ul *ngFor="let download of category.downloads">
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /acccordion -->
      </div>
    </div>
    <!-- /Asset Management Facts Sheets local & global-->

    <!-- Trading and Investment Forms:-->

    <div class="row mt-5" id="asset-management-fact-sheets">
      <div class="col-md-12">
        <div>
          <h3 class="small-heading mb-3 pl-1">Fact Sheets:</h3>
        </div>
      </div>

      <!-- left col -->
      <div
        class="col-md-6"
        *ngFor="
          let category of content?.body?.divisions[1]?.factSheets.categories
        "
      >
        <!-- acccordion -->
        <div
          class="accordion"
          [id]="'accordionwealthFact' + removeWhiteSpace(category.name)"
        >
          <div class="accordian-inner">
            <div [id]="'wealthFact' + removeWhiteSpace(category.name)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapsewealthFact' + removeWhiteSpace(category.name)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapsewealthFact' + removeWhiteSpace(category.name)
                  "
                >
                  {{ category.name }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapsewealthFact' + removeWhiteSpace(category.name)"
              class="collapse"
              [attr.aria-labelledby]="
                'wealthFact' + removeWhiteSpace(category.name)
              "
              [attr.data-parent]="
                '#accordionwealthFact' + removeWhiteSpace(category.name)
              "
            >
              <div class="accordian-body">
                <ul *ngFor="let download of category.downloads">
                  <li style="list-style: none">
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.url"
                      *ngIf="download?.url"
                      >{{ download?.label }}</a
                    >
                    <a
                      class="textlink"
                      target="_blank"
                      [href]="download?.file"
                      *ngIf="download?.file"
                      >{{ download?.label }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /acccordion -->
      </div>

      <!-- /Trading and Investment Forms:-->
    </div>
  </div>
</div>
<!-- /Asset Management -->
