<div class="container-fluid py-5 my-5 h-100">
    <div class="container-xl">
        <div class="row ">
            <div class="col-md-12">
                <div class="container align-items-center">

                    <div class="col-sm-12 col-md-6 offset-md-3">
                        <h2 class="mb-3 d-none d-lg-block">Search PSG</h2>
                        <h4 class="mb-3 d-lg-none">Search PSG</h4>
                        <!-- Basic Input -->
                        <form-field>
                            <icon-font name="icon-search" class="suffix"></icon-font>
                            <input id="searchInput" [(ngModel)]="searchValue" (ngModelChange)="onSearchChanged($event)"
                                placeholder="Input placeholder">
                        </form-field>
                    </div>

                    <div class="col-sm-12 col-md-6 offset-md-3 search-container">
                        <div *ngIf="searchValue  && searchedItems[0].category == 'none'" class="mt-5 text-align-center">
                            <h5>No Results for {{ searchValue }}</h5>
                            <p class="slate">Keep Searching</p>
                        </div>


                        <!-- Search Financial advice -->
                        <div *ngIf="searchContainsGettingStarted" [routerLink]="['/financial-advice/overview']"
                            (click)="toggleSearch('search')">
                            <h6>Product page with Invest.</h6>
                            <hr class="cyan-bg mt-n1">
                        </div>
                        <div *ngFor="let searchItem of searchedItems">
                            <div class="search-item">
                                <a [routerLink]="[searchItem.routerLink]" (click)="toggleSearch('search')"
                                    *ngIf="searchItem.category=='Product'">
                                    <h4 class="mt-2" innerHTML="{{searchItem.heading | highlight : searchValue}}">
                                    </h4>
                                    <p class="search-paragraph" innerHTML="...{{searchItem.text | highlight : searchValue}}"
                                        *ngIf="searchItem.text!='none'"></p>
                                </a>
                            </div>
                        </div>
                        <!--/Search Financial advice -->


                        <!-- Search Funds -->
                        <div *ngIf="searchContainsBrand" [routerLink]="['/Funds']" (click)="toggleSearch('search')">
                            <h6>Fund to Invest in.</h6>
                            <hr class="red-bg mt-n1">
                        </div>
                        <div *ngFor="let searchItem of searchedItems">
                            <div class="search-item">
                                <a [routerLink]="[searchItem.routerLink]" (click)="toggleSearch('search')"
                                    *ngIf="searchItem.category=='Funds'">
                                    <h4 class="mt-2" innerHTML="{{searchItem.heading | highlight : searchValue}}">
                                    </h4>
                                    <p class="search-paragraph" innerHTML="...{{searchItem.text | highlight : searchValue}}"
                                        *ngIf="searchItem.text!='none'"></p>
                                </a>
                            </div>
                        </div>


                        <!-- Search Support -->
                        <div *ngIf="searchContainsFoundation" [routerLink]="['/Support']" (click)="toggleSearch('search')">
                            <h6>Support.</h6>
                            <hr class="yellow-bg mt-n1">
                        </div>
                        <div *ngFor="let searchItem of searchedItems">
                            <div class="search-item">
                                <a [routerLink]="[searchItem.routerLink]" (click)="toggleSearch('search')"
                                    *ngIf="searchItem.category=='Support'">
                                    <h4 class="mt-2" innerHTML="{{searchItem.heading | highlight : searchValue}}">
                                    </h4>
                                    <p class="search-paragraph" innerHTML="...{{searchItem.text | highlight : searchValue}}"
                                        *ngIf="searchItem.text!='none'"></p>
                                </a>
                            </div>
                        </div>


                        <!-- Search other -->
                        <div *ngIf="searchContainsComponent" [routerLink]="['/home']" (click)="toggleSearch('search')">
                            <h6>other</h6>
                            <hr class="green-bg mt-n1">
                        </div>
                        <div *ngFor="let searchItem of searchedItems">
                            <div class="search-item">
                                <a [routerLink]="[searchItem.routerLink]" (click)="toggleSearch('search')"
                                    *ngIf="searchItem.category=='other'">
                                    <h4 class="mt-2" innerHTML="{{searchItem.heading | highlight : searchValue}}">
                                    </h4>
                                    <p class="search-paragraph" innerHTML="...{{searchItem.text | highlight : searchValue}}"
                                        *ngIf="searchItem.text!='none'"></p>
                                </a>
                            </div>
                        </div>


                        <!-- Search Navigation Items -->
                        <div *ngIf="searchContainsNavigation" [routerLink]="['/navigation']"
                            (click)="toggleSearch('search')">
                            <h6>Navigation</h6>
                            <hr class="purple-bg mt-n1">
                        </div>
                        <div *ngFor="let searchItem of searchedItems">
                            <div class="search-item">
                                <a [routerLink]="[searchItem.routerLink]" (click)="toggleSearch('search')"
                                    *ngIf="searchItem.category=='Navigation'">
                                    <h4 class="mt-2" innerHTML="{{searchItem.heading | highlight : searchValue}}">
                                    </h4>
                                    <p class="search-paragraph" innerHTML="...{{searchItem.text | highlight : searchValue}}"
                                        *ngIf="searchItem.text!='none'"></p>
                                </a>
                            </div>
                        </div>
                        <!-- Search Resources Items -->
                        <div *ngIf="searchContainsResources" [routerLink]="['/resources']" (click)="toggleSearch('search')">
                            <h6>Resources</h6>
                            <hr class="blue-bg mt-n1">
                        </div>
                        <div *ngFor="let searchItem of searchedItems" class="mb-2">
                            <div class="search-item">
                                <a [routerLink]="[searchItem.routerLink]" (click)="toggleSearch('search')"
                                    *ngIf="searchItem.category=='Resources'">
                                    <h4 class="mt-2" innerHTML="{{searchItem.heading | highlight : searchValue}}">
                                    </h4>
                                    <p class="search-paragraph" innerHTML="...{{searchItem.text | highlight : searchValue}}"
                                        *ngIf="searchItem.text!='none'"></p>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
