import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { EventsAndWebinarsPage } from "src/app/models/content/news-and-publications/events-and-webinars-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-events-and-webinars",
  templateUrl: "./events-and-webinars.component.html",
  styles: [],
})
export class EventsAndWebinarsComponent implements OnInit {
  public content: any = {};
  public shareHeadline: string;
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<EventsAndWebinarsPage>(pageService.URIs.eventsAndWebinars)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new EventsAndWebinarsPage(content);
        this.shareHeadline = this.content?.header?.heading?.replace("&", "%26"); // TODO: Temp workaround for & characters
        this.content.body.categories[0].events.sort((a, b) =>
          a.dateTime.localeCompare(b.dateTime)
        );
        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description,
          "article"
        );
        this.content.body.categories[0].events.sort((a, b) =>
          a.dateTime.localeCompare(b.dateTime)
        );
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }
}
