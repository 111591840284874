<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  class="container-fluid become-an-adviser--hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="d-flex flex-column align-content-start justify-content-md-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
      <div
        class="pl-4 pl-md-4 text-shadow"
        [innerHTML]="content?.header?.content"
        [ngClass]="
          content?.header?.contentColour
            ? content?.header?.contentColour
            : 'white'
        "
      ></div>
    </div>
  </div>
</div>
<div class="container-xl become-an-adviser--card-row">
  <div class="row">
    <div class="col-md-4 mb-4" *ngFor="let card of content?.header?.cards">
      <div class="blur-card d-flex flex-column justify-content-between h-100">
        <div class="">
          <h2 class="medium-heading">{{ card?.heading }}</h2>
          <div [innerHTML]="card?.content"></div>
        </div>
        <div>
          <button-text
            *ngFor="let action of card?.actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ hero section -->

<div
  *ngIf="
    content.body != null && content.body.sections.length > 0 && !isPageLoading
  "
>
  <!-- Individual advisers -->
  <div class="container-fluid mt-5 py-5 greybg-right">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content?.body?.sections[0]?.heading }}
            </h2>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-6 offset-lg-1 mb-4 order-0 order-md-1">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[0]?.image"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-5 py-md-5 order-1 order-md-0">
          <h3 class="medium-heading">
            {{ content?.body?.sections[0]?.sections[0]?.heading }}
          </h3>
          <div
            class="mt-4"
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Individual advisers -->

  <!-- Criteria for a short-term adviser -->
  <div class="container-fluid py-5 my-md-5 greybg-left">
    <div class="container-xl">
      <div class="row align-items-center">
        <div class="col-md-6 mb-4">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[1]?.image"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-5 offset-lg-1 py-md-5">
          <h3 class="medium-heading">
            {{ content?.body?.sections[1]?.sections[0]?.heading }}
          </h3>
          <div
            class="mt-4"
            [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Criteria for a short-term adviser -->

  <!-- Criteria for a stockbroker  -->
  <div class="container-fluid py-5 greybg-right">
    <div class="container-xl">
      <div class="row align-items-center">
        <div class="col-md-6 offset-lg-1 mb-4 order-0 order-md-1">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[2]?.image"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-5 py-md-5 order-1 order-md-0">
          <h3 class="medium-heading">
            {{ content?.body?.sections[2]?.sections[0]?.heading }}
          </h3>
          <div
            class="mt-4"
            [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Criteria for a stockbroker  -->

  <!-- Adviser offices -->
  <div class="container-fluid py-5 mt-5 greybg-left">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-left">
            <h2 class="mega-title--mobile">
              {{ content?.body?.sections[3]?.heading }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-5 offset-lg-5 col-md-8 offset-md-3">
          <div>
            <div
              [innerHTML]="content?.body?.sections[3]?.sections[0]?.content"
            ></div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-5 offset-lg-5 col-md-8 offset-md-3">
          <div>
            <h3 class="medium-heading">
              {{ content?.body?.sections[3]?.sections[1]?.heading }}
            </h3>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-lg-4 offset-lg-5 col-md-4 offset-md-3"
          *ngFor="let card of content?.body?.sections[3]?.sections[1]?.cards"
        >
          <div
            class="saving-card-bg-img"
            [style.backgroundImage]="'url(' + card?.image + ')'"
          >
            <div class="saving--card">
              <div class="d-flex flex-column">
                <p class="body-large body-bold white">{{ card?.heading }}</p>
                <div class="inner-content" [innerHTML]="card?.content"></div>
                <a
                  class="cyan"
                  href="tel:{{
                    content?.body?.sections[3].sections[1]?.cards[0]?.contact[0]
                      ?.contactNumber
                  }}"
                  >{{
                    content?.body?.sections[3].sections[1]?.cards[0]?.contact[0]
                      ?.contactNumber
                  }}</a
                >
                <a class="cyan">{{
                  content?.body?.sections[3]?.sections[1]?.cards[0]?.contact[0]
                    ?.emailAddress
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Shareholder communication -->
</div>
