<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!--  hero section -->
<div
  class="container-fluid investor-relations-hero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title home-page mb-1 text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
      <div class="pl-4 pl-md-4">
        <div
          [innerHTML]="content?.header?.content"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
      </div>
      <div class="pl-4 pl-md-4 btn-row">
        <ng-container *ngFor="let action of content?.header?.actions">
          <button-text
            class="{{ action.isPrimary ? '' : 'mr-2' }}"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline inverse'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- hero section -->

<div class="container-fluid py-5 slate-bg-10">
  <div class="container-xl">
    <div class="row align-items-center">
      <div class="col-md-6 p-0 mb-4 order-2 order-md-1">
        <div class="card-round extreme-raise white-bg px-4 py-5 p-md-5">
          <h2 class="large-heading">
            {{ content?.body?.annualResults?.heading }}
          </h2>
          <p class="body-large body-bold">
            {{ content?.body?.annualResults?.subheading }}
          </p>
          <div [innerHTML]="content?.body?.annualResults?.content"></div>
        </div>
      </div>
      <div class="col-md-6 col-lg-5 offset-lg-1 order-1 order-md-2">
        <div>
          <h2 class="mega-title--mobile">
            {{ content?.body?.financialHighlights?.heading }}
          </h2>
          <p
            class="body-med"
            [innerHTML]="content?.body?.financialHighlights?.content"
          ></p>
        </div>

        <div class="d-flex flex-row align-items-start justify-content-around">
          <div class="p-4">
            <p class="body-small body-bold mb-1">
              Recurring headline earnings per share
            </p>
            <p class="xlarge-heading cyan">
              {{ content?.body?.financialHighlights?.earningsPerShare }}
            </p>
          </div>

          <div class="p-4">
            <p class="body-small body-bold mb-1">Dividend per share</p>
            <p class="xlarge-heading cyan">
              {{ content?.body?.financialHighlights?.dividendPerShare }}
            </p>
          </div>
        </div>
        <div class="d-flex flex-row align-items-start justify-content-around">
          <div class="p-4">
            <p class="body-small body-bold mb-1">Assets under management</p>
            <p class="xlarge-heading cyan">
              {{ content?.body?.financialHighlights?.assetsUnderManagement }}
            </p>
          </div>

          <div class="p-4">
            <p class="body-small body-bold mb-1">Gross written premium</p>
            <p class="xlarge-heading cyan">
              {{ content?.body?.financialHighlights?.grossWrittenPremium }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- cards -->
<div class="container-fluid py-5 my-5">
  <div class="container-xl">
    <div class="row">
      <div
        class="col-md-4 col-sm-6 mb-4"
        *ngFor="let card of content?.body?.sections[0]?.cards"
      >
        <div
          class="card-flat investors-relations slight-raise d-flex flex-column flex-fill h-100 justify-content-between"
        >
          <h3>{{ card?.heading }}</h3>
          <div [innerHTML]="card?.content"></div>
          <div class="btn-row">
            <ng-container *ngFor="let action of card?.actions">
              <button-text
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /cards -->

<div class="container-fluid py-5 mb-5 greybg-right">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-9 offset-md-3">
        <div
          class="heading-border-right d-inline-flex align-items-end align-items-md-start"
        >
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[1]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 offset-md-3">
        <div
          [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
        ></div>
        <div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[1]?.sections[0]?.actions
            "
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- awards -->
<div class="container-fluid py-5 mt-5 greybg-left">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-6">
        <div class="heading-border-left d-inline-flex">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[2]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <h3 class="large-heading mb-4">
          {{ content?.body?.sections[2]?.sections[0]?.heading }}
        </h3>
        <div
          [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
        ></div>
        <div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[2]?.sections[0]?.actions
            "
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-7 offset-lg-5">
        <!-- PSG Wealth awards -->
        <div class="mt-5">
          <h3 class="large-heading mb-4">
            {{ content?.body?.sections[2]?.sections[1]?.heading }}
          </h3>
          <div
            [innerHTML]="content?.body?.sections[2]?.sections[1]?.content"
          ></div>
        </div>

        <!-- PSG Asset Management awards -->
        <div class="mt-5">
          <h3 class="large-heading mb-4">
            {{ content?.body?.sections[2]?.sections[2]?.heading }}
          </h3>
          <div
            [innerHTML]="content?.body?.sections[2]?.sections[2]?.content"
          ></div>
        </div>

        <!-- PSG Insure awards -->
        <div class="mt-5">
          <h3 class="large-heading mb-4">
            {{ content?.body?.sections[2]?.sections[3]?.heading }}
          </h3>
          <div
            [innerHTML]="content?.body?.sections[2]?.sections[3]?.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /awards -->

<!-- Recommended/Latest news-cards section -->
<div class="container-fluid py-5 slate-bg-10">
  <div class="container-xl">
    <div>
      <h2 class="mb-4">{{ content?.body?.sections[3]?.heading }}</h2>
    </div>
    <div class="card-deck">
      <div
        class="card news--card"
        *ngFor="let news of content?.body?.sections[3]?.articles"
      >
        <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
        <div class="card-body">
          <div class="card-tags pb-2">
            <span
              *ngFor="let category of news?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div>
          <h5 class="small-heading">{{ news?.headline }}</h5>
          <p class="body-small" [innerHTML]="news?.excerpt"></p>
          <a [routerLink]="news?.uri" class="textlink">Read more</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Recommended/Latest news-cards section -->
