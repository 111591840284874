export class RetirementCalculator {
    public static readonly inflation: number = 0.05; //inflation
    public static readonly cont_incr: number = 0.06; //Retirement contribution increases p.a.
    public static readonly sal_gro: number = 0.06; //Pension/salary payment increases p.a
    public static readonly d_age: string = "n/a";
    public static readonly comp: number = 1;
    public static readonly T: number = 1;

    RetirementCalculator() { }
}
