import { AfterViewInit, Component, OnInit, Renderer2 } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize } from "rxjs";
import { Campaign } from "src/app/models/content/campaign/campaign.models";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-campaign-v2",
  templateUrl: "./campaign-v2.component.html",
})
export class CampaignV2Component implements OnInit, AfterViewInit {
  public fragment: string;
  public content: any = {};
  public shareHeadline: string;
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    this.pageService
      .GetCampaign("campaign-2")
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new Campaign(content);
        this.shareHeadline = this.content.header?.headline?.replace("&", "%26"); // TODO: Temp workaround for & characters
        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });

    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
    });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  public sanitizeURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngAfterViewInit() {
    this.scrollTimer();
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public async scrollTimer() {
    setTimeout(() => {
      let doc = document.getElementById(this.fragment);
      if (doc != undefined) {
        window.scrollTo(0, doc.offsetTop - 120);
      } else {
        this.scrollTimer();
      }
    }, 1000);
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
        window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }
}
