import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { AllFundsPage } from "src/app/models/content/funds/all-funds-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
@Component({
  selector: "app-all-funds",
  templateUrl: "./all-funds.component.html",
  styles: [],
})
export class AllFundsComponent implements OnInit {
  public content: any = {};

  public pageTabs = ["Overview", "Price and performance"];
  public currentTab = "Overview";
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<AllFundsPage>(pageService.URIs.allFunds)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new AllFundsPage(content);
        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }
}
