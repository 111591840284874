<div class="container-fluid py-5 slate-bg-10">
  <div class="container-xl">
    <div>
      <h2 class="mb-4">
        {{ content?.body?.infoCards[contentIndex]?.heading }}
      </h2>
    </div>
    <div
      class="card-deck"
      *ngIf="content?.body?.infoCards[contentIndex]?.cards"
    >
      <div
        class="card news--card-calculators"
        *ngFor="let card of content.body.infoCards[contentIndex].cards"
      >
        <img
          *ngIf="card.image"
          class="card-img-top"
          src="{{ card?.image }}"
          alt="Card image cap"
        />
        <div class="card-body">
          <div class="card-tags pb-2"></div>
          <h5 class="small-heading">{{ card?.heading }}</h5>
          <p class="calculators body-small" [innerHTML]="card?.content"></p>
          <ul class="cards-list">
            <li *ngFor="let action of card?.actions">
              <a class="textlink" href="{{ action?.url }}" target="_blank">{{
                action?.label
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
