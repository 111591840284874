import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dynamic-content-template',
    templateUrl: './dynamic-content-template.component.html',
})
export class DynamicContentTemplateComponent implements OnInit {
    @Input() public section: any = {};
    @Input() public isFromContentGroup: boolean = false;
    @Input() public index: number = 0;

    public currentItem: string;
    public itemTabs: string[] = [];

    public contentGroupSections: any[] = null;

    constructor(private router: Router) { }

    ngOnInit() { }

    public GetSectionType(section: any): string {
        switch (section.type) {
            case 'cards':
                return "Cards";
            case 'content':
                if (section.cards === null && section.actions !== null)
                    return "ContentAction";
                if (section.cards !== null && section.actions !== null)
                    return "ContentActionCards";
                if (section.cards !== null && section.actions === null)
                    if (section.cards[0].image !== null)
                        return "ContentImageCards";
                    else
                        return "ContentCards";
                else {
                    this.section.content = this.section.content.replace(/<p>/g, '<p class="p-length-sm">');
                    return "Content"
                }
            case 'contentGroup':
                if (this.contentGroupSections == null) {
                    var ret = JSON.parse(JSON.stringify(this.section.sections));
                    ret.splice(0, 1);
                    this.contentGroupSections = ret;
                }

                return "ContentGroup"
            case 'contentList':
                return "ContentList"
            case 'testimonials':
                return "Testimonials";
            case 'news':
                return "NewsFeed"
            case 'quote':
                return "Quote"
            case 'wizard':
                this.itemTabs = this.ItemTabs(section.items);
                this.section.content = this.section.content.replace(/<p>/g, '<p class="p-lg-1"">');

                if (this.currentItem == null)
                    this.currentItem = this.itemTabs[0];

                return "Wizard"
            default:
                return "Content"
        }
    }

    public DynamicButtonClick(action: any) {
        if (action.isExternal)
            window.location.replace(action.url);
        else
            this.router.navigateByUrl(action.url);
    }

    public IsLeft() {
        return (this.index) % 2 == 0;
    }

    public IsRight() {
        return !this.IsLeft();
    }

    public IsLeftFromIndex(i: number) {
        return (i) % 2 == 0;
    }

    public IsRightFromIndex(i: number) {
        return !this.IsLeftFromIndex(i);
    }

    public GetNameWithoutWhiteSpace(str: string) {
        return str.replace(/ /g,'');
    }

    public ItemTabs(items: any[]): string[] {
        var ret = [];
        items.forEach(x => {
            ret.push(x.name);
        });
        return ret;
    }
}
