<app-hero [content]="content?.header"></app-hero>

<app-about-section [content]="content"></app-about-section>

<div
  class="d-flex flex-wrap align-items-center justify-content-center mx-auto mb-5"
  *ngIf="content?.body?.actions?.length > 0"
>
  <ng-container *ngFor="let action of content?.body?.actions">
    <button
      type="button"
      class="button"
      ngClass="{{
        action?.buttonColor
          ? 'solid ' + action?.buttonColor
          : action.isPrimary
          ? 'solid'
          : 'outline'
      }} mr-2"
      (click)="DynamicButtonClick(action)"
      style="width: 240px"
    >
      {{ action?.label }}
    </button>
  </ng-container>
</div>

<app-info-cards-section
  [content]="content"
  [contentIndex]="0"
></app-info-cards-section>

<!--social media video-->
<div class="container-fluid greybg-right">
  <div class="container-xl my-5 py-5">
    <!--social icons-->
    <div class="row d-flex justify-content-end mb-2">
      <div class="p-2">
        <button class="button simple-icon" (click)="shareToMail()">
          <icon-font
            name="icon-envelope"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToLinkedIn()">
          <icon-font
            name="icon-linkedin"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToTwitter()">
          <icon-font
            name="icon-twitter"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToFb()">
          <icon-font
            name="icon-facebook"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToWhatsApp()">
          <icon-font
            name="icon-whatsapp"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
      </div>
    </div>
    <!--social icons-->
    <!-- video -->
    <div class="row d-flex justify-content-between">
      <div class="col-md-4">
        <h4 class="small-heading mb-2">
          {{ content?.body?.video_sections[0]?.heading }}
        </h4>
        <p [innerHTML]="content?.body?.video_sections[0]?.content"></p>
        <div class="row" *ngIf="content?.body?.videoActions?.length > 0">
          <div class="col-12 col-md-6">
            <button
              type="button"
              class="button mb-2 w-100"
              ngClass="{{
                content?.body?.videoActions[0]?.buttonColor
                  ? 'solid ' + content?.body?.videoActions[0]?.buttonColor
                  : content?.body?.videoActions[0]?.isPrimary
                  ? 'solid'
                  : 'outline'
              }} mr-2"
              (click)="DynamicButtonClick(content?.body?.videoActions[0])"
              *ngIf="content?.body?.videoActions[0]"
            >
              {{ content?.body?.videoActions[0]?.label }}
            </button>
          </div>
          <div class="col-12 col-md-6">
            <button
              type="button"
              class="button mb-2 w-100"
              ngClass="{{
                content?.body?.videoActions[1]?.buttonColor
                  ? 'solid ' + content?.body?.videoActions[1]?.buttonColor
                  : content?.body?.videoActions[1]?.isPrimary
                  ? 'solid'
                  : 'outline'
              }} mr-2"
              (click)="DynamicButtonClick(content?.body?.videoActions[1])"
              *ngIf="content?.body?.videoActions[1]"
            >
              {{ content?.body?.videoActions[1]?.label }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div
          class="embed-responsive embed-responsive-16by9 card-round significant-raise"
        >
          <iframe
            class="embed-responsive-item"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            [src]="content?.body?.video_sections[0]?.url | safe"
          ></iframe>
        </div>
      </div>
    </div>
    <!-- /video -->
  </div>
</div>
<!--social media video-->

<!-- social icons and video-->
<div class="container-fluid greybg-right">
  <div class="container-xl my-5 py-5">
    <!--social icons-->
    <div class="row d-flex justify-content-star offset-md-4 mb-2">
      <div class="col-md-12 offset-md-2">
        <div class="p-2">
          <button class="button simple-icon" (click)="shareToMail()">
            <icon-font
              name="icon-envelope"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToLinkedIn()">
            <icon-font
              name="icon-linkedin"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToTwitter()">
            <icon-font
              name="icon-twitter"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToFb()">
            <icon-font
              name="icon-facebook"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToWhatsApp()">
            <icon-font
              name="icon-whatsapp"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
        </div>
      </div>
    </div>
    <!--social icons-->
    <!-- video -->
    <div class="row d-flex justify-content-between">
      <div class="col-md-8">
        <div
          class="embed-responsive embed-responsive-16by9 card-round significant-raise"
        >
          <iframe
            id="second-video"
            class="embed-responsive-item"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            [src]="content?.body?.video_sections[1]?.url | safe"
          ></iframe>
        </div>
      </div>
      <div class="col-md-4">
        <h4 class="small-heading mb-2">
          {{ content?.body?.video_sections[1]?.heading }}
        </h4>
        <p [innerHTML]="content?.body?.video_sections[1]?.content"></p>
        <div class="row" *ngIf="content?.body?.videoActions?.length > 0">
          <div class="col-12 col-md-6">
            <button
              type="button"
              class="button mb-2 w-100"
              ngClass="{{
                content?.body?.videoActions[2]?.buttonColor
                  ? 'solid ' + content?.body?.videoActions[2]?.buttonColor
                  : content?.body?.videoActions[2]?.isPrimary
                  ? 'solid'
                  : 'outline'
              }} mr-2"
              (click)="DynamicButtonClick(content?.body?.videoActions[2])"
              *ngIf="content?.body?.videoActions[2]"
            >
              {{ content?.body?.videoActions[2]?.label }}
            </button>
          </div>
          <div class="col-12 col-md-6">
            <button
              type="button"
              class="button mb-2 w-100"
              ngClass="{{
                content?.body?.videoActions[3]?.buttonColor
                  ? 'solid ' + content?.body?.videoActions[3]?.buttonColor
                  : content?.body?.videoActions[3]?.isPrimary
                  ? 'solid'
                  : 'outline'
              }} mr-2"
              (click)="DynamicButtonClick(content?.body?.videoActions[3])"
              *ngIf="content?.body?.videoActions[3]"
            >
              {{ content?.body?.videoActions[3]?.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /video -->
  </div>
</div>
<!-- /social icons and video-->

<app-info-cards-section
  [content]="content"
  [contentIndex]="1"
></app-info-cards-section>

<!-- <app-info-cards-section
  [content]="content"
  [contentIndex]="2"
></app-info-cards-section>

<app-info-cards-section
  [content]="content"
  [contentIndex]="3"
></app-info-cards-section> -->
