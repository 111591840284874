<div class="container-fluid my-5">
    <div class="container-xl">
        <div class="row">
            <div class="col-12">
                <h2 class="mb-4" *ngIf="previousPublications != undefined">
                    {{ previousPublications.heading }}
                </h2>
              </div>
        </div>
        <div class="row d-flex my-3" *ngIf="previousPublications != undefined">
            <div class="card-deck"
                *ngIf="previousPublications?.articles?.length > 0">
                <div class="card news--card"
                    *ngFor="let article of previousPublications.articles">
                    <img class="card-img-top" [src]="article?.image" alt="Card image cap" />
                    <div class="card-body d-flex flex-column">
                        <div class="card-tags pb-2">
                            <span
                              *ngFor="let tag of article.categories"
                              role="button"
                              class="basic-badge mb-1"
                              data-toggle="modal"
                              data-target="#modal-article-tags"
                              (click)="OpenArticle(tag)"
                              >{{ tag }}</span
                            >
                          </div>
                        <h5 class="small-heading mb-3">
                            {{ article.headline }}
                        </h5>
                        <p class="body-small" [innerHTML]="article.excerpt"></p>
                        <a [routerLink]="article?.uri" class="textlink mt-auto">Read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>