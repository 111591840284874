<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div *ngIf="!isPageLoading" class="container-fluid zoomout-banner-m">
  <div
    class="container-fluid falcon-hero"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div class="heading-border-left col-11">
          <h1
            class="mega-title--mobile falconHeading text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
          <h3
            class="mt-4 large-heading text-shadow pl-2"
            [ngClass]="
              content?.header?.subheadingColour
                ? content?.header?.subheadingColour
                : 'white'
            "
          >
            {{ content?.header?.subheading }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /hero section -->

<!-- single card -->
<div *ngIf="!isPageLoading" class="container campaign-hero--cards">
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="blur-card" *ngFor="let card of content?.header?.cards">
        <p
          class="large-heading p-lg-4 p-md-3 text-center largeQuote"
          [innerHTML]="card?.content"
        ></p>
      </div>
    </div>
  </div>
</div>
<!-- /single cards -->

<!-- contact form -->
<div
  *ngIf="!isPageLoading"
  class="container my-5 py-5 h-100 zoomout-banner-m"
  style="top: 70px"
>
  <div class="container-xl py-4 campaign-hero--cards">
    <div class="col-md-10 offset-md-1">
      <div class="row m-1 justify-content-around blur-card card-blue-border">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <form [formGroup]="leadForm">
            <!-- Name -->
            <form-field>
              <label class="required">Name</label>
              <input
                formControlName="firstName"
                placeholder="Name"
                [ngClass]="{ 'is-invalid': formValidation.firstName.errors }"
              />
              <div
                *ngIf="
                  formValidation.firstName.errors &&
                  formValidation.firstName.touched
                "
                class="invalid-feedback"
              >
                <form-error *ngIf="formValidation.firstName.errors.required"
                  >First Name is required
                </form-error>
              </div>
            </form-field>

            <!-- Surname -->
            <form-field>
              <label class="">Surname</label>
              <input
                formControlName="surname"
                placeholder="Surname"
                [ngClass]="{ 'is-invalid': formValidation.surname.errors }"
              />
            </form-field>

            <!-- Contact Number -->
            <form-field>
              <label [class.required]="isContactNumberRequired" class="required"
                >Contact Number</label
              >
              <input
                formControlName="contactNumber"
                placeholder="Contact Number"
                [ngClass]="{
                  'is-invalid': formValidation.contactNumber.errors
                }"
              />
              <div
                *ngIf="
                  formValidation.contactNumber.errors &&
                  formValidation.contactNumber.touched
                "
                class="invalid-feedback"
              >
                <form-error *ngIf="formValidation.contactNumber.errors.required"
                  >Contact Number is required
                </form-error>
              </div>
            </form-field>

            <!-- Email Address -->
            <form-field>
              <label [class.required]="isEmailRequired" class="required"
                >Email Address</label
              >
              <input
                formControlName="email"
                placeholder="Email Address"
                [ngClass]="{ 'is-invalid': formValidation.email.errors }"
              />
              <div
                *ngIf="
                  formValidation.email.errors && formValidation.email.touched
                "
                class="invalid-feedback"
              >
                <form-error *ngIf="formValidation.email.errors.required"
                  >Email address is required
                </form-error>
                <form-error
                  *ngIf="
                    formValidation.email.errors.email ||
                    formValidation.email.errors?.pattern
                  "
                >
                  Email must be a valid email address
                </form-error>
              </div>
            </form-field>
          </form>
          <!-- Contact me button  -->
          <div class="row py-4 mt-4 card-blue-border">
            <div class="col-md-12">
              <button-check>
                <input
                  type="checkbox"
                  [(ngModel)]="isConsetCheckBoxChecked"
                  (change)="changeEvent($event)"
                />
                I allow PSG to contact me
              </button-check>
            </div>
            <div class="col-md-10 ml-2 mt-3">
              <button
                class="button solid"
                style="min-width: 10.6rem"
                [disabled]="!isConsetCheckBoxChecked"
                (click)="contactus()"
              >
                Contact me
                <img
                  style="height: 1px; width: 1px; display: none"
                  alt=""
                  src="https://px.ads.linkedin.com/collect/?pid=1334132&conversionId=5291412&fmt=gif"
                />
                <img
                  style="height: 1px; width: 1px; display: none"
                  alt=""
                  src="https://px.ads.linkedin.com/collect/?pid=1332788&conversionId=5257684&fmt=gif"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- contact form -->

<!-- social icons and video-->
<div class="container-fluid greybg-right">
  <div class="container-xl my-5 py-5">
    <!--social icons-->
    <div class="row d-flex justify-content-end mb-2">
      <div class="p-2">
        <button class="button simple-icon" (click)="shareToMail()">
          <icon-font
            name="icon-envelope"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToLinkedIn()">
          <icon-font
            name="icon-linkedin"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToTwitter()">
          <icon-font
            name="icon-twitter"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToFb()">
          <icon-font
            name="icon-facebook"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
        <button class="button simple-icon" (click)="shareToWhatsApp()">
          <icon-font
            name="icon-whatsapp"
            colour="cyan"
            class="body-large cyan"
          ></icon-font>
        </button>
      </div>
    </div>
    <!--social icons-->
    <!-- video -->
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div
          class="embed-responsive embed-responsive-16by9 card-round significant-raise"
        >
          <iframe
            class="embed-responsive-item"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <!-- /video -->
  </div>
</div>
<!-- /social icons and video-->

<!-- content-row -->
<div *ngIf="!isPageLoading" class="container-fluid bg--left p-0 h-100">
  <div class="container-xl my-5 py-5 h-100">
    <div class="row">
      <div class="col-lg-5 col-md-6 py-4">
        <h2 class="large-heading black">
          {{ content?.body?.sections[1]?.sections[0]?.heading }}
        </h2>
        <h4 class="small-heading black mb-2 mt-4">
          {{ content?.body?.sections[1]?.sections[0]?.subheading }}
        </h4>
        <p [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"></p>
      </div>
      <div class="col-lg-6 offset-lg-1 col-md-6 position-relative">
        <div class="blue-line col-md-3 d-none d-lg-block"></div>
        <img
          [src]="content?.body?.sections[1]?.image"
          class="img-cover significant-raise card-round"
          [ngStyle]="{ visibility: isPageLoading ? 'hidden' : 'visible' }"
        />
      </div>
    </div>
  </div>
</div>
<!-- /content-row -->

<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5 h-100">
  <div class="container-xl h-100">
    <div class="row d-flex align-items-center">
      <div class="col-md-6">
        <img
          alt=""
          class="img-cover"
          [src]="content?.body?.sections[3]?.image"
        />
      </div>
      <div class="col-md-5">
        <h4 class="large-heading black mb-2 mt-4">
          {{ content?.body?.sections[3]?.sections[0]?.heading }}
        </h4>
        <p
          [innerHTML]="content?.body?.sections[3]?.sections[0]?.subheading"
        ></p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container py-4" style="max-width: 930px">
  <h3 class="large-heading black text-center">
    {{ content?.body?.sections[2]?.heading }}
  </h3>
  <div class="row py-4">
    <div class="col-md-6">
      <div
        class="falcon-card-bg-img"
        [style.backgroundImage]="'url(' + card1_bg_Image + ')'"
      >
        <div class="falcon--card">
          <div>
            <h3 class="large-heading white">
              {{ content?.body?.sections[2]?.sections[0]?.cards[0]?.heading }}
            </h3>
            <p
              [innerHTML]="
                content?.body?.sections[2]?.sections[0]?.cards[0]?.content
              "
            ></p>
          </div>
          <div>
            <button
              class="button solid"
              (click)="
                goToURI(
                  content?.body?.sections[2]?.sections[0]?.cards[0]?.actions[0]
                    ?.url
                )
              "
            >
              Read more
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div
        class="falcon-card-bg-img"
        [style.backgroundImage]="'url(' + card2_bg_Image + ')'"
      >
        <div class="falcon--card">
          <h3 class="large-heading white">
            {{ content?.body?.sections[2]?.sections[0]?.cards[1]?.heading }}
          </h3>
          <p
            class="white"
            [innerHTML]="
              content?.body?.sections[2]?.sections[0]?.cards[1]?.content
            "
          ></p>
          <div>
            <button
              class="button solid"
              (click)="
                goToURI(
                  content?.body?.sections[2]?.sections[0]?.cards[1]?.actions[0]
                    ?.url
                )
              "
            >
              Read more
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="modal fade"
  id="modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-md">
    <!-- modal container -->
    <div class="modal-content text-center" *ngIf="isInitLoading">
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-grow gold" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div class="modal-content" *ngIf="!isInitLoading">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading">Email Status</h5>
      </div>

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <h4>{{ contactUsResponseMsg }}</h4>
      </div>

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button
          id="closeModal"
          type="button"
          class="button outline"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
