import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InsuranceForYourselfPage } from 'src/app/models/content/insurance/for-yourself.model';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-gap-cover',
  templateUrl: './gap-cover.component.html',
  styles: []
})
export class GapCoverComponent implements OnInit {

  public content: any = {};

  constructor(private pageService: PageService, private router: Router) {
    pageService.GetPage<InsuranceForYourselfPage>(pageService.URIs.insuranceForYourself).subscribe(content => {
      this.content = new InsuranceForYourselfPage(content);
    });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  ngOnInit() {
  }

}
