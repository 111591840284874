import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-info-cards-section",
  templateUrl: "./info-cards-section.component.html",
  styles: [],
})
export class InfoCardsSectionComponent implements OnInit {
  @Input("content") content: any;
  @Input("contentIndex") contentIndex: number;

  constructor() {}

  ngOnInit() {}
}
