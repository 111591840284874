<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- Insurence hero section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid insurence-hero-section"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
      *ngIf="content.header !== undefined"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title insurance text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
      <div class="pl-md-4">
        <div
          [innerHTML]="content.header.content"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
      </div>
      <div class="pl-md-4">
        <button-text
          *ngFor="let action of content?.header?.actions"
          buttonStyle="{{
            action?.buttonColor
              ? 'solid ' + action?.buttonColor
              : action.isPrimary
              ? 'solid'
              : 'outline inverse'
          }}"
          [label]="action?.label"
          (click)="DynamicButtonClick(action)"
        >
        </button-text>
      </div>
    </div>
  </div>
</div>

<div *ngIf="content.header !== undefined && !isPageLoading">
  <div class="container h-100 insurence-card-mt">
    <div class="row d-flex">
      <div
        class="col-md-5 offset-md-7"
        *ngFor="let card of content?.header?.cards"
      >
        <div class="insurence-card">
          <div>
            <h2 class="large-heading">{{ card?.heading }}</h2>
            <div [innerHTML]="card?.content"></div>
          </div>
          <div>
            <button-text
              *ngFor="let action of card?.actions"
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/ Insurence hero section -->
</div>

<div *ngIf="content.body !== undefined && !isPageLoading">
  <!-- For your things section -->
  <div class="container-fluid py-5 my-5 svg-bg-l For-your-things-card h-100">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12 For-your-things-header">
          <div class="heading-border-left">
            <h2 class="mega-title--mobile">
              {{ content?.body?.sections[0]?.heading }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container-xl h-100">
      <div class="row d-flex align-items-center">
        <div class="col-md-6">
          <img
            alt=""
            class="img-cover significant-raise"
            [src]="content?.body?.sections[0]?.image"
          />
        </div>
        <div class="col-md-5 offset-md-1">
          <div>
            <div
              class="p-length-sm"
              [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
            ></div>
          </div>
          <div>
            <button-text
              *ngFor="
                let action of content?.body?.sections[0]?.sections[0]?.actions
              "
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
      </div>

      <div class="row h-100 mt-5">
        <div
          class="col-md-3 mb-4"
          *ngFor="let card of content?.body?.sections[0]?.sections[1]?.cards"
        >
          <div
            class="card-round noticeable-raise white-bg p-4 p-md-4 h-100 d-flex flex-column justify-content-around"
          >
            <h4 class="medium-heading">{{ card?.heading }}</h4>
            <div [innerHTML]="card?.content"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 offset-md-5">
          <button-text
            *ngFor="
              let action of content?.body?.sections[0]?.sections[1]?.actions
            "
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
  <!-- For your things section -->

  <!-- For yourself section section -->
  <div class="container-fluid py-5 my-5 svg-bg for-yourself h-100">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-8 offset-3 text-right For-your-things-header">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile">
              {{ content?.body?.sections[1]?.heading }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container-xl h-100">
      <div class="row d-flex align-items-center">
        <div class="col-md-5 offset-md-1">
          <div>
            <div
              class="p-length-sm"
              [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
            ></div>
          </div>
          <div class="mb-4">
            <button-text
              *ngFor="
                let action of content?.body?.sections[1]?.sections[0]?.actions
              "
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>

        <div class="col-md-6">
          <img
            alt="retirement card img "
            class="img-cover"
            [src]="content?.body?.sections[1]?.image"
          />
        </div>
      </div>

      <div class="row h-100 mt-5">
        <div
          class="col-md-3 mb-4"
          *ngFor="let card of content?.body?.sections[1]?.sections[1]?.cards"
        >
          <div
            class="card-round noticeable-raise white-bg p-4 p-md-4 h-100 d-flex flex-column justify-content-around"
          >
            <h4>{{ card.heading }}</h4>
            <div [innerHTML]="card?.content"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 offset-md-5">
          <button-text
            *ngFor="
              let action of content?.body?.sections[1]?.sections[1]?.actions
            "
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
  <!-- For yourself section -->

  <!-- For your employees section section -->
  <div class="container-fluid py-5 my-5 svg-bg-l for-your-employees h-100">
    <div class="container-xl h-100">
      <div class="row d-flex align-items-center">
        <div class="col-md-6 mt-5">
          <img
            alt=""
            class="img-cover significant-raise"
            [src]="content?.body?.sections[2]?.image"
          />
        </div>
        <div class="col-md-5 offset-md-1">
          <div>
            <h2>{{ content?.body?.sections[2]?.sections[0]?.heading }}</h2>
            <div
              class="p-length-sm"
              [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
            ></div>
          </div>
          <div>
            <button-text
              *ngFor="
                let action of content.body.sections[2].sections[0].actions
              "
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- For your employees section -->

  <!-- For your business section section -->
  <div class="container-fluid py-5 svg-bg for-your-business h-100">
    <div class="container-xl h-100">
      <div class="row d-flex align-items-center">
        <div class="col-md-5 offset-md-1">
          <div>
            <h2>{{ content.body.sections[3].sections[0].heading }}</h2>
            <div
              class="p-length-sm"
              [innerHTML]="content.body.sections[3].sections[0].content"
            ></div>
          </div>
          <div class="mb-4">
            <button-text
              *ngFor="
                let action of content.body.sections[3].sections[0].actions
              "
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>

        <div class="col-md-6">
          <img
            alt=""
            class="img-cover significant-raise"
            [src]="content.body.sections[3].image"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- For your business section -->

  <!-- TODO: Recommended news -->
  <div class="container-fluid py-5 slate-bg-10">
    <div class="container-xl h-100">
      <div class="row d-flex align-items-center justify-content-left h-100">
        <h2 class="mb-4">{{ content?.body?.sections[4]?.heading }}</h2>

        <div class="card-deck">
          <div
            class="card news--card"
            *ngFor="let news of content?.body?.sections[4]?.articles"
          >
            <img
              class="card-img-top"
              [src]="news?.image"
              alt="Card image cap"
            />
            <div class="card-body">
              <div class="card-tags pb-2">
                <span
                  *ngFor="let category of news?.categories"
                  role="button"
                  class="basic-badge"
                  data-toggle="modal"
                  data-target="#modal-article-tags"
                  (click)="
                    pageService.SetArticle(category); $event.preventDefault()
                  "
                  >{{ category }}</span
                >
              </div>
              <h5 class="small-heading">{{ news?.headline }}</h5>
              <p class="body-small" [innerHTML]="news?.excerpt"></p>
              <a [routerLink]="news?.uri" class="textlink">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Recommended news -->
</div>
