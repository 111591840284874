export class EmailTemplate {
  /**
   *
   */
  constructor() {}

  public getGoalCalTemplate(firstName: string = null) {
    return `<div>Hello ${firstName},</div><br/>
      <div>Congratulations in taking a first step towards achieving your goal.</div><br/>
      <div>Here is the information that you have requested:</div>
      <div>How regularly would you like to invest? ${localStorage.getItem(
        "inv_pattern_input"
      )}</div>
      <div>How much can you invest? R${localStorage.getItem("amount")}</div>
      <div>How many years do you want to invest for? ${localStorage.getItem(
        "yearsInvested"
      )} years</div>
      <div>Your values below are shown in ${localStorage.getItem(
        "returns_in_value"
      )}</div>
      <div>You will ${localStorage.getItem("inc_rate")}</div>
      <div>Total invested over ${localStorage.getItem(
        "yearsInvested"
      )} years:R${localStorage.getItem("total_inv")}</div>
      <div>In ${localStorage.getItem(
        "yearsInvested"
      )} years’ time, your investment could amount to: R${localStorage.getItem(
      "final_results_saving_amount"
    )}</div><br/>
      <div>Assumptions used for this calculation:</div>
      <div>
      <ul><li>Inflation is assumed to be 5%.</li>
      <li>Your annual investment returns are ${localStorage.getItem(
        "inv_returns"
      )}% / ${localStorage.getItem("real_return")}% real.</li>
      </ul>
      </div>
      <div>If you would like to make this goal a reality, speak to your financial adviser or find a PSG adviser via our <a href='https://www.psg.co.za/financial-advice/find-an-adviser'>website</a>.</div><br/>
      <div>[Disclaimer]</div>
      <div>Copyright © PSG Konsult Ltd (1998-2019), All Rights Reserved. FAIS affiliates of the PSG Konsult Group are authorised financial services providers.</div>
      `;
  }

  public getRetirementCalTemplate(firstName: string) {
    return `<div>Hello ${firstName},</div><br/>
    <div>Congratulations in taking a step towards a comfortable retirement.</div><br/>
    <div>Here are the results of your retirement calculation:</div>
    <div>You would like your retirement income to last for: ${localStorage.getItem(
      "yearsInvested"
    )} years</div>
    <div>Your current age is: ${localStorage.getItem(
      "age_input_value"
    )} years</div>
    <div>You want to retire when you are: ${localStorage.getItem(
      "rage_input_value"
    )} years old</div><br/>
    <div>To make this happen, you need to make a monthly contribution of: R${localStorage.getItem(
      "final_results_monthly_contribution"
    )}</div>
    <div>You can afford to make a monthly contribution of: R${localStorage.getItem(
      "cont_input_value"
    )}</div>
    <div>Investing R${localStorage.getItem(
      "cont_input_value"
    )}, you will either be able to retire with an income (in terms of today’s money) of: R${localStorage.getItem(
      "final_results_new_monthly_income"
    )}</div>

    <div>And you have already saved R${localStorage.getItem(
      "savings_value"
    )} towards retirement.</div><br/>

    <div>Or you will need to work until you are: ${localStorage.getItem(
      "final_results_work_until_you_are"
    )} years old.</div><br/>
    <div>Assumptions used for this calculation:</div>
    <div>
    <ul><li>Inflation is 5%</li>
    <li>Your monthly contributions are increased by a salary inflation of 6% (inflation plus 1% allowing for career growth)</li>
    <li>Your annual investment returns are ${localStorage.getItem(
      "inv_returns"
    )}% / ${localStorage.getItem("real_return")}% real</li>
    </ul>
    </div>
    <div>If you would like to make this goal a reality, speak to your financial adviser or find a PSG adviser via our <a href='https://www.psg.co.za/financial-advice/find-an-adviser'>website</a>.</div><br/>
    <div>[Disclaimer]</div>
    <div>Copyright © PSG Konsult Ltd (1998-2019), All Rights Reserved. FAIS affiliates of the PSG Konsult Group are authorised financial services providers.</div>
    `;
  }

  public getCallmeBackTemplate(
    name: string,
    surname: string,
    email: string,
    contactNumber: string
  ) {
    return `<div>Hi ${name}
    ${surname}</div><div> Email Address:
    ${email} </div><div> Contact Number :
    ${contactNumber} </div>`;
  }
}
