import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { finalize } from "rxjs";
import { MonthlyEvents, WeekAheadLandingPage } from "src/app/models/content/news-and-publications/week-ahead-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-week-ahead",
  templateUrl: "./week-ahead.component.html",
  styleUrls: [],
})
export class WeekAheadComponent implements OnInit {
  public content: any = {};
  public currentMonth: string;
  public eventsCalendar: any;
  public expectedReleases: any;
  public isPageLoading: boolean = true;

  constructor(
    public pageService: PageService,
    private router: Router,
    public seoService: SeoService
  ) {

    this.currentMonth = moment(new Date()).format("MMMM");

    pageService
      .GetPage<WeekAheadLandingPage>(pageService.URIs.weekAhead)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new WeekAheadLandingPage(content);

        this.expectedReleases = this.content.body?.expectedReleases.map((e) => {
          return {
            heading: e.name,
            headline: moment(e.date).format("DD-MMMM-YYYY"),
            content: e.content,
            excerpt: e.excerpt,
          };
        });

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });

    pageService
      .GetAllCalendarEvents<MonthlyEvents>(pageService.URIs.monthlyCalendar)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.eventsCalendar = pageService.GetCurrentEvents(content?.events, 'month');
      });
  }

  ngOnInit(): void { }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public NavigateToSearch() {
    this.router.navigateByUrl("/search-results");
  }
}
