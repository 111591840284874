<app-header [ngClass]="{ 'menu-open': header.menuActive }" #header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

<div class="backToTop d-none d-md-block" *ngIf="showBackToTop">
  <button-icon
    buttonIcon="outline-icon"
    icon="icon-angle-up"
    (click)="gotoTop()"
  ></button-icon>
</div>
