<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid graduate-programme--hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="d-flex flex-column align-content-start justify-content-md-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-xl graduate-programme--card-row">
  <div class="row">
    <div
      class="col-md-5 mb-4 offset-md-1"
      *ngIf="content?.header?.cards?.length > 0"
    >
      <div class="blur-card">
        <div>
          <h2 class="section-title mb-4">
            {{ content?.header?.cards[0]?.heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[0]?.content"></div>
        </div>
        <div>
          <button-text
            *ngFor="let action of content?.header?.cards[0]?.actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>

    <div class="col-md-5" *ngIf="content.header.cards.length > 0">
      <div class="blur-card">
        <div>
          <h2 class="large-heading">
            {{ content?.header?.cards[1]?.heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[1]?.content"></div>
        </div>
        <div>
          <button-text
            *ngFor="let action of content?.header?.cards[1]?.actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ hero section -->

<div
  *ngIf="
    content.body != null && content.body.sections.length > 0 && !isPageLoading
  "
>
  <!-- Why we want you -->
  <div class="container-fluid mt-5 py-5 greybg-right">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content?.body?.sections[0]?.heading }}
            </h2>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-6 mb-4 order-0 order-md-1">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[0]?.image"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-5 py-md-5 order-1 order-md-0">
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
          <div class="btn-100-sm">
            <button-text
              *ngFor="
                let action of content?.body?.sections[0]?.sections[0]?.actions
              "
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Why we want you -->

  <!-- blockquote -->
  <div class="container-xl my-5 py-5">
    <div class="col-md-10 offset-md-2">
      <blockquote class="blockquote xlarge">
        <span class="cyan">“ </span>{{ content?.body?.sections[1]?.content
        }}<span class="cyan"> ”</span>
      </blockquote>
    </div>
  </div>
  <!-- /blockquote -->

  <!-- Why we want you -->
  <div class="container-fluid mt-5 py-5">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-11 offset-md-1">
          <div class="heading-border-left">
            <h2 class="mega-title--mobile">
              {{ content?.body?.sections[2]?.heading }}
            </h2>
          </div>
          <div class="mt-4">
            <div
              class="body-large p-length-sm"
              [innerHTML]="content?.body?.sections[2]?.content"
            ></div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="offset-md-1">
          <div class="row">
            <div
              class="col-md-3 mb-4"
              *ngFor="let card of content?.body?.sections[2]?.cards"
            >
              <div class="card-round slight-raise white-bg p-4 p-md-5 h-100">
                <h3 class="small-heading">{{ card?.heading }}</h3>
                <div [innerHTML]="card?.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Why we want you -->

  <!-- Bursary programme -->
  <div class="container-fluid mt-5 py-5">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content?.body?.sections[3]?.heading }}
            </h2>
          </div>
          <div class="col-md-5 offset-md-6 mt-4">
            <div
              class="body-large p-length-sm"
              [innerHTML]="content?.body?.sections[3]?.content"
            ></div>
          </div>
        </div>
      </div>

      <div class="row mt-4 justify-content-end">
        <div class="offset-md-1">
          <div class="row">
            <div
              class="col-md-3 mb-4"
              *ngFor="let card of content?.body?.sections[3]?.cards"
            >
              <div class="card-round slight-raise white-bg p-4 p-md-5 h-100">
                <h3 class="small-heading">{{ card?.heading }}</h3>
                <div [innerHTML]="card?.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Bursary programme -->
</div>

<!-- social media -->
<div *ngIf="!isPageLoading" class="container-fluid pt-4 pb-4 my-5 slate-bg-10">
  <h6 class="text-center mt-3">
    Follow us on social media to stay up to date with our career fair events,
    programme details and more.
  </h6>
  <div class="d-flex justify-content-center align-items-center row">
    <button class="button simple-icon">
      <icon-font name="icon-linkedin" class="icon-md cyan"></icon-font>
    </button>
    <button class="button simple-icon">
      <icon-font name="icon-twitter" class="icon-md cyan"></icon-font>
    </button>
    <button class="button simple-icon">
      <icon-font name="icon-facebook" class="icon-md cyan"></icon-font>
    </button>
  </div>
</div>
<!-- /social media -->
