import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, map, throwError, catchError } from "rxjs";
import { LoggerService } from "./logger.service";
import { HttpServiceRequestType } from '../../models/base/http-service-request-types.enum';

@Injectable()
export class BaseService {
  constructor(public loggerService: LoggerService, public http: HttpClient) {}

  protected Patch(
    url: string,
    body: any,
    isLoader: boolean = true
  ): Observable<any> {
    this.loggerService.LogHttpRequest(HttpServiceRequestType.PATCH, url, body);

    return this.http
      .patch(url, body)
      .pipe(map(response =>
        this.HandleResponse(response, HttpServiceRequestType.PATCH, url, body)
      ),
      catchError(err => this.HandleError(err, isLoader)));
  }

  protected Put(
    url: string,
    body: any,
    options?: any,
    isLoader: boolean = true
  ): Observable<any> {
    this.loggerService.LogHttpRequest(HttpServiceRequestType.PUT, url, body);

    return this.http
      .put(url, body, options)
      .pipe(map(response =>
        this.HandleResponse(response, HttpServiceRequestType.PUT, url, body)
      ),
      catchError(err => this.HandleError(err, isLoader)));
  }

  protected Delete(url: string, isLoader: boolean = true): Observable<any> {
    this.loggerService.LogHttpRequest(HttpServiceRequestType.DELETE, url);

    return this.http
      .delete(url)
      .pipe(map(response =>
        this.HandleResponse(response, HttpServiceRequestType.DELETE, url)
      ),
      catchError(err => this.HandleError(err, isLoader)));
  }

  protected Post(
    url: string,
    body: any,
    options?: any,
    isLoader: boolean = true
  ): Observable<any> {

    return this.http
      .post(url, body, options)
      .pipe(map(response =>
        this.HandleResponse(response, HttpServiceRequestType.POST, url, body)
      ),
      catchError(err => this.HandleError(err, isLoader)));
  }

  protected Get(
    url: string,
    options?: any,
    isLoader: boolean = true
  ): Observable<any> {
    this.loggerService.LogHttpRequest(HttpServiceRequestType.GET, url);

    return this.http
      .get(url, options)
      .pipe(map(response =>
        this.HandleResponse(response, HttpServiceRequestType.GET, url)
      ),
      catchError(err => this.HandleError(err, isLoader)));
      
  }

  protected ExtractExcelAsBlob(res: any): Blob {
    return new Blob([new Uint8Array(res)], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  }

  private HandleResponse(
    response: any,
    type: HttpServiceRequestType,
    url: any,
    body: any = {}
  ): Object {
    this.loggerService.LogHttpResponse(type, url, body, response);
    return response;
  }

  private HandleError(error: Object | any, isLoader: boolean) {
    this.loggerService.LogHttpError(error.error || error);

    return throwError(error.error ? error.error : error.error || error);
  }

  protected BuildDateStringForHttp(date: Date): string {
    date = new Date(date);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
}
