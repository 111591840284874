import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-contact-me',
  templateUrl: './lead-contact-me.component.html'
})
export class LeadContactMeComponent implements OnInit {

  url = 'https://forms.office.com/r/8sb0TQULLE';

  constructor() { }

  ngOnInit() {
  }

}
