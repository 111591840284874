import { DatePipe } from "@angular/common";
import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { EconomicCalendarPage } from "src/app/models/content/news-and-publications/economic-calendar.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-diu-economic-calender",
  templateUrl: "./diu-economic-calender.component.html",
  styles: [],
})
export class DiuEconomicCalenderComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;

  constructor(
    public pageService: PageService,
    private router: Router,
    private datepipe: DatePipe,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<EconomicCalendarPage>(pageService.URIs.economicCalendar)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new EconomicCalendarPage(content);

        this.content.body.events.sort(
          (a, b) =>
            <number>(<unknown>new Date(b.date.toString())) -
            <number>(<unknown>new Date(a.date.toString()))
        );
        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description,
          "article"
        );
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }

  public convertDate(dateString: string): string {
    let latest_date = this.datepipe.transform(
      new Date(dateString),
      "dd-MMMM-yyyy"
    );

    return latest_date.toString();
  }

  public getDay(dateString: string): string {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[new Date(dateString).getDay()];
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToMail() {
    window.open(
      "mailto:?subject=PSG%20Daily%20Investment%20Update&body=" +
      window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }
}
