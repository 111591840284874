<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- home hero section -->
<div
  class="container-fluid compareHero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--mobile home-page mb-1 text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-xl" style="margin-top: -4rem">
  <div class="card-round white-bg extreme-raise py-4 px-4 px-md-5 py-md-5">
    <div class="row">
      <div class="col-md-12">
        <h2 class="medium-heading text-center-md">
          Select up to three options to compare
        </h2>
      </div>
    </div>

    <div class="row justify-content-around mt-4">
      <!-- ----------checkbox array --------------------- -->
      <div
        class="col-md-auto mb-3 mb-md-4"
        *ngFor="let option of content?.body?.options; let i = index"
      >
        <button-check>
          <input
            id="{{ option.name }}"
            type="checkbox"
            [(ngModel)]="option.isChecked"
            (change)="changeSelection()"
          />
          {{ option?.name }}
        </button-check>
      </div>
      <!-- ----------checkbox array --------------------- -->
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-fluid my-4 py-4">
  <div class="container-xl h-100">
    <div class="row">
      <!-- ----------checked product array --------------------- -->
      <div
        class="col-md-4 mb-4"
        *ngFor="let item of selectedItemsList; let i = index"
      >
        <div
          class="d-flex flex-column alignt-items-center justify-content-center card-round slate-bg-10 p-2"
        >
          <div>
            <div class="compare-card slight-raise">
              <div>
                <h2 class="small-heading">{{ item?.name }}</h2>
                <p class="body-small" [innerHTML]="item?.excerpt"></p>
              </div>
              <div
                class="inner-cardSection"
                style="overflow-x: hidden; overflow-y: auto"
              >
                <p class="body-small body-bold mb-0">
                  Minimum lump sum investment
                </p>
                <p class="body-small mb-2">{{ item?.minimumLumpSum }}</p>
                <p class="body-small body-bold mb-0">
                  Minimum recurring debit order
                </p>
                <p class="body-small mb-2">{{ item?.monthlyDebitOrder }}</p>
                <p class="body-small body-bold mb-0">
                  Access to your investment
                </p>
                <p class="body-small mb-2" [innerHTML]="item?.content"></p>
                <p class="body-small body-bold mb-0" *ngIf="item?.tax">
                  Tax benefits
                </p>
                <p class="body-small mb-2" *ngIf="item?.tax">{{ item?.tax }}</p>
              </div>

              <div
                class="d-flex align-items-center pl-2 product-dl"
                *ngFor="let download of item?.downloads"
              >
                <icon-font
                  name="icon-file-download"
                  colour="cyan"
                  class="icon-dl mr-2"
                >
                </icon-font>
                <a
                  class="textlink body-med body-bold text-dl"
                  target="_blank"
                  *ngIf="download?.url"
                  [href]="download?.url"
                  >{{ download?.label }}</a
                >
                <a
                  class="textlink body-med body-bold text-dl"
                  target="_blank"
                  [href]="download?.file"
                  *ngIf="download?.file"
                  >{{ download?.label }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ----------checked product array --------------------- -->
      <!-- blank state unchecked -->
      <div class="col-md-4 mb-4" *ngIf="selectedItemsList.length === 0">
        <div
          class="d-flex flex-column align-items-center justify-content-center card-round slate-bg-10 p-2 inset-shadow"
          style="min-height: 420px"
        >
          <div>
            <p class="small-heading slate text-center">Option 1</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4" *ngIf="selectedItemsList.length < 2">
        <div
          class="d-flex flex-column align-items-center justify-content-center card-round slate-bg-10 p-3 inset-shadow"
          style="min-height: 420px"
        >
          <div>
            <p class="small-heading slate text-center">Option 2</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4" *ngIf="selectedItemsList.length < 3">
        <div
          class="d-flex flex-column align-items-center justify-content-center card-round slate-bg-10 p-3 inset-shadow"
          style="min-height: 420px"
        >
          <div>
            <p class="small-heading slate text-center">Option 3</p>
          </div>
        </div>
      </div>
      <!-- /blank state unchecked -->
    </div>
  </div>
</div>
