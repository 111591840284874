import { DOCUMENT, Location } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { finalize } from "rxjs";
import { DuiArticlePage } from "src/app/models/content/news-and-publications/dui-article-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-diu-article",
  templateUrl: "./diu-article.component.html",
})
export class DiuArticleComponent implements OnInit, AfterViewInit {
  public content: any = {};
  public fragment: string;
  public newsArticle: string;
  public goToURL: string;
  public timeDate: Date;
  public shareHeadline: string;

  public types: Array<any> = new Array<any>();
  public sections: Array<any> = new Array<any>();
  public isPageLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public pageService: PageService,
    public sanitizer: DomSanitizer,
    public seoService: SeoService,
    private hostElement: ElementRef,
    @Inject(DOCUMENT) document,
    private router: Router,
    private location: Location,
    private renderer: Renderer2
  ) {
    this.route.params.subscribe((parms: Params) => {
      this.newsArticle = parms.newsArticle;
      this.pageService
        .GetPage<DuiArticlePage>(
          this.pageService.URIs.dialyInvestmentArticleBase +
          "/" +
          this.newsArticle
        )
        .pipe(
          finalize(() => {
            this.isPageLoading = false;
          })
        )
        .subscribe((content) => {
          this.content = new DuiArticlePage(content);
          this.shareHeadline = this.content.header.headline.replace("&", "%26"); // TODO: Temp workaround for & characters

          this.seoService.updateMetaInfoForPage(
            this.content.seo.title,
            window.location.href,
            this.content?.header?.image,
            this.content.seo.description,
            "article"
          );

          const recentInvestmentFrame =
            this.hostElement.nativeElement.querySelector(
              "#recentInvestmentFrame"
            );

          recentInvestmentFrame.src =
            this.content.body.sections[1]?.videos[0]?.url;
          const dailyInvestmentFrame =
            this.hostElement.nativeElement.querySelector(
              "#dailyInvestmentFrame"
            );

          dailyInvestmentFrame.src =
            this.content.body.sections[1]?.videos[1]?.url;
          const finalFrame =
            this.hostElement.nativeElement.querySelector("#finalFrame");
          finalFrame.src = this.content.body.sections[1]?.videos[2]?.url;
        });
    });

    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
  }

  public goToAdvisorProfile(id) {
    if (id == null) {
      this.router.navigateByUrl("/");
    }
    else {
      this.router.navigateByUrl(`financial-advice/adviser-profile/${id}`);
    }
  }

  public sanitizeURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }

  ngAfterViewInit() {
    this.scrollTimer();
  }

  public async scrollTimer() {
    setTimeout(() => {
      let doc = document.getElementById(this.fragment);
      if (doc != undefined) {
        doc.focus();
        doc.scrollIntoView();
      } else {
        this.scrollTimer();
      }
    }, 3000);
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public displayVideos() {
    this.pageService
      .GetPage<DuiArticlePage>(
        this.pageService.URIs.dialyInvestmentArticleBase +
        "/" +
        this.newsArticle
      )

      .subscribe((content) => {
        this.content = new DuiArticlePage(content);
        const recentInvestmentFrame =
          this.hostElement.nativeElement.querySelector(
            "#recentInvestmentFrame"
          );
        recentInvestmentFrame.src = this.content.body.sections[1].videos[0].url;
        const dailyInvestmentFrame =
          this.hostElement.nativeElement.querySelector("#dailyInvestmentFrame");
        dailyInvestmentFrame.src = this.content.body.sections[1].videos[1].url;
        const finalFrame =
          this.hostElement.nativeElement.querySelector("#finalFrame");
        finalFrame.src = this.content.body.sections[1].videos[2].url;
      });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
