<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- all psg funds hero section -->
<div *ngIf="content.header !== undefined">
  <div
    class="container-fluid all-psg-funds"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="pl-4 pl-md-4">
          <div
            [innerHTML]="content?.header?.content"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- all psg funds hero section -->

<!-- all-psg-funds card -->
<div
  class="container h-100 all-psg-funds-card-mt"
  *ngIf="content.header != null && !isPageLoading"
>
  <div class="row d-flex">
    <div class="col-md-7"></div>
    <div class="col-md-5" *ngFor="let card of content?.header?.cards">
      <div class="all-psg-funds-card">
        <div>
          <h2 class="large-heading">{{ card?.heading }}</h2>
          <div [innerHTML]="card?.content"></div>
        </div>
        <div>
          <ng-container *ngFor="let action of card?.actions">
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/all-psg-funds card -->

<!-- Local funds  -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid pt-5 pt-md-0 pb-5 my-5 greybg-left"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-12">
        <div
          class="heading-border-left d-inline-flex justify-content-start transformY-50"
        >
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[0]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>
      <div class="col-md-5 offset-md-1">
        <div>
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[0]?.sections[0]?.actions
            "
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>

        <div class="mt-4">
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[1]?.content"
          ></div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[0]?.sections[1]?.actions
            "
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Local funds  -->

<!-- local funds table -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5">
  <div class="container-xl h-100">
    <div class="ml-5 local-funds-text-mobile">
      <p
        class="p-length-sm"
        [innerHTML]="content?.body?.sections[1]?.content"
      ></p>
    </div>
  </div>
  <div class="container-xl px-0 mt-5" id="local-all-psg-funds">
    <app-psg-funds-local [contentInput]="content"> </app-psg-funds-local>
  </div>
  <div class="container-xl px-0 mt-3">
    <div class="mt-4 ml-0 col-md-5 offset-md-0">
      <button-text
        buttonStyle="solid"
        [label]="'View PSG Multi-Management funds quarterly commentary'"
        fill="true"
        (click)="viewQuarterlyCommentary()"
      >
      </button-text>
    </div>
  </div>
</div>
<!--/ local funds table -->

<!-- Global funds -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5 greybg-right">
  <div class="container-xl h-100">
    <div class="row d-flex">
      <div class="col-md-8 offset-2">
        <div class="heading-border-right">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[3]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-center">
      <div class="col-md-5">
        <div>
          <p
            class="p-length-md"
            [innerHTML]="content?.body?.sections[3]?.sections[0]?.content"
          ></p>
        </div>
        <ng-container
          *ngFor="
            let action of content?.body?.sections[3]?.sections[0]?.actions
          "
        >
          <button-text
            class="{{ action.isPrimary ? 'mt-2' : 'mt-2' }}"
            buttonStyle="{{
              action.isPrimary ? 'solid col-md-8 mt-2' : 'outline mt-2'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </ng-container>
      </div>
      <div class="col-md-6 offset-md-1 mt-4 mt-md-0">
        <img
          [src]="content?.body?.sections[3]?.image"
          class="img-cover significant-raise"
        />
      </div>
    </div>
  </div>
</div>
<!--/ Global funds -->

<!-- Global funds table -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-3 my-3"
  id="global-all-psg-funds"
>
  <div class="container-xl px-0 h-100">
    <app-psg-funds-global [contentInput]="content"> </app-psg-funds-global>
  </div>
</div>
<!--/ Global funds table -->

<!-- Invest in other currencies  table -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-3 my-3"
  id="other-all-psg-funds"
>
  <div class="container-xl px-0 h-100">
    <app-psg-funds-other-currencies [contentInput]="content">
    </app-psg-funds-other-currencies>

    <div class="ml-5 mt-5 local-funds-text-mobile">
      <p
        class="black"
        style="line-height: 0"
        [innerHTML]="content?.body?.sections[6]?.content"
      ></p>
    </div>
  </div>
</div>
<!--/ Invest in other currencies  table -->

<!-- Fund performance -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 svg-bg-l details--fundP h-100"
  id="fund-performance-all-psg-funds"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-7 ml-md-4">
        <div class="large-heading">
          <h2>{{ content?.body?.sections[7]?.sections[0]?.heading }}</h2>
        </div>
        <div>
          <p
            class="p-length-lg"
            [innerHTML]="content?.body?.sections[7]?.sections[0]?.content"
          ></p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Fund performance -->
