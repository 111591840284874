<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  *ngIf="content.header !== undefined && !isPageLoading"
  class="container-fluid corporate-culure--hero mb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="d-flex flex-column align-content-start justify-content-md-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
      <div class="pl-4 pl-md-4">
        <div
          class="text-shadow"
          [innerHTML]="content?.header?.content"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-xl corporate-culure--card-row">
  <div class="row">
    <div class="col-md-4 mb-4" *ngFor="let card of content?.header?.cards">
      <div class="blur-card d-flex flex-column justify-content-between h-100">
        <div>
          <h2 class="medium-heading">{{ card?.heading }}</h2>
          <div [innerHTML]="card?.content"></div>
        </div>
        <div>
          <button-text
            *ngFor="let action of card?.actions"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/ hero section -->

<!-- empty space -->
<div class="container-fluid py-4 my-5">&nbsp;</div>
<!-- /empty space -->

<div
  *ngIf="
    content.body != null && content.body.sections.length > 0 && !isPageLoading
  "
>
  <!-- Corporate culture -->
  <div class="container-fluid mt-5 pb-5 greybg-left" id="corporate-culture">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-left transformY-25">
            <h2 class="mega-title--mobile">
              {{ content?.body?.sections[0]?.heading }}
            </h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[0]?.image"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-5 offset-lg-1 py-md-5">
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
          <div class="btn-row">
            <button-text
              *ngFor="
                let action of content?.body?.sections[0]?.sections[0]?.actions
              "
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Corporate culture -->

  <!-- blockquote -->
  <div *ngIf="!isPageLoading" class="container-xl my-5 py-5">
    <div class="col-md-11 offset-md-1">
      <blockquote class="blockquote large">
        <span class="cyan">“ </span>{{ content?.body?.sections[1]?.content
        }}<span class="cyan"> ”</span>
      </blockquote>
    </div>
  </div>
  <!-- /blockquote -->

  <!-- Partner with PSG -->
  <div *ngIf="!isPageLoading" class="container-fluid mt-5 py-5 greybg-right">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content?.body?.sections[2]?.heading }}
            </h2>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-6 offset-lg-1 mb-4 order-0 order-md-1">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[2]?.image"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-5 py-md-5 order-1 order-md-0">
          <div
            [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
          ></div>
          <div class="btn-100-sm">
            <button-text
              *ngFor="
                let action of content?.body?.sections[2]?.sections[0]?.actions
              "
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Partner with PSG -->
</div>
