import { ChangeDetectorRef, Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { finalize, startWith, take } from "rxjs/operators";
import { FindAdviserPage } from "src/app/models/content/financial-advice/find-adviser-page.model";
import { AdvisorService } from "src/app/services/advisor.service";
import { PageService } from "src/app/services/page.service";
import { SearchService } from "src/app/services/search.service";
import { BranchOfficePage } from "src/app/models/content/financial-advice/branch-office-page.model";
import { forkJoin, Observable } from "rxjs";
import { SeoService } from "src/app/services/socialLinks.service";
import { HelperService } from 'src/app/services/helper/helper.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: "app-find-an-adviser",
  templateUrl: "./find-an-adviser.component.html",
})
export class FindAnAdviserComponent implements OnInit {
  isLoadingOffice: boolean = false;
  public isLoading: boolean = false;
  public isInitLoading: boolean = true;
  private token: string;
  private adviser: any = {};
  public advisers: any = [];

  // Lists
  private cities: any = [];
  private countryCities: any = [];
  public products: any = [];

  // Search Fields
  public SearchFormGroup: FormGroup;
  public product: string = "";
  public location: string = "";
  public displayLocation: string = "";
  public previousProductSelected: string;

  //City
  public filteredCountryCities: any[];
  public filteredCities: any[];

  public emailResponseMessage: string;

  private captchaResolved: boolean = false;

  public isWebLeadAllowed: boolean = false;

  public leadForm: FormGroup;
  private sendLeadObject: any = {};
  public loactionResults: boolean = false;
  public noResultsForLocation: boolean = false;
  public noResultsForLocationAndProduct: boolean = false;

  //Lists
  public titles: any[] = [];
  private genders: any[] = [];

  //search radio buttons

  private radioSel: any;
  public countrySelected: string;
  private countrySelected_ID: number = 0;
  private countrySelectedString: string;
  public radioSearchSelected: string;
  private radioSearchSelectedString: string;
  public countriesArray: [];
  private countryLocation: any;
  public adviserName: string;

  private empList: any[] = [];

  private getAadvisers: any = [];

  public content: FindAdviserPage = new FindAdviserPage({});
  public isPageLoading: boolean = true;
  advisersUrl = environment.advisersUrl;

  constructor(
    private formBuilder: FormBuilder,
    private advisorService: AdvisorService,
    public sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private pageService: PageService,
    private searchService: SearchService,
    public seoService: SeoService,
    private renderer: Renderer2,
    public helper: HelperService
  ) { }

  public ngOnInit() {

    // temp solution to redirect
    window.location.href = environment.advisersUrl;

    this.GetCMSContent();
    this.GetBranches();
    this.GetProducts();
    this.BuildForm();
    this.BuildLeadForm();
    this.onGetCountries();

    //Selecting Default Radio country here
    this.countrySelected = "South Africa";

    //Selecting Default Radio search button here
    this.radioSearchSelected = "searchByProduct";
    this.getSelectedSearchButton();
    this.seoService.setJsonLd(this.renderer, null);
  }

  public onGetAllBranches(countryID) {
    this.advisorService.getAllBranches(countryID).subscribe((x) => {
      this.countryCities = [];
      x.data.forEach((c) => {
        if (c.branch.includes("&amp;")) {
          let branch = c;
          branch.branch = c.branch.replace("&amp;", "&");
          this.countryCities.push(branch);
        } else {
          this.countryCities.push(c);
        }
      }),
        (error) => { };
    });
  }

  private onGetCountries() {
    this.advisorService.getContries().subscribe((x) => {
      this.countriesArray = x.data;
    });
  }

  private getSelecteditem() {
    var countries_Array = this.countriesArray as any;

    this.radioSel = countries_Array.find(
      (x) => x.country_name === this.countrySelected
    );

    this.countrySelected_ID = countries_Array.find((x) => x.id);

    this.countrySelected_ID = this.radioSel.id;
    this.countrySelectedString = JSON.stringify(this.radioSel);

    this.onGetAllBranches(this.countrySelected_ID);
  }

  //Radio Change Event for search country
  public onRadioBtnChange(item) {
    this.getSelecteditem();
  }

  private getSelectedSearchButton() {
    this.radioSearchSelectedString = "searchByProduct";
  }

  // Radio Change Event for search options
  //getting the  search value for the selected radio(searh) button
  public onSearchItemChange(item) {
    this.getSelectedSearchButton();
    if (item === "searchByLocation") {
      this.radioSearchSelectedString = item;
      this.getSelecteditem();
    }

    if (item === "adviserName") {
      this.radioSearchSelectedString = item;
    }

    if (item === "searchByProduct") {
      this.radioSearchSelectedString = item;
    }
  }

  public onSearchAdviser(isFirst: boolean = false) {
    this.isLoading = true;

    const results = document.getElementById("results");
    results.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    if (this.radioSearchSelectedString === "searchByProduct") {
      this.location = this.SearchFormGroup.controls.city.value;

      if (this.location.includes("&")) {
        let mutationLocation1 = this.location;
        let mutationLocation2 = this.location.replace("&", "%26");
        let mutationLocation3 = this.location.replace("&", "%26amp;");
        this.advisorService
          .Search(
            this.location,
            this.product.replace(/(?:^|\s)\S/g, (res) => {
              return res.toUpperCase();
            })
          )
          .subscribe((advisers1) => {
            if (advisers1.length === 0) {
              this.advisorService
                .Search(
                  this.location,
                  this.product.replace(/(?:^|\s)\S/g, (res) => {
                    return res.toUpperCase();
                  })
                )
                .subscribe((advisers2) => {
                  if (advisers2.length === 0) {
                    this.advisorService
                      .Search(
                        this.location,
                        this.product.replace(/(?:^|\s)\S/g, (res) => {
                          return res.toUpperCase();
                        })
                      )
                      .subscribe((advisers3) => {
                        if (advisers3 === 0) {
                        } else {
                          this.PopulateSearchResults(advisers3);
                        }
                      });
                  } else {
                    this.PopulateSearchResults(advisers2);
                  }
                });
            } else {
              this.PopulateSearchResults(advisers1);
            }
          });
      } else {
        this.advisorService
          .Search(
            this.location,
            this.product.replace(/(?:^|\s)\S/g, (res) => {
              return res.toUpperCase();
            })
          )
          .subscribe(
            (x) => {
              this.PopulateSearchResults(x);
            },
            () => (this.isLoading = false)
          );
      }
    }

    if (this.radioSearchSelectedString === "adviserName") {
      if (this.adviserName.includes("&")) {
        let mutationLocation1 = this.location;
        let mutationLocation2 = this.location.replace("&", "%26");
        let mutationLocation3 = this.location.replace("&", "%26amp;");
        this.advisorService
          .Search(
            "",
            "",
            0,
            this.adviserName.replace(/(?:^|\s)\S/g, (res) => {
              return res.toUpperCase().replace(" ", "");
            })
          )
          .subscribe((advisers1) => {
            if (advisers1.length === 0) {
              this.advisorService
                .Search(
                  "",
                  "",
                  0,
                  this.adviserName.replace(/(?:^|\s)\S/g, (res) => {
                    return res.toUpperCase().replace(" ", "");
                  })
                )
                .subscribe((advisers2) => {
                  if (advisers2.length === 0) {
                    this.advisorService
                      .Search(
                        "",
                        "",
                        0,
                        this.adviserName.replace(/(?:^|\s)\S/g, (res) => {
                          return res.toUpperCase().replace(" ", "");
                        })
                      )
                      .subscribe((advisers3) => {
                        if (advisers3 === 0) {
                        } else {
                          this.PopulateSearchResults(advisers3);
                        }
                      });
                  } else {
                    this.PopulateSearchResults(advisers2);
                  }
                });
            } else {
              this.PopulateSearchResults(advisers1);
            }
          });
      } else {
        this.advisorService
          .Search(
            "",
            "",
            0,
            this.adviserName.replace(/(?:^|\s)\S/g, (res) => {
              return res.toUpperCase().replace(" ", "");
            })
          )
          .subscribe(
            (x) => {
              this.PopulateSearchResults(x);
            },
            () => (this.isLoading = false)
          );
      }
    }

    if (this.radioSearchSelectedString === "searchByLocation") {
      this.countryLocation = this.SearchFormGroup.controls.city.value;
      if (this.countryLocation.includes("&")) {
        let mutationLocation1 = this.countryLocation;
        let mutationLocation2 = this.countryLocation.replace("&", "%26");
        let mutationLocation3 = this.countryLocation.replace("&", "%26amp;");
        this.advisorService
          .Search(
            this.countryLocation.replace(/(?:^|\s)\S/g, (res) => {
              return res.toUpperCase();
            }),
            "",
            this.countrySelected_ID
          )
          .subscribe((advisers1) => {
            if (advisers1.length === 0) {
              this.advisorService
                .Search(
                  this.countryLocation.replace(/(?:^|\s)\S/g, (res) => {
                    return res.toUpperCase();
                  }),
                  "",
                  this.countrySelected_ID
                )
                .subscribe((advisers2) => {
                  if (advisers2.length === 0) {
                    this.advisorService
                      .Search(
                        this.countryLocation.replace(/(?:^|\s)\S/g, (res) => {
                          return res.toUpperCase();
                        }),
                        "",
                        this.countrySelected_ID
                      )
                      .subscribe((advisers3) => {
                        if (advisers3 === 0) {
                        } else {
                          this.PopulateSearchResults(advisers3);
                        }
                      });
                  } else {
                    this.PopulateSearchResults(advisers2);
                  }
                });
            } else {
              this.PopulateSearchResults(advisers1);
            }
          });
      } else {
        this.advisorService
          .Search(
            this.countryLocation.replace(/(?:^|\s)\S/g, (res) => {
              return res.toUpperCase();
            }),
            "",
            this.countrySelected_ID
          )
          .subscribe(
            (x) => {
              this.PopulateSearchResults(x);
            },
            () => (this.isLoading = false)
          );
      }
    }
  }

  public PopulateSearchResults(searchResults: any) {
    this.getAadvisers = new Array<any>();

    this.empList.push(searchResults);

    for (var i = 0; i < this.empList.length; i++) {
      var data = this.empList[i];
      for (const prop in data) {
        this.getAadvisers.push(data[prop]);
      }
    }

    this.loactionResults = false;
    this.noResultsForLocation = false;
    this.noResultsForLocationAndProduct = false;

    this.advisers.splice(0, this.advisers.length);
    this.empList.splice(0, this.empList.length);
    this.advisers = this.getAadvisers.sort(() => Math.random() - 0.5);

    this.advisers.forEach((x) => {
      this.advisorService.GetProfileImage(x.person_id).subscribe((element) => {
        if (element != null) {
          x["photo_decoded"] = element.data.data_arr.data.image_string
            ? this.cleanURL(
              "data:image/png;base64," +
              element.data.data_arr.data.image_string
            )
            : "../../../../assets/images/adviser-profile.svg";
        } else {
          x["photo_decoded"] = "../../../../assets/images/adviser-profile.svg";
        }
      });
    });
    this.isLoading = false;
  }

  public sanitizeJobTitle(jobtitle: string) {
    return this.sanitizer.bypassSecurityTrustHtml(
      jobtitle.replace("&amp;", "&")
    )["changingThisBreaksApplicationSecurity"];
  }

  public ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  private GetCMSContent() {
    this.pageService
      .GetPage<FindAdviserPage>(this.pageService.URIs.findAdviser)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new FindAdviserPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  public _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    var cities = this.cities.filter((city) =>
      city.branch.toLowerCase().includes(filterValue)
    );
    return cities.map((x) => x.branch);
  }

  public filter_(value: string): string[] {
    const filterValue = value.toLowerCase();

    var country_cities = this.countryCities.filter((city) =>
      city.branch.toLowerCase().includes(filterValue)
    );
    return country_cities.map((x) => x.branch);
  }

  public GetBranches() {
    this.advisorService.GetBranches().subscribe((x) => {
      x.data.forEach((c) => {
        if (c.branch.includes("&amp;")) {
          let branch = c;
          branch.branch = c.branch.replace("&amp;", "&");
          this.cities.push(branch);
        } else {
          this.cities.push(c);
        }
      });
    });
  }

  public GetProducts() {
    this.advisorService.GetProducts().subscribe((x) => {
      this.products = x;

      const indexOfFamilyOffice = this.products.findIndex(
        ({ discipline_name }) => discipline_name === "Family Office"
      );
      this.products = this.products.filter(
        (_, index) => index !== indexOfFamilyOffice
      );

      const indexOfAssetManagment = this.products.findIndex(
        ({ discipline_name }) => discipline_name === "Asset Managment"
      );
      this.products = this.products.filter(
        (_, index) => index !== indexOfAssetManagment
      );

      const indexOfCorporateFinance = this.products.findIndex(
        ({ discipline_name }) => discipline_name === "Corporate Finance"
      );

      this.products = this.products.filter(
        (_, index) => index !== indexOfCorporateFinance
      );
    });
  }

  public Search(isFirst: boolean = false) {
    this.isLoading = true;
    this.location = this.SearchFormGroup.controls.city.value;
    this.displayLocation = this.location.replace("&amp;", "&");
    const results = document.getElementById("results");
    results.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    if (this.location.includes("&")) {
      let mutationLocation1 = this.location;
      let mutationLocation2 = this.location.replace("&", "%26");
      let mutationLocation3 = this.location.replace("&", "%26amp;");
      this.advisorService
        .Search(
          this.product,
          mutationLocation1.replace(/(?:^|\s)\S/g, (res) => {
            return res.toUpperCase();
          })
        )
        .subscribe((advisers1) => {
          if (advisers1.length === 0) {
            this.advisorService
              .Search(
                this.product,
                mutationLocation2.replace(/(?:^|\s)\S/g, (res) => {
                  return res.toUpperCase();
                })
              )
              .subscribe((advisers2) => {
                if (advisers2.length === 0) {
                  this.advisorService
                    .Search(
                      this.product,
                      mutationLocation3.replace(/(?:^|\s)\S/g, (res) => {
                        return res.toUpperCase();
                      })
                    )
                    .subscribe((advisers3) => {
                      if (advisers3 === 0) {
                      } else {
                        this.PopulateSearchResults(advisers3);
                      }
                    });
                } else {
                  this.PopulateSearchResults(advisers2);
                }
              });
          } else {
            this.PopulateSearchResults(advisers1);
          }
        });
    } else {
      this.advisorService
        .Search(
          this.product,
          this.location.replace(/(?:^|\s)\S/g, (res) => {
            return res.toUpperCase();
          })
        )
        .subscribe(
          (x) => {
            this.PopulateSearchResults(x);
          },
          () => (this.isLoading = false)
        );
    }
  }

  public SelectAdviser(advisor: any): void {
    window.open(
      "financial-advice/adviser-profile/" + advisor.person_id,
      "_blank"
    );
  }

  public GetLocationFromAdvisor(advisor) {
    let location = advisor.address;
    let result = "";

    if (location.suburb == "" && location.city == "") {
      result = advisor.office_name;
    }
    if (location.suburb != "" && location.city != "") {
      if (location.suburb == location.city) {
        result = location.city;
      } else {
        result = location.suburb + "," + location.city;
      }
    }

    if (location.suburb == "" && location.city != "") {
      result = location.city;
    }
    if (location.suburb != "" && location.city == "") {
      result = location.suburb;
    }

    return result;
  }

  public OpenAdviserLeadDialog(adviser: any): void {
    this.adviser = adviser;

    this.advisorService.GetToken().subscribe((x) => {
      this.token = x;
      this.CheckAllowedWebLeads();

      this.GetTitles();
      this.GetGenders();
    });
  }

  public branchLinkClicked(adviser: any) {

    this.isLoadingOffice = true;

    this.advisorService.setSelectedBranch(adviser.team_id);

    // get uri from a search on the team name
    this.searchService.GetSearch(adviser.team_name, "").subscribe((result: any) => {

      // if there is a result, get the last alias in the uri and navigate
      if (result.length > 0) {

        this.isLoadingOffice = false;

        const uri = result[0].uri;
        if (uri) {
          this.router.navigate([`branch-office/${uri.substring(uri.lastIndexOf("/") + 1, uri.length)}`]);
        } else {
          this.router.navigate([`branch-office/${adviser.web_team_name}`]);
        }

      } else {

        this.isLoadingOffice = false;
        this.router.navigate([`branch-office/${adviser.web_team_name}`]);

      }

    });

  }

  public cleanURL(oldURL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  public BuildForm() {
    this.SearchFormGroup = this.formBuilder.group({
      city: [""],
    });

    this.SearchFormGroup.controls.city.valueChanges
      .pipe(startWith(""))
      .subscribe((value) => {
        this.location = value.toString();
        this.displayLocation = this.location.replace(" &amp;", "&");
        if (this.radioSearchSelected === "searchByProduct") {
          this.filterBranchByProduct();
        } else {
          this.filteredCities = this._filter(value.toString());
        }
        this.filteredCountryCities = this.filter_(value.toString());
      });
  }

  public filterBranchByProduct() {
    this.advisorService
      .Search(
        this.location,
        this.product.replace(/(?:^|\s)\S/g, (res) => {
          return res.toUpperCase();
        })
      )
      .subscribe((x) => this.GetProductBranches(x));
  }

  public GetProductBranches(advisers) {
    let branches = new Array<string>();
    for (let advisor in advisers) {
      branches.push(advisers[advisor].team_name);
    }
    this.filteredCities = branches;
  }

  public GetTitles() {
    this.advisorService.GetTitles(this.token).subscribe((x) => {
      this.titles = x;
    });
  }

  public GetGenders() {
    this.advisorService.GetGenders(this.token).subscribe((x) => {
      this.genders = x;
    });
  }

  public CheckAllowedWebLeads() {
    this.advisorService
      .CheckAllowedWebLeads(this.adviser.person_id, this.token)
      .subscribe((x) => {
        this.isWebLeadAllowed = x;

        this.isInitLoading = false;
      });
  }

  public resolved(captchaResponse: string) {
    this.captchaResolved = true;
  }

  public Save() {
    if (this.isLoading) return;

    this.MarkLeadFormAsTouched();

    if (this.leadForm.invalid || !this.captchaResolved) return;

    this.ExtractLeadForm();

    this.isLoading = true;

    this.advisorService
      .SendLead(this.sendLeadObject, this.token)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.BuildLeadForm();
          $("#modal-standard-sm").modal("hide");
          $("#email-response-modal").modal("show");
        })
      )
      .subscribe(
        (next) => {
          this.emailResponseMessage = "Email sent successfully";
        },
        (error) => {
          if (error.code == "lead--post--leadexists")
            this.emailResponseMessage =
              "Email not sent, You have already requested an advisor.";
          else
            this.emailResponseMessage =
              "Email not sent, please try again later.";
        }
      );
  }

  private MarkLeadFormAsTouched(): void {
    this.leadForm.controls.gender.markAsTouched();
    this.leadForm.controls.title.markAsTouched();
    this.leadForm.controls.initials.markAsTouched();
    this.leadForm.controls.firstName.markAsTouched();
    this.leadForm.controls.lastName.markAsTouched();
    this.leadForm.controls.emailAddress.markAsTouched();
    this.leadForm.controls.cellNumber.markAsTouched();
    this.leadForm.controls.idNumber.markAsTouched();
    this.leadForm.controls.province.markAsTouched();
    this.leadForm.controls.closestCityTown.markAsTouched();
    this.leadForm.controls.message.markAsTouched();
  }

  private ExtractLeadForm(): void {
    this.sendLeadObject.per_gender = this.leadForm.controls.gender.value;
    this.sendLeadObject.per_title = this.leadForm.controls.title.value;
    this.sendLeadObject.per_initial = this.leadForm.controls.initials.value;
    this.sendLeadObject.per_firstname = this.leadForm.controls.firstName.value;
    this.sendLeadObject.per_lastname = this.leadForm.controls.lastName.value;
    this.sendLeadObject.per_email = this.leadForm.controls.emailAddress.value;
    this.sendLeadObject.per_cellnr = this.leadForm.controls.cellNumber.value;
    this.sendLeadObject.lead_idnr = this.leadForm.controls.idNumber.value;

    this.sendLeadObject.message = `Province: ${this.leadForm.controls.province.value}
    City: ${this.leadForm.controls.closestCityTown.value}
    ${this.leadForm.controls.message.value}`;

    this.sendLeadObject.ibase_id = this.adviser.person_id;
  }

  private BuildLeadForm(): void {
    this.leadForm = this.formBuilder.group({
      gender: [0, Validators.compose([Validators.required])],
      title: ["", Validators.compose([Validators.required])],
      initials: ["", Validators.compose([Validators.required])],
      firstName: ["", Validators.compose([Validators.required])],
      lastName: ["", Validators.compose([Validators.required])],
      emailAddress: ["", Validators.compose([Validators.required])],
      cellNumber: ["", Validators.compose([Validators.required])],
      idNumber: [""],
      province: [""],
      closestCityTown: [""],
      message: [""],
    });

    this.leadForm.controls.closestCityTown.valueChanges
      .pipe(startWith(""))
      .subscribe((value) => {
        this.location = value.toString();
        this.filteredCities = this._filter(value.toString());
      });
  }
}
