<div class="container-fluid my-5">
    <div class="container-xl">
        <div class="row">
            <div class="col-12">
                <h2 class="mb-4" *ngIf="previousReports != undefined">
                    {{ previousReports.heading }}
                </h2>
              </div>
        </div>

        <div class="row d-flex my-3" *ngIf="previousReports.reports != undefined">
            <div class="card-deck"
                *ngIf="previousReports.reports.length > 0">
                <div class="card news--card"
                    *ngFor="let report of previousReports.reports">
                    <img *ngIf="report?.embededVideo == null" class="card-img-top" [src]="report?.image" alt="Card image cap" />
                    <iframe
                    *ngIf="report?.embededVideo != null"
                    class="card-vid-top"
                    [src]="report?.embededVideo | safe"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                    <div class="card-body d-flex flex-column">
                        <div class="card-tags pb-2">
                            <span
                              *ngFor="let tag of report.categories"
                              role="button"
                              class="basic-badge mb-1"
                              >{{ tag }}</span
                            >
                          </div>
                        <h5 class="small-heading mb-3">
                            {{ report.heading }}
                        </h5>
                        <p class="body-small" [innerHTML]="report.content"></p>
                        <a (click)="DynamicButtonClick(report)"
                         class="textlink mt-auto">
                         Read more
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>