<!-- saveAndInvest hero section -->
<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  class="container-fluid saveAndInvest"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>

<div
  class="container-xl h-100 saveAndInvestCard-Mt"
  *ngIf="content.header !== undefined"
>
  <div class="row d-flex">
    <div class="col-md-6">
      <div class="saveAndInvest-Card">
        <h2 class="large-heading">{{ content?.header?.cards[0]?.heading }}</h2>
        <div [innerHTML]="content?.header?.cards[0]?.content"></div>
        <div class="mt-4">
          <button-text
            *ngFor="let action of content?.header?.cards[0]?.actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline inverse'
            }}"
            [label]="action.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="saveAndInvest-Card">
        <h2 class="large-heading">{{ content?.header?.cards[1]?.heading }}</h2>
        <div [innerHTML]="content?.header?.cards[1]?.content"></div>
      </div>
      <div class="saveAndInvest-Card mt-4">
        <h2 class="large-heading">{{ content?.header?.cards[2]?.heading }}</h2>
        <div [innerHTML]="content?.header?.cards[2]?.content"></div>
      </div>
    </div>
  </div>
</div>
<!--/saveAndInvest hero section -->

<div *ngIf="content?.body != null">
  <!-- Savings div-->
  <div class="container-fluid py-5 my-5 svg-bg-l savings h-100">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12 savings-header">
          <div class="heading-border-left">
            <h2 class="mega-title--mobile">
              {{ content.body.sections[0].heading }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-xl mt-4">
      <div class="row">
        <div
          class="col-md-4"
          *ngFor="let card of content.body.sections[0].cards"
        >
          <div
            class="saving-card-bg-img"
            [ngClass]="{ 'saving-card-bg-img-height': card.isPrimary }"
            [style.backgroundImage]="'url(' + card.image + ')'"
          >
            <div class="saving--card">
              <h4 class="white">{{ card.heading }}</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="mx-auto">
          <button-text
            *ngFor="let action of content.body.sections[0].actions"
            class="mr-2"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
  <!--/ Savings div-->

  <!-- Investments -->
  <div class="container-fluid py-5 my-5 svg-bg Investments">
    <div class="container-xl">
      <div class="row d-flex">
        <div class="col-md-8 offset-md-4">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content.body.sections[1].heading }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container-xl mt-4">
      <div class="row d-flex align-items-center">
        <div class="col-md-6">
          <p class="cyan small-heading mb-2">
            {{ content.body.sections[1].sections[0].headline }}
          </p>
          <h2 class="large-heading">
            {{ content.body.sections[1].sections[0].heading }}
          </h2>
          <div [innerHTML]="content.body.sections[1].sections[0].content"></div>
          <div>
            <button-text
              *ngFor="
                let action of content.body.sections[1].sections[0].actions
              "
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div class="investement--card">
                <div>
                  <h4 class="black">
                    {{ content.body.sections[1].sections[1].cards[0].heading }}
                  </h4>
                  <div
                    class="body-small"
                    [innerHTML]="
                      content.body.sections[1].sections[1].cards[0].content
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="investement--card">
                <div style="align-self: flex-end">
                  <h4 class="black">
                    {{ content.body.sections[1].sections[1].cards[1].heading }}
                  </h4>
                  <div
                    class="body-small"
                    [innerHTML]="
                      content.body.sections[1].sections[1].cards[1].content
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-6">
              <div class="investement--card">
                <div>
                  <h4 class="black">
                    {{ content.body.sections[1].sections[1].cards[2].heading }}
                  </h4>
                  <div
                    class="body-small"
                    [innerHTML]="
                      content.body.sections[1].sections[1].cards[2].content
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="investement--card">
                <div>
                  <h4 class="black">
                    {{ content.body.sections[1].sections[1].cards[3].heading }}
                  </h4>
                  <div
                    class="body-small"
                    [innerHTML]="
                      content.body.sections[1].sections[1].cards[3].content
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Investments -->

  <!-- Retirement -->
  <div class="container-fluid py-5 my-5 svg-bg-l retirement h-100">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12 retirement-header">
          <div class="heading-border-left">
            <h2 class="mega-title--mobile">
              {{ content.body.sections[2].heading }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container-xl h-100">
      <div class="row d-flex align-items-center">
        <div class="col-md-6">
          <img
            alt=""
            class="img-cover significant-raise"
            [src]="content.body.sections[2].image"
          />
        </div>
        <div class="col-md-5 offset-md-1">
          <h2 class="large-heading">
            {{ content.body.sections[2].sections[0].heading }}
          </h2>
          <div [innerHTML]="content.body.sections[2].sections[0].content"></div>
          <div>
            <button-text
              *ngFor="
                let action of content.body.sections[2].sections[0].actions
              "
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Retirement -->

  <!-- Stockbroking -->
  <div class="container-fluid py-5 my-5 svg-bg stockbroking">
    <div class="container-xl h-100">
      <div class="row d-flex">
        <div class="col-md-8 offset-md-4">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content.body.sections[3].heading }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-5">
          <div>
            <h2 class="large-heading">
              {{ content.body.sections[3].sections[0].heading }}
            </h2>
            <div
              class="p-length-md"
              [innerHTML]="content.body.sections[3].sections[0].content"
            ></div>
            <div class="mt-2">
              <button-text
                *ngFor="
                  let action of content.body.sections[3].sections[0].actions
                "
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
        <div class="col-md-6 offset-md-1 mt-4 mt-md-0">
          <img [src]="content.body.sections[3].image" class="img-cover" />
        </div>
      </div>
    </div>
  </div>
  <!-- Stockbroking -->

  <!-- Recommended news -->
  <div
    class="container-fluid my-5 py-5 slate-bg-10"
    *ngIf="content?.body?.sections[4]?.heading != null"
  >
    <div class="container-xl h-100">
      <div class="row d-flex align-items-center justify-content-left h-100">
        <h2 class="mb-4">{{ content.body.sections[4].heading }}</h2>

        <div class="card-deck">
          <div
            class="card news--card"
            *ngFor="let news of content?.body?.sections[4]?.articles"
          >
            <img
              class="card-img-top"
              [src]="news?.image"
              alt="Card image cap"
            />
            <div class="card-body d-flex flex-column">
              <div class="card-tags pb-2">
                <span
                  *ngFor="let category of news?.categories"
                  role="button"
                  class="basic-badge"
                  data-toggle="modal"
                  data-target="#modal-article-tags"
                  (click)="
                    pageService.SetArticle(category); $event.preventDefault()
                  "
                  >{{ category }}</span
                >
              </div>
              <h5 class="small-heading">{{ news?.headline }}</h5>
              <p class="body-small" [innerHTML]="news?.excerpt"></p>
              <a [routerLink]="news?.uri" class="textlink mt-auto">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Recommended news -->
</div>
