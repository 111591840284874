export class InsuranceForYourselfPage {
  header: any = {};
  body: any = {};

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    for (let i = 0; i < this.body.products.lenght; i++) {
      this.body.products[i].content = this.body.products[i].content.replace(/<p>/g, '<p class="body-med">');
    }


    if (this.header.content)
      this.header.content = this.header.content.replace(/<p>/g, '<p class="p-length-sm text-shadow">');
  }
}
