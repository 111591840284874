import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { EsgInvestmentPhilosophy } from "src/app/models/content/save-and-invest/esg-investment-philosophy.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-esg",
  templateUrl: "./esg.component.html",
  styleUrls: [],
})
export class ESGComponent implements OnInit {
  fragment: string;
  public shareHeadline: string;
  public content: any = {};
  public teamMember: any;
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    public sanitizer: DomSanitizer,
    private hostElement: ElementRef,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<EsgInvestmentPhilosophy>(
        pageService.URIs.investorRelationsEsgPage
      )
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new EsgInvestmentPhilosophy(content);
        this.shareHeadline = this.content.header.heading?.replace("&", "%26"); // TODO: Temp workaround for & characters

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
        window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public goToURI(uri: string) {
    this.router.navigateByUrl(uri);
  }
}
