import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lastes-news-carousel',
  templateUrl: './lastes-news-carousel.component.html',
  styles: []
})
export class LastesNewsCarouselComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
