import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  content: any = {};
  isPageLoading: boolean = true;

  constructor(public pageService: PageService) { }

  ngOnInit() {
    this.getBannerContent();
  }

  getBannerContent() {

    this.pageService.GetPage(this.pageService.URIs.banner).pipe(finalize(() => { this.isPageLoading = false; })).subscribe((content) => {

      this.content = content;

    });

  }

}
