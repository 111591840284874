import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EmailTemplate } from "src/app/models/content/email/email-templates.model";
import { CalculatorService } from "src/app/services/calculator/calculator.service";
declare var $: any;

@Component({
  selector: "app-call-me-back-modal",
  templateUrl: "./call-me-back-modal.component.html",
  styles: [],
})
export class CallMeBackModalComponent implements OnInit {
  public callmeBackForm: FormGroup;

  //send email settings
  public contactUsResponseMsg: string;
  public isInitLoading: boolean;
  public isConsetCheckBoxChecked: boolean;
  private emailTemplate: EmailTemplate;

  constructor(
    private formBuilder: FormBuilder,
    private calculatorService: CalculatorService
  ) {
    this.callmeBackFormBuilder();
    this.contactUsResponseMsg = "";
    this.emailTemplate = new EmailTemplate();
    this.isConsetCheckBoxChecked = false;
    this.isInitLoading = true;
  }

  ngOnInit() { }

  private callmeBackFormBuilder(): void {
    this.callmeBackForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      surname: ["", [Validators.required]],
      contactNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("[- +()0-9]+"),
        ]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]),
      ],
      iagree: [, [Validators.required]],
    });
  }

  get formValidation() {
    return this.callmeBackForm.controls;
  }

  private produceFaceBookTrackingEvent() {
    try {
      (window as any).fbq("track", "CompleteRegistration");
    } catch (err) {
      return;
    }
  }

  private resetForm() {
    this.isConsetCheckBoxChecked = false;
  }

  public changeEvent(event) {
    let target = event.target.parentElement.children.iagree;
    target.checked = !target.checked;

    this.callmeBackForm.patchValue({
      iagree: target.checked,
    });

    this.isConsetCheckBoxChecked = target.checked;
  }

  public submitForm() {
    if (this.callmeBackForm.invalid) return;

    $("#modal-call-me-back").modal("hide");

    this.calculatorService
      .sendComms({
        Name: `${this.callmeBackForm.controls["name"].value} ${this.callmeBackForm.controls["surname"].value}`,
        Email: this.callmeBackForm.controls["email"].value,
        ContactNumber: this.callmeBackForm.controls["contactNumber"].value,
        Message: '',
        Subject: "Call me back",
        ToAddresses: ["social.leads.reporting@psg.co.za"],
      })
      .subscribe(
        (x) => {
          if (x) {
            this.contactUsResponseMsg = "Request successfully sent!";
            $("#modal-standard-sm").modal("show");
            this.produceFaceBookTrackingEvent();
          } else {
            this.contactUsResponseMsg = "Request not sent!";
            $("#modal-standard-sm").modal("show");
          }
          this.callmeBackForm.reset();
        },
        (error) => {
          this.contactUsResponseMsg = "Request not sent!";
          $("#modal-standard-sm").modal("show");
        }
      );

    this.resetForm();
    this.isInitLoading = false;
  }
}
