import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { FundResearchLandingPage } from "src/app/models/content/news-and-publications/fund-research-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-fund-research",
  templateUrl: "./fund-research.component.html",
  styleUrls: [],
})
export class FundResearchComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;
  public latestReports: any = [];
  public latestReportsKeywords: any = [];
  public previousPublications: any = [];
  public previousReports: any = [];
  public shareHeadline: string;
  public webinars: any = [];

  constructor(
    public pageService: PageService,
    private router: Router,
    public seoService: SeoService
  ) {
    pageService
      .GetResearchPage<FundResearchLandingPage>(pageService.URIs.fundResearch)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new FundResearchLandingPage(content);
        this.shareHeadline = this.content?.header?.headline?.replace(
          "&",
          "%26"
        );

        this.latestReports = this.content.body.sections
          .find((val) => val.type == "reports" &&
            val.heading.toLowerCase().includes("latest"));

        this.previousReports = this.content.body.sections
          .find((val) => val.type == "reports" &&
            val.heading.toLowerCase().includes("previous"));

        this.previousPublications = this.content.body.sections
          .find((val) => val.type == "articles" &&
            val.heading.toLowerCase().includes("previous"));

        this.webinars = this.content.body.sections
          .find((val) => val.type == "videos");

        this.latestReportsKeywords = [
          ...new Set(this.content.header.tags.map((item) => item.label)),
        ].map((keyword) => {
          return {
            label: keyword,
            selected: false,
          };
        });

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  ngOnInit(): void { }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public NavigateToSearch() {
    this.router.navigateByUrl("/search-results");
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }
}
