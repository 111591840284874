<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid news-page-hero"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title news-page text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="ml-0">
          <div class="row pl-4">
            <div class="col-md-12">
              <h3
                class="mb-4 text-shadow"
                [ngClass]="
                  content?.header?.subheadingColour
                    ? content?.header?.subheadingColour
                    : 'white'
                "
              >
                {{ content?.header?.subheading }}
              </h3>
              <div class="btn-row">
                <ng-container *ngFor="let action of content?.header?.actions">
                  <button-text
                    class="{{ action.isPrimary ? '' : '' }}"
                    buttonStyle="{{
                      action?.buttonColor
                        ? 'solid ' + action?.buttonColor
                        : action.isPrimary
                        ? 'solid'
                        : 'outline'
                    }}"
                    [label]="action?.label"
                    (click)="DynamicButtonClick(action)"
                  >
                  </button-text>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- The PSG Think Big Series -->
<div *ngIf="!isPageLoading" class="container-fluid py-4 my-4 h-100">
  <div class="container-xl h-100 heading-border-left text-center">
    <h1>{{ content?.header?.contentHeading }}</h1>
    <div [innerHTML]="content?.header?.content"></div>
  </div>
</div>
<!-- The PSG Think Big Series -->

<!-- Events -->
<div
  class="container-fluid py-3 my-3 h-100"
  *ngIf="content?.body?.categories?.length >= 1 && !isPageLoading"
>
  <div class="container-xl h-100">
    <div *ngFor="let event of content?.body?.categories[0]?.events">
      <div class="row">
        <div class="col-md-2 mb-4">
          <p
            class="mb-0 body-med body-bold"
            [innerHTML]="event?.dateTime | amDateFormat : 'dddd'"
          ></p>
          <p
            class="slate body-med"
            [innerHTML]="event?.dateTime | amDateFormat : 'DD MMMM YYYY'"
          ></p>
        </div>

        <div class="col-md-4 mb-4">
          <p
            class="mb-0 body-large body-bold"
            [innerHTML]="event?.headline"
          ></p>
          <p
            class="slate p-length-sm body-med"
            [innerHTML]="event?.excerpt"
          ></p>
        </div>

        <div class="col-md-3 mb-4">
          <p class="mb-0 body-med body-bold" [innerHTML]="event?.address"></p>
          <p class="mb-0 body-med body-bold" [innerHTML]="event?.location"></p>
          <p class="mb-0 body-med body-bold" [innerHTML]="event?.province"></p>
          <p
            class="slate body-med"
            [innerHTML]="event?.dateTime | amDateFormat : 'HH:mm'"
          ></p>
        </div>

        <div class="col-md-3 mb-4">
          <div class="btn-row-nowrap">
            <ng-container *ngFor="let action of event?.actions">
              <button-text
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Events -->

<!-- embedded videos -->
<div
  class="container-fluid py-3 my-3 h-100"
  *ngFor="let category of content?.body?.categories"
>
  <div *ngIf="!isPageLoading" class="container-xl">
    <div class="col-md-12">
      <h1 *ngIf="category?.video_sections.length > 0">{{ category?.name }}</h1>
    </div>
  </div>

  <div
    class="container-xl py-3 my-3 h-100"
    *ngFor="let videos of category.video_sections; let index = index"
  >
    <div
      class="row d-flex align-items-start justify-content-start"
      *ngIf="(0 === index % 2) == true && !isPageLoading"
    >
      <div class="col-md-6 col-sm-12 justify-content-start heading-border-left">
        <h4 class="large-heading cyan">{{ videos?.heading }}</h4>
        <div [innerHTML]="videos?.content"></div>
      </div>

      <div class="col-md-6 col-sm-12 greybg-right-large-event-webinairs">
        <div class="row d-flex justify-content-end mb-2">
          <div class="p-2">
            <button class="button simple-icon" (click)="shareToMail()">
              <icon-font
                name="icon-envelope"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToLinkedIn()">
              <icon-font
                name="icon-linkedin"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToTwitter()">
              <icon-font
                name="icon-twitter"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToFb()">
              <icon-font
                name="icon-facebook"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToWhatsApp()">
              <icon-font
                name="icon-whatsapp"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
          </div>
        </div>
        <div
          class="embed-responsive embed-responsive-16by9 card-round significant-raise"
        >
          <iframe
            width="520"
            height="270"
            [src]="videos?.url | safe"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>

    <div
      class="row mt-5 d-flex align-items-start justify-content-start"
      *ngIf="(0 === index % 2) == false && !isPageLoading"
    >
      <div
        class="heading-border-left col-md-6 col-sm-12 greybg-right-large-event-webinairs"
      >
        <div class="row d-flex justify-content-end mb-2">
          <div class="p-2">
            <button class="button simple-icon" (click)="shareToMail()">
              <icon-font
                name="icon-envelope"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToLinkedIn()">
              <icon-font
                name="icon-linkedin"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToTwitter()">
              <icon-font
                name="icon-twitter"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToFb()">
              <icon-font
                name="icon-facebook"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToWhatsApp()">
              <icon-font
                name="icon-whatsapp"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
          </div>
        </div>
        <div class="embed-responsive card-round embed-responsive-16by9">
          <iframe
            width="560"
            height="300"
            [src]="videos?.url | safe"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 p-5 justify-content-start">
        <h4 class="large-heading cyan">{{ videos?.heading }}</h4>
        <div [innerHTML]="videos?.content"></div>
      </div>
    </div>
  </div>
</div>
<!-- /embedded videos -->
