export class InsuranceForYourThingsPage {
  header: any = {};
  body: any = {};

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    for (let i = 0; i < this.body.products.lenght; i++) {
      this.body.products[i].content = this.body.products[i].content.replace(/<p>/g, '<p class="body-med">');
      this.body.products[i].content = this.body.products[i].content.replace(/<ul>/g, '<ul class="pl-md-3 pl-3">');
      this.body.products[i].content = this.body.products[i].content.replace(/<li>/g, '<li class="body-med dot-list mb-2">');
    }


    if (this.header.content)
      this.header.content = this.header.content.replace(/<p>/g, '<p class="p-length-sm text-shadow">');
  }
}
