<!-- Forms and Facts Sheets section -->
<div class="container-fluid reprtsAndNotices">
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div class="heading-border-left">
        <h1 class="mega-title--page-title text-shadow">Reports & notices</h1>
      </div>
    </div>
  </div>
</div>
<!-- Forms and Facts Sheets content section -->

<!-- Wealth -->
<div class="container-fluid py-5 my-5">
  <div class="container-xl">
    <div class="row">
      <div class="heading-border-left">
        <h2 class="mb-0">Notices</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="accordion" id="accordionPsg">
          <div class="accordian-inner">
            <div id="headingThree">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  PSG Global Funds SICAV Plc
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionPsg"
            >
              <div class="accordian-body">Content goes here ....</div>
            </div>
          </div>
        </div>

        <div class="accordion" id="accordionPsg1">
          <div class="accordian-inner">
            <div id="headingFour">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  PSG Wealth Global Preserver Fund of Funds (USD) IC Limited
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionPsg1"
            >
              <div class="accordian-body">Content goes here ....</div>
            </div>
          </div>
        </div>
        <div class="accordion" id="accordionPsg2">
          <div class="accordian-inner">
            <div id="headingFive">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  PSG Wealth Global Flexible Fund of Funds (USD) IC Limited
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionPsg2"
            >
              <div class="accordian-body">Content goes here ....</div>
            </div>
          </div>
        </div>
        <div class="accordion" id="accordionPsg3">
          <div class="accordian-inner">
            <div id="headingSix">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  PSG Wealth Global Flexible Fund of Funds (USD) IC Limited
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              id="collapseSix"
              class="collapse"
              aria-labelledby="headingSix"
              data-parent="#accordionPsg3"
            >
              <div class="accordian-body">Content goes here ....</div>
            </div>
          </div>
        </div>
        <div class="accordion" id="accordionPsg4">
          <div class="accordian-inner">
            <div id="headingSeven">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  PSG Wealth Global Flexible Fund of Funds (USD) IC Limited
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              id="collapseSeven"
              class="collapse"
              aria-labelledby="headingSeven"
              data-parent="#accordionPsg4"
            >
              <div class="accordian-body">Content goes here ....</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="accordion" id="accordionPsg5">
          <div class="accordian-inner">
            <div id="headingNine">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  PSG Wealth Global Flexible Fund of Funds (USD) IC Limited
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              id="collapseNine"
              class="collapse"
              aria-labelledby="headingNine"
              data-parent="#accordionPsg5"
            >
              <div class="accordian-body">Content goes here ....</div>
            </div>
          </div>
        </div>
        <div class="accordion" id="accordionPsg6">
          <div class="accordian-inner">
            <div id="headingTenth">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTenth"
                  aria-expanded="false"
                  aria-controls="collapseTenth"
                >
                  PSG Wealth Global Flexible Fund of Funds (USD) IC Limited
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              id="collapseTenth"
              class="collapse"
              aria-labelledby="headingTenth"
              data-parent="#accordionPsg6"
            >
              <div class="accordian-body">Content goes here ....</div>
            </div>
          </div>
        </div>
        <div class="accordion" id="accordionPsg7">
          <div class="accordian-inner">
            <div id="headingElevent">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseElevent"
                  aria-expanded="false"
                  aria-controls="collapseElevent"
                >
                  PSG Wealth Global Flexible Fund of Funds (USD) IC Limited
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              id="collapseElevent"
              class="collapse"
              aria-labelledby="headingEleventh"
              data-parent="#accordionPsg7"
            >
              <div class="accordian-body">Content goes here ....</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Wealth -->
