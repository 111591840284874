import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InvestmentProductsPage } from 'src/app/models/content/save-and-invest/investment-products.model';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-endowment-investment-plan',
  templateUrl: './endowment-investment-plan.component.html',
})
export class EndowmentInvestmentPlanComponent implements OnInit {

  public content: any = {};

  constructor(private pageService: PageService, private router: Router) {
    pageService.GetPage<InvestmentProductsPage>(pageService.URIs.investmentProducts).subscribe(content => {
      this.content = new InvestmentProductsPage(content);
    });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  ngOnInit() {
  }

}
