import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-insurance-quote',
  templateUrl: './lead-insurance-quote.component.html'
})
export class LeadInsuranceQuoteComponent implements OnInit {

  url = 'https://forms.office.com/r/8hQ0QUBnxB';

  constructor() { }

  ngOnInit() {
  }

}
