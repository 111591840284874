<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="container-fluid equity-research-hero mb-5 pb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div class="row d-flex align-content-start justify-content-center h-100">
      <div class="col-md-12 mt-5">
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title equity-research-header white text-shadow"
          >
            {{ content?.header?.heading }}
          </h1>
          <h3 class="white text-shadow">
            {{ content?.header?.subheading }}
          </h3>
        </div>
      </div>
      <div class="col-md-12 mb-5">
        <div class="pl-md-4">
          <div [innerHTML]="content?.header?.content"></div>
        </div>
        <div class="pl-md-4">
          <ng-container *ngFor="let action of content?.header?.actions">
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }} mr-2"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl banner-card">
  <div class="row d-flex">
    <div
      class="col-md-12"
      *ngIf="content?.header?.headerBannerContent.length > 0"
    >
      <div class="research-insights-hero-card">
        <h3 class="medium-heading cyan">
          {{ content?.header?.headerBannerContent[0].heading }}
        </h3>
        <p>{{ content?.header?.headerBannerContent[0].headline }}</p>
        <div
          [innerHTML]="content?.header?.headerBannerContent[0].content"
        ></div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid slate-bg-10 py-5 my-5 h-100">
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-6 col-xs-12 mb-4 mb-md-0 d-flex flex-column">
        <h2 class="mb-4">
          {{ content?.body?.sections[0]?.heading }}
        </h2>
        <p
          class="body-small"
          [innerHTML]="content?.body?.sections[0]?.content"
        ></p>
        <div class="btn-row mt-auto">
          <button-text
            *ngFor="let action of content?.body?.sections[0]?.actions"
            class="mr-2"
            buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
      <div class="col-md-6 col-xs-12 d-flex flex-column">
        <h2 class="mb-4">
          {{ content?.body?.sections[1]?.heading }}
        </h2>
        <p
          class="body-small"
          [innerHTML]="content?.body?.sections[1]?.content"
        ></p>
        <div class="btn-row mt-auto">
          <button-text
            *ngFor="let action of content?.body?.sections[1]?.actions"
            class="mr-2"
            buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
</div>

<app-avoiding-mistakes [content]="avoidingMistakesContent">
</app-avoiding-mistakes>

<app-frequently-asked-questions [faqList]="faqList">
</app-frequently-asked-questions>

<div class="container-xl py-5">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>
