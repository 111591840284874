import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { ArchivePage } from "src/app/models/content/about-us/psg-archive.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
@Component({
  selector: "app-archive",
  templateUrl: "./archive.component.html",
  styles: [],
})
export class ArchiveComponent implements OnInit {
  public content: any = {};

  public sortYears: any = [];
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<ArchivePage>(pageService.URIs.archive)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.sortYears = content.body.categories[0].categories;
        this.sortYears.sort((a, b) => b.name.localeCompare(a.name));

        this.content = new ArchivePage(content);

        this.sortYears = content.body.categories[0].categories;
        this.sortYears.sort((a, b) => b.name.localeCompare(a.name));

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.body?.categories[0]?.image,
          this.content.seo.description
        );
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }

  public concat(name: string, numberOne: number, numberTwo: number): string {
    return name.concat(numberOne.toString(), numberTwo.toString());
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
