<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- For your things header -->
<div *ngIf="!isPageLoading" class="container-fluid slides-scale mt-3 h-100">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <h2 class="mega-title--mobile">{{ content?.header?.heading }}</h2>
      </div>
    </div>
  </div>
</div>
<!--For your things/For yourself header -->

<div *ngIf="!isPageLoading" class="mx-auto" id="for-yourthings-carousel">
  <owl-carousel-o [options]="customOptions" *ngIf="!isPageLoading">
    <ng-template carouselSlide dataHash="motor">
      <app-motor-cover></app-motor-cover>
    </ng-template>

    <ng-template
      carouselSlide
      dataHash="home-contents-and-possessions"
      *ngIf="!isPageLoading"
    >
      <app-home-contents-possessions></app-home-contents-possessions>
    </ng-template>

    <ng-template
      carouselSlide
      dataHash="liability-and-accident-cover"
      *ngIf="!isPageLoading"
    >
      <app-liability-accident-cover></app-liability-accident-cover>
    </ng-template>

    <ng-template
      carouselSlide
      dataHash="value-add-insurance-cover"
      *ngIf="!isPageLoading"
    >
      <app-value-add-insurance-cover></app-value-add-insurance-cover>
    </ng-template>
  </owl-carousel-o>
</div>
