<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid glossary-page-hero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>
<!-- /hero section -->

<!-- search bar -->
<div *ngIf="!isPageLoading" class="container-xl mt-5">
  <div class="row">
    <div class="col-md-6">
      <form-field>
        <!-- Suffix - needs to be before the input -->
        <icon-font
          name="icon-search"
          colour="cyan"
          class="suffix clickable"
        ></icon-font>
        <!-- Input -->
        <input placeholder="Search by word or phrase" />
      </form-field>
    </div>
  </div>
</div>
<!-- /search bar -->

<!-- accordions -->
<div *ngIf="!isPageLoading" class="container-xl">
  <div class="row">
    <div class="col-md-6">
      <div *ngFor="let category of content?.body?.categories; let i = index">
        <div *ngIf="i < 13">
          <div class="accordion" [id]="'accordionOuterPsg' + i.toString()">
            <div class="accordian-inner">
              <div [id]="'heading' + i.toString()">
                <h2 class="mb-0">
                  <button
                    class="accordian-button collapsed"
                    type="button"
                    data-toggle="collapse"
                    [attr.data-target]="'#collapse' + i.toString()"
                    aria-expanded="false"
                    [attr.aria-controls]="'collapse' + i.toString()"
                  >
                    {{ category?.name }}
                    <icon-font name="icon-angle-up" class="icon-accordian">
                    </icon-font>
                  </button>
                </h2>
              </div>
              <div
                [id]="'collapse' + i.toString()"
                class="collapse"
                [attr.aria-labelledby]="'heading' + i.toString()"
                [attr.data-parent]="'#accordionOuterPsg' + i.toString()"
              >
                <div class="accordian-body">
                  <div
                    *ngFor="let item of category?.items; let sI = index"
                    class="accordion"
                    [id]="'accordionPsg' + sI.toString() + i.toString()"
                  >
                    <div class="accordian-inner">
                      <div [id]="'heading' + sI.toString() + i.toString()">
                        <h2 class="mb-0">
                          <button
                            class="accordian-button collapsed"
                            type="button"
                            data-toggle="collapse"
                            [attr.data-target]="
                              '#collapse' + sI.toString() + i.toString()
                            "
                            aria-expanded="false"
                            [attr.aria-controls]="
                              'collapse' + sI.toString() + i.toString()
                            "
                          >
                            {{ item?.term }}
                            <icon-font
                              name="icon-angle-up"
                              class="icon-accordian"
                            >
                            </icon-font>
                          </button>
                        </h2>
                      </div>
                      <div
                        [id]="concat('collapse', sI, i)"
                        class="collapse"
                        [attr.aria-labelledby]="concat('heading', sI, i)"
                        aria-expanded="false"
                        [attr.data-parent]="concat('#accordionPsg', sI, i)"
                      >
                        <div
                          class="accordian-body"
                          [innerHTML]="item?.definition"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div *ngFor="let category of content?.body?.categories; let i = index">
        <div *ngIf="i >= 13">
          <div class="accordion" [id]="'accordionOuterPsg' + i.toString()">
            <div class="accordian-inner">
              <div [id]="'heading' + i.toString()">
                <h2 class="mb-0">
                  <button
                    class="accordian-button collapsed"
                    type="button"
                    data-toggle="collapse"
                    [attr.data-target]="'#collapse' + i.toString()"
                    aria-expanded="false"
                    [attr.aria-controls]="'collapse' + i.toString()"
                  >
                    {{ category?.name }}
                    <icon-font name="icon-angle-up" class="icon-accordian">
                    </icon-font>
                  </button>
                </h2>
              </div>
              <div
                [id]="'collapse' + i.toString()"
                class="collapse"
                [attr.aria-labelledby]="'heading' + i.toString()"
                [attr.data-parent]="'#accordionOuterPsg' + i.toString()"
              >
                <div class="accordian-body">
                  <div
                    *ngFor="let item of category?.items; let sI = index"
                    class="accordion"
                    [id]="'accordionPsg' + sI.toString() + i.toString()"
                  >
                    <div class="accordian-inner">
                      <div [id]="'heading' + sI.toString() + i.toString()">
                        <h2 class="mb-0">
                          <button
                            class="accordian-button collapsed"
                            type="button"
                            data-toggle="collapse"
                            [attr.data-target]="
                              '#collapse' + sI.toString() + i.toString()
                            "
                            aria-expanded="false"
                            [attr.aria-controls]="
                              'collapse' + sI.toString() + i.toString()
                            "
                          >
                            {{ item?.term }}
                            <icon-font
                              name="icon-angle-up"
                              class="icon-accordian"
                            >
                            </icon-font>
                          </button>
                        </h2>
                      </div>
                      <div
                        [id]="'collapse' + sI.toString() + i.toString()"
                        class="collapse"
                        [attr.aria-labelledby]="
                          'heading' + sI.toString() + i.toString()
                        "
                        aria-expanded="false"
                        [attr.data-parent]="
                          '#accordionPsg' + sI.toString() + i.toString()
                        "
                      >
                        <div
                          class="accordian-body"
                          [innerHTML]="item?.definition"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /accordions -->
