export class AllArticle {
  title: string = "";
  byLine: string = "";
  imageUrl: string = "";
  publishDate: string = "";
  categories: string[] = [];
  excerpt: string = "";
  headline: string = "";
  body: string = "";
  uri: string = "";
  author: Author[] = [];

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    this.excerpt = this.excerpt.replace(/<p>/g, '<p class="mt-3">');
    this.body = this.body.replace(/<p>/g, '<p class="body-large mb-3">');
  }
}

export class Author {
  name: string = "";
}

export class MonthsDropdown implements SelectItem {
  label: string;
  value: number;

  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
  }
}

export class YearsDropdown {
  label: string;
  value: number;

  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
  }
}

export interface SelectItem {
  label?: string;
  value: any;
}
