import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-asset-management-client',
  templateUrl: './lead-asset-management-client.component.html'
})
export class LeadAssetManagementClientComponent implements OnInit {

  url = 'https://forms.office.com/r/ZfSS4b2uiu';

  constructor() { }

  ngOnInit() {
  }

}
