<!--mm-funds-house-view table-->
<div class="w-100">
  <div class="significant-raise table-card h-100">
    <!-- tabs -->
    <div
      class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between"
    >
      <div class="pl-2 large-heading">
        <h2>{{ content?.body?.sections[4]?.sections[0]?.heading }}</h2>
      </div>
      <div>
        <tab-horisontal
          [hidden]="showOnlyPriceAndPerformance == true"
          [tabSelected]="currentTab"
          (tabSelectedChange)="selectedTabChanged($event)"
          [tabs]="pageTabs"
          class="tab-nowrap"
        >
        </tab-horisontal>
      </div>
    </div>
    <!-- tabs -->

    <!--tables-->
    <div class="mt-4" [ngSwitch]="currentTab">
      <!-- overview -->
      <div *ngSwitchCase="'Overview'">
        <div>
          <div class="table-responsive">
            <!-- -------------------- -->
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" class="overview-1">ASISA category</th>
                  <th scope="col" class="overview-2">Fund fact sheet</th>
                  <th scope="col" class="overview-4">Reg 28 compliant</th>
                  <th scope="col" class="overview-5">
                    Available for TFIP* investment
                  </th>
                  <th scope="col" class="overview-6">
                    Only available via PSG advisers
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- ASISA category-1 with 1 fund name(s) -->
                <tr>
                  <td class="body-small" rowspan="4"></td>
                  <!--fund name 1-->
                  <td>
                    <a
                      href="https://download.psg.co.za/files/wealth/fund-fact-sheets/PSG-Wealth-House-View-SA-Equity-Portfolio.pdf"
                      target="_blank"
                      class="textlink"
                      rowspan="1"
                    >
                      PSG Wealth House View SA Equity Portfolio
                    </a>
                  </td>
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    <icon-font name="icon-check-circle" class="icon-md">
                    </icon-font>
                  </td>
                </tr>

                <!--fund name 2-->
                <tr>
                  <td>
                    <a
                      href="https://download.psg.co.za/files/wealth/fund-fact-sheets/PSG-Wealth-House-View-Offshore-Equity-Portfolio.pdf"
                      target="_blank"
                      class="textlink"
                      rowspan="1"
                    >
                      PSG Wealth House View Offshore Equity Portfolio
                    </a>
                  </td>
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    <icon-font name="icon-check-circle" class="icon-md">
                    </icon-font>
                  </td>
                </tr>

                <!--fund name 3-->
                <tr>
                  <td>
                    <a
                      href="https://download.psg.co.za/files/wealth/fund-fact-sheets/PSG-Wealth-House-View-Income-Growth-Equity-Portfolio.pdf"
                      target="_blank"
                      class="textlink"
                      rowspan="1"
                    >
                      PSG Wealth House View Income Growth Equity Portfolio
                    </a>
                  </td>
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    <icon-font name="icon-check-circle" class="icon-md">
                    </icon-font>
                  </td>
                </tr>

                <!--fund name 4-->
                <tr>
                  <td>
                    <a
                      href="https://download.psg.co.za/files/communications/PSG-Wealth-SA-Property-Equity-Portfolio.pdf"
                      target="_blank"
                      class="textlink"
                      rowspan="1"
                    >
                      PSG Wealth House View SA Property Portfolio
                    </a>
                  </td>
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    <icon-font name="icon-check-circle" class="icon-md">
                    </icon-font>
                  </td>
                </tr>
                <!-- ASISA category-1 with 1 fund name(s) -->
              </tbody>
            </table>
            <!-- -------------------- -->
          </div>
        </div>
      </div>
      <!-- /overview -->

      <!-- Price and performance -->
      <div *ngSwitchCase="'Price and performance'">
        <div>
          <div class="table-responsive">
            <!-- -------------------- -->
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th rowspan="2" class="pP-1">Fund display name</th>
                  <th class="pP-3 text-center" colspan="4">
                    Performance –As at
                    {{
                      fundPerformances?.length > 0
                        ? (fundPerformances[0].Date
                          | amDateFormat: "YYYY/MM/DD")
                        : ""
                    }}
                  </th>
                </tr>
                <tr>
                  <th>1 year %</th>
                  <th>3 year %</th>
                  <th>5 year %</th>
                  <th>Inception %</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let performance of fundPerformances">
                  <td>{{ performance?.Portfolio }}</td>
                  <td>
                    {{ formattingService.tryFormatDecimal(performance?.Y1, 2) }}
                  </td>
                  <td>
                    {{ formattingService.tryFormatDecimal(performance?.Y3, 2) }}
                  </td>
                  <td>
                    {{ formattingService.tryFormatDecimal(performance?.Y5, 2) }}
                  </td>
                  <td>
                    {{
                      formattingService.tryFormatDecimal(
                        performance?.Inception,
                        2
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /Price and performance -->
    </div>
    <!--tables-->
    <div *ngIf="showButton">
      <p
        class="body-small mb-0"
        [innerHTML]="content?.body?.sections[4]?.sections[0]?.content"
      ></p>
      <div class="container">
        <div class="row">
          <ng-container
            *ngFor="
              let action of content?.body?.sections[4]?.sections[0]?.actions
            "
          >
            <button-text
              class="mr-2 mb-2"
              buttonStyle="{{
                action?.buttonColor != null
                  ? action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="!showButton">
      <P class="body-small">
        PSG Wealth equity portfolio performance are shown gross of management
        fees, but net of brokerage and other trading costs.</P
      >
      <p class="body-small">
        The House view portfolios are bespoke solution portfolios and not part
        of the Collective Investment Schemes’ portfolios.
      </p>
      <button-text
        buttonStyle="solid"
        label="View fact sheets"
        [routerLink]="['/forms-and-Facts-sheets']"
        rowspan=""
      >
      </button-text>
    </div>
  </div>
</div>
<!--mm-funds-house-view table-->
