<!-- content-row -->
<div class="container-fluid slate-bg-10 p-0 h-100">
  <div class="container-xl my-3 py-3 h-100">
    <div class="row mb-5">
      <div class="col-lg-5 col-md-6 py-4">
        <h4 class="small-heading mb-2">
          {{ content?.body?.content[0]?.heading }}
        </h4>
        <p [innerHTML]="content?.body?.content[0]?.content"></p>
        <div class="row" *ngIf="content?.body?.content[0]?.actions?.length > 0">
          <div
            class="col-12 col-md-6"
            *ngFor="let action of content?.body?.content[0]?.actions"
          >
            <button
              type="button"
              class="button mb-2 w-100"
              ngClass="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }} mr-2"
              (click)="DynamicButtonClick(action)"
            >
              {{ action?.label }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1 col-md-6 position-relative">
        <div class="blue-line col-md-5 d-none d-lg-block"></div>
        <img
          src="{{ content?.body?.content[0]?.image }}"
          class="img-cover significant-raise card-round"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 offset-lg-1 col-md-6 position-relative">
        <div class="blue-line col-md-5 d-none d-lg-block"></div>
        <img
          src="{{ content?.body?.content[1]?.image }}"
          class="img-cover significant-raise card-round"
        />
      </div>
      <div class="col-lg-5 col-md-6 py-4">
        <h4 class="small-heading mb-2">
          {{ content?.body?.content[1]?.heading }}
        </h4>
        <p [innerHTML]="content?.body?.content[1]?.content"></p>
        <div class="row" *ngIf="content?.body?.content[1]?.actions?.length > 0">
          <div
            class="col-12 col-md-6"
            *ngFor="let action of content?.body?.content[1]?.actions"
          >
            <button
              type="button"
              class="button mb-2 w-100"
              ngClass="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }} mr-2"
              (click)="DynamicButtonClick(action)"
            >
              {{ action?.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /content-row -->
<app-call-me-back-modal></app-call-me-back-modal>
