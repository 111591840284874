import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ForYourselfPage } from "src/app/models/content/financial-advice/for-yourself.model";
import { PageService } from "src/app/services/page.service";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";

@Component({
  selector: "app-advice-for-yourself",
  templateUrl: "./advice-for-yourself.component.html",
})
export class AdviceForYourselfComponent implements OnInit, AfterViewInit {
  public content: any = {};

  public currentItem: string;
  public itemTabs: string[] = [];
  public fragment: string;
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    private route: ActivatedRoute,
    public seoService: SeoService,
    @Inject(DOCUMENT) document,
    private renderer: Renderer2
  ) {
    this.pageService
      .GetPage<ForYourselfPage>(
        this.pageService.URIs.financialAdviceForYourself
      )
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new ForYourselfPage(content);

        this.itemTabs = this.ItemTabs(content.body.sections[1].items);
        this.content.body.sections[1].content =
          this.content.body.sections[1].content.replace(
            /<p>/g,
            '<p class="p-lg-1"">'
          );

        if (this.currentItem == null) this.currentItem = this.itemTabs[0];

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });

    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
    });
  }

  onLoadFinished() {}

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public ItemTabs(items: any[]): string[] {
    var ret = [];
    items.forEach((x) => {
      ret.push(x.name);
    });
    return ret;
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  ngAfterViewInit() {
    this.scrollTimer();
  }

  public async scrollTimer() {
    setTimeout(() => {
      let doc = document.getElementById(this.fragment);
      if (doc != undefined) {
        window.scrollTo(0, doc.offsetTop - 120);
      } else {
        this.scrollTimer();
      }
    }, 1000);
  }
}
