import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SaveInvestService {

  constructor(private http: HttpClient) { }

  getEnhancedInterestFof() {
    return this.http.get<any>(`${environment.enhancedInterestFof.url}`, {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.enhancedInterestFof.value
      }
    });
  }

}
