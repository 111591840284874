import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeePage } from 'src/app/models/content/careers/employee-page.module';
import { PageService } from 'src/app/services/page.service';
import { SeoService } from 'src/app/services/socialLinks.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
})
export class EmployeeComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;
  public shareHeadline: string;

  constructor(public pageService: PageService,
    private router: Router,
    public seoService: SeoService) { 
    pageService
    .GetPage<EmployeePage>(pageService.URIs.careerEmployeePage)
    .subscribe((content) => {
      this.content = new EmployeePage(content);
      this.shareHeadline = this.content?.header?.heading?.replace("&", "%26"); 
      this.isPageLoading = false; 

      this.seoService.updateMetaInfoForPage(
        this.content.seo.title,
        window.location.href,
        this.content?.header?.image,
        this.content.seo.description
      );
    });
  }

  ngOnInit(): void {
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }
  
  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }
  
  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
        window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }

  jumpToSection(id) {
    document.getElementById(id).scrollIntoView(
      {
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
  }
}
