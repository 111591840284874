import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { ContactUsPage } from "src/app/models/content/contact-us/contact-us.model";
import { PageService } from "src/app/services/page.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AdvisorService } from "src/app/services/advisor.service";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";
declare var $: any;

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styles: [],
})
export class ContactUsComponent implements OnInit {
  public content: any = {};
  public contactUsForm: FormGroup = this.createContactUsForm();

  public contactUsResponse: boolean = false;
  public isInitLoading: boolean = true;
  public contactUsResponseMsg: string = "";
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private advisorService: AdvisorService,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<ContactUsPage>(pageService.URIs.contactUs)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        let split: ContactUsPage = new ContactUsPage(content);
        split.body.offices[0].physicalAddress =
          split.body.offices[0].physicalAddress.replace(/\|/g, "<br />");
        split.body.offices[1].physicalAddress =
          split.body.offices[1].physicalAddress.replace(/\|/g, "<br />");
        split.body.offices[2].physicalAddress =
          split.body.offices[2].physicalAddress.replace(/\|/g, "<br />");
        split.body.offices[3].physicalAddress =
          split.body.offices[3].physicalAddress.replace(/\|/g, "<br />");

        split.body.offices[0].postalAddress =
          split.body.offices[0].postalAddress.replace(/\|/g, "<br />");
        split.body.offices[1].postalAddress =
          split.body.offices[1].postalAddress.replace(/\|/g, "<br />");
        split.body.offices[2].postalAddress =
          split.body.offices[2].postalAddress.replace(/\|/g, "<br />");
        split.body.offices[3].postalAddress =
          split.body.offices[3].postalAddress.replace(/\|/g, "<br />");

        this.content = split;

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.open(action.url, "_blank");
    else this.router.navigateByUrl(action.url);
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  public createContactUsForm(): FormGroup {
    return this.formBuilder.group({
      name: [""],
      assitance: [""],
      emailAddress: [""],
      phoneNumber: [""],
    });
  }

  public findAnAdviserClicked() {
    if (this.contactUsForm.invalid) {
      this.contactUsResponseMsg = "Please complete all fields.";
      $("#modal-standard-sm").modal("show");
      return;
    }

    this.advisorService
      .sendComms({
        Name: this.contactUsForm.controls.name.value,
        Email: this.contactUsForm.controls.emailAddress.value,
        ContactNumber: this.contactUsForm.controls.phoneNumber.value,
        Message: this.contactUsForm.controls.assitance.value,
        Subject: "Contact Us",
        ToAddresses: ["social.leads.reporting@psg.co.za"],
      })
      .pipe(
        finalize(() => {
          $("#modal-standard-sm").modal("show");
        })
      )
      .subscribe((x) => {
        this.contactUsResponse = x;
        if (this.contactUsResponse == true) {
          this.contactUsResponseMsg = "Message successfully sent!";
        } else {
          this.contactUsResponseMsg = "Message not sent!";
        }
        this.isInitLoading = false;
      });
  }

  public showDownloadAccordionControl(office: any): boolean {
    if (office != undefined && office != null) {
      if (
        office.documentsHeading != "" &&
        office.documentsHeading != undefined
      ) {
        return true;
      }
    }

    return false;
  }
}
