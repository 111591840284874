<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="container-fluid markets-manual-hero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
      *ngIf="content.header !== undefined"
    >
      <div class="heading-border-left">
        <h1
          class="mega-title--page-title markets-manual-header white text-shadow"
        >
          {{ content?.header?.heading }}
        </h1>
        <h3 class="white text-shadow">{{ content?.header?.subheading }}</h3>
      </div>
      <div class="pl-md-4">
        <div [innerHTML]="content?.header?.content"></div>
      </div>
      <div class="pl-md-4">
        <ng-container *ngFor="let action of content?.header?.actions">
          <button-text
            class="{{ action.isPrimary ? '' : 'mr-2' }}"
            buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }} mr-2 mb-2"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="
    content.body != null && content.body.sections.length > 0 && !isPageLoading
  "
>
  <!-- Our latest content -->

  <div class="container-fluid pt-5 my-5 greybg-right h-100">
    <div class="container-xl h-100">
      <div class="row">
        <div class="col-md-10 offset-md-2">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content?.body?.sections[0]?.heading }}
            </h2>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="content?.body?.sections[0]?.cards.length > 0">
        <div
          class="card research-hub--card mb-4 mr-3 col-lg-2 col-sm-3"
          *ngFor="let card of content?.body?.sections[0]?.cards"
        >
          <div class="card-body d-flex flex-column">
            <div class="card-tags pb-2"></div>
            <h5 class="small-heading mb-3">
              {{ card.heading }}
            </h5>
            <p>{{ card.subheading }}</p>
            <p class="body-small" [innerHTML]="card.content"></p>
            <div class="text-center mb-4">
              <img
                *ngIf="card?.image != null"
                class="rounded-circle"
                width="100px"
                height="100px"
                [src]="card?.image"
                alt="icon"
              />
            </div>
            <div class="btn-row mt-auto">
              <button-text
                *ngFor="let action of card.actions"
                class="mr-2"
                buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Slides -->
  <div
    class="container-fluid pt-5 mt-5"
    *ngIf="content?.header?.headerBannerContent.length > 0"
  >
    <div class="container-xl">
      <div class="row mb-4 pl-3">
        <div
          class="d-flex flex-column align-content-start justify-content-md-center h-100 mt-5"
        >
          <h1 class="mb-4">
            {{ content?.header?.headerBannerContent[0].heading }}
          </h1>
        </div>
      </div>
      <div class="row d-flex mb-5" id="slide-viewer">
        <div class="col-12">
          <div class="markets-manual-hero-card">
            <h3 class="medium-heading cyan">
              {{ content?.header?.headerBannerContent[0].headline }}
            </h3>
            <div
              [innerHTML]="content?.header?.headerBannerContent[0].content"
            ></div>
            <div>
              <ng-container
                *ngFor="
                  let action of content?.header?.headerBannerContent[0].actions
                "
              >
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action.isPrimary ? 'solid' : 'outline'
                  }} mr-2"
                  [label]="action?.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-12">
          <div
            id="carouselMarketManualIndicators"
            class="carousel banner-carousel slide"
            data-ride="carousel"
          >
            <!-- carousel-indicators -->
            <ol class="carousel-indicators">
              <li
                data-target="#carouselMarketManualIndicators"
                *ngFor="let data of content?.header?.carousel; let i = index"
                attr.data-slide-to="{{ i }}"
                [ngClass]="i == 0 ? 'active' : ''"
              ></li>
            </ol>
            <!-- carousel-indicators -->

            <!-- carousel-content & image -->
            <div class="carousel-inner landing-p-carousel">
              <div
                data-interval="7000"
                class="carousel-item banner-carousel-item market-manual-carousel-item"
                *ngFor="
                  let data of content?.header?.carousel;
                  let index = index;
                  let last = last
                "
                [ngClass]="{ active: index === 0 }"
              >
                <img [src]="data.image" class="img-cover" />
                <div
                  class="carousel-caption landing-p-carousel-caption ml-1 ml-sm-3 py-4 d-flex flex-column"
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-md-11 offset-md-1">
                        <h1 class="landing-p-carousel-title">
                          {{ data.heading }}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div class="container pl-3 pl-md-4">
                    <div class="col-md-11 offset-md-1">
                      <div class="row">
                        <div class="col-md-12">
                          <h2 class="white">{{ data.headline }}</h2>
                          <div [innerHTML]="data.content"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-auto justify-content-sm-end">
                    <div class="mx-3">
                      <ng-container
                        *ngFor="let action of data.actions"
                        class="mb-3"
                      >
                        <button-text
                          class="primary mr-2"
                          buttonStyle="{{
                            action.isPrimary ? 'solid' : 'outline inverse'
                          }} mb-2"
                          [label]="action?.label"
                          (click)="DynamicButtonClick(action)"
                        >
                        </button-text>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <!-- carousel-content & image -->

              <a
                class="carousel-control-prev banner-carousel-ctr-prev market-indicator-prev"
                href="#carouselMarketManualIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next banner-carousel-ctr-next market-indicator-next"
                href="#carouselMarketManualIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="row">
            <div class="col-7">
              <div class="p-2">
                <button class="button simple-icon" (click)="shareToMail()">
                  <icon-font
                    name="icon-envelope"
                    colour="cyan"
                    class="body-large cyan"
                  ></icon-font>
                </button>
                <button class="button simple-icon" (click)="shareToLinkedIn()">
                  <icon-font
                    name="icon-linkedin"
                    colour="cyan"
                    class="body-large cyan"
                  ></icon-font>
                </button>
                <button class="button simple-icon" (click)="shareToTwitter()">
                  <icon-font
                    name="icon-twitter"
                    colour="cyan"
                    class="body-large cyan"
                  ></icon-font>
                </button>
                <button class="button simple-icon" (click)="shareToFb()">
                  <icon-font
                    name="icon-facebook"
                    colour="cyan"
                    class="body-large cyan"
                  ></icon-font>
                </button>
                <button class="button simple-icon" (click)="shareToWhatsApp()">
                  <icon-font
                    name="icon-whatsapp"
                    colour="cyan"
                    class="body-large cyan"
                  ></icon-font>
                </button>
              </div>
            </div>
            <div class="col-5 text-right">
              <ng-container>
                <button-text
                  class="mr-2"
                  label="Download"
                  buttonStyle="solid"
                  (click)="ViewSlide()"
                >
                </button-text>
                <button-text
                  class="mr-2"
                  label="Print"
                  buttonStyle="solid"
                  (click)="PrintSlide()"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Weekly event calendar -->

  <div class="container-fluid mt-5 pt-5 pb-5 greybg-left h-100">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-left transformY-25">
            <h2 class="section-title--mobile">Weekly event calendar</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-xl h-100">
      <div class="row pb-3">
        <div class="col-12">
          <h3 class="large-heading">
            {{ currentMonth }}
          </h3>
        </div>
      </div>
      <div class="card-deck" *ngIf="eventsCalendar?.length > 0">
        <div class="card news--card" *ngFor="let card of eventsCalendar">
          <div class="card-body d-flex flex-column">
            <h5 class="small-heading mb-3">
              {{ card.heading }}
            </h5>
            <p>{{ card.headline }}</p>
            <p class="body-small" [innerHTML]="card.content"></p>
            <div class="btn-row mt-auto">
              <button-text
                *ngFor="let action of card.actions"
                class="mr-2"
                buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="eventsCalendar?.length < 1" class="row">
        <div class="col-12">
          <p>There are no upcoming events.</p>
        </div>
      </div>
      <div class="row text-center mt-3">
        <div class="col-12">
          <div class="btn-row mt-auto">
            <button-text
              buttonStyle="solid"
              label="Monthly Calendar"
              (click)="RouteToWeekAhead()"
            >
            </button-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl py-5">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>
