export class EmployeePage { 
  header: any = {};
  body: any = {};

  constructor(init: any = {}) {
      for (const key of Object.keys(init)) {
          this[key] = init[key];
      }
  }
}
