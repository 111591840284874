<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!--  hero section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title home-page mb-1 text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
      <div class="pl-3 pl-md-4 pb-5">
        <div
          [innerHTML]="content?.header?.content"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
      </div>
      <div class="pl-3 pl-md-4 pb-5"></div>
    </div>
  </div>
</div>
<!-- hero section -->

<!-- cards -->
<div *ngIf="!isPageLoading" class="container-xl sens-card-row">
  <div class="row">
    <div class="col-lg-6 col-md-6 mb-4">
      <div class="blur-card h-100">
        <h3 class="large-heading mb-3">Share price feed</h3>
        <div *ngIf="!noTrades" class="shareFeed-table">
          <div class="table-responsive">
            <table class="table table-bordered table-hover mb-1">
              <thead>
                <tr>
                  <th colspan="3">
                    {{ sharePriceFeed?.Description }} -
                    {{ sharePriceFeed?.Date }}
                  </th>
                </tr>
                <tr>
                  <th>Trade time</th>
                  <th>Price</th>
                  <th>Volume</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trade of sharePriceFeed?.Trades">
                  <td>{{ trade?.Time }}</td>
                  <td>{{ trade?.Price }}</td>
                  <td>{{ trade?.Volume }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="noTrades" class="shareFeed-table">
          <div class="table-responsive">
            <table class="table table-bordered table-hover mb-1">
              <thead>
                <tr>
                  <th
                    *ngIf="noTrades && tradeDate != 'Invalid date'"
                    colspan="3"
                  >
                    {{ sharePriceFeed?.Description }} - {{ tradeDate }}
                  </th>
                  <th
                    *ngIf="noTrades && tradeDate === 'Invalid date'"
                    colspan="3"
                  >
                    {{ sharePriceFeed?.Description }}
                  </th>
                </tr>
                <tr>
                  <th>Trade time</th>
                  <th>Price</th>
                  <th>Volume</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trade of TradesPrevious">
                  <td>{{ trade?.Time }}</td>
                  <td>{{ trade?.Price }}</td>
                  <td>{{ trade?.Volume }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 mb-4">
      <div class="blur-card h-100">
        <h3 class="large-heading mb-3">Last trade</h3>
        <h6 *ngIf="noTrades && tradeDate != 'Invalid date'">
          No trades today showing {{ tradeDate }}
        </h6>
        <h6 *ngIf="noTrades && tradeDate === 'Invalid date'">
          No trades today
        </h6>
        <div
          *ngIf="graphData != null && !noTrades && tradeDate != 'Invalid date'"
        >
          <canvas
            baseChart
            width="400"
            height="400"
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [type]="lineChartType"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /cards -->

<!-- SENS articles -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 mt-5 greybg-right">
  <div class="container-xl">
    <div class="row justify-content-end">
      <div class="col-md-10 col-lg-10">
        <div class="heading-border-right d-flex justify-content-end">
          <h2 class="mega-title--mobile text-right">SENS articles</h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-10 col-md-12 offset-lg-1">
        <div class="card-round significant-raise white-bg p-4">
          <div class="p-lg-1">
            <div>
              <h3 class="large-heading my-2">SENS</h3>
              <p class="body-small">
                SENS announcements are only displayed on this page 15 mins after
                being released by the JSE.
              </p>
            </div>

            <!-- search form -->
            <div>
              <form [formGroup]="sensArticleSearchForm">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <form-field>
                      <!-- Suffix - needs to be before the input -->
                      <icon-font
                        name="icon-search"
                        class="suffix clickable"
                      ></icon-font>
                      <!-- Input -->
                      <input
                        placeholder="Search..."
                        formControlName="searchText"
                      />
                    </form-field>
                  </div>
                </div>
              </form>
            </div>
            <!-- /search form -->

            <!-- filter by -->
            <div>
              <div>
                <h4 class="body-large body-bold mt-3">Filter by period:</h4>
              </div>
              <form [formGroup]="sensArticleDateSearchForm">
                <div class="row no-gutters">
                  <div
                    class="col-sm-12 col-md-6 d-flex flex-row align-items-end"
                  >
                    <div class="col-7 col-sm-8 col-md-8 px-0">
                      <form-field>
                        <label class="body-small body-bold">From:</label>
                        <dropdown-select>
                          <select formControlName="fromMonth">
                            <option value="Month">Months</option>
                            <option
                              *ngFor="let month of months"
                              [value]="month"
                            >
                              {{ month }}
                            </option>
                          </select>
                        </dropdown-select>
                      </form-field>
                    </div>
                    <div class="col-5 col-sm-4 col-md-4">
                      <form-field>
                        <dropdown-select *ngIf="yearsLoaded == true">
                          <select formControlName="fromYear">
                            <option value="Year">Year</option>
                            <option *ngFor="let year of years" [value]="year">
                              {{ year }}
                            </option>
                          </select>
                        </dropdown-select>
                      </form-field>
                    </div>
                  </div>

                  <div
                    class="col-sm-12 col-md-6 d-flex flex-row align-items-end"
                  >
                    <div class="col-7 col-sm-8 col-md-8 px-0 pl-md-3">
                      <form-field>
                        <label class="body-small body-bold">To:</label>
                        <dropdown-select>
                          <select formControlName="toMonth">
                            <option value="Month">Months</option>
                            <option
                              *ngFor="let month of months"
                              [value]="month"
                            >
                              {{ month }}
                            </option>
                          </select>
                        </dropdown-select>
                      </form-field>
                    </div>
                    <div class="col-5 col-sm-4 col-md-4 pr-sm-0">
                      <form-field>
                        <dropdown-select *ngIf="yearsLoaded == true">
                          <select formControlName="toYear">
                            <option value="Year">Year</option>
                            <option *ngFor="let year of years" [value]="year">
                              {{ year }}
                            </option>
                          </select>
                        </dropdown-select>
                      </form-field>
                    </div>
                  </div>
                </div>
              </form>
              <!-- /filter by -->
            </div>
            <!-- search/filter results -->
            <div class="sens-results-container mb-3">
              <div
                class="sens-results py-4"
                *ngFor="let article of sensArticleData"
              >
                <div class="row">
                  <div class="col-sm-2 col-md-2 col-lg-2">
                    <h2 class="large-heading mb-0">
                      {{ article?.DateStamp | amDateFormat : "DD" }}
                    </h2>
                    <p class="body-small">
                      {{ article?.DateStamp | amDateFormat : "MMM YYYY" }}
                    </p>
                  </div>
                  <div class="col-sm-7 col-md-7 col-lg-8">
                    <h3 class="small-heading mb-1">{{ article.Title }}</h3>
                    <p class="body-small">
                      <em>Posted at</em> -
                      <span class="cyan">{{ article?.TimeStamp }}</span>
                    </p>
                  </div>
                  <div
                    class="col-sm-3 col-md-3 col-lg-2 d-flex justify-content-sm-end"
                  >
                    <button-text
                      buttonStyle="shaded"
                      label="Read more"
                      (click)="ReadMore(article)"
                    >
                    </button-text>
                  </div>
                </div>
              </div>
            </div>
            <!-- search/filter results -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /SENS articles -->

<!-- OTC trading history -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 mt-5 greybg-left">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left d-inline-flex">
          <h2 class="section-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row align-items-lg-center">
      <div class="col-md-6 col-lg-6 mb-4">
        <div>
          <img
            [src]="content?.body?.sections[0]?.image"
            class="img-cover significant-raise"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-5 offset-lg-1">
        <div
          [innerHTML]="content?.body?.sections[0]?.sections[0].content"
        ></div>
        <ng-container
          *ngFor="let action of content?.body?.sections[0]?.sections[0].actions"
        >
          <button-text
            class="{{ action.isPrimary ? '' : 'mr-2' }}"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- /OTC trading history -->

<!-- Dividend history -->

<div *ngIf="!isPageLoading" class="container-fluid py-5 mt-5 greybg-right">
  <div class="container-xl">
    <div class="row justify-content-end">
      <div class="col-md-10 col-lg-10">
        <div class="heading-border-right d-flex justify-content-end">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[1]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-7 offset-md-4 col-lg-5 offset-lg-4">
        <div class="">
          <div
            [innerHTML]="content?.body?.sections[1]?.sections[0].content"
          ></div>
          <ng-container
            *ngFor="
              let action of content?.body?.sections[1]?.sections[0].actions
            "
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12 col-lg-10 offset-lg-1">
        <div class="table-card significant-raise">
          <div class="table-responsive">
            <!-- -------------------- -->
            <table class="table table-bordered table-hover mb-1">
              <div
                [innerHTML]="content?.body?.sections[1].sections[1]?.content"
              ></div>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Dividend history -->
