<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- how to invest hero section -->
<div
  class="container-fluid howto-invest"
  *ngIf="content.header !== undefined"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content.header.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
    </div>
  </div>
</div>

<!-- talk to adviser content section -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5 svg-bg-r how-to-i">
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div class="heading-border-right d-inline-flex justify-content-start">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row d-flex align-items-center justify-content-between pt-md-4">
      <div class="col-md-5 offset-md-1 order-2 order-md-1 mt-4 mt-md-0">
        <p
          class="p-length-md"
          [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
        ></p>
        <div class="mt-4">
          <ng-container
            *ngFor="
              let action of content?.body?.sections[0]?.sections[0]?.actions
            "
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6 order-1 order-md-2">
        <img
          [src]="content?.body?.sections[0]?.image"
          class="img-cover significant-raise"
        />
      </div>
    </div>
  </div>
</div>

<!-- invest online content section -->

<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5 svg-bg-l how-to-i">
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-10">
        <div class="heading-border-left d-inline-flex justify-content-start">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[1]?.heading }}
          </h2>
        </div>
      </div>
    </div>

    <div class="row d-flex align-items-center justify-content-between pt-md-4">
      <div class="col-md-6">
        <img
          [src]="content?.body?.sections[1]?.image"
          class="img-cover significant-raise"
        />
      </div>
      <div class="col-md-5 offset-md-1 mt-4 mt-md-0">
        <p
          class="p-length-md"
          [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
        ></p>
        <div class="mt-4">
          <ng-container
            *ngFor="
              let action of content?.body?.sections[1]?.sections[0]?.actions
            "
          >
            <button-text
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Investment & Savings accordions -->

<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5">
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-8 offset-md-4">
        <div class="heading-border-right justify-content-end">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[2]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <!-- accordion tfip -->
        <div
          class="accordion"
          [id]="'accordionTfip' + removeWhiteSpace(item.heading)"
          *ngFor="let item of content?.body?.sections[2]?.items"
        >
          <div class="accordian-inner">
            <div [id]="'Tfip' + removeWhiteSpace(item.heading)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapseTfip' + removeWhiteSpace(item.heading)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapseTfip' + removeWhiteSpace(item.heading)
                  "
                >
                  {{ item.heading }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapseTfip' + removeWhiteSpace(item.heading)"
              class="collapse"
              [attr.aria-labelledby]="'Tfip' + removeWhiteSpace(item.heading)"
              [attr.data-parent]="
                '#accordionTfip' + removeWhiteSpace(item.heading)
              "
            >
              <div class="accordian-body">
                <div [innerHTML]="item?.content"></div>
                <div>
                  <ng-container *ngFor="let action of item?.actions">
                    <button-text
                      class="{{ action.isPrimary ? '' : 'mr-2' }}"
                      buttonStyle="{{
                        action?.buttonColor
                          ? 'solid ' + action?.buttonColor
                          : action.isPrimary
                          ? 'solid'
                          : 'outline'
                      }}"
                      [label]="action.label"
                      (click)="DynamicButtonClick(action)"
                    >
                    </button-text>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /accordion tfip -->
      </div>
    </div>
  </div>
</div>

<!-- Retirement accordions -->

<div *ngIf="!isPageLoading" class="container-fluid py-5 my-5">
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-10">
        <div class="heading-border-left d-inline-flex">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[3]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <!-- accordion Retirement Annuity -->
        <div
          class="accordion"
          [id]="'accordionRA' + removeWhiteSpace(item.heading)"
          *ngFor="let item of content?.body?.sections[3]?.items"
        >
          <div class="accordian-inner">
            <div [id]="'RA' + removeWhiteSpace(item.heading)">
              <h2 class="mb-0">
                <button
                  class="accordian-button collapsed"
                  type="button"
                  data-toggle="collapse"
                  [attr.data-target]="
                    '#collapseRA' + removeWhiteSpace(item.heading)
                  "
                  aria-expanded="false"
                  [attr.aria-controls]="
                    'collapseRA' + removeWhiteSpace(item.heading)
                  "
                >
                  {{ item.heading }}
                  <icon-font
                    name="icon-angle-up"
                    class="icon-accordian"
                  ></icon-font>
                </button>
              </h2>
            </div>
            <div
              [id]="'collapseRA' + removeWhiteSpace(item.heading)"
              class="collapse"
              [attr.aria-labelledby]="'RA' + removeWhiteSpace(item.heading)"
              [attr.data-parent]="
                '#accordionRA' + removeWhiteSpace(item.heading)
              "
            >
              <div class="accordian-body">
                <div [innerHTML]="item?.content"></div>
                <div>
                  <ng-container *ngFor="let action of item?.actions">
                    <button-text
                      class="{{ action.isPrimary ? '' : 'mr-2' }}"
                      buttonStyle="{{
                        action?.buttonColor
                          ? 'solid ' + action?.buttonColor
                          : action.isPrimary
                          ? 'solid'
                          : 'outline'
                      }}"
                      [label]="action.label"
                      (click)="DynamicButtonClick(action)"
                    >
                    </button-text>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /accordion Retirement Annuity -->
      </div>
    </div>
  </div>
</div>
