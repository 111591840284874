import {
  Component,
  OnInit,
  Input,
  Inject,
  AfterViewInit,
  Renderer2,
} from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { RouterModule } from "@angular/router";
import { PageService } from "src/app/services/page.service";
import { InsuranceForYourselfPage } from "src/app/models/content/insurance/for-yourself.model";
import { DOCUMENT } from "@angular/common";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";

@Component({
  selector: "app-for-yourselft",
  templateUrl: "./for-yourselft.component.html",
  styleUrls: [],
})
export class ForYourselftComponent implements OnInit, AfterViewInit {
  @Input() dataHash: string;

  customOptions: OwlOptions = {
    loop: true,
    animateOut: "",
    animateIn: "",
    slideTransition: "ease-in-out",
    URLhashListener: true,
    startPosition: "URLHash",
    autoHeight: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="icon icon-angle-left"></i>',
      '<i class="icon icon-angle-right"></i>',
    ],

    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };

  public content: any = {};
  public fragment: string;
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    private route: ActivatedRoute,
    private RouterModule: RouterModule,
    public seoService: SeoService,
    @Inject(DOCUMENT) document,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<InsuranceForYourselfPage>(pageService.URIs.insuranceForYourself)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new InsuranceForYourselfPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.body?.products[0]?.image,
          this.content.seo.description
        );
      });

    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
    });
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }

  ngAfterViewInit() {
    this.scrollTimer();
  }

  public async scrollTimer() {
    setTimeout(() => {
      let doc = document.getElementById(this.fragment);
      if (doc != undefined) {
        window.scrollTo(0, doc.offsetTop - 120);
      } else {
        this.scrollTimer();
      }
    }, 1000);
  }
}
