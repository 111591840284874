import { Component, OnDestroy, OnInit } from '@angular/core';
import { DailyInvestmentService } from 'src/app/services/dailyInvestment/daily-investment.service';
import { MarketIndicatorResponse } from 'src/app/services/dailyInvestment/daily-ivestment.model';
import { FormattingService } from 'src/app/services/formatting.service';

@Component({
  selector: 'app-share-price',
  templateUrl: './share-price.component.html',

})
export class SharePriceComponent implements OnInit, OnDestroy {

  public locality:string = "OTHER-MARKET";
  public alive:boolean = true;
  public MarketIndicators:Array<MarketIndicatorResponse>;



  constructor(
    private dailyInvestmentService:DailyInvestmentService,
    public formattingService:FormattingService
  ) { }

  public ngOnInit() {
    this.getMarketIndicators();
  }

  public ngOnDestroy(){
    this.alive = false;
  }

  public getMarketIndicators(){
    this.dailyInvestmentService.getMarketIndicators({
      category:"MARKET-INDEX",
      date: "",
      locality:this.locality,
      pageNumber:1,
      pageSize:50
    })
    .subscribe((result) => {
      this.MarketIndicators = result;
    })
  }

}
