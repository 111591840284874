<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- hero section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid legal-hero-section"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div
      class="row d-flex flex-column align-content-start justify-content-center h-100"
    >
      <div *ngIf="!isPageLoading" class="heading-border-left">
        <h1
          class="mega-title--page-title mb-0 text-shadow"
          [ngClass]="
            content?.header?.headingColour
              ? content?.header?.headingColour
              : 'dark-black'
          "
        >
          {{ content?.header?.heading }}
        </h1>
      </div>
      <div class="pl-3 pl-md-4">
        <div
          [innerHTML]="content?.header?.excerpt"
          [ngClass]="
            content?.header?.contentColour
              ? content?.header?.contentColour
              : 'white'
          "
        ></div>
      </div>
    </div>
  </div>
</div>
<!-- /hero section -->

<div
  *ngIf="!isPageLoading"
  class="container-xl mb-5 legal-hero-section--cards_ py-5"
>
  <div class="row d-flex">
    <div class="col-md-8 offset-md-2">
      <div
        class="card-round pt-5 pb-3 mb-4 px-4 extreme-raise white-bg"
        *ngFor="let content of content?.body?.categories"
      >
        <div>
          <h2 class="large-heading">{{ content.name }}</h2>
          <ul>
            <li
              class="cyan"
              style="list-style: none"
              *ngFor="let download of content.downloads"
            >
              <a
                class="textlink"
                target="_blank"
                [href]="download?.url"
                *ngIf="download?.url"
                >{{ download?.label }}</a
              >
              <a
                class="textlink"
                target="_blank"
                [href]="download?.file"
                *ngIf="download?.file"
                >{{ download?.label }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
