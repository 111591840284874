<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- For yourself header -->
<div *ngIf="!isPageLoading" class="container-fluid slides-scale mt-3 h-100">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <h2 class="mega-title--mobile">{{ content?.header?.heading }}</h2>
      </div>
    </div>
  </div>
</div>
<!--/For yourself header -->

<div *ngIf="!isPageLoading" class="mx-auto" id="for-yourself-carousel">
  <owl-carousel-o [options]="customOptions" *ngIf="!isPageLoading">
    <ng-template carouselSlide dataHash="medical-aid">
      <app-medical-aid></app-medical-aid>
    </ng-template>

    <ng-template carouselSlide dataHash="gap-cover">
      <app-gap-cover></app-gap-cover>
    </ng-template>

    <ng-template carouselSlide dataHash="life-cover">
      <app-life-cover></app-life-cover>
    </ng-template>

    <ng-template carouselSlide dataHash="income-products">
      <app-income-products></app-income-products>
    </ng-template>

    <ng-template carouselSlide dataHash="disability-cover">
      <app-disability-cover></app-disability-cover>
    </ng-template>

    <ng-template carouselSlide dataHash="dread-disease-cover">
      <app-dread-disease-cover></app-dread-disease-cover>
    </ng-template>
  </owl-carousel-o>
</div>
