export class Falcon {
  header: any = {};
  body: any = {};
  content: any = {};

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    if (this.header.content)
      this.header.content = this.header.content.replace(
        /<p>/g,
        '<p class="p-length-sm text-shadow">'
      );

    if (this.body.sections[2].sections[0].cards)
      this.body.sections[2].sections[0].cards[0].content = this.body.sections[2].sections[0].cards[0].content.replace(
        /<p>/g,
        '<p class="p-length-sm text-shadow">'
      );

    if (this.body.sections[2].sections[0].cards[1].content)
      this.body.sections[2].sections[0].cards[1].content = this.body.sections[2].sections[0].cards[1].content.replace(
        /<p>/g,
        '<p class="p-length-sm text-shadow">'
      );

  }
}
