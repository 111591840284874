import { Component, OnInit, ElementRef, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Falcon } from "src/app/models/content/campaign/falcon.model";
import { CanonicalService } from "src/app/services/canonical-services/shared/canonical.service";
import { PageService } from "src/app/services/page.service";
import { AdvisorService } from "src/app/services/advisor.service";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";
declare var $: any;

@Component({
  selector: "app-falcon",
  templateUrl: "./falcon.component.html",
  styles: [],
})
export class FalconComponent implements OnInit {
  public isLoading: boolean = false;
  public leadForm: FormGroup;
  public sendLeadObject: any = {};
  public fragment: string;
  public content: any = {};

  public isConsetCheckBoxChecked: boolean = false;

  public defaultBannerUrl: string =
    "../../../../assets/images/home-page/hp-za.png";
  public selectedItemsList = [];
  public checkedIDs = [];
  public isOtherInterestChecked = false;
  public selectedCheckbox: string = "";
  public selectedCheckboxes = [];

  public card1_bg_Image: string;
  public card2_bg_Image: string;

  public contactUsResponse: boolean = false;
  public isInitLoading: boolean = true;
  public contactUsResponseMsg: string = "";

  public isContactNumberRequired: boolean = true;
  public isEmailRequired: boolean = true;
  public otherInterest: string = "";
  public email: string = "";

  public shareHeadline: string;
  public isPageLoading: boolean = true;

  // checkboxesDataList
  checkboxesDataList = [
    { label: "Wealth", id: "00", isChecked: false },
    { label: "Insurance", id: "01", isChecked: false },
    { label: "Other", id: "02", isChecked: false },
  ];
  constructor(
    private pageService: PageService,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private hostElement: ElementRef,
    private formBuilder: FormBuilder,
    private canonicalService: CanonicalService,
    private advisorService: AdvisorService,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    this.BuildLeadForm();
    this.BindValueChangeEvents();
    this.pageService
      .GetCampaign("campaign-3")
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new Falcon(content);
        this.shareHeadline = this.content.header.heading.replace("&", "%26"); // TODO: Temp workaround for & characters

        const iframe = this.hostElement.nativeElement.querySelector("iframe");
        iframe.src = this.content.body.sections[0].actions[0].url;
        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );

        if (
          content.body.sections[2].sections[0].cards[0].image != null &&
          content.body.sections[2].sections[0].cards[0].image != undefined
        ) {
          this.card1_bg_Image = `${content.body.sections[2].sections[0].cards[0].image}`;
        } else {
          this.card1_bg_Image = this.defaultBannerUrl;
        }

        if (
          content.body.sections[2].sections[0].cards[1].image != null &&
          content.body.sections[2].sections[0].cards[0].image != undefined
        ) {
          this.card2_bg_Image = `${content.body.sections[2].sections[0].cards[1].image}`;
        } else {
          this.card2_bg_Image = this.defaultBannerUrl;
        }
        this.route.fragment.subscribe((fragment: string) => {
          this.fragment = fragment;
        });
      });
  }

  ngOnInit() {
    this.fetchSelectedItems();
    this.fetchCheckedIDs();
    this.canonicalService.setCanonicalURL();
    this.seoService.setJsonLd(this.renderer, null);
  }

  public sanitizeURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngAfterViewInit() {
    this.scrollTimer();
  }

  public goToURI(uri: string) {
    this.router.navigateByUrl(uri);
  }

  public async scrollTimer() {
    setTimeout(() => {
      let doc = document.getElementById(this.fragment);
      if (doc != undefined) {
        window.scrollTo(0, doc.offsetTop - 120);
      } else {
        this.scrollTimer();
      }
    }, 1000);
  }

  public changeSelection(checkBoxLabel) {
    this.selectedCheckbox = checkBoxLabel;
    this.fetchSelectedItems();
  }

  public fetchSelectedItems() {
    this.selectedItemsList = this.checkboxesDataList.filter(
      (value) => value.label == "Other" && value.isChecked
    );
    if (this.selectedItemsList != null && this.selectedItemsList.length > 0) {
      this.isOtherInterestChecked = true;
      this.selectedCheckbox = "Other";
    } else {
      this.isOtherInterestChecked = false;
    }
  }

  public fetchCheckedIDs() {
    this.checkedIDs = [];
    this.checkedIDs = this.checkboxesDataList.filter((x) => x.isChecked);
  }

  private produceFaceBookTrackingEvent() {
    (window as any).fbq("track", "CompleteRegistration");
  }

  public contactus() {
    this.MarkLeadFormAsTouched();

    if (this.leadForm.invalid) {
      return;
    }

    this.advisorService
      .sendComms({
        Name: this.leadForm.controls.firstName.value + ' ' + this.leadForm.controls.surname.value,
        Email: this.leadForm.controls.email.value,
        ContactNumber: this.leadForm.controls.contactNumber.value,
        Message: '',
        Subject: "Message Me",
        ToAddresses: ["social.leads.reporting@psg.co.za"],
      })
      .subscribe(
        (x) => {
          this.contactUsResponse = x;
          if ((this.contactUsResponse = true)) {
            this.contactUsResponseMsg = "Email successfully sent!";
            this.produceFaceBookTrackingEvent();
            $("#modal-standard-sm").modal("show");
          } else {
            this.contactUsResponseMsg = "Email not sent!";
            $("#modal-standard-sm").modal("show");
          }
        },
        (error) => {
          this.contactUsResponseMsg = "Email not sent!";
          $("#modal-standard-sm").modal("show");
        }
      );

    this.isInitLoading = false;
    this.leadForm.reset();
  }

  // convenience getter for easy access to form fields
  get formValidation() {
    return this.leadForm.controls;
  }

  public changeEvent(event) {
    if (event.target.checked) {
      this.isConsetCheckBoxChecked = true;
    } else {
      this.isConsetCheckBoxChecked = false;
    }
  }

  private MarkLeadFormAsTouched(): void {
    this.leadForm.controls.firstName.markAsTouched();
    this.leadForm.controls.contactNumber.markAsTouched();
    this.leadForm.controls.email.markAsTouched();
    this.leadForm.controls.surname.markAsTouched();
  }

  private BuildLeadForm(): void {
    this.leadForm = this.formBuilder.group({
      firstName: ["", Validators.compose([Validators.required])],
      surname: [""],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]),
      ],
      contactNumber: ["", Validators.compose([Validators.required])],
    });
  }

  private BindValueChangeEvents() {
    this.leadForm.controls.email.valueChanges.subscribe((value) => {
      if (value != "" && value != null) {
        this.leadForm.controls.contactNumber.clearValidators();
        this.leadForm.controls.contactNumber.updateValueAndValidity({
          emitEvent: false,
        });
        this.isContactNumberRequired = false;
      } else {
        this.leadForm.controls.contactNumber.setValidators([
          Validators.required,
        ]);
        this.leadForm.controls.contactNumber.updateValueAndValidity({
          emitEvent: false,
        });
        this.isContactNumberRequired = true;
      }
    });

    this.leadForm.controls.contactNumber.valueChanges.subscribe((value) => {
      if (value != "" && value != null) {
        this.leadForm.controls.email.clearValidators();
        this.leadForm.controls.email.setValidators([
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]);
        this.leadForm.controls.email.updateValueAndValidity({
          emitEvent: false,
        });
        this.isEmailRequired = false;
      } else {
        this.leadForm.controls.email.setValidators([
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]);
        this.leadForm.controls.email.updateValueAndValidity({
          emitEvent: false,
        });
        this.isEmailRequired = true;
      }
    });
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }
}
