<!-- Global funds -->
<div class="w-100">
  <div class="significant-raise table-card h-100">
    <!-- tabs -->
    <div
      class="d-flex flex-column flex-md-row align-items-start align-items-md-end justify-content-between"
    >
      <div class="pl-2 large-heading">
        <h2>Global funds</h2>
        <p class="body-med p-length-md">
          Through our tried-and tested investment philosophy, we have built
          competitive global solutions to help clients achieve their investment
          goals.
        </p>
        <p class="body-med mb-0">
          The following funds are rand-denominated, but invest internationally:
        </p>
      </div>
      <div>
        <tab-horisontal
          [hidden]="showOnlyPriceAndPerformance == true"
          [tabSelected]="currentTab"
          (tabSelectedChange)="selectedTabChanged($event)"
          [tabs]="pageTabs"
          class="tab-nowrap"
        >
        </tab-horisontal>
      </div>
    </div>

    <!-- tabs -->

    <!--tables-->
    <div class="mt-4" [ngSwitch]="currentTab">
      <!-- overview -->
      <div *ngSwitchCase="'Overview'">
        <div>
          <overview-table
            locality="GLOBAL"
            category="PSG-FUND"
          ></overview-table>
        </div>
      </div>
      <!-- /overview -->

      <!-- Price and performance -->
      <div *ngSwitchCase="'Price and performance'">
        <div>
          <price-and-performance-table locality="GLOBAL" category="PSG-FUND">
          </price-and-performance-table>
        </div>
      </div>
      <!-- /Price and performance -->
    </div>
    <!--tables-->
    <div *ngIf="showButton">
      <p
        class="body-small mb-0"
        [innerHTML]="content?.body?.sections[3]?.sections[1]?.content"
      ></p>
      <div class="container">
        <div class="row">
          <ng-container
            *ngFor="
              let action of content?.body?.sections[3]?.sections[1]?.actions
            "
          >
            <button-text
              class="mr-2 mb-2"
              buttonStyle="{{
                action?.buttonColor != null
                  ? action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="!showButton">
      <div [innerHTML]="contentInput?.body?.sections[4]?.disclaimer"></div>
    </div>
  </div>
</div>
<!-- Global funds -->
