import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ExternalRedirectsService } from 'src/app/services/external-redirects/external-redirects.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  showNotFoundMessage = false;

  constructor(private router: Router, private externalRedirects: ExternalRedirectsService) {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {

      if (event instanceof NavigationEnd) {

        const urlExists = this.externalRedirects.checkIfCurrentUrlExists(event.url);
        if (urlExists) {
          window.location.href = environment.advisersUrl + urlExists.to;
        } else {
          this.showNotFoundMessage = true;
        }

      }

    });
  }

  ngOnInit() {
  }

}
