import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map, Observable, throwError } from "rxjs";
import { BaseService } from "./base/base.service";
import { SettingsService } from "./base/settings.service";
import { LoggerService } from "./base/logger.service";
import { FindAdviserPage } from "../models/content/financial-advice/find-adviser-page.model";
import { Campaign } from "../models/content/campaign/campaign.models";
import { ArticleService } from "src/app/services/article.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";

@Injectable()
export class PageService extends BaseService {
  public readonly URIs = {
    home: "/home",
    aboutUs: "/about-us",

    financialAdvice: "/financial-advice",
    findAdviser: "/financial-advice/find-an-adviser",
    financialAdviceForYourself: "/financial-advice/for-yourself",
    financialAdviceForYourEstate: "/financial-advice/for-your-estate",
    financialAdviceForYourEmployees: "/financial-advice/for-your-employees",
    branchOffice: "/financial-advice/find-an-adviser/branches/",

    saveAndInvest: "/save-and-invest",
    saveAndInvestHowToInvest: "/save-and-invest/how-to-invest",
    stockbroking: "/save-and-invest/stockbroking",
    businessRetirementServices: "/save-and-invest/retirement-fund-services",
    allFunds: "/save-and-invest/all-funds",
    allPsgFunds: "/save-and-invest/psg-funds",
    educateYourself: "/save-and-invest/educate-yourself",

    insurance: "/insurance",
    insuranceForYourEmployees: "/insurance/for-your-employees",
    insuranceForYourBusiness: "/insurance/for-your-business",
    insuranceForYourself: "/insurance/for-yourself",
    insuranceForYourThings: "/insurance/for-your-things",

    careerCorporateCulture: "/careers",
    careerGraduateProgramme: "/careers/graduate-programme",
    careerPartnerWithPsg: "/careers/partner-with-psg",
    careerBecomeAnAdviser: "/careers/become-an-adviser",
    careerEmployeePage: "/careers/life-at-psg",

    duiarticle:
      "/news-and-publications/daily-investment-updates/daily-investment-update",

    faQ: "/faq",

    aboutUsPSGAssetManagement: "/about-us/psg-asset-management",
    aboutUsPSGInsure: "/about-us/psg-insure",
    corporateGovernance:
      "/about-us/investor-relations/corporate-governance-and-responsibility",
    archive: "/about-us/investor-relations/archive",
    singleManagedFunds: "/save-and-invest/single-managed-funds",
    multiManagedFunds: "/save-and-invest/multi-managed-funds",
    eventsAndWebinars: "/news-and-publications/events-and-webinars",
    compareInvestmentProducts: "/save-and-invest/investment-options",
    retirementAnnuity: "/save-and-invest/retirement-products",
    livingAnnuity: "/save-and-invest/retirement-products",
    preservationFund: "/save-and-invest/retirement-products",
    savingsLandingPage: "/save-and-invest/savings",
    retirementPlanning: "/save-and-invest/retirement-planning",

    investorInformationPage:
      "/about-us/investor-relations/investor-information",
    sensShareData: "/about-us/investor-relations/sens-and-share-data",
    investorRelations: "/about-us/investor-relations",
    glossaryPage: "/glossary",
    compareInsurance: "/insurance/compare-options",

    psgWealth: "/about-us/psg-wealth",
    legal: "/legal",
    tools: "/tools",
    formsAndFactSheets: "/forms-and-fact-sheets",
    contactUs: "/contact-us",
    investmentProducts: "/save-and-invest/investment-products",

    economicCalendar: "/news-and-publications/economic-calendar",
    newsAndPublications: "/news-and-publications",
    articles: "/news-and-publications/articles",
    officeArticlesBase: "/news-and-publications/articles", // TODO: Fix implement this correctly by fetching from the correct uri '/office'
    marketUpdate: "/news-and-publications/daily-investment-updates",
    dialyInvestmentUpdatesArticle:
      "/news-and-publications/daily-investment-updates/daily-investment-update",
    dialyInvestmentArticleBase:
      "/news-and-publications/daily-investment-updates",
    insureArticleBase: "/news-and-publications/articles/insure",
    wealthArticlesBase: "/news-and-publications/articles/wealth",
    assetManagementArticlesBase:
      "/news-and-publications/articles/asset-management",
    featureArticleBase: "/news-and-publications/articles/feature",
    assetManagementArticleBase: "/about-us/psg-asset-management/articles",
    campaignV1LandingPage: "/campaigns/campaign-1",
    campaignV2LandingPage: "/campaigns/campaign-2",
    investorRelationsEsgPage:
      "/about-us/psg-asset-management/esg-investment-philosophy",
    investmentResearch: "/news-and-publications/diu-static/psg-direct-trading",
    marketsManual: "/news-and-publications/research-hub/markets-manual",
    macroEconomicResearch:
      "/news-and-publications/research-hub/macroeconomic-research",
    fundResearch:
      "/news-and-publications/research-hub/fund-research",
    equityResearch:
      "/news-and-publications/research-hub/equity-research",
    inTheMedia:
      "/news-and-publications/research-hub/in-the-media",
    researchInsights: "/news-and-publications/research-hub/research-insights",
    solutionInsights: "/news-and-publications/research-hub/solution-insights",
    houseViewEquityPortfolios:
      "/news-and-publications/research-hub/solution-insights/house-view-equity-portfolios",
    weekAhead: "/news-and-publications/research-hub/week-ahead",
    monthlyCalendar: "/news-and-publications/research-hub/week-ahead/monthly-calendar",
    fundOfFunds:
      "/news-and-publications/research-hub/solution-insights/fund-of-funds",
    researchHubReportBase:
      "/news-and-publications/research-hub/markets-manual/reports/",
    psgAssetManagementNewsAndPublications: "/about-us/psg-asset-management/news-and-publications",
    psgAssetManagementForms: "/about-us/psg-asset-management/asset-management-forms",
    psgAssetManagementFactSheet: "/about-us/psg-asset-management/asset-management-fact-sheets",
    investmentCalculatorsPage: "/save-and-invest/investment-calculators",
    saveForAGoal: "/save-and-invest/investment-calculators/save-for-a-goal",
    saveForEducation:
      "/save-and-invest/investment-calculators/save-for-education",
    saveForRetirement:
      "/save-and-invest/investment-calculators/save-for-retirement",
    startYourJourney: "/start-your-journey",
    moneyMarketFunds: "/save-and-invest/money-market-funds",
    thinkBigSouthAfrica: "/news-and-publications/think-big-south-africa",
    banner: "/banner",
  };

  public isGlobal: boolean = false;
  public article: string = "";

  // All Articles
  public totalArticles: any = {};

  // Search All Articles
  public searchArticle: Array<any> = [];
  public searchDailyInvestmentUpdateArticle: Array<any> = [];

  // Daily Investment Updates
  public marketUpdate: any = {};

  constructor(
    public settings: SettingsService,
    loggerService: LoggerService,
    http: HttpClient,
    private allArticleSerivce: ArticleService
  ) {
    super(loggerService, http);
  }

  public GetPage<TObject>(uri: string): Observable<TObject> {
    var body = {
      uri: this.isGlobal ? uri : `/za${uri}`,
    };

    return this.Post(this.settings.PageService.GetPage, body).pipe(
      map((result) => {
        return result as TObject;
      }),
      catchError((err) => {
        if (err.status === 503 || err.status === 403 || !err.status) {
          window.location.href = environment.defaultLandingPageUrl;
        }
        return throwError(err);
      })
    );
  }

  public GetResearchPage<TObject>(uri: string): Observable<TObject> {
    var body = {
      uri: this.isGlobal ? uri : `/za${uri}`,
      limit: null,
      filters: null
    };

    return this.Post(this.settings.PageService.GetPage, body).pipe(
      map((result) => {
        return result as TObject;
      })
    );
  }

  public GetCampaign(campaignName: string) {
    var body = {
      uri: this.isGlobal ? campaignName : `/za/campaigns/${campaignName}`,
    };

    return this.Post(this.settings.PageService.GetPage, body).pipe(
      map((result) => {
        return result as Campaign;
      })
    );
  }

  public GetFindAnAdviserPage<TObject>(
    uri: string,
    branch: string
  ): Observable<TObject> {
    var body = {
      uri: this.isGlobal ? uri : `/za${uri}`,
      filters: [
        {
          field: "branch",
          value: branch,
        },
      ],
    };

    return this.Post(this.settings.PageService.GetPage, body).pipe(
      map((result) => {
        return result as TObject;
      })
    );
  }

  public SetArticle(article) {
    this.article = article;

    if (article.toLowerCase() == "daily investment update") {
      this.allArticleSerivce
        .GetAllDailyInvestmentUpdatesArticles()
        .subscribe((diuArticles) => {
          diuArticles = diuArticles.sort((a, b) =>
            a.publishDate < b.publishDate ? 1 : -1
          );
          this.totalArticles = diuArticles;
          this.searchArticle = (<Array<any>>this.totalArticles).slice(
            0,
            (<Array<any>>this.totalArticles).length
          );
        });
    } else {
      this.allArticleSerivce
        .SearchAllArticles(null)
        .subscribe((totalArticles) => {
          totalArticles = totalArticles.sort((a, b) =>
            Date.parse(a.publishDate) < Date.parse(b.publishDate) ? 1 : -1
          );
          this.totalArticles = totalArticles;
          this.searchArticle = this.totalArticles.filter((x) =>
            x.categories == null
              ? false
              : x.categories.filter((y) => y == article).length > 0
          );
        });
    }
  }

  public GetAllCalendarEvents<TObject>(
    uri: string,
  ): Observable<TObject> {
    var body = {
      uri: this.isGlobal ? uri : `/za${uri}`,
      limit: 40
    };

    return this.Post(this.settings.PageService.GetPage, body).pipe(
      map((result) => {
        return result as TObject;
      })
    );
  }

  public GetCurrentEvents(events: any, unit: moment.unitOfTime.StartOf) {
    return events
      .sort((a, b) => {
        return <any>new Date(a.date) - <any>new Date(b.date)
      })
      .filter((e) => moment(new Date(e.date)).isSame(new Date(), unit))
      .map((e) => {
        let date = new Date(e.date);
        return {
          heading: date.getDate(),
          headline: date.toLocaleString("default", { weekday: "long" }),
          content: e.content,
          actions: e.actions
        }
      });
  }
}
