import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { CompareInsurancePage } from "src/app/models/content/insurance/compare-insurance-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";

@Component({
  selector: "app-compare-insurance",
  templateUrl: "./compare-insurance.component.html",
})
export class CompareInsuranceComponent implements OnInit {
  selectedItemsList = [];
  checkedIDs = [];
  public isPageLoading: boolean = true;

  // product options
  options = [
    {
      label: "Medical aid",
      id: "00",
      isChecked: false,
      description: "lorem ipsum dolor...",
    },
    {
      label: "Gap cover",
      id: "01",
      isChecked: false,
      description: "lorem ipsum dolor...",
    },
    {
      label: "Life cover",
      id: "02",
      isChecked: false,
      description: "lorem ipsum dolor...",
    },
    {
      label: "Income products",
      id: "03",
      isChecked: false,
      description: "lorem ipsum dolor...",
    },
    {
      label: "Disability cover",
      id: "04",
      isChecked: false,
      description: "lorem ipsum dolor...",
    },
    {
      label: "Dread disease cover",
      id: "05",
      isChecked: false,
      description: "lorem ipsum dolor...",
    },
  ];

  public content: any = {};

  constructor(
    private pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<CompareInsurancePage>(pageService.URIs.compareInsurance)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new CompareInsurancePage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }
  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  ngOnInit(): void {
    this.fetchSelectedItems();
    this.fetchCheckedIDs();
    this.seoService.setJsonLd(this.renderer, null);
  }

  changeSelection() {
    this.fetchSelectedItems();
  }

  public fetchSelectedItems() {
    if (this.content.body === undefined || this.content.body === null) {
    } else {
      this.selectedItemsList = this.content.body.options.filter(
        (value, index) => {
          return value.isChecked;
        }
      );
    }
  }

  fetchCheckedIDs() {
    this.checkedIDs = [];
    if (this.content.body === undefined || this.content.body === null) {
    } else {
      this.content.body.options.forEach((value, index) => {
        if (value.isChecked) {
          this.checkedIDs.push(value.name);
        }
      });
    }
  }
}
