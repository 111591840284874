import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { SavingsLandingPage } from "src/app/models/content/save-and-invest/savings-landing-page.model";
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
@Component({
  selector: "app-savings",
  templateUrl: "./savings.component.html",
})
export class SavingsComponent implements OnInit {
  public content: any = {};
  public isPageLoading: boolean = true;

  constructor(
    private pageService: PageService,
    private router: Router,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<SavingsLandingPage>(pageService.URIs.savingsLandingPage)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new SavingsLandingPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public Split(str: string): string {
    if (str != null) return str.replace(/\s/g, "<br />");
    else return str;
  }

  ngOnInit() {
    this.seoService.setJsonLd(this.renderer, null);
  }
}
