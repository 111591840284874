import { Component, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HeaderComponent } from 'src/app/components/shared/header/header.component';
import { SEO_FOR_PAGES } from 'src/app/models/SEO/seo-page-script';
import { SeoService } from 'src/app/services/socialLinks.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  @ViewChild(HeaderComponent) header: HeaderComponent;
  public menuActive: Boolean = true;
  showBackToTop = false;
  JSON_LD_DATA: string = SEO_FOR_PAGES.home_script;

  constructor(
    private seoService: SeoService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.seoService.initialDefaultMetaInformation();
    this.seoService.setJsonLd(this.renderer, this.JSON_LD_DATA);
  }

  @HostListener("window:scroll")
  checkScroll() {

    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const percetageScrolled = scrollPosition / document.body.clientHeight;

    this.showBackToTop = percetageScrolled >= 0.5 ? true : false;

  }

  gotoTop() {

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  }

}
