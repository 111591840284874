import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DuiArticlePage } from 'src/app/models/content/news-and-publications/dui-article-page.model';
import { DailyInvestmentService } from 'src/app/services/dailyInvestment/daily-investment.service';
import { FundPriceAndPerformanceHousePortfilioResponse, FundsPricingPerformanceResponse } from 'src/app/services/dailyInvestment/daily-ivestment.model';
import { PageService } from 'src/app/services/page.service';
import { FormattingService } from 'src/app/services/formatting.service';
import { ArticleService } from 'src/app/services/article.service';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-mm-funds-house-view',
  templateUrl: './mm-funds-house-view.component.html',
  styles: []
})
export class MmFundsHouseViewComponent implements OnInit, OnDestroy {

  public pageTabs = ["Overview", "Price and performance"];
  public currentTab = "Overview";
  public alive: boolean = true;
  public fundPerformances: Array<FundPriceAndPerformanceHousePortfilioResponse>;

  @Input() showOnlyPriceAndPerformance: boolean = false;
  public showButton: boolean = false;
  public content: any = {};
  public newsArticle: string;

  //Latest Article
  public latestArticleURL = '';

  constructor(
    private dailyInvestmentService: DailyInvestmentService,
    private route: ActivatedRoute,
    private pageService: PageService,
    private router: Router,
    public formattingService: FormattingService,
    public diuArticleService: ArticleService) { }

  public ngOnInit() {
    this.route.params.subscribe((parms: Params) => {
      this.newsArticle = parms.newsArticle;
      this.diuArticleService.GetAllDailyInvestmentUpdatesArticles().subscribe((diuArticles) => {
        diuArticles = diuArticles.sort((a, b) =>
          a.publishDate < b.publishDate ? 1 : -1);
        this.latestArticleURL = diuArticles[0].uri;

        var n = this.latestArticleURL.lastIndexOf('/');
        this.newsArticle = this.latestArticleURL.substring(n + 1);

        this.pageService
          .GetPage<DuiArticlePage>(this.pageService.URIs.dialyInvestmentArticleBase +
            "/" +
            this.newsArticle)

          .subscribe((content) => {
            this.content = new DuiArticlePage(content);
          });
      });
    });

    if (this.showOnlyPriceAndPerformance == true) {
      this.currentTab = "Price and performance"
      this.showButton = true;
      this.getFundPricesAndPerformance();
    }
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public getFundPricesAndPerformance() {
    let today = new Date(Date.now());
    this.dailyInvestmentService.getFundPriceAndPerformanceHousePortfilio({
      date: "",
      pageNumber: 1,
      pageSize: 50
    }).pipe(
      takeWhile(() => this.alive))
      .subscribe((result) => {
        this.fundPerformances = result;
      })
  }

  public selectedTabChanged(tab: string) {
    this.currentTab = tab;
    this.getFundPricesAndPerformance();
  }


  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, '_blank');
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

}
