<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- retirement planning hero section -->
<div *ngIf="content.header !== undefined">
  <div
    class="container-fluid retirement-planning"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-start justify-content-sm-center pt-4 pt-sm-0 h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title savings text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            Invest in your dream
            <span class="retirementBorder-Bottom">retirement</span>
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- retirement planning  hero section -->

<!-- retirement-planning-page cards -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid mx-auto retirement-planning-cards"
  style="max-width: 1280px"
>
  <div class="row">
    <div class="col-md-4 mb-4 mt-0 mt-md-3">
      <div class="blur-card--retire">
        <div>
          <h2 class="small-heading">{{ content?.body?.cards[0]?.heading }}</h2>
          <div [innerHTML]="content?.body?.cards[0]?.content"></div>
        </div>
        <div class="row">
          <div class="col-md-12 pl-md-2 btn-row">
            <ng-container
              *ngFor="let action of content?.body?.cards[0]?.actions"
            >
              <button-text
                fragment="who-are-we"
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-4">
      <div class="blur-card--retire">
        <div>
          <h2 class="small-heading">{{ content?.body?.cards[1]?.heading }}</h2>
          <div [innerHTML]="content?.body?.cards[1]?.content"></div>
        </div>

        <div class="row">
          <div class="col-md-12 pl-md-2 btn-row">
            <ng-container
              *ngFor="let action of content?.body?.cards[1]?.actions"
            >
              <button-text
                fragment="who-are-we"
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-4 mt-0 mt-md-3">
      <div class="blur-card--retire">
        <div>
          <h2 class="small-heading">{{ content?.body?.cards[2]?.heading }}</h2>
          <div [innerHTML]="content?.body?.cards[2]?.content"></div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 pl-md-2 btn-row">
            <ng-container
              *ngFor="let action of content?.body?.cards[2]?.actions"
            >
              <button-text
                fragment="who-are-we"
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- retirement-planning-page cards -->
