<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- For your employees header -->
<div *ngIf="!isPageLoading" class="container-fluid mt-5 h-100">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <h2 class="mega-title--mobile">{{ content?.header?.heading }}</h2>
      </div>
    </div>
  </div>
</div>
<!-- For your employees header -->

<!-- For your employees section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 greybg-left"
  id="healthcare-benefits"
>
  <div class="container-xl">
    <!--border-->
    <div class="row">
      <div class="col-md-4 offset-md-4 bottom-border"></div>
    </div>
    <!--/border-->

    <div class="row h-100 py-5">
      <div class="col-md-6 mb-4">
        <img
          alt=""
          class="img-cover significant-raise"
          [src]="content?.body?.sections[0]?.image"
          [ngStyle]="{ visibility: isPageLoading ? 'hidden' : 'visible' }"
        />
      </div>
      <div class="col-md-5 offset-md-1 py-md-5">
        <div>
          <h2>{{ content?.body?.sections[0]?.heading }}</h2>
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
        </div>
        <div>
          <button-text
            *ngFor="
              let action of content?.body?.sections[0]?.sections[0]?.actions
            "
            buttonStyle="{{
              action.isPrimary ? 'solid' : 'solid altWhite mt-3 mt-md-0'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- For your employees section -->

<div *ngIf="!isPageLoading" class="container-fluid py-5 h-100">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <h1 class="section-title--mobile text-center">
          <span class="cyan">“</span>
          {{ content?.body?.sections[1]?.content }}
          <span class="cyan">”</span>
        </h1>
      </div>
    </div>
  </div>
</div>

<!--group-risk-benefits  section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 greybg-right"
  id="group-risk-benefits"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-5 py-md-5">
        <div>
          <h2>{{ content?.body?.sections[2]?.heading }}</h2>
          <div
            [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
          ></div>
        </div>
        <div>
          <button-text
            *ngFor="
              let action of content?.body?.sections[2]?.sections[0]?.actions
            "
            buttonStyle="{{
              action.isPrimary ? 'solid' : 'solid altWhite mt-3 mt-md-0'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </div>
      </div>

      <div class="col-md-6 offset-md-1">
        <img
          alt=""
          class="img-cover significant-raise"
          [src]="content?.body?.sections[2]?.image"
          [ngStyle]="{ visibility: isPageLoading ? 'hidden' : 'visible' }"
        />
      </div>
    </div>
  </div>
</div>
<!-- group-risk-benefits  section -->
