export class EventsAndWebinarsPage {
  header: any = {};
  body: any = {};

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    if (this.header.heading)
      this.header.heading = this.header.heading.replace(
        /<p>/g,
        '<p class="p-length-sm text-shadow">'
      );

    // for (let index = 0; index < this.body.categories.length; index++) {
    //    this.body.categories[index].content = this.body.categories[index].content.replace(
    //      /<p>/g,
    //      '<p class="body-med slate mb-0">'
    //    );

    // }
  }
}
