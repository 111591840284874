export interface MarketIndicatorsRequest {
  date: string;
  pageNumber: number;
  pageSize: number;
  category: string;
  locality: string;
}

export interface MarketIndicatorResponse {
  SecId: string;
  Performance: string;
  CompanyName: string;
  FundName: string;
  ShareClassLegalName: string;
  FundCategory: string;
  Locality: string;
  AsisaCategory: string;
  DisplayName: string;
  Date: Date;
  CurrencyISO: string;
  CurrentLevel: number;
  D1: number;
  M1: number;
  M3: number;
  M6: number;
  M12: number;
  Inception: number;
}

export interface FundsPricingPerformanceRequest {
  date: string;
  pageNumber: number;
  pageSize: number;
  fundCategory: string;
  locality: string;
}

export interface FundsPricingPerformanceResponse {
  SecId: string;
  PerformanceId: string;
  CompanyName: string;
  AsisaCategory: string;
  FundName: string;
  ShareClassLegalName: string;
  FundDisplayName: string;
  Class: string;
  FundUrl: string;
  FundPrices: Array<FundPrices>;
  FundPerformances: Array<FundPerformance>;
  FundInfo: Array<FundInfo>;
  FundValue: Array<FundValue>;
}

export interface FundInfo {
  Reg28Compliant: boolean;
  TFIPAvailability: boolean;
  PSGAdvisersOnly: boolean;
}

export interface FundValue {
  TIC: string;
}

export interface FundPriceAndPerformanceHousePortfilioReqeust {
  date: string;
  pageNumber: number;
  pageSize: number;
}

export interface FundPriceAndPerformanceHousePortfilioResponse {
  EPID: number;
  Portfolio: string;
  Date: Date;
  M6: number;
  Y1: number;
  Y2: number;
  Y3: number;
  Y4: number;
  Y5: number;
  Inception: number;
}

export interface FundPerformance {
  Date: string;
  Y1: number;
  Y3: number;
  Y5: number;
  Inception: number;
  TER: number;
}

export interface FundPrices {
  Date: string;
  CurrencyISO: string;
  PreTaxNav: number;
}

export class FundTablesOverviewModel {
  constructor(
    fundsPricingPerformanceResponse: Array<FundsPricingPerformanceResponse>
  ) {
    this.AsisaCategories = new Array<FundTableAsisaCategory>();
    var categories = fundsPricingPerformanceResponse
      .map((item) => item.AsisaCategory)
      .filter((value, index, self) => self.indexOf(value) == index);

    categories.forEach((category) => {
      this.AsisaCategories.push(
        new FundTableAsisaCategory(
          category,
          fundsPricingPerformanceResponse.filter(
            (x) => x.AsisaCategory == category
          )
        )
      );
    });
  }

  AsisaCategories: Array<FundTableAsisaCategory>;
}

export class FundTableAsisaCategory {
  constructor(
    categoryName: string,
    filteredFundsPricingPerformanceResponse: Array<FundsPricingPerformanceResponse>
  ) {
    this.Rows = new Array<FundTableFundRow>();
    this.Category = categoryName;

    filteredFundsPricingPerformanceResponse.forEach((response) => {
      this.Rows.push(new FundTableFundRow(response));
    });
  }

  Category: string;
  Rows: Array<FundTableFundRow>;
}

export class FundTableFundRow {
  constructor(pricingPerformanceResponse: FundsPricingPerformanceResponse) {
    this.FundName = pricingPerformanceResponse.FundDisplayName;
    this.FundUrl = pricingPerformanceResponse.FundUrl;
    this.Class = pricingPerformanceResponse.Class;
    if (
      pricingPerformanceResponse.FundInfo !== null ||
      pricingPerformanceResponse.FundInfo.length > 0
    ) {
      this.Reg28Compliant =
        pricingPerformanceResponse.FundInfo[0].Reg28Compliant;
      this.TFIPAvailability =
        pricingPerformanceResponse.FundInfo[0].TFIPAvailability;
      this.PSGAdviserOnly =
        pricingPerformanceResponse.FundInfo[0].PSGAdvisersOnly;
    }

    if (
      pricingPerformanceResponse.FundValue !== null ||
      pricingPerformanceResponse.FundValue.length > 0
    ) {
      this.TIC = pricingPerformanceResponse.FundValue[0].TIC;
    }

    if (
      pricingPerformanceResponse.FundPerformances !== null ||
      pricingPerformanceResponse.FundPerformances.length > 0
    ) {
      this.FundPerformanceDate =
        pricingPerformanceResponse.FundPerformances[0].Date;
      this.FundPerformanceInception =
        pricingPerformanceResponse.FundPerformances[0].Inception;
      this.FundPerformanceTER =
        pricingPerformanceResponse.FundPerformances[0].TER;
      this.FundPerformanceY1 =
        pricingPerformanceResponse.FundPerformances[0].Y1;
      this.FundPerformanceY3 =
        pricingPerformanceResponse.FundPerformances[0].Y3;
      this.FundPerformanceY5 =
        pricingPerformanceResponse.FundPerformances[0].Y5;
    }

    if (
      pricingPerformanceResponse.FundPrices !== null ||
      pricingPerformanceResponse.FundPrices.length > 0
    ) {
      this.FundPriceDate = pricingPerformanceResponse.FundPrices[0].Date;
      this.PreTaxNav = pricingPerformanceResponse.FundPrices[0].PreTaxNav;
    }
  }

  FundName: string;
  Class: string;
  FundUrl: string;
  Reg28Compliant: boolean;
  TFIPAvailability: boolean;
  PSGAdviserOnly: boolean;
  TIC: string;

  FundPerformanceDate: string;
  FundPerformanceY1: number;
  FundPerformanceY3: number;
  FundPerformanceY5: number;
  FundPerformanceInception: number;
  FundPerformanceTER: number;

  PreTaxNav: number;
  FundPriceDate: string;
}

export class ContentFilter {
  field: string;
  value: number;
  dateRange?: DateRange;

  constructor(data: any) {
    if (!data) {
      return;
    }
    this.field = data.field;
    this.value = data.value;
    this.dateRange = data.dateRange;
  }
}

export class DateRange {
  startDate: Date;
  endDate: Date;

  constructor(data: any) {
    if (!data) {
      return;
    }
    this.startDate = data.startDate;
    this.endDate = data.endDate;
  }
}
