import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { SendMailRequest } from "../advisor.models";
import { BaseService } from "../base/base.service";
import { LoggerService } from "../base/logger.service";
import { SettingsService } from "../base/settings.service";

@Injectable({
  providedIn: "root",
})
export class CalculatorService extends BaseService {
  constructor(
    public settings: SettingsService,
    loggerService: LoggerService,
    http: HttpClient
  ) {
    super(loggerService, http);
  }

  public sendComms(sendMailRequest: SendMailRequest) {
    return this.Post(this.settings.CommsService.CommsApi, sendMailRequest).pipe(map(
      (result) => {
        return true;
      }
    ));
  }
}
