<div class="container-fluid py-5 my-5 h-100">
  <div class="container-xl">
    <div class="row d-flex flex-column">
      <div class="col-md-6 offset-md-3 order-sm-1">
        <div>
          <h2 class="mb-3 d-none d-lg-block">Search PSG</h2>
          <h4 class="mb-3 d-lg-none">Search PSG</h4>

          <!-- search Input -->
          <form-field>
            <icon-font name="icon-search" class="suffix"></icon-font>
            <input
              id="searchInput"
              [(ngModel)]="searchValue"
              (ngModelChange)="onSearchChanged($event)"
              placeholder="What are you looking for?"
            />
          </form-field>
          <!-- search Input -->
        </div>
      </div>
    </div>

    <div class="row d-flex align-items-start">
      <div class="col-md-2 offset-md-1">
        <div class="row d-none d-lg-block" *ngIf="searched_items">
          <tab-vertical
            (onChange)="tabSelected($event)"
            [tabSelected]="selectedTab"
            [tabs]="exampleTabs"
          >
          </tab-vertical>
        </div>

        <div class="row d-lg-none" *ngIf="searched_items">
          <tab-horisontal
            (onChange)="tabSelected($event)"
            [tabSelected]="selectedTab"
            [tabs]="exampleTabs"
          >
          </tab-horisontal>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row h-100">
          <div class="col-sm-12 search-container">
            <div
              *ngIf="searchValue && searchedItems[0].category == 'none'"
              class="mt-5 text-align-center"
            >
              <h5>No Results for {{ searchValue }}</h5>
              <p class="slate">Keep Searching</p>
            </div>

            <!-- Search Product -->
            <div
              *ngIf="searchContainsProducts"
              [routerLink]="['/financial-advice/overview']"
              (click)="toggleSearch('search')"
            >
              <h6>Product</h6>
              <hr class="cyan-bg mt-n1" />
            </div>
            <div *ngFor="let searchItem of searchedItems">
              <div class="search-item">
                <a
                  [routerLink]="[searchItem.routerLink]"
                  [fragment]="searchItem.fragment"
                  (click)="toggleSearch('search')"
                  *ngIf="searchItem.category == 'Products'"
                >
                  <h4
                    class="mt-2"
                    innerHTML="{{
                      searchItem.heading | highlight: searchValue
                    }}"
                  ></h4>
                  <p
                    class="search-paragraph"
                    innerHTML="...{{
                      searchItem.text | highlight: searchValue
                    }}"
                    *ngIf="searchItem.text != 'none'"
                  ></p>
                </a>
              </div>
            </div>
            <!--/Search Financial advice -->

            <!-- Search Articles -->
            <div
              *ngIf="searchContainsArticles"
              [routerLink]="['/news-and-publications']"
              (click)="toggleSearch('search')"
            >
              <h6>Articles</h6>
              <hr class="red-bg mt-n1" />
            </div>
            <div *ngFor="let searchItem of searchedItems">
              <div class="search-item">
                <a
                  [routerLink]="[searchItem.routerLink]"
                  (click)="toggleSearch('search')"
                  *ngIf="searchItem.category == 'Articles'"
                >
                  <h4
                    class="mt-2"
                    innerHTML="{{
                      searchItem.heading | highlight: searchValue
                    }}"
                  ></h4>
                  <p
                    class="search-paragraph"
                    innerHTML="...{{
                      searchItem.text | highlight: searchValue
                    }}"
                    *ngIf="searchItem.text != 'none'"
                  ></p>
                </a>
              </div>
            </div>

            <!-- Search Funds -->
            <div
              *ngIf="searchContainsFunds"
              [routerLink]="['/Funds']"
              (click)="toggleSearch('search')"
            >
              <h6>Funds</h6>
              <hr class="yellow-bg mt-n1" />
            </div>
            <div *ngFor="let searchItem of searchedItems">
              <div class="search-item">
                <a
                  [routerLink]="[searchItem.routerLink]"
                  (click)="toggleSearch('search')"
                  *ngIf="searchItem.category == 'Funds'"
                >
                  <h4
                    class="mt-2"
                    innerHTML="{{
                      searchItem.heading | highlight: searchValue
                    }}"
                  ></h4>
                  <p
                    class="search-paragraph"
                    innerHTML="...{{
                      searchItem.text | highlight: searchValue
                    }}"
                    *ngIf="searchItem.text != 'none'"
                  ></p>
                </a>
              </div>
            </div>

            <!-- Search Branches -->
            <div
              *ngIf="searchContainsBranches"
              [routerLink]="['/resources']"
              (click)="toggleSearch('search')"
            >
              <h6>Branches</h6>
              <hr class="blue-bg mt-n1" />
            </div>
            <div *ngFor="let searchItem of searchedItems" class="mb-2">
              <div *ngIf="searchItem">
                <a
                  [routerLink]="[searchItem.routerLink]"
                  (click)="toggleSearch('search')"
                  *ngIf="searchItem.category == 'Branches'"
                >
                  <h4
                    class="mt-2"
                    innerHTML="{{
                      searchItem.heading | highlight: searchValue
                    }}"
                  ></h4>
                  <p
                    class="search-paragraph"
                    innerHTML="...{{
                      searchItem.text | highlight: searchValue
                    }}"
                    *ngIf="searchItem.text != 'none'"
                  ></p>
                </a>
              </div>
            </div>

            <!-- Search Navigation Items -->
            <div
              *ngIf="searchContainsOther"
              [routerLink]="['/home']"
              (click)="toggleSearch('search')"
            >
              <h6>Other</h6>
              <hr class="purple-bg mt-n1" />
            </div>
            <div *ngFor="let searchItem of searchedItems">
              <div class="search-item">
                <a
                  [routerLink]="[searchItem.routerLink]"
                  (click)="toggleSearch('search')"
                  *ngIf="searchItem.category == 'Other'"
                >
                  <h4
                    class="mt-2"
                    innerHTML="{{
                      searchItem.heading | highlight: searchValue
                    }}"
                  ></h4>
                  <p
                    class="search-paragraph"
                    innerHTML="...{{
                      searchItem.text | highlight: searchValue
                    }}"
                    *ngIf="searchItem.text != 'none'"
                  ></p>
                </a>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              searchValue.length > 0 && searchedItems[0].category != 'none'
            "
            class="pl-3"
            style="display: flex; flex-direction: row; align-items: center"
          >
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link button outline" (click)="Previous()"
                    >Previous</a
                  >
                </li>
                <div style="margin-right: 5em"></div>
                <label class="pt-2"
                  >page {{ currentPage }} of {{ getTotalNumberOfPages }}</label
                >
                <div style="margin-right: 5em"></div>
                <li class="page-item">
                  <a
                    class="page-link button outline"
                    (click)="Next()"
                    [class.disabled]="
                      currentPage == getTotalNumberOfPages ? true : false
                    "
                    >Next</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div class="row h-100">
          <div class="col-sm-12 order-sm-2_">
            <p class="body-med body-bold">Popular Searches</p>
            <div class="card-tags mt-3 pb-2">
              <span
                class="basic-badge"
                [style.cursor]="'pointer'"
                (click)="QuickSearch('Financial')"
                >Financial</span
              >
              <span
                class="basic-badge"
                [style.cursor]="'pointer'"
                (click)="QuickSearch('Contact us')"
                >Contact us</span
              >
              <span
                class="basic-badge"
                [style.cursor]="'pointer'"
                (click)="QuickSearch('Careers')"
                >Careers</span
              >
              <span
                class="basic-badge"
                [style.cursor]="'pointer'"
                (click)="QuickSearch('FAQ')"
                >FAQ</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
