<h4 class="small-heading">Navigation</h4>
<div class="p-3">
  <ul>
    <p
      class="nav-list"
      [routerLink]="marketIndicatorUrl"
      fragment="market-indicator"
    >
      Market indicators
    </p>
    <p class="nav-list" [routerLink]="localFundsUrl" fragment="local-diu-funds">
      Local funds performance
    </p>
    <p
      class="nav-list"
      [routerLink]="globalFundsUrl"
      fragment="global-diu-funds"
    >
      Global funds performance
    </p>
    <p class="nav-list" [routerLink]="otherFundsUrl" fragment="other-diu-funds">
      Invest in other currencies
    </p>
    <p class="nav-list" [routerLink]="houseFundsUrl" fragment="house-diu-funds">
      House view equity portfolios
    </p>
    <p
      class="nav-list"
      [routerLink]="recentInvestmentIdeasUrl"
      fragment="recent-investment-ideas"
    >
      Recent investment ideas
    </p>
    <p
      class="nav-list"
      [routerLink]="'/news-and-publications/economic-calendar'"
      *ngIf="
        content?.body?.sections[0]?.sections[1]?.cards != null ||
        content?.body?.sections[0]?.sections[1]?.cards != undefined
      "
    >
      Economic calendar
    </p>
    <p
      class="nav-list"
      [routerLink]="corporateActionsUrl"
      fragment="corporate-actions-calendar"
      *ngIf="
        content?.body?.sections[5]?.heading != null ||
        content?.body?.sections[5]?.heading != undefined
      "
    >
      Corporate actions calendar
    </p>
    <p
      class="nav-list"
      [routerLink]="fundPerformanceUrl"
      fragment="fund-performance"
      *ngIf="
        content?.body?.sections[6]?.heading != null ||
        content?.body?.sections[6]?.heading != undefined
      "
    >
      Fund performance
    </p>
  </ul>
</div>
