<div class="row no-gutters">



    <!-- tabs top mobile -->
    <div class="col-md-12 mb-2">
        <div class="tabScroll overflow-auto">
            <tab-horisontal [(tabSelected)]="currentTab" [tabs]="pageTabs" class="tab-nowrap-space">
            </tab-horisontal>
        </div>
    </div>
    <!-- /tabs top mobile -->

    <div class="col-md-12" [ngSwitch]="currentTab">
        <!-- Step 1 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 1'">
            <div>
                <div class="slate-bg-10 p-4 overflow-auto h-100 advice-steps-process">
                    <p class="body-bold cyan">{{content?.body?.sections[1]?.items[0]?.name}}</p>
                    <h2 class="small-heading">{{content?.body?.sections[1]?.items[0]?.heading}}</h2>
                    <div [innerHTML]="content?.body?.sections[1]?.items[0]?.content"></div>
                </div>
            </div>
        </div>
        <!-- /Step 1 -->

        <!-- Step 2 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 2'">
            <div>
                <div class="slate-bg-10 p-4 overflow-auto h-100 advice-steps-process">
                    <p class="body-bold cyan">{{content?.body?.sections[1]?.items[1]?.name}}</p>
                    <h2 class="small-heading">{{content?.body?.sections[1]?.items[1]?.heading}}</h2>
                    <div [innerHTML]="content?.body?.sections[1]?.items[1]?.content"></div>
                </div>
            </div>
        </div>
        <!-- /Step 2 -->

        <!-- Step 3 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 3'">
            <div>
                <div class="slate-bg-10 p-4 overflow-auto h-100 advice-steps-process">
                    <p class="body-bold cyan">{{content?.body?.sections[1]?.items[2]?.name}}</p>
                    <h2 class="small-heading">{{content?.body?.sections[1]?.items[2]?.heading}}</h2>
                    <div [innerHTML]="content?.body?.sections[1]?.items[2]?.content"></div>
                </div>
            </div>
        </div>
        <!-- /Step 3 -->

        <!-- Step 4 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 4'">
            <div>
                <div class="slate-bg-10 p-4 overflow-auto h-100 advice-steps-process">
                    <p class="body-bold cyan">{{content?.body?.sections[1]?.items[3]?.name}}</p>
                    <h2 class="small-heading">{{content?.body?.sections[1]?.items[3]?.heading}}</h2>
                    <div [innerHTML]="content?.body?.sections[1]?.items[3]?.content"></div>
                </div>
            </div>
        </div>
        <!-- /Step 4 -->

        <!-- Step 5 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 5'">
            <div>
                <div class="slate-bg-10 p-4 overflow-auto h-100 advice-steps-process">
                    <p class="body-bold cyan">{{content?.body?.sections[1]?.items[4]?.name}}</p>
                    <h2 class="small-heading">{{content?.body?.sections[1]?.items[4]?.heading}}</h2>
                    <div [innerHTML]="content?.body?.sections[1]?.items[4]?.content"></div>
                </div>
            </div>
        </div>
        <!-- /Step 5 -->

        <!-- Step 6 -->
        <div class="row no-gutters" *ngSwitchCase="'Step 6'">
            <div>
                <div class="slate-bg-10 p-4 overflow-auto h-100 advice-steps-process">
                    <p class="body-bold cyan">{{content?.body?.sections[1]?.items[5]?.name}}</p>
                    <h2 class="small-heading">{{content?.body?.sections[1]?.items[5]?.heading}}</h2>
                    <div [innerHTML]="content?.body?.sections[1]?.items[5]?.content"></div>
                </div>
            </div>
        </div>
        <!-- /Step 6 -->
    </div>
</div>
