import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-diu-article-navigation',
  templateUrl: './diu-article-navigation.component.html',
  styleUrls: ['./diu-article-navigation.component.scss']
})
export class DiuArticleNavigationComponent implements OnInit {

  @Input() content: any;

  public marketIndicatorUrl: string;
  public localFundsUrl: string;
  public globalFundsUrl: string;
  public otherFundsUrl: string;
  public houseFundsUrl: string;
  public corporateActionsUrl: string;
  public fundPerformanceUrl: string;
  public recentInvestmentIdeasUrl: string;

  constructor(router: Router) {

    this.marketIndicatorUrl = router.url;
    this.localFundsUrl = router.url;
    this.globalFundsUrl = router.url;
    this.otherFundsUrl = router.url;
    this.houseFundsUrl = router.url;
    this.corporateActionsUrl = router.url;
    this.fundPerformanceUrl = router.url;
    this.recentInvestmentIdeasUrl = router.url;

  }

  ngOnInit() {
  }

}
