import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branch-office-news',
  templateUrl: './branch-office-news.component.html',
})
export class BranchOfficeNewsComponent implements OnInit {

  presentations = [
    { image: '../../../assets/images/home-page/talk-to-adviser.png', title: 'Presentation title#1' },
    { image: '../../../assets/images/home-page/talk-to-adviser.png', title: 'Presentation title#2' },
    { image: '../../../assets/images/home-page/talk-to-adviser.png', title: 'Presentation title#3' }
  ]

  articles = [
    { day: '21', month: 'December', year: '2020', title: 'This is a heading to a latest news article.', author: 'Adrian Pask', snippet: 'lorem ipsum dolor.' },
    { day: '3', month: 'January', year: '2021', title: 'This is a heading to a latest news article.', author: 'Joe Soap', snippet: 'lorem ipsum dolor..' },
    { day: '16', month: 'January', year: '2021', title: 'This is a heading to a latest news article.', author: 'Adrian Pask', snippet: 'lorem ipsum dolor....' },
    { day: '16', month: 'February', year: '2021', title: 'This is a heading to a latest news article.', author: 'Adrian Pask', snippet: 'lorem ipsum dolor....' },
  ]


  constructor() { }

  ngOnInit() {
  }

}
