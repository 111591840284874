<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<!-- hero section -->
<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid Advice-forYourself mb-5"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="d-flex flex-column align-content-start justify-content-md-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="pl-4 pl-md-4">
          <div
            [innerHTML]="content?.header?.content"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-xl Advice-forYourself--cards">
    <div class="row d-flex">
      <div class="col-md-5 mb-4">
        <div class="blur-card">
          <div>
            <h2 class="large-heading">
              {{ content?.header?.cards[0]?.heading }}
            </h2>
            <div [innerHTML]="content?.header?.cards[0]?.content"></div>
          </div>
          <div>
            <ng-container
              *ngFor="let action of content?.header?.cards[0]?.actions"
            >
              <button-text
                fragment="who-are-we"
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="blur-card">
          <div>
            <h2 class="large-heading">
              {{ content?.header?.cards[1]?.heading }}
            </h2>
            <div [innerHTML]="content?.header?.cards[1]?.content"></div>
          </div>
          <div>
            <ng-container
              *ngFor="let action of content?.header?.cards[1]?.actions"
            >
              <button-text
                fragment="who-are-we"
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="my-4 py-4"></div>
  <!-- Advice process -->
  <div class="container-fluid mt-5 pb-5 greybg-left h-100" id="advice-process">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-left transformY-25">
            <h2 class="section-title--mobile">
              {{ content?.body?.sections[0]?.heading }}
            </h2>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-6 mb-4">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[0]?.image"
            />
          </div>
        </div>
        <div class="col-md-6 col-lg-5 offset-lg-1">
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Advice process -->

  <!-- Advice process steps -->
  <div class="container-fluid mb-5 py-5 greybg-right">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-6">
          <h3 class="small-heading mb-3">
            {{ content?.body?.sections[1]?.heading }}
          </h3>
          <app-advice-process-steps></app-advice-process-steps>
        </div>
        <div class="col-md-6 mb-4">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[1]?.image"
            />
          </div>
        </div>
      </div>
      <div class="row mt-lg-n3">
        <div class="col-md-6">
          <div [innerHTML]="content?.body?.sections[1]?.content"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Advice process steps -->

  <!-- Investment advice -->
  <div class="container-fluid mb-5 py-5 greybg-left" id="investment-advice">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-left">
            <h2 class="mega-title--mobile">
              {{ content?.body?.sections[2]?.heading }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 py-5">
          <p
            class="body-large body-bold cyan"
            [innerHTML]="content?.body?.sections[2]?.sections[0]?.headline"
          ></p>
          <div
            [innerHTML]="content?.body?.sections[2]?.sections[0]?.content"
          ></div>
        </div>

        <div class="col-md-7 mb-4 mb-md-0">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[2]?.image"
            />
          </div>
        </div>
      </div>

      <ng-container
        *ngFor="let action of content?.body?.sections[2]?.sections[0]?.actions"
      >
        <button-text
          fragment="who-are-we"
          class="mr-2"
          buttonStyle="{{
            action?.buttonColor
              ? 'solid ' + action?.buttonColor
              : action.isPrimary
              ? 'solid'
              : 'outline'
          }}"
          [label]="action?.label"
          (click)="DynamicButtonClick(action)"
        >
        </button-text>
      </ng-container>

      <div class="row" id="retirement-planning-advice">
        <div class="col-md-5 offset-md-6 col-lg-5 offset-lg-6 py-5">
          <h3 class="large-heading">
            {{ content?.body?.sections[2]?.sections[1]?.heading }}
          </h3>
          <div
            [innerHTML]="content?.body?.sections[2]?.sections[1]?.content"
          ></div>
          <div class="btn-100-sm">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[2]?.sections[1]?.actions
              "
            >
              <button-text
                fragment="who-are-we"
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Investment advice -->

  <!-- Insurance advice -->
  <div class="container-fluid mb-5 py-5 greybg-right" id="insurance-advice">
    <div class="container-xl">
      <div class="row">
        <div class="col-md-12">
          <div class="heading-border-right">
            <h2 class="mega-title--mobile text-right">
              {{ content?.body?.sections[3]?.heading }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 py-5 order-0 order-md-1">
          <div
            [innerHTML]="content?.body?.sections[3]?.sections[0]?.content"
          ></div>
          <div>
            <ng-container
              *ngFor="
                let action of content?.body?.sections[3]?.sections[0]?.actions
              "
            >
              <button-text
                fragment="who-are-we"
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
        <div class="col-md-7 mb-4 mb-md-0 order-1 order-md-0">
          <div class="w-100 h-100">
            <img
              class="img-cover significant-raise"
              [src]="content?.body?.sections[3]?.image"
            />
          </div>
        </div>
      </div>
      <div class="row" id="life-and-health-cover-advice">
        <div class="col-md-5 offset-md-1 col-lg-5 offset-lg-1 py-5">
          <h3 class="large-heading">
            {{ content?.body?.sections[3]?.sections[1]?.heading }}
          </h3>
          <p
            class="body-large body-bold cyan"
            [innerHTML]="content?.body?.sections[3]?.sections[1]?.headline"
          ></p>
          <div
            [innerHTML]="content?.body?.sections[3]?.sections[1]?.content"
          ></div>
          <div class="btn-100-sm">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[3]?.sections[1]?.actions
              "
            >
              <button-text
                fragment="who-are-we"
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Insurance advice -->
</div>
