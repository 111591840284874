import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: [],
})
export class FrequentlyAskedQuestionsComponent implements OnInit {
  @Input() faqList: any;
  
  constructor(private router: Router) {    
   }

  ngOnInit(): void {
  }
  
  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }
}
