<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="container-fluid event-impact-assessment-hero mb-5 pb-5"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div class="row d-flex align-content-start justify-content-center h-100">
      <div class="col-md-12 mt-5">
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title house-view-equity-header white text-shadow"
          >
            {{ content?.header?.heading }}
          </h1>
          <h3 class="white text-shadow">
            {{ content?.header?.subheading }}
          </h3>
        </div>
      </div>
      <div class="col-md-12 mb-5">
        <div class="pl-md-4">
          <div [innerHTML]="content?.header?.content"></div>
        </div>
        <div class="pl-md-4 mb-3">
          <ng-container *ngFor="let action of content?.header?.actions">
            <button-text
              class="mr-2"
              buttonStyle="{{ action.isPrimary ? 'solid' : 'outline' }} mr-2"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl banner-card">
  <div class="row d-flex">
    <div
      class="col-md-12"
      *ngIf="content?.header?.headerBannerContent.length > 0"
    >
      <div class="research-insights-hero-card">
        <h3 class="medium-heading cyan">
          {{ content?.header?.headerBannerContent[0].heading }}
        </h3>
        <p>{{ content?.header?.headerBannerContent[0].headline }}</p>
        <div
          [innerHTML]="content?.header?.headerBannerContent[0].content"
        ></div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt-5 mb-3 pb-5 h-100 position-relative bottom-10vh">
  <div class="container-xl h-100">
    <div class="row mb-3">
      <div class="col-12">
        <h2 class="mb-1">
          {{ content?.body?.sections[0]?.heading }}
        </h2>
        <p>{{ content?.body?.sections[0]?.headline }}</p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <button-text
          *ngFor="let keyword of keywords"
          class="mr-2"
          [buttonStyle]="[keyword.selected ? 'solid' : 'outline', 'mb-2']"
          [label]="keyword.label"
          (click)="toggleNewsletterContent(keyword.label)"
        >
        </button-text>
      </div>
    </div>
    <div class="row" *ngIf="selectedNewsletter.length > 0">
      <div class="col-md-7 col-xs-12 mb-4">
        <p class="col-xs-12" [innerHTML]="selectedNewsletter[0].content"></p>
        <div class="btn-row mt-auto">
          <button-text
            [buttonStyle]="[
              selectedNewsletter[0].actions[0].isPrimary ? 'solid' : 'outline'
            ]"
            [label]="selectedNewsletter[0].actions[0].label"
            (click)="
              AccessCompleteNewsletter(selectedNewsletter[0].actions[0].url)
            "
          >
          </button-text>
        </div>
      </div>
      <div class="col-md-5 col-xs-12 mb-4">
        <h4 class="small-heading mb-2">
          {{ selectedNewsletter[0].contact[0].heading }}
        </h4>
        <div class="row d-flex justify-content-end mb-2 text-right">
          <div class="col-12">
            <button
              class="button simple-icon"
              (click)="shareToMail(content.vid.heading)"
            >
              <icon-font
                name="icon-envelope"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToLinkedIn()">
              <icon-font
                name="icon-linkedin"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToTwitter()">
              <icon-font
                name="icon-twitter"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToFb()">
              <icon-font
                name="icon-facebook"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
            <button class="button simple-icon" (click)="shareToWhatsApp()">
              <icon-font
                name="icon-whatsapp"
                colour="cyan"
                class="body-large cyan"
              ></icon-font>
            </button>
          </div>
        </div>
        <div class="row d-flex justify-content-between">
          <div class="col-12">
            <div
              class="embed-responsive embed-responsive-16by9 card-round significant-raise"
            >
              <iframe
                class="embed-responsive-item"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                [src]="selectedNewsletter[0].contact[0].url | safe"
              >
              </iframe>
            </div>
          </div>
          <div
            class="col-12 mt-3"
            *ngIf="selectedNewsletter[0].mainImage != null"
          >
            <img
              class="card-round img-fluid"
              [src]="selectedNewsletter[0].mainImage"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-avoiding-mistakes [content]="avoidingMistakesContent">
</app-avoiding-mistakes>

<app-frequently-asked-questions [faqList]="faqList">
</app-frequently-asked-questions>

<div class="container-xl py-5">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>
