<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- Financial advice hero section -->
<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid financial-advice-hero"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title financial-advice-header text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="pl-md-4">
          <div
            [innerHTML]="content?.header?.content"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
        </div>
        <div class="pl-md-4">
          <ng-container *ngFor="let action of content?.header?.actions">
            <button-text
              fragment="who-are-we"
              class="{{ action.isPrimary ? '' : 'mr-2' }}"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }} mr-2"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Employee benefit consulting section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 greybg-right h-100"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-10 offset-md-2 text-right">
        <div class="heading-border-right">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <div class="container-xl h-100">
    <div class="row d-flex align-items-center">
      <div class="col-md-6">
        <div>
          <div
            [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
          ></div>
        </div>
        <div class="row pl-md-2">
          <div class="col-md-12 pl-md-2 btn-100-sm">
            <ng-container
              *ngFor="
                let action of content?.body?.sections[0]?.sections[0]?.actions
              "
            >
              <button-text
                class="{{ action.isPrimary ? '' : '' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <img
          alt=""
          class="img-cover significant-raise"
          [src]="content?.body?.sections[0]?.image"
        />
      </div>
    </div>

    <div class="row h-100 mt-5">
      <div class="col-md-12 mb-4">
        <h4 class="large-heading">
          {{ content?.body?.sections[0]?.sections[1]?.heading }}
        </h4>
      </div>
      <div
        class="col-md-3 mb-4"
        *ngFor="let card of content?.body?.sections[0]?.sections[1]?.cards"
      >
        <div
          class="card-round noticeable-raise white-bg p-4 p-md-4 h-100 d-flex flex-column justify-content-start"
        >
          <h4>{{ card?.heading }}</h4>
          <div [innerHTML]="card?.content"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 offset-md-5">
        <ng-container
          *ngFor="
            let action of content?.body?.sections[0]?.sections[1]?.actions
          "
        >
          <button-text
            class="{{ action.isPrimary ? '' : 'mr-2' }}"
            buttonStyle="{{
              action?.buttonColor
                ? 'solid ' + action?.buttonColor
                : action.isPrimary
                ? 'solid'
                : 'outline'
            }}"
            [label]="action?.label"
            (click)="DynamicButtonClick(action)"
          >
          </button-text>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Business Insurance Advice -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 svg-bg-l retirement h-100"
  id="business-insurance"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-10 offset-md-2 text-right">
        <div class="heading-border-right">
          <h4 class="mega-title--mobile">
            {{ content?.body?.sections[1]?.heading }}
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="container-xl h-100">
    <div class="row d-flex align-items-center">
      <div class="col-md-6">
        <img
          alt=""
          class="img-cover significant-raise"
          [src]="content?.body?.sections[1]?.image"
        />
      </div>
      <div class="col-md-5 offset-md-1">
        <div
          [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"
        ></div>
      </div>
    </div>
  </div>
</div>
<!-- Business Insurance Advice -->
