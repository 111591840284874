import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from "@angular/router";
import { PSGAssetManagementFormsModel } from 'src/app/models/content/about-us/psg-asset-management-forms.model';
import { PageService } from "src/app/services/page.service";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs/operators";
import { HelperService } from 'src/app/services/helper/helper.service';

@Component({
  selector: 'app-asset-management-forms',
  templateUrl: './asset-management-forms.component.html'
})
export class AssetManagementFormsComponent implements OnInit {

  content: any = {};
  isPageLoading: boolean = true;

  constructor(
    private router: Router,
    public pageService: PageService,
    public seoService: SeoService,
    private renderer: Renderer2,
    public helperService: HelperService
  ) { }

  ngOnInit() {
    this.getContent();
    this.seoService.setJsonLd(this.renderer, null);
  }

  getContent() {

    this.pageService.GetPage<PSGAssetManagementFormsModel>(this.pageService.URIs.psgAssetManagementForms).pipe(finalize(() => { this.isPageLoading = false; })).subscribe((content) => {

      this.content = new PSGAssetManagementFormsModel(content);
      this.seoService.updateMetaInfoForPage(this.content.seo.title, window.location.href, this.content?.header?.image, this.content.seo.description, "article");

    });

  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) window.location.replace(action.url);
    else this.router.navigateByUrl(action.url);
  }

  public removeWhiteSpace(str: string): string {
    return str.replace(/\s/g, "");
  }

  public goToURI(uri: string) {
    this.router.navigateByUrl(uri);
  }

}
