<!-- hero section -->
<div class="container-fluid zoomout-banner-m">
  <div
    class="container-fluid falcon-hero"
    [style.backgroundImage]="'url(' + content?.image + ')'"
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column align-content-start justify-content-center h-100"
      >
        <div class="heading-border-left col-11">
          <h1
            class="mega-title--mobile falconHeading text-shadow"
            [ngClass]="
              content?.headingColour ? content?.headingColour : 'dark-black'
            "
          >
            {{ content?.heading }}
          </h1>
          <h3
            class="mt-4 large-heading text-shadow pl-2"
            [ngClass]="
              content?.subheadingColour ? content?.subheadingColour : 'white'
            "
          >
            {{ content?.subheading }}
          </h3>
        </div>

        <div class="col-md-9 text-center mx-auto">
          <div
            class="d-flex flex-wrap align-items-center justify-content-center mx-auto"
          >
            <ng-container *ngFor="let action of content?.actions">
              <button
                type="button"
                class="button mb-2"
                ngClass="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }} mr-2"
                (click)="DynamicButtonClick(action)"
                style="width: 240px"
              >
                {{ action?.label }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /hero section -->

<!-- single card -->
<div class="container campaign-hero--cards">
  <div class="row d-flex justify-content-center">
    <div class="col-md-12">
      <div class="blur-card">
        <p
          class="small-heading text-center largeQuote"
          [innerHTML]="content?.excerpt"
          [ngClass]="content?.contentColour ? content?.contentColour : 'white'"
        ></p>
      </div>
    </div>
  </div>
</div>
<!-- /single cards -->
