<div class="d-flex flex-row col-12 justify-content-center mb-5" *ngIf="!this.overViewData">
  <div class="spinner-border " role="status" >
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="table-responsive" [hidden]="!this.overViewData">
  <!-- -------------------- -->
  <table class="table table-bordered table-hover">
      <thead>
          <tr>
              <th rowspan="2"   >
                  ASISA category</th>
              <th rowspan="2" >Fund
                  name</th>
              <th rowspan="2" *ngIf="showclass" class="overview-4 text-center">Class
              </th>
              <th rowspan="2" class="pP-2">NAV (Rands)<br>at {{(overViewData?.AsisaCategories[0]?.Rows?.length > 0) ? (overViewData?.AsisaCategories[0]?.Rows[0]?.FundPriceDate  | amDateFormat: 'DD MMM YYYY'):""}}</th>
              <th class="pP-3 text-center" colspan="4">Performance –As at
                  {{(overViewData?.AsisaCategories[0]?.Rows?.length > 0) ? (overViewData?.AsisaCategories[0]?.Rows[0]?.FundPerformanceDate | amDateFormat:
                  'YYYY/MM/DD' ):"" }}
              </th>
          </tr>
          <tr>
            <th>1 year %</th>
            <th>3 year %</th>
            <th>5 year %</th>
            <th>Inception %</th>
        </tr>
      </thead>
      <tbody *ngFor="let row of this.overViewData?.AsisaCategories">
          <tr >
            <td class="body-small" [rowSpan]="row?.Rows?.length">
              {{row.Category}}
            </td>
            <td>
              <a [href]="row?.Rows[0]?.FundUrl"
                    target="_blank" class="textlink" rowspan="1">
                    {{row.Rows[0].FundName}}</a>
            </td>
            <td *ngIf="showclass" class="text-center">{{row.Rows[0].Class}}</td>
            <td class="text-center">
              {{row?.Rows[0]?.PreTaxNav}}
            </td>
            <td class="text-center">
              {{row?.Rows[0]?.FundPerformanceY1}}
            </td>
            <td class="text-center">
              {{row?.Rows[0]?.FundPerformanceY3}}
            </td>
            <td class="text-center">
              {{row?.Rows[0]?.FundPerformanceY5}}
            </td>
            <td class="text-center">
              {{row?.Rows[0]?.FundPerformanceInception}}
            </td>
          </tr>
          <tr *ngFor="let innerRow of row.Rows,let i = index" [hidden]="i == 0">
            <td>
              <a [href]="innerRow.FundUrl"
                    target="_blank" class="textlink" rowspan="1">
                    {{innerRow.FundName}}</a>

            </td>
            <td *ngIf="showclass" class="text-center">{{innerRow.Class}}</td>
            <td class="text-center">
              {{innerRow?.PreTaxNav}}
            </td>
            <td class="text-center">
              {{innerRow?.FundPerformanceY1}}
            </td>
            <td class="text-center">
              {{innerRow?.FundPerformanceY3}}
            </td>
            <td class="text-center">
              {{innerRow?.FundPerformanceY5}}
            </td>
            <td class="text-center">
              {{innerRow?.FundPerformanceInception}}
            </td>
          </tr>
    </tbody>
</table>

</div>
