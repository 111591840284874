<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- psg-single-managed-funds main content section -->
<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid py-5 py-md-0 single-managed-funds-hero"
    [style.backgroundImage]="'url(' + content?.header?.image + ')'"
  >
    <div class="container-xl h-100">
      <div class="row align-items-center h-100">
        <div class="col-md-8">
          <div class="heading-border-left">
            <h1
              class="mega-title--page-title sm-funds text-shadow"
              [ngClass]="
                content?.header?.headingColour
                  ? content?.header?.headingColour
                  : 'dark-black'
              "
            >
              {{ content?.header?.heading }}
            </h1>
          </div>
          <div class="pl-4 pl-md-4">
            <div
              [innerHTML]="content?.header?.content"
              [ngClass]="
                content?.header?.contentColour
                  ? content?.header?.contentColour
                  : 'white'
              "
            ></div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="psg-single-managed-funds-card"
            *ngIf="content?.header?.cards?.length > 0"
          >
            <div>
              <h2 class="large-heading">
                {{ content?.header?.cards[0]?.heading }}
              </h2>
              <div [innerHTML]="content?.header?.cards[0]?.content"></div>
            </div>
            <div>
              <ng-container
                *ngFor="let action of content?.header?.cards[0]?.actions"
              >
                <button-text
                  class="{{ action.isPrimary ? '' : 'mr-2' }}"
                  buttonStyle="{{
                    action?.buttonColor
                      ? 'solid ' + action?.buttonColor
                      : action.isPrimary
                      ? 'solid'
                      : 'outline'
                  }}"
                  [label]="action?.label"
                  (click)="DynamicButtonClick(action)"
                >
                </button-text>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center h-100"></div>
    </div>
  </div>
</div>

<div *ngIf="!isPageLoading">
  <div class="container-xl" style="margin-top: -2rem">
    <div class="row d-flex">
      <div class="col-md-7 mb-4">
        <div class="blur-card" *ngIf="content?.header?.cards !== undefined">
          <div>
            <h2 class="large-heading mb-4">
              {{ content?.header?.cards[1]?.heading }}
            </h2>
            <div [innerHTML]="content?.header?.cards[1]?.content"></div>
          </div>
          <div>
            <ng-container
              *ngFor="let action of content?.header?.cards[1]?.actions"
            >
              <button-text
                class="mr-2"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--/psg-single-managed-funds main content section -->

<!--SM local funds table -->
<div *ngIf="!isPageLoading" class="container-fluid my-3">
  <div class="container-xl h-100">
    <div class="ml-5 local-funds-text-mobile">
      <div [innerHTML]="content?.body?.sections[0]?.content"></div>
    </div>
  </div>
  <div class="container-xl px-0 mt-5" id="local-single-managed-funds">
    <app-sm-funds-local [content]="content"> </app-sm-funds-local>
  </div>
  <div *ngFor="let section of content?.body?.sections; let i = index">
    <div
      class="container-xl px-0 mt-3"
      *ngIf="section?.type === 'funds' && section?.heading === 'Local funds'"
    >
      <ng-container *ngFor="let action of section?.actions">
        <div class="mt-4 ml-0 col-md-4 offset-md-0">
          <button-text
            buttonStyle="{{
              action?.buttonColor != null
                ? action?.buttonColor
                : action.isPrimary
                ? 'green'
                : 'outline'
            }}"
            [label]="action?.label"
            fill="true"
            (click)="viewLocalFundsQuarterlyCommentary(action?.url)"
          >
          </button-text>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!--/SM local funds table -->

<!--SM global funds table -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid my-5"
  id="global-single-managed-funds"
>
  <div class="container-xl px-0 h-100">
    <app-sm-funds-global [content]="content"> </app-sm-funds-global>
  </div>
  <div class="container-xl px-0 mt-3">
    <div class="mt-4 ml-0 col-md-4 offset-md-0">
      <button-text
        buttonStyle="solid"
        [label]="'View PSG Global funds quarterly commentary'"
        fill="true"
        (click)="viewQuarterlyCommentary()"
      >
      </button-text>
    </div>
  </div>
</div>
<!--/SM global funds table -->

<!-- The fine print-->
<div *ngIf="!isPageLoading" class="container-fluid py-5">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="slight-raise card-rounder p-5">
          <h2 class="small-heading">
            {{ content?.body?.sections[3]?.heading }}
          </h2>
          <div [innerHTML]="content?.body?.sections[3]?.content"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /The fine print -->

<!-- Fund performance -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid mt-5 pt-5 svg-bg-l details--fundP h-100"
  id="fund-performance-single-managed-funds"
>
  <div class="container-fluid h-100">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-7 ml-md-4">
        <div class="large-heading">
          <h2>{{ content?.body?.sections[4]?.sections[0]?.heading }}</h2>
        </div>
        <div
          [innerHTML]="content?.body?.sections[4]?.sections[0]?.content"
        ></div>
      </div>
    </div>
  </div>
</div>
<!--/Fund performance -->

<div *ngIf="!isPageLoading" class="container-fluid slate-bg-10 py-5 my-5">
  <div class="container-xl">
    <div>
      <h2 class="mb-4">{{ content?.body?.sections[5]?.heading }}</h2>
    </div>
    <div class="card-deck">
      <div
        class="card news--card"
        *ngFor="let news of content?.body?.sections[5]?.articles"
      >
        <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
        <div class="card-body d-flex flex-column">
          <div class="card-tags pb-2">
            <span
              *ngFor="let category of news?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div>
          <h5 class="small-heading">{{ news?.headline }}</h5>
          <p class="body-small" [innerHTML]="news?.excerpt"></p>
          <a [routerLink]="news?.uri" class="textlink mt-auto">Read more</a>
        </div>
      </div>
    </div>
  </div>
</div>
