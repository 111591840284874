import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ChartDataset, ChartOptions, ChartType } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { SensShareDataPage } from "src/app/models/content/about-us/sens-and-share-data-page.model";
import { PageService } from "src/app/services/page.service";
import {
  GraphData,
  SensArticle,
  SharePriceFeed,
  Trade,
} from "src/app/services/sens/sens-trade.models";
import { SensTradeService } from "src/app/services/sens/sens-trade.service";
import xml2js from "xml2js";
import * as moment from "moment";
import { debounceTime, finalize, iif, takeWhile } from "rxjs";
import { SeoService } from "src/app/services/socialLinks.service";
@Component({
  selector: "app-sens-and-share-data",
  templateUrl: "./sens-and-share-data.component.html",
})
export class SensAndShareDataComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  public sensArticleData: Array<SensArticle>;
  public sharePriceFeed: SharePriceFeed;
  public TradesPrevious: Array<Trade> = [];
  public graphData: GraphData = null;
  public alive: boolean = true;

  public sensArticleSearchForm = this.buildArticleSearchForm();
  public sensArticleDateSearchForm = this.buildArticleDateSearchForm();

  public months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  public years: Array<number> = new Array<number>();
  public yearsLoaded: boolean = false;

  public lineChartData: ChartDataset[] = [{ data: [], label: "Volume" }];
  public lineChartLabels: any[] = []; // Label[] = [];
  public noTrades: boolean = false;

  public tradeDate: string = "";

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  public content: any = {};
  public isPageLoading: boolean = true;

  constructor(
    private sensTradeService: SensTradeService,
    private router: Router,
    private pageService: PageService,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    public seoService: SeoService,
    private renderer: Renderer2
  ) {
    pageService
      .GetPage<SensShareDataPage>(pageService.URIs.sensShareData)
      .pipe(
        finalize(() => {
          this.isPageLoading = false;
        })
      )
      .subscribe((content) => {
        this.content = new SensShareDataPage(content);

        this.seoService.updateMetaInfoForPage(
          this.content.seo.title,
          window.location.href,
          this.content?.header?.image,
          this.content.seo.description
        );
      });
  }

  public DynamicButtonClick(action: any) {
    if (action.isExternal) {
      window.open(action.url, "_blank");
    } else {
      this.router.navigateByUrl(action.url);
    }
  }

  public ngOnInit() {
    this.sensArticleData = new Array<SensArticle>();
    this.getSensArticleData();

    this.sensTradeService
      .getAllTradesData()
      .pipe(takeWhile(() => this.alive))
      .subscribe((result) => {
        this.parseArticleXML(result).then((data: any[]) => {
          this.sensArticleData = data;
        });
      });

    this.sensTradeService
      .getAllTradesData()
      .pipe(takeWhile(() => this.alive))
      .subscribe((result) => {
        this.parseTradeXML(result).then((data: any) => {
          this.sharePriceFeed = data;
          if (this.sharePriceFeed.Trades.length == 0) {
            this.noTrades = true;
          } else {
            this.noTrades = false;
          }
        });
      });

    this.sensTradeService
      .getGraphData()
      .pipe(takeWhile(() => this.alive))
      .subscribe((result) => {
        this.parseGraphDataXML(result).then((data: any) => {
          this.graphData = data;
          this.lineChartData = [
            {
              data: this.graphData.LastPrice,
              label: "Price",
              backgroundColor: "rgba(148,159,177,0.2)",
              borderColor: "rgba(148,159,177,1)",
              pointBackgroundColor: "rgba(148,159,177,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(148,159,177,0.8)",
            },
          ];
          this.lineChartLabels = this.graphData.LastUpdated;

          this.tradeDate = moment(
            new Date(this.graphData.DateUpdated).toDateString()
          ).format("DD MMM YYYY");
          for (
            let index = 0;
            index < this.graphData.LastPrice.length;
            index++
          ) {
            this.TradesPrevious.push({
              Price: this.graphData.LastPrice[index].toString(),
              Time: this.graphData.LastUpdated[index],
              Volume: this.graphData.Volume[index].toString(),
            });
          }
        });
      });

    this.sensArticleSearchForm.controls.searchText.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(debounceTime(2000))
      .subscribe((result) => {
        if (result == "") {
          this.getSensArticleData();
        } else {
          this.sensArticleData = this.sensArticleData.filter((x) =>
            x.Title.toLocaleLowerCase().includes(result.toLocaleLowerCase())
          );

          this.sensArticleData.forEach((x) => {
            if (
              this.years.findIndex((y) => y == x.DateStamp.getFullYear()) == -1
            ) {
              this.years.push(x.DateStamp.getFullYear());
            }
          });
          this.yearsLoaded = true;
        }
      });

    this.sensArticleDateSearchForm.controls.fromMonth.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((result) => {
        this.searchByDates();
      });

    this.sensArticleDateSearchForm.controls.fromYear.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((result) => {
        this.searchByDates();
      });

    this.sensArticleDateSearchForm.controls.toMonth.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((result) => {
        this.searchByDates();
      });

    this.sensArticleDateSearchForm.controls.toYear.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe((result) => {
        this.searchByDates();
      });

    this.seoService.setJsonLd(this.renderer, null);
  }

  public searchByDates() {
    let fromMonth = this.sensArticleDateSearchForm.controls.fromMonth.value;
    let fromYear = this.sensArticleDateSearchForm.controls.fromYear.value;
    let toMonth = this.sensArticleDateSearchForm.controls.toMonth.value;
    let toYear = this.sensArticleDateSearchForm.controls.toYear.value;

    if (
      fromMonth != "Month" &&
      fromYear != "Year" &&
      toMonth != "Month" &&
      toYear != "Year"
    ) {
      let dateFrom = new Date(`01 ${fromMonth} ${fromYear}`);
      let dateTo = new Date(`01 ${toMonth} ${toYear}`);

      this.sensArticleData = this.sensArticleData.filter(
        (x) => x.DateStamp >= dateFrom && x.DateStamp <= dateTo
      );
    } else {
      this.getSensArticleData();
    }
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public getSensArticleData() {
    this.sensTradeService
      .getSENSArticleData()
      .pipe(takeWhile(() => this.alive))
      .subscribe((result) => {
        this.parseArticleXML(result).then((data: any[]) => {
          this.sensArticleData = data;
          this.sensArticleData.forEach((x) => {
            if (
              this.years.findIndex((y) => y == x.DateStamp.getFullYear()) == -1
            ) {
              this.years.push(x.DateStamp.getFullYear());
            }
          });
          this.yearsLoaded = true;
        });
      });
  }

  private parseArticleXML(data) {
    return new Promise((resolve) => {
      var sensArticles: Array<SensArticle> = new Array<SensArticle>();
      var parser = new xml2js.Parser({
        trim: true,
        explicitArray: false,
      });
      parser.parseString(data, function (err, result) {
        var obj = result;
        if (obj.data != undefined) {
          (<Array<any>>obj.data.record).forEach((element) => {
            sensArticles.push(new SensArticle(element));
          });
        }

        resolve(sensArticles);
      });
    });
  }

  private parseTradeXML(data) {
    return new Promise((resolve) => {
      var sharePrice: SharePriceFeed;
      var parser = new xml2js.Parser({
        trim: true,
        explicitArray: true,
      });
      parser.parseString(data, function (err, result) {
        var obj = result;
        sharePrice = new SharePriceFeed(result);
        resolve(sharePrice);
      });
    });
  }

  private parseGraphDataXML(data) {
    return new Promise((resolve) => {
      var graphData: GraphData;
      var parser = new xml2js.Parser({
        trim: true,
        explicitArray: true,
      });
      parser.parseString(data, function (err, result) {
        var obj = result;
        graphData = new GraphData(obj.data.price[0]);
        resolve(graphData);
      });
    });
  }

  public ReadMore(article: SensArticle) {
    this.sensTradeService.currentSensArticle.next(article);
    this.router.navigate(["investor-relations/sens-articles"]);
  }

  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: {},
      yAxes: {},
      // [
      //   {
      //     id: "y-axis-0",
      //     position: "left",
      //   },
      // ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "orange",
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: "orange",
            content: "LineAnno",
          },
        },
      ],
    },
  };
  public lineChartColors: any[] = [
    // Color[] = [
    {
      // grey
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // dark grey
      backgroundColor: "rgba(77,83,96,0.2)",
      borderColor: "rgba(77,83,96,1)",
      pointBackgroundColor: "rgba(77,83,96,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(77,83,96,1)",
    },
    {
      // red
      backgroundColor: "rgba(255,0,0,0.3)",
      borderColor: "red",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = "line";

  private buildArticleSearchForm(): FormGroup {
    return this.formBuilder.group({
      searchText: [""],
    });
  }

  private buildArticleDateSearchForm(): FormGroup {
    return this.formBuilder.group({
      fromMonth: ["Month"],
      fromYear: ["Year"],
      toMonth: ["Month"],
      toYear: ["Year"],
    });
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
}
