import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  forwardRef,
} from "@angular/core";
import { Location } from "@angular/common";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ViewportScroller } from "@angular/common";
import { SearchableItems, StyleguideSearchType } from "./searchItems";
import { environment } from "src/environments/environment";
import { share } from "rxjs";
import { ArticleService } from "src/app/services/article.service";
declare var $: any;

export enum menuItem {
  FinancialAdvice,
  SaveAndInvest,
  Insurance,
  AboutUs,
  NewsAndPublications,
  Careers,
  ContactUs,
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [
    SearchableItems,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HeaderComponent),
      multi: true,
    },
  ],
})
export class HeaderComponent {
  public activeFragment = this.route.fragment.pipe(share());
  public searchableItems: Array<StyleguideSearchType>;

  public canDisplayFinancialAdviceMenu: boolean = true;
  public canDisplaySaveInvestMenu: boolean = true;
  public canDisplayInsuranceMenu: boolean = true;
  public canDisplayAboutUsMenu: boolean = true;
  public canDisplayNewsAndPublicationMenu: boolean = true;
  public canDisplayCareersMenu: boolean = true;
  public canDisplayContactUsMenu: boolean = true;

  public showFinancialAdviceItems: boolean = false;
  public showFinancialAdviceManu: boolean = true;
  public financialAdviceActive: boolean = false;
  public selectedFinancialAdviceSubMenu: string = "";

  public showSaveInvestItems: boolean = false;
  public showSaveInvestManu: boolean = true;
  public saveInvestActive: boolean = false;
  public selectedSaveInvestSubMenu: string = "";

  public showInsurenceItems: boolean = false;
  public showInsurenceManu: boolean = true;
  public insuranceActive: boolean = false;
  public selectedInsuranceSubMenu: string = "";

  public showAboutUsItems: boolean = false;
  public showAboutUsManu: boolean = true;
  public aboutUsActive: boolean = false;
  public selectedAboutUsSubMenu: string = "";

  public showContactUsItems: boolean = false;
  public showContactUsManu: boolean = true;
  public contactUsActive: boolean = false;
  public selectedContactUsSubMenu: string = "";

  public showNewsAndPublicationItems: boolean = false;
  public showNewsAndPublicationManu: boolean = true;
  public newsAndPublicationActive: boolean = false;
  public selectedNewsAndPublicationSubMenu: string = "";

  public showCareesItems: boolean = false;
  public showCareersManu: boolean = true;
  public careersActive: boolean = false;
  public careersSubMenuActive: boolean = false;
  public selectedCareersSubMenu: string = "";
  public lastSelectedSubMenu: string = "";
  public selectedMenuItem: menuItem;
  public lastSelectedMenu: menuItem;
  private lastScrollPosition: number = 0;

  @ViewChild("stickyMenu", { static: false }) menuElement: ElementRef;

  sticky: boolean = false;
  elementPosition: any;
  fragment: string;

  public ClickedOnTaxFreeInvestments: boolean = false;

  // Search Bar
  public searchForm: FormGroup;

  //Latest Article
  public latestArticleURL: string = "";
  public menuActive: Boolean = false;
  private subMenuHeight: Number;
  advisersUrl = environment.advisersUrl;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private viewportScoller: ViewportScroller,
    private searchaleItemsData: SearchableItems,
    private location: Location,
    public diuArticleService: ArticleService
  ) { }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.alignSubMenuItems();
  }

  ngOnInit() {
    this.searchableItems = this.searchaleItemsData.searchableItems;
    this.getLatestPublicationArticle();
    this.getCurrentlySelectedMenu();
  }

  getLatestPublicationArticle() {
    this.diuArticleService
      .GetAllDailyInvestmentUpdatesArticles()
      .subscribe((diuArticles) => {
        diuArticles = diuArticles.sort((a, b) =>
          a.publishDate < b.publishDate ? 1 : -1
        );
        this.latestArticleURL = diuArticles[0].uri;
      });
  }

  navigateToLatestPublication() {
    this.router.navigate([this.latestArticleURL]);
  }

  clickOutsideMenu(event) {
    if (event.target.dataset.toggle === "dropdown") {
      return;
    }

    let anyItemsShowing =
      this.showFinancialAdviceItems ||
      this.showSaveInvestItems ||
      this.showInsurenceItems ||
      this.showAboutUsItems ||
      this.showNewsAndPublicationItems ||
      this.showCareesItems ||
      this.showContactUsItems;

    if (anyItemsShowing) {
      this.hideMenu();
    }
  }

  hideMenu() {
    switch (this.selectedMenuItem) {
      case menuItem.FinancialAdvice:
        this.resetMenuSelectionStatus();
        this.menuActive = true;
        this.financialAdviceActive = true;

        this.selectedFinancialAdviceSubMenu =
          this.lastSelectedSubMenu === ""
            ? "forYourself"
            : this.lastSelectedSubMenu;

        this.showFinancialAdviceItems = false;
        break;

      case menuItem.SaveAndInvest:
        this.lastSelectedMenu = menuItem.SaveAndInvest;

        this.resetMenuSelectionStatus();
        this.menuActive = true;
        this.saveInvestActive = true;

        this.selectedSaveInvestSubMenu =
          this.lastSelectedSubMenu === ""
            ? "savings"
            : this.lastSelectedSubMenu;

        this.showSaveInvestItems = false;
        break;

      case menuItem.Insurance:
        this.lastSelectedMenu = menuItem.Insurance;

        this.resetMenuSelectionStatus();
        this.menuActive = true;
        this.insuranceActive = true;

        this.selectedInsuranceSubMenu =
          this.lastSelectedSubMenu === ""
            ? "yourThings"
            : this.lastSelectedSubMenu;

        this.showInsurenceItems = false;
        break;

      case menuItem.AboutUs:
        this.lastSelectedMenu = menuItem.AboutUs;

        this.resetMenuSelectionStatus();
        this.menuActive = true;
        this.aboutUsActive = true;

        this.selectedAboutUsSubMenu =
          this.lastSelectedSubMenu === ""
            ? "company"
            : this.lastSelectedSubMenu;

        this.showAboutUsItems = false;
        break;

      case menuItem.NewsAndPublications:
        this.lastSelectedMenu = menuItem.NewsAndPublications;

        this.resetMenuSelectionStatus();
        this.menuActive = true;
        this.newsAndPublicationActive = true;

        this.selectedNewsAndPublicationSubMenu =
          this.lastSelectedSubMenu === ""
            ? "testimonials"
            : this.lastSelectedSubMenu;

        this.showNewsAndPublicationItems = false;
        break;

      case menuItem.Careers:
        this.lastSelectedMenu = menuItem.Careers;

        this.resetMenuSelectionStatus();
        this.menuActive = true;
        this.careersActive = true;

        this.selectedCareersSubMenu =
          this.lastSelectedSubMenu === ""
            ? "workingForUs"
            : this.lastSelectedSubMenu;

        this.showCareesItems = false;
        break;

      case menuItem.ContactUs:
        this.lastSelectedMenu = menuItem.ContactUs;
        this.resetMenuSelectionStatus();
        this.menuActive = true;
        this.contactUsActive = true;

        this.selectedContactUsSubMenu =
          this.lastSelectedSubMenu === "" ? "none" : this.lastSelectedSubMenu;

        this.showContactUsItems = false;
        break;

      default:
        break;
    }
  }

  getCurrentlySelectedMenu() {
    this.resetMenuSelectionStatus();
    var currentPath = window.location.pathname;
    if (
      currentPath.startsWith("/save-and-invest/goal-calculator") ||
      currentPath.startsWith("/save-and-invest/education-calculator") ||
      currentPath.startsWith("/save-and-invest/retirement-calculator")
    ) {
      this.saveInvestActive = true;
      this.selectedSaveInvestSubMenu = "calculators";
    }
  }

  public resetMenuSelectionStatus(): void {
    this.financialAdviceActive = false;
    this.saveInvestActive = false;
    this.insuranceActive = false;
    this.aboutUsActive = false;
    this.newsAndPublicationActive = false;
    this.careersActive = false;
    this.contactUsActive = false;

    this.selectedFinancialAdviceSubMenu = "";
    this.selectedSaveInvestSubMenu = "";
    this.selectedInsuranceSubMenu = "";
    this.selectedAboutUsSubMenu = "";
    this.selectedNewsAndPublicationSubMenu = "";
    this.selectedCareersSubMenu = "";
    this.selectedContactUsSubMenu = "";
    this.menuActive = false;
  }

  //  Search
  public searchOpen = false;

  public toggleSearch(id): void {
    this.showSaveInvestItems = false;
    this.showInsurenceItems = false;
    this.showAboutUsItems = false;
    this.showNewsAndPublicationItems = false;
    this.showCareesItems = false;
    this.showContactUsItems = false;
    this.showFinancialAdviceItems = false;
    this.searchOpen = !this.searchOpen;
    if (this.searchOpen) {
      this.router.navigateByUrl("/search-results");
      document.getElementById(id).classList.add("show");
      document.getElementById("searchInput").focus();
    } else {
    }
  }

  //  Mobile Nav

  public mobileNavOpen = false;
  public toggleMobileNav(id): void {
    this.mobileNavOpen = !this.mobileNavOpen;
    if (this.mobileNavOpen) {
      document.getElementById(id).classList.add("show");
    } else {
      document.getElementById(id).classList.remove("show");
    }
  }

  public toggleMenu() {
    this.resetMenuSelectionStatus();
    this.showSaveInvestItems = false;
    this.showInsurenceItems = false;
    this.showAboutUsItems = false;
    this.showNewsAndPublicationItems = false;
    this.showCareesItems = false;
    this.showContactUsItems = false;
    this.showFinancialAdviceItems = false;
  }

  public toggleFinancialAdvice(subItemSelected: string = "") {
    this.selectedMenuItem = menuItem.FinancialAdvice;

    this.alignSubMenuItems();

    this.resetMenuSelectionStatus();
    this.financialAdviceActive = true;
    this.menuActive = true;

    this.selectedFinancialAdviceSubMenu =
      this.lastSelectedSubMenu && !subItemSelected
        ? this.lastSelectedSubMenu
        : subItemSelected;

    this.lastSelectedSubMenu = this.selectedFinancialAdviceSubMenu;

    this.showSaveInvestItems = false;
    this.showInsurenceItems = false;
    this.showAboutUsItems = false;
    this.showNewsAndPublicationItems = false;
    this.showCareesItems = false;
    this.showContactUsItems = false;

    if (this.lastSelectedMenu != menuItem.FinancialAdvice) {
      this.showFinancialAdviceItems = true;
    } else {
      this.showFinancialAdviceItems = !this.showFinancialAdviceItems;
    }
    this.lastSelectedMenu = menuItem.FinancialAdvice;

    if (this.showFinancialAdviceItems == true) {
      this.showFinancialAdviceManu = true;
    }
  }

  public toggleSaveAndInvest(subItemSelected: string = "") {
    this.selectedMenuItem = menuItem.SaveAndInvest;

    this.alignSubMenuItems();
    this.resetMenuSelectionStatus();
    this.saveInvestActive = true;
    this.menuActive = true;

    this.selectedSaveInvestSubMenu =
      this.lastSelectedSubMenu && !subItemSelected
        ? this.lastSelectedSubMenu
        : subItemSelected;

    this.lastSelectedSubMenu = this.selectedSaveInvestSubMenu;

    this.showFinancialAdviceItems = false;
    this.showInsurenceItems = false;
    this.showAboutUsItems = false;
    this.showNewsAndPublicationItems = false;
    this.showCareesItems = false;
    this.showContactUsItems = false;

    if (this.lastSelectedMenu != menuItem.SaveAndInvest) {
      this.showSaveInvestItems = true;
    } else {
      this.showSaveInvestItems = !this.showSaveInvestItems;
    }
    this.lastSelectedMenu = menuItem.SaveAndInvest;

    if (this.showSaveInvestItems == true) {
      this.showSaveInvestManu = true;
    }
  }

  public toggleInsurence(subItemSelected: string = "") {
    this.selectedMenuItem = menuItem.Insurance;

    this.alignSubMenuItems();
    this.resetMenuSelectionStatus();
    this.insuranceActive = true;
    this.menuActive = true;

    this.selectedInsuranceSubMenu =
      this.lastSelectedSubMenu && !subItemSelected
        ? this.lastSelectedSubMenu
        : subItemSelected;

    this.lastSelectedSubMenu = this.selectedInsuranceSubMenu;

    this.showSaveInvestItems = false;
    this.showFinancialAdviceItems = false;
    this.showAboutUsItems = false;
    this.showNewsAndPublicationItems = false;
    this.showCareesItems = false;
    this.showContactUsItems = false;

    if (this.lastSelectedMenu != menuItem.Insurance) {
      this.showInsurenceItems = true;
    } else {
      this.showInsurenceItems = !this.showInsurenceItems;
    }
    this.lastSelectedMenu = menuItem.Insurance;

    if (this.showInsurenceItems == true) {
      this.showInsurenceManu = true;
    }
  }

  public toggleAboutUs(subItemSelected: string = "") {
    this.selectedMenuItem = menuItem.AboutUs;

    this.alignSubMenuItems();
    this.resetMenuSelectionStatus();
    this.aboutUsActive = true;
    this.menuActive = true;

    this.selectedAboutUsSubMenu =
      this.lastSelectedSubMenu && !subItemSelected
        ? this.lastSelectedSubMenu
        : subItemSelected;

    this.lastSelectedSubMenu = this.selectedAboutUsSubMenu;

    this.showSaveInvestItems = false;
    this.showFinancialAdviceItems = false;
    this.showInsurenceItems = false;
    this.showNewsAndPublicationItems = false;
    this.showCareesItems = false;
    this.showContactUsItems = false;

    if (this.lastSelectedMenu != menuItem.AboutUs) {
      this.showAboutUsItems = true;
    } else {
      this.showAboutUsItems = !this.showAboutUsItems;
    }
    this.lastSelectedMenu = menuItem.AboutUs;

    if (this.showAboutUsItems == true) {
      this.showAboutUsManu = true;
    }
  }

  public toggleNewsAndPublication(subItemSelected: string = "") {
    this.selectedMenuItem = menuItem.NewsAndPublications;

    this.alignSubMenuItems();
    this.resetMenuSelectionStatus();
    this.newsAndPublicationActive = true;
    this.menuActive = true;

    this.selectedNewsAndPublicationSubMenu =
      this.lastSelectedSubMenu && !subItemSelected
        ? this.lastSelectedSubMenu
        : subItemSelected;

    this.lastSelectedSubMenu = this.selectedNewsAndPublicationSubMenu;

    this.showSaveInvestItems = false;
    this.showFinancialAdviceItems = false;
    this.showInsurenceItems = false;
    this.showAboutUsItems = false;
    this.showCareesItems = false;
    this.showContactUsItems = false;

    if (this.lastSelectedMenu != menuItem.NewsAndPublications) {
      this.showNewsAndPublicationItems = true;
    } else {
      this.showNewsAndPublicationItems = !this.showNewsAndPublicationItems;
    }
    this.lastSelectedMenu = menuItem.NewsAndPublications;

    if (this.showNewsAndPublicationItems == true) {
      this.showNewsAndPublicationManu = true;
    }
  }

  public toggleCarees(subItemSelected: string = "") {
    this.selectedMenuItem = menuItem.Careers;

    this.alignSubMenuItems();
    this.resetMenuSelectionStatus();
    this.careersActive = true;
    this.menuActive = true;

    this.selectedCareersSubMenu =
      this.lastSelectedSubMenu && !subItemSelected
        ? this.lastSelectedSubMenu
        : subItemSelected;

    this.lastSelectedSubMenu = this.selectedCareersSubMenu;

    this.showSaveInvestItems = false;
    this.showFinancialAdviceItems = false;
    this.showInsurenceItems = false;
    this.showNewsAndPublicationItems = false;
    this.showContactUsItems = false;
    this.showAboutUsItems = false;

    if (this.lastSelectedMenu != menuItem.Careers) {
      this.showCareesItems = true;
    } else {
      this.showCareesItems = !this.showCareesItems;
    }
    this.lastSelectedMenu = menuItem.Careers;

    if (this.showCareesItems == true) {
      this.showCareersManu = true;
    } else {
      //underline selected submenu item and hide items
      this.careersSubMenuActive = true;
    }
  }

  public toggleContactUs(subItemSelected: string = "") {
    this.selectedMenuItem = menuItem.ContactUs;

    this.alignSubMenuItems();
    this.resetMenuSelectionStatus();
    this.contactUsActive = true;
    this.menuActive = true;

    this.selectedContactUsSubMenu =
      this.lastSelectedSubMenu && !subItemSelected
        ? this.lastSelectedSubMenu
        : subItemSelected;

    this.lastSelectedSubMenu = this.selectedContactUsSubMenu;

    this.showSaveInvestItems = false;
    this.showFinancialAdviceItems = false;
    this.showInsurenceItems = false;
    this.showNewsAndPublicationItems = false;
    this.showAboutUsItems = false;
    this.showCareesItems = false;

    if (this.lastSelectedMenu != menuItem.ContactUs) {
      this.showContactUsItems = true;
    } else {
      this.showContactUsItems = !this.showContactUsItems;
    }
    this.lastSelectedMenu = menuItem.ContactUs;

    if (this.showContactUsItems == true) {
      this.showContactUsManu = true;
    }
  }

  private alignSubMenuItems() {
    $(".submenu-heading").css("height", "");

    setTimeout(() => {
      let headingElem = $(".submenu-heading");
      let maxHeight = 18;

      for (let index = 0; index < headingElem.length; index++) {
        let height = $(headingElem[index]).height();
        let lineHeight = +$(headingElem[index])
          .css("line-height")
          .replace("px", "");

        if (height > lineHeight) {
          maxHeight = height;
        }
      }
      $(".submenu-heading").css("height", `${maxHeight}px`);
    }, 10);
  }

  public currentlyVisibleSubmenu = "none";

  public showMobileSubMenu(id): void {
    document.getElementById(id).classList.add("show");
    this.currentlyVisibleSubmenu = id;
  }

  public hideMobileSubMenu(id): void {
    document.getElementById(id).classList.remove("show");
    this.currentlyVisibleSubmenu = "none";
  }

  public HideFinanceAdviceMenu() {
    this.canDisplayFinancialAdviceMenu = false;
    setTimeout(() => {
      this.canDisplayFinancialAdviceMenu = true;
    }, 50);
  }

  public HideSaveInvestMenu() {
    this.canDisplaySaveInvestMenu = false;
    setTimeout(() => {
      this.canDisplaySaveInvestMenu = true;
    }, 50);
  }
  public HideInsuranceMenu() {
    this.canDisplayInsuranceMenu = false;
    setTimeout(() => {
      this.canDisplayInsuranceMenu = true;
    }, 50);
  }
  public HideAboutUsMenu() {
    this.canDisplayAboutUsMenu = false;
    setTimeout(() => {
      this.canDisplayAboutUsMenu = true;
    }, 50);
  }
  public HideNewsAndPublicationMenu() {
    this.canDisplayNewsAndPublicationMenu = false;
    setTimeout(() => {
      this.canDisplayNewsAndPublicationMenu = true;
    }, 50);
  }
  public HideCareersMenu() {
    this.canDisplayCareersMenu = false;
    setTimeout(() => {
      this.canDisplayCareersMenu = true;
    }, 50);
  }
  public HideContactUsMenu() {
    this.canDisplayContactUsMenu = false;
    setTimeout(() => {
      this.canDisplayContactUsMenu = true;
    }, 50);
  }

  //----------------------------------------------------------------------------------

  goToGlobalSite() {
    window.open(environment.globalSiteUrl, "_blank");
  }

  goToLocalSite() {
    window.open(this.location.path(), "_blank");
  }

  logInMyPSG() {
    window.open(environment.loginURL.myPSG, "_blank");
  }
  logInWealth() {
    window.open(environment.loginURL.wealthFunds, "_blank");
  }
  logInPSGOnline() {
    window.open(environment.loginURL.PSGOnline, "_blank");
  }
  logInAll() {
    window.open(environment.loginURL.allPlatformAccess, "_blank");
  }

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener("window:scroll")
  handleScroll(e) {
    var currentPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentPosition > this.lastScrollPosition) {
      this.hideMenu();
    }
    this.lastScrollPosition = currentPosition <= 0 ? 0 : currentPosition;

    const windowScroll = window.pageYOffset;
    this.sticky = true;
    if (windowScroll > 0) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }
}
