export class ContactUsPage {
  header: any = {};
  body: any = {};

  constructor(init: any = {}) {
    for (const key of Object.keys(init)) {
      this[key] = init[key];
    }

    if (this.header.content)
      this.header.content = this.header.content.replace(
        /<p>/g, '<p class="p-length-md text-shadow">'
      );

    if (this.header.excerpt)
      this.header.excerpt = this.header.excerpt.replace(
        /<p>/g, '<p class="p-length-md">'
      );
  }
}

export class ContactUsDownloadModel {
  constructor(download: any) {
    this.label = download.label;
    this.file = download.file;
    this.publishDate = download.publishDate;
    this.url = download.url;
  }

  label: string;
  publishDate: string;
  file: string;
  url: string;
}
