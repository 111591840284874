<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- savings-page hero section -->
<div *ngIf="content.header !== undefined && !isPageLoading">
  <div
    class="container-fluid savings-page"
    [style.backgroundImage]="'url(' + content?.header?.image + ')'"
  >
    <div class="container-xl h-100">
      <div
        class="row d-flex flex-column h-100 align-content-start justify-content-start justify-content-sm-center pt-4 pt-sm-0"
      >
        <div class="heading-border-left">
          <h1
            class="mega-title--page-title savings text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            Invest in your<br />
            dream <span class="savings-animatio-text"></span>
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- savings-page hero section -->

<!-- savings-page cards -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid mx-auto savings-page-cards"
  style="max-width: 1170px"
>
  <div class="row">
    <div class="col-md-4 mb-4">
      <div
        class="blur-card--savings d-flex flex-column h-100 align-content-start justify-content-start"
      >
        <div>
          <h2 class="medium-heading">{{ content?.body?.cards[0]?.heading }}</h2>
          <div [innerHTML]="content?.body?.cards[0]?.content"></div>
        </div>
        <div class="row">
          <div class="col-md-12 pl-md-2 btn-row">
            <ng-container
              *ngFor="let action of content?.body?.cards[0]?.actions"
            >
              <button-text
                fragment="who-are-we"
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-4">
      <div
        class="blur-card--savings d-flex flex-column h-100 align-content-start justify-content-start"
      >
        <div>
          <h2 class="medium-heading">{{ content?.body?.cards[1]?.heading }}</h2>
          <div [innerHTML]="content?.body?.cards[1]?.content"></div>
        </div>
        <div class="row">
          <div class="col-md-12 pl-md-2 btn-row">
            <ng-container
              *ngFor="let action of content?.body?.cards[1]?.actions"
            >
              <button-text
                fragment="who-are-we"
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-4">
      <div
        class="blur-card--savings d-flex flex-column h-100 align-content-start justify-content-start"
      >
        <div>
          <h2 class="medium-heading">{{ content?.body?.cards[2]?.heading }}</h2>
          <div [innerHTML]="content?.body?.cards[2]?.content"></div>
        </div>

        <div class="row">
          <div class="col-md-12 pl-md-2 btn-row">
            <ng-container
              *ngFor="let action of content?.body?.cards[2]?.actions"
            >
              <button-text
                fragment="who-are-we"
                class="{{ action.isPrimary ? '' : 'mr-2' }}"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- savings-page cards -->
