<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="!isPageLoading"
  class="container-fluid research-insights--hero"
  [style.backgroundImage]="
    !isPageLoading && 'url(' + content?.header?.image + ')'
  "
>
  <div class="container-xl h-100">
    <div class="row d-flex align-content-start justify-content-center h-100">
      <div class="col-md-12 mt-5">
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title equity-research-header white text-shadow"
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-xl research-insights--card-row">
  <div class="row d-flex">
    <div
      class="col-md-12"
      *ngIf="content?.header?.headerBannerContent.length > 0"
    >
      <div class="research-insights-hero-card">
        <h3 class="medium-heading cyan">
          {{ content?.header?.headerBannerContent[0].heading }}
        </h3>
        <p>{{ content?.header?.headerBannerContent[0].headline }}</p>
        <div
          [innerHTML]="content?.header?.headerBannerContent[0].content"
        ></div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid slate-bg-10 py-5 my-5 h-100">
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-8 col-xs-12 mb-4">
        <div class="row mb-3">
          <div class="col-12">
            <h3>{{ content?.body?.sections[0]?.heading }}</h3>
          </div>
        </div>
        <div
          class="row mb-3"
          *ngIf="content?.body?.sections[0].articles?.length > 0"
        >
          <div class="col-12 mb-3">
            <div
              class="card news--card mb-3"
              *ngFor="let article of content?.body?.sections[0].articles"
            >
              <img
                class="card-img-top"
                [src]="article?.image"
                alt="Card image cap"
              />
              <div class="card-body d-flex flex-column">
                <div class="card-tags pb-2">
                  <span
                    *ngFor="let tag of article.categories"
                    role="button"
                    class="basic-badge mb-1"
                    data-toggle="modal"
                    data-target="#modal-article-tags"
                    (click)="DynamicButtonClick(article)"
                    >{{ tag }}</span
                  >
                </div>
                <div
                  class="row mb-3"
                  *ngIf="content?.body?.sections[0].articles?.length > 0"
                >
                  <div class="col-12 mb-3">
                    <div
                      class="card news--card mb-3"
                      *ngFor="
                        let article of content?.body?.sections[0].articles
                      "
                    >
                      <img
                        class="card-img-top"
                        [src]="article?.image"
                        alt="Card image cap"
                      />
                      <div class="card-body d-flex flex-column">
                        <div class="card-tags pb-2">
                          <span
                            *ngFor="let tag of article.categories"
                            role="button"
                            class="basic-badge mb-1"
                            data-toggle="modal"
                            data-target="#modal-article-tags"
                            (click)="OpenArticle(tag)"
                            >{{ tag }}</span
                          >
                        </div>
                        <h5 class="small-heading mb-3">
                          {{ article.headline }}
                        </h5>
                        <p class="body-small" [innerHTML]="article.excerpt"></p>
                        <div class="mt-auto">
                          <a [routerLink]="article?.uri" class="textlink"
                            >Read more</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xs-12 mb-4">
        <div class="row">
          <div class="col-12">
            <h3>{{ content?.body?.sections[1].heading }}</h3>
          </div>
          <div
            class="col-12 mb-3"
            *ngFor="let article of content?.body?.sections[1].cards"
          >
            <div class="card news--card">
              <div class="card-body flex-column">
                <h5 class="small-heading mb-3">
                  {{ article.headline }}
                </h5>
                <p class="body-small" [innerHTML]="article.content"></p>
                <div class="btn-row mt-auto">
                  <button-text
                    *ngFor="let action of article.actions"
                    class="mr-2"
                    [buttonStyle]="action.isPrimary ? 'solid' : 'outline'"
                    [label]="action?.label"
                    (click)="DynamicButtonClick(action)"
                  >
                  </button-text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>{{ content?.body?.sections[2].heading }}</h3>
          </div>
          <div
            class="col-12 mb-3"
            *ngFor="let card of content?.body?.sections[2].cards"
          >
            <div class="card news--card">
              <div class="card-body flex-column">
                <h5 class="small-heading mb-3">
                  {{ card.heading }}
                </h5>
                <p class="body-small" [innerHTML]="card.content"></p>
                <div class="btn-row mt-auto">
                  <button-text
                    *ngFor="let action of card.actions"
                    class="mr-2"
                    [buttonStyle]="action.isPrimary ? 'solid' : 'outline'"
                    [label]="action?.label"
                    (click)="DynamicButtonClick(action)"
                  >
                  </button-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-avoiding-mistakes [content]="avoidingMistakesContent">
</app-avoiding-mistakes>

<app-frequently-asked-questions [faqList]="faqList">
</app-frequently-asked-questions>

<div class="container-xl py-5">
  <div class="col-md-12">
    <app-share-price></app-share-price>
  </div>
</div>
