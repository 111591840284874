<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isPageLoading" class="container-xl mt-4">
  <!-- breadcrumbs -->
  <div class="row">
    <div class="col-md-12">
      <div>
        <ul class="breadcrumb pl-0">
          <li>
            <a href="/financial-advice">Financial advice</a>
          </li>
          <icon-font name="icon-angle-right">
            <span class="icon icon-angle-right"></span>
          </icon-font>
          <li>
            <a href="financial-advice/find-an-adviser">Find an adviser</a>
          </li>
          <icon-font name="icon-angle-right">
            <span class="icon icon-angle-right"></span>
          </icon-font>
          <li class="breadcrumb-active">
            {{ content?.header?.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- /breadcrumbs -->

  <div class="row my-4">
    <div class="col-md-6">
      <div class="row">
        <!--Headings-->
        <div class="col-md-12 mb-4">
          <h2 class="large-heading">
            {{ sanitizeTeamName(branchDetail[0]?.team_name) }}
          </h2>

          <!-- image on mobile here-->
          <div class="mb-3 mobile-advisor-branch-img">
            <img
              class="img-fluid office-img"
              [src]="
                branchDetail[0]?.office_banner != null &&
                branchDetail[0]?.office_banner != ''
                  ? branchDetail[0].office_banner
                  : '../../../../assets/images/advisor-default-branch-office350x350.jpg'
              "
              alt="office"
            />
          </div>

          <div
            *ngIf="
              branchDetail[0]?.team_description != null ||
              branchDetail[0]?.team_description != ''
            "
            [innerHTML]="branchDetail[0]?.team_description"
          >
            API
          </div>
          <div
            *ngIf="
              (branchDetail[0]?.team_description == null ||
                branchDetail[0]?.team_description == '') &&
              (content?.header?.content != '' ||
                content?.header?.content != null ||
                content?.header?.content != undefined)
            "
            [innerHTML]="content?.header?.content"
          >
            Umbraco
          </div>
          <div
            *ngIf="
              (branchDetail[0]?.team_description == '' ||
                branchDetail[0]?.team_description == null) &&
              (content?.header?.content == '' ||
                content?.header?.content == null ||
                content?.header?.content == undefined)
            "
            class="mb-0"
          >
            <p>
              At PSG, our clients are the heart of our business. For us, it’s
              important to get to know you, to understand exactly what you hope
              to achieve and to build a lasting relationship with you to help
              you succeed. We specialise in the private client market, which
              means that you will never take second place to large corporate
              clients. You have access to a full range of financial products and
              services, backed by our commitment to professional, personalised
              advice. This means that we can comfortably tailor your portfolio
              to your exact needs.
            </p>
          </div>
        </div>
        <!--Bookmarks -->
        <div class="col-12">
          <div class="row">
            <!--Primamry Disciplines-->
            <div class="col-md-5">
              <div
                *ngIf="branchDetail[0]?.primary_disciplines.length >= 1"
              ></div>
              <h3 class="medium-heading cyan mb-3">What we offer</h3>
              <div>
                <ul
                  class="no-bullets ul-p-0 slate"
                  *ngFor="
                    let offer of branchDetail[0]?.primary_disciplines | keyvalue
                  "
                >
                  <li
                    *ngIf="offer.value != 'null'"
                    class="p-length-md body-med"
                  >
                    {{ offer.value }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-7 dynamic-text-align">
              <div class="row">
                <div class="mb-3 btn-w col-sm-4 col-md-12">
                  <button-text
                    buttonStyle="solid"
                    label="Contact office"
                    (click)="scrollToContactUs()"
                    style="width: 120px"
                  ></button-text>
                </div>
                <div class="mb-3 btn-w col-sm-4 col-md-12">
                  <button-text
                    buttonStyle="outline"
                    label="Meet our advisers"
                    (click)="scrollToTeam()"
                  >
                  </button-text>
                </div>
                <div
                  class="mb-3 btn-w col-sm-12 col-md-12"
                  *ngIf="
                    articleSection?.articles != null &&
                    articleSection?.articles != undefined
                  "
                >
                  <button-text
                    buttonStyle="outline"
                    label="Latest news"
                    (click)="scrollToLatestNews()"
                  >
                  </button-text>
                </div>
              </div>
            </div>

            <!--Social Media Links-->
            <div class="d-flex mt-3 text-left">
              <button
                class="button simple-icon"
                *ngIf="
                  branchDetail[0]?.team_linkedin != 'null' &&
                  branchDetail[0]?.team_linkedin != ''
                "
              >
                <icon-font
                  name="icon-linkedin"
                  colour="cyan"
                  class="body-large cyan"
                  (click)="navToLink(branchDetail[0]?.team_linkedin)"
                ></icon-font>
              </button>
              <button
                class="button simple-icon"
                *ngIf="
                  branchDetail[0]?.team_twitter != 'null' &&
                  branchDetail[0]?.team_twitter != ''
                "
              >
                <icon-font
                  name="icon-twitter"
                  colour="cyan"
                  class="body-large cyan"
                  (click)="navToLink(branchDetail[0]?.team_twitter)"
                ></icon-font>
              </button>
              <button
                class="button simple-icon"
                *ngIf="
                  branchDetail[0]?.team_facebook != 'null' &&
                  branchDetail[0]?.team_facebook != ''
                "
              >
                <icon-font
                  name="icon-facebook"
                  colour="cyan"
                  class="body-large cyan"
                  (click)="navToLink(branchDetail[0]?.team_facebook)"
                ></icon-font>
              </button>
            </div>
          </div>
          <div class="row mb-4" *ngIf="content?.header?.actions">
            <div class="col btn-w btn-mb">
              <button-text
                *ngFor="let action of content?.header?.actions"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                class="mr-3"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
          <div class="row mb-4" *ngIf="content?.header?.actionsExtra">
            <div class="col btn-w btn-mb">
              <button-text
                *ngFor="let action of content?.header?.actionsExtra"
                buttonStyle="{{
                  action?.buttonColor
                    ? 'solid ' + action?.buttonColor
                    : action.isPrimary
                    ? 'solid'
                    : 'outline'
                }}"
                class="mr-3"
                [label]="action?.label"
                (click)="DynamicButtonClick(action)"
              >
              </button-text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 advisor-branch-img">
      <img
        class="img-fluid office-img"
        [src]="
          branchDetail[0]?.office_banner != null &&
          branchDetail[0]?.office_banner != ''
            ? branchDetail[0].office_banner
            : '../../../../assets/images/advisor-default-branch-office450x450.jpg'
        "
        alt="office"
      />
    </div>
  </div>
</div>

<!-- Meet the team -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid mt-0 py-5 two-colors-bg"
  id="meet-the-team"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-7"></div>
      <div class="col-md-5">
        <div class="heading-border-right">
          <h2 class="mega-title--mobile text-right">Meet the team</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- Principals -->
  <div *ngIf="teamDetails?.principles" class="container-xl mb-5">
    <div class="col-md-5 mb-4 order-0 order-md-1">
      <h2 class="large-heading">Principals</h2>
    </div>
    <div class="row">
      <div
        class="branchTeamContainer"
        *ngFor="let teamMember of teamDetails?.principles; let i = index"
      >
        <div
          class="p-0 m-0 hover--card"
          [routerLink]="[
            '/financial-advice/adviser-profile/' + teamMember.person_id
          ]"
        >
          <div class="m-2">
            <img
              *ngIf="teamMember['photo_decoded'] != undefined"
              [src]="teamMember['photo_decoded']"
              alt="Avatar"
              class="team-hoverCard-bg-img adviser-b-w"
            />
            <img
              *ngIf="teamMember['photo_decoded'] == undefined"
              src="../../../../assets/images/adviser-profile.svg"
              alt="Loader"
              class="team-hoverCard-bg-img adviser-b-w"
            />
            <section class="front-card m-0">
              <h4 class="white mb-2">
                {{ teamMember.person_name }} {{ teamMember.person_surname }}
              </h4>
              <p class="body-small body-bold white mb-1">
                {{ sanitizeTeamName(teamMember.person_jobtitle) }}
              </p>
            </section>
            <section class="back-card">
              <h4 class="mb-2 white">
                {{ teamMember.person_name }} {{ teamMember.person_surname }}
              </h4>
              <p class="body-small body-bold white mb-1">
                {{ sanitizeTeamName(teamMember.person_jobtitle) }}
              </p>

              <h6 class="mb-2 white mt-1 body-bold">Contact Details:</h6>
              <a
                class="body-small body-bold cyan mb-1"
                href="tel:{{ '+' + branchDetail[0]?.team_phone }}"
                *ngIf="
                  branchDetail[0]?.team_phone != 'null' &&
                  branchDetail[0]?.team_phone != ''
                "
              >
                +{{ branchDetail[0]?.team_phone }}</a
              >
              <a
                [href]="'mailto:' + teamMember.person_emailexch"
                class="body-small body-bold white mb-1"
                *ngIf="
                  teamMember.person_emailexch != 'null' &&
                  teamMember.person_emailexch != ''
                "
              >
                {{ teamMember.person_emailexch }}
              </a>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Principals -->

  <!-- Advisors -->
  <div *ngIf="teamDetails?.advisors" class="container-xl mb-5">
    <div class="col-md-5 mb-4 order-0 order-md-1">
      <h2 class="large-heading">Advisers</h2>
    </div>
    <div class="row">
      <div
        class="branchTeamContainer"
        *ngFor="let teamMember of teamDetails?.advisors; let i = index"
      >
        <div
          class="p-0 m-0 hover--card"
          [routerLink]="[
            '/financial-advice/adviser-profile/' + teamMember.person_id
          ]"
        >
          <div class="m-2 mb-0">
            <img
              *ngIf="teamMember['photo_decoded'] != undefined"
              [src]="teamMember['photo_decoded']"
              alt="Avatar"
              class="team-hoverCard-bg-img adviser-b-w"
            />
            <img
              *ngIf="teamMember['photo_decoded'] == undefined"
              src="../../../../assets/images/adviser-profile.svg"
              alt="Loader"
              class="team-hoverCard-bg-img adviser-b-w"
            />
            <section class="front-card m-0">
              <h4 class="white m-2">
                {{ teamMember.person_name }} {{ teamMember.person_surname }}
              </h4>
              <p class="body-small body-bold white mb-1 ml-2">
                {{ sanitizeTeamName(teamMember.person_jobtitle) }}
              </p>
            </section>
            <section class="back-card">
              <h4 class="mb-2 white">
                {{ teamMember.person_name }} {{ teamMember.person_surname }}
              </h4>
              <p class="body-small body-bold white mb-1">
                {{ sanitizeTeamName(teamMember.person_jobtitle) }}
              </p>

              <h6 class="mb-2 white mt-1 body-bold">Contact Details:</h6>
              <a
                class="body-small body-bold cyan mb-1"
                href="tel:{{ '+' + branchDetail[0]?.team_phone }}"
                *ngIf="
                  branchDetail[0]?.team_phone != 'null' &&
                  branchDetail[0]?.team_phone != ''
                "
              >
                +{{ branchDetail[0]?.team_phone }}</a
              >
              <a
                [href]="'mailto:' + teamMember.person_emailexch"
                class="body-small body-bold white mb-1"
                *ngIf="
                  teamMember.person_emailexch != 'null' &&
                  teamMember.person_emailexch != ''
                "
              >
                {{ teamMember.person_emailexch }}
              </a>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Advisors -->

  <!-- Support Staff -->
  <div *ngIf="teamDetails?.support" class="container-xl mb-5">
    <div class="col-md-5 mb-4 order-0 order-md-1">
      <h2 class="large-heading">Support Staff</h2>
    </div>
    <div class="row">
      <div
        class="branchTeamContainer"
        *ngFor="let teamMember of teamDetails?.support; let i = index"
      >
        <div class="p-0 m-0 hover--card">
          <div class="m-2">
            <img
              *ngIf="teamMember['photo_decoded'] != undefined"
              [src]="teamMember['photo_decoded']"
              alt="Avatar"
              class="team-hoverCard-bg-img adviser-b-w"
            />
            <img
              *ngIf="teamMember['photo_decoded'] == undefined"
              src="../../../../assets/images/adviser-profile.svg"
              alt="Loader"
              class="team-hoverCard-bg-img adviser-b-w"
            />
            <section class="front-card m-0">
              <h4 class="white mb-2">
                {{ teamMember.person_name }} {{ teamMember.person_surname }}
              </h4>
              <p class="body-small body-bold white">
                {{ sanitizeTeamName(teamMember.person_jobtitle) }}
              </p>
            </section>
            <section class="back-card">
              <h4 class="mb-2 white">
                {{ teamMember.person_name }} {{ teamMember.person_surname }}
              </h4>
              <p class="body-small body-bold white mb-1">
                {{ sanitizeTeamName(teamMember.person_jobtitle) }}
              </p>
              <h6 class="mb-2 white mt-1 body-bold">Contact Details:</h6>
              <a
                class="body-small body-bold cyan mb-1"
                href="tel:{{ '+' + branchDetail[0]?.team_phone }}"
                *ngIf="
                  branchDetail[0]?.team_phone != 'null' &&
                  branchDetail[0]?.team_phone != ''
                "
              >
                +{{ branchDetail[0]?.team_phone }}</a
              >
              <a
                [href]="'mailto:' + branchDetail[0]?.team_email"
                class="body-small body-bold white mb-1"
                *ngIf="branchDetail[0]?.team_email != null"
              >
                {{ branchDetail[0]?.team_email }}</a
              >
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Support Staff -->
</div>
<!-- /Meet the team -->

<!-- Latest news section -->
<div
  class="container-fluid bacground-two-colors py-5"
  id="latest-news"
  *ngIf="articleSection != null && articleSection && !isPageLoading"
>
  <div class="container-xl">
    <div>
      <h2 class="mb-4 text-right heading-left">{{ articleSection.heading }}</h2>
    </div>
    <div class="container-fluid">
      <div id="carouselControls3" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner" style="overflow: unset">
          <div class="carousel-item active">
            <div class="row">
              <div
                class="col-md-3 d-flex flex-fill multi"
                *ngFor="
                  let item of articleDataShown;
                  let index = index;
                  let last = last
                "
              >
                <div class="card news--card mb-4 d-flex">
                  <img
                    class="card-img-top"
                    [src]="item?.image"
                    alt="Card image cap"
                  />
                  <div class="card-body h-100">
                    <div class="card-tags pb-2">
                      <span
                        *ngFor="let category of item?.categories"
                        role="button"
                        class="basic-badge"
                        data-toggle="modal"
                        data-target="#modal-article-tags"
                        (click)="
                          pageService.SetArticle(category);
                          $event.preventDefault()
                        "
                        >{{ category }}</span
                      >
                    </div>
                    <h5 class="small-heading">{{ item?.headline }}</h5>
                    <p class="body-small" [innerHTML]="item?.excerpt"></p>
                    <a [routerLink]="item?.uri" class="textlink">Read more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="control-buttons">
          <button
            class="button outline-icon"
            href="#carouselControls3"
            role="button"
            [hidden]="!isPrevButton"
            (click)="previous()"
          >
            <icon-font name="icon-angle-left"></icon-font>
            <span class="sr-only">Previous</span>
          </button>
          <button
            class="button outline-icon ml-2"
            href="#carouselControls3"
            role="button"
            [hidden]="!isNextButton"
            (click)="next()"
          >
            <icon-font name="icon-angle-right"></icon-font>
            <span class="sr-only">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /Latest news section -->

<!-- contact office -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid svg-bg-l contact--us"
  id="contact-us"
>
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left">
          <h2 class="section-title--mobile">Contact our office</h2>
        </div>
      </div>
    </div>
    <div class="row align-items-start justify-content-center mt-4">
      <form class="col-md-5 mb-4 order-2 order-md-0" [formGroup]="requestForm">
        <div>
          <form-field>
            <label class="required">Name</label>
            <input placeholder="Name" formControlName="name" />
          </form-field>

          <form-field>
            <label>Surname</label>
            <input placeholder="Surname" formControlName="surname" />
          </form-field>

          <form-field>
            <label [class.required]="isEmailAddressRequired">Email</label>
            <input placeholder="Email" formControlName="emailAddress" />
          </form-field>

          <form-field>
            <label [class.required]="isContactNumberRequired" class="required"
              >Contact number</label
            >
            <input
              placeholder="Contact number"
              formControlName="contactNumber"
            />
          </form-field>

          <form-field>
            <label>Message</label>
            <textarea
              placeholder="Message"
              formControlName="message"
            ></textarea>
          </form-field>
          <div class="mt-4">
            <button-text
              buttonStyle="solid"
              label="Send Request"
              fill="true"
              [disabled]="requestForm.invalid"
              (click)="sendRequest()"
            >
            </button-text>
          </div>
        </div>
      </form>

      <div class="col-md-6 py-3 my-3 offset-1">
        <div
          class="d-flex flex-row mb-3 justify-content-start align-items-stretch"
        >
          <div class="order-0 order-md-1 col-6">
            <h4>Contact Details</h4>
            <div>
              <a
                class="mb-0 cyan"
                href="tel:{{ '+' + branchDetail[0]?.team_phone }}"
                *ngIf="
                  branchDetail[0]?.team_phone != 'null' &&
                  branchDetail[0]?.team_phone != ''
                "
              >
                <i class="fas fa-phone"></i> +{{
                  branchDetail[0]?.team_phone
                }}</a
              >
            </div>
            <div>
              <p
                class="mb-0"
                *ngIf="
                  branchDetail[0]?.fax_number != 'null' &&
                  branchDetail[0]?.fax_number != ''
                "
              >
                <i class="fas fa-fax"></i> +{{ branchDetail[0]?.fax_number }}
              </p>
            </div>
            <div>
              <a
                [href]="'mailto:' + branchDetail[0]?.team_email"
                *ngIf="
                  branchDetail[0]?.team_email != 'null' &&
                  branchDetail[0]?.team_email != ''
                "
              >
                <i class="far fa-envelope"></i>
                {{ branchDetail[0]?.team_email }}</a
              >
            </div>
          </div>

          <div
            class="order-0 order-md-2 ml-5 ml-sm-4 col-6"
            *ngIf="
              branchDetail[0]?.team_location != 'null' &&
              branchDetail[0]?.team_location != ''
            "
          >
            <h4>Physical Address</h4>
            <p
              class="m-0"
              *ngIf="
                this.formattedBranchAddress != undefined &&
                this.formattedBranchAddress.AddressLine1 != ''
              "
            >
              {{ this.formattedBranchAddress?.AddressLine1 }}
            </p>
            <p
              class="m-0"
              *ngIf="
                this.formattedBranchAddress != undefined &&
                this.formattedBranchAddress.AddressLine2 != ''
              "
            >
              {{ this.formattedBranchAddress?.AddressLine2 }}
            </p>
            <p
              class="m-0"
              *ngIf="
                this.formattedBranchAddress != undefined &&
                this.formattedBranchAddress.AddressLine3 != ''
              "
            >
              {{ this.formattedBranchAddress?.AddressLine3 }}
            </p>
            <p
              class="m-0"
              *ngIf="
                this.formattedBranchAddress != undefined &&
                this.formattedBranchAddress.AddressLine4 != ''
              "
            >
              {{ this.formattedBranchAddress?.AddressLine4 }}
            </p>
            <p
              class="m-0"
              *ngIf="
                this.formattedBranchAddress != undefined &&
                this.formattedBranchAddress.AddressLine5 != ''
              "
            >
              {{ this.formattedBranchAddress?.AddressLine5 }}
            </p>
            <p
              class="m-0"
              *ngIf="
                this.formattedBranchAddress != undefined &&
                this.formattedBranchAddress.AddressLine6 != ''
              "
            >
              {{ this.formattedBranchAddress?.AddressLine6 }}
            </p>
            <p
              class="m-0"
              *ngIf="
                this.formattedBranchAddress != undefined &&
                this.formattedBranchAddress.AddressLine7 != ''
              "
            >
              {{ this.formattedBranchAddress?.AddressLine7 }}
            </p>
            <p
              class="m-0"
              *ngIf="
                this.formattedBranchAddress != undefined &&
                this.formattedBranchAddress.AddressLine8 != ''
              "
            >
              {{ this.formattedBranchAddress?.AddressLine8 }}
            </p>
          </div>
        </div>
        <div class="col-md-12 order-0 order-md-3">
          <div style="width: 100%">
            <div
              class="d-flex flex-row col-12 justify-content-center mb-5 align-items-center map-iframe"
              *ngIf="!isMapLoaded && this.lat == 0 && this.lng == 0"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              class="d-flex flex-row col-12 justify-content-center mb-5 align-items-center"
              *ngIf="isMapLoaded && this.lat != 0 && this.lng != 0"
            >
              <agm-map
                class="map-iframe"
                [latitude]="lat"
                [longitude]="lng"
                [zoom]="zoom"
                [disableDefaultUI]="true"
                [zoomControl]="true"
                (mapReady)="mapLoaded($event)"
              >
                <div id="placecard" class="placecard-container row">
                  <div class="col-8">
                    <h6>{{ lat }}, {{ lng }}</h6>
                    <p>{{ inlineFormattedBranchAddress }}</p>
                    <a target="_blank" [href]="redirectToGoogleMapsUrl"
                      >View larger map</a
                    >
                  </div>
                  <div class="col-4">
                    <a
                      class="direction-link text-center"
                      target="_blank"
                      [href]="officeDirectionUrl"
                    >
                      <div class="direction-icon"></div>
                      Directions
                    </a>
                  </div>
                </div>

                <agm-marker [latitude]="lat" [longitude]="lng">
                  <agm-info-window>
                    <strong>{{ branchDetail[0]?.team_name }}</strong>
                  </agm-info-window>
                </agm-marker>
              </agm-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
