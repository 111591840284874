<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- PSG Asset Management hero section -->
<div *ngIf="content?.header !== undefined && !isPageLoading">
  <div
    class="container-fluid psg-asset-management mb-5"
    id="who-are-we"
    [style.backgroundImage]="
      !isPageLoading && 'url(' + content?.header?.image + ')'
    "
  >
    <div class="container-xl h-100 mb-2">
      <div
        class="row d-flex flex-column align-content-start justify-content-md-center h-100"
      >
        <div *ngIf="!isPageLoading" class="heading-border-left">
          <h1
            class="mega-title--page-title assetM text-shadow"
            [ngClass]="
              content?.header?.headingColour
                ? content?.header?.headingColour
                : 'dark-black'
            "
          >
            {{ content?.header?.heading }}
          </h1>
        </div>
        <div class="pl-4 pl-md-4">
          <div
            [innerHTML]="content?.header?.cards[0]?.content"
            [ngClass]="
              content?.header?.contentColour
                ? content?.header?.contentColour
                : 'white'
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--/PSG Asset Management hero section -->
<div *ngIf="!isPageLoading" class="container-xl" style="margin-top: -9rem">
  <div class="row d-flex" *ngIf="content?.header?.cards !== undefined">
    <div class="col-md-7 mb-4">
      <div class="blur-card" *ngIf="content?.header?.cards !== undefined">
        <div>
          <h2 class="large-heading mb-4">
            {{ content?.header?.cards[2]?.heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[2]?.content"></div>
        </div>
        <div>
          <ng-container
            *ngFor="let action of content?.header?.cards[2]?.actions"
          >
            <button-text
              class="mr-2"
              buttonStyle="{{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="blur-card" *ngIf="content?.header?.cards !== undefined">
        <div>
          <h2 class="large-heading">
            {{ content?.header?.cards[1]?.heading }}
          </h2>
          <div [innerHTML]="content?.header?.cards[1]?.content"></div>
        </div>
        <div>
          <ng-container
            *ngFor="let action of content?.header?.cards[1]?.actions"
          >
            <button-text
              class="mr-2"
              buttonStyle="mb-2 {{
                action?.buttonColor
                  ? 'solid ' + action?.buttonColor
                  : action.isPrimary
                  ? 'solid'
                  : 'outline'
              }}"
              [label]="action?.label"
              (click)="DynamicButtonClick(action)"
            >
            </button-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!--/our-process&our-funds -->

<div id="philosophy-and-process"></div>
<!-- philosophy&process -->
<div *ngIf="!isPageLoading" class="container-fluid mt-5 pb-5 greybg-left h-100">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-12">
        <div class="heading-border-left transformY-25">
          <h2 class="mega-title--mobile">
            {{ content?.body?.sections[0]?.heading }}
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <img
          class="img-fluid pr-md-5"
          [src]="content?.body?.sections[0]?.image"
        />
      </div>
      <div class="col-md-6">
        <div
          [innerHTML]="content?.body?.sections[0]?.sections[0]?.content"
        ></div>
      </div>
    </div>
  </div>
</div>
<!-- philosophy&process -->

<!-- Meet the Asset Management team section -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid py-5 my-5 greybg-right-small h-100"
  id="meet-the-team"
>
  <div class="container-xl">
    <div class="row justify-content-end">
      <div class="col-md-10">
        <div class="heading-border-right d-flex justify-content-end">
          <h2 class="mega-title--mobile text-right">
            {{ content?.body?.sections[1]?.heading }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <!-- team images commitee -->
  <div *ngIf="!isPageLoading" class="container-xl mt-4 mb-4">
    <div class="row">
      <div
        class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-4"
        *ngFor="
          let manager of content?.body?.sections[1]?.members;
          let i = index
        "
      >
        <!-- team member card -->
        <div class="p-4 p-md-0">
          <div
            data-toggle="modal"
            data-target="#modal-standard-sm"
            class="team-flipCard-bg-img"
            [style.backgroundImage]="'url(' + manager?.image + ')'"
            (click)="openTeamMemberModal(manager)"
          >
            <div class="card-black-layer">
              <h5 class="white mb-2">{{ manager?.name }}</h5>
            </div>
          </div>
        </div>
        <!-- team member card -->
      </div>
    </div>
  </div>
  <!-- /team images -->
</div>
<!--/  Meet the Asset Management team section -->

<!-- quote -->
<div *ngIf="!isPageLoading" class="container-xl py-5">
  <div class="row justify-content-around">
    <div class="col-md-11">
      <blockquote class="blockquote xlarge text-center">
        <span class="cyan">“</span>{{ content?.body?.sections[2]?.content
        }}<span class="cyan">”</span>
      </blockquote>
    </div>
  </div>
</div>
<!-- /quote -->

<!-- Recommended/Latest news-cards section -->
<div *ngIf="!isPageLoading" class="container-fluid py-5 slate-bg-10">
  <div class="container-xl">
    <div>
      <h2 class="mb-4">{{ content?.body?.sections[3]?.heading }}</h2>
    </div>
    <div class="card-deck">
      <div
        class="card news--card"
        *ngFor="let news of content?.body?.sections[3]?.articles"
      >
        <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
        <div class="card-body">
          <div class="card-tags pb-2">
            <span
              *ngFor="let category of news?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div>
          <h5 class="small-heading">{{ news?.headline }}</h5>
          <p class="body-small" [innerHTML]="news?.excerpt"></p>
          <a [routerLink]="news?.uri" class="textlink">Read more</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Recommended/Latest news-cards section -->

<!-- team memmber's modal -->
<div
  class="modal fade"
  id="modal-standard-sm"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <!-- sizing  modal-sm/md/lg/xl -->
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <!-- modal container -->
    <div class="modal-content">
      <!-- modal header expanded-->
      <div class="modal-header expanded">
        <h5 class="modal-heading"></h5>
      </div>
      <!-- / modal header -->

      <!-- modal body expanded-->
      <div class="modal-body expanded">
        <div class="row">
          <div class="col-md-4">
            <div
              class="img-size"
              [style.backgroundImage]="'url(' + teamMember?.image + ')'"
            ></div>
            <!--title -->
            <h4 class="mt-3 mb-0">{{ teamMember?.name }}</h4>
          </div>

          <div class="col-md-8">
            <!--content -->
            <p class="body-med mb-0" [innerHTML]="teamMember?.content"></p>
            <!-- member qualifications list-->
            <h5
              class="cyan"
              *ngIf="
                teamMember?.qualifications != null &&
                teamMember?.qualifications != ''
              "
            >
              Qualifications
            </h5>
            <p class="body-med" [innerHTML]="teamMember?.qualifications"></p>
          </div>
        </div>
      </div>
      <!-- / modal body -->

      <!-- modal footer expanded-->
      <div class="modal-footer expanded">
        <button
          id="closeModal"
          type="button"
          class="button outline"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
      <!-- / modal footer -->
    </div>
    <!-- / modal container -->
  </div>
</div>
<!--/ team memmber's modal -->
